import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import {
    selectedPrescripteursSelector,
    SousClient,
    SousClientApi,
    SousClientsSelector,
    useTiersService,
    useTiersServiceSelector,
} from '@europrocurement/l2d-domain';
import { ChoiceItemProps, ChoiceList } from '@europrocurement/flexy-components';
import {
    AdresseJsonldTiersRead,
    TiersSousclientJsonldSousclientCreated,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { useDebouncedCallback } from 'use-debounce';
import AddPrescriberClientButton, {
    AddPrescriberClientModal,
} from '../../PrescriberClient/components/AddPrescriberClient';

export type SelectSocieteProps = {
    onSelect?: (ids: number[], sousClients: SousClientApi[]) => void;
    multiple?: boolean;
};

const addressToString = (address: AdresseJsonldTiersRead) => {
    let addresseFact = '';
    if (address.adresseApi !== null && address.adresseApi !== '') {
        addresseFact = address.adresseApi ? address.adresseApi : '';
    } else {
        addresseFact = [
            address.adresse1,
            address.adresse2,
            address.adresse3,
            address.adresse4,
            address.codePostal,
            address.ville,
        ]
            .filter(Boolean)
            .join(' ');
    }
    return addresseFact;
};
const selectAddressFacturante = (addresses: AdresseJsonldTiersRead[]) =>
    addresses.filter((adresse) => adresse.facturation === true);

const SelectSociete: React.FunctionComponent<SelectSocieteProps> = function ({
    onSelect,
    multiple = false,
}) {
    const datasource = useTiersServiceSelector(SousClientsSelector).main;
    const dispatch = useDispatch();
    const [sousClients, setSousClients] = useState<Array<SousClient>>([]);
    const prescripteur = useTiersServiceSelector(selectedPrescripteursSelector);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [listFiltered, setListFiltered] = useState<Array<ChoiceItemProps>>([]); // liste filtré par la fonction search
    const [listPrescripteur, setListPrescripteur] = useState<Array<ChoiceItemProps>>([]); // Liste de société du prescripteur
    const [societeSelected, setSocieteSelected] = useState<Array<string>>([]); // tableau d'id sélectionné

    const { getSousClients } = useTiersService();

    if (!prescripteur || prescripteur.id === undefined) {
        throw new Error('no prescriber');
    }

    // suppression de la recherche à l'entrée du formulaire seulement
    useEffect(() => {
        dispatch({
            type: 'sousClient/resetmainSearch',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (datasource.status === 'succeeded') {
            setSousClients(datasource.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasource.status]);

    // va rechercher au changement de societeselected
    useEffect(() => {
        dispatch({
            type: 'sousClient/setmainFilter',
            payload: {
                key: 'orSousClientId',
                value: societeSelected,
            },
        });
    }, [dispatch, societeSelected]);

    // initialisation liste Prescripteur/Societé
    useEffect(() => {
        if (!prescripteur || !prescripteur.id) {
            return;
        }
        dispatch({
            type: 'sousClient/setmainFilter',
            payload: {
                key: 'prescripteurId',
                value: prescripteur.id,
            },
        });
        dispatch(getSousClients({}) as unknown as AnyAction);
        const prescripteurPrincipal: ChoiceItemProps = {
            label: prescripteur.raisonSociale,
            value: prescripteur.id?.toString(),
            siren: prescripteur.siren,
        };
        const address = selectAddressFacturante(prescripteur.adresses);
        if (address.length > 0) {
            Object.assign(prescripteurPrincipal, { description: addressToString(address[0]) });
        }
        setListPrescripteur([prescripteurPrincipal]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // MAJ sousclient en fonction du datasource etc...
    useEffect(() => {
        if (datasource.status === 'succeeded') {
            const listSC: ChoiceItemProps[] = [];
            sousClients.forEach((sclient) => {
                if (sclient.id === undefined) {
                    return;
                }
                const obj = {
                    label: sclient.raisonSociale,
                    value: sclient.id.toString(),
                };
                if (sclient.siren !== '') {
                    Object.assign(obj, { siren: sclient.siren });
                }
                const address = selectAddressFacturante(sclient.adresses);
                if (address.length > 0) {
                    Object.assign(obj, { description: addressToString(address[0]) });
                }

                listSC.push(obj);
            });
            setListFiltered(listSC);
        }
    }, [datasource, sousClients]);

    const onSubmitWrapper = useCallback(
        (data: { idsSociete: Array<string> }) => {
            const arrayIds = data.idsSociete;
            if (onSelect) {
                onSelect(
                    arrayIds.map((str: string) => Number(str)),
                    [...sousClients, prescripteur].filter(
                        (sousClient) => arrayIds.indexOf(`${sousClient.id}`) !== -1,
                    ),
                );
            }
        },
        [onSelect, sousClients, prescripteur],
    );

    // APPEL OPENSEARCH au changement du filtre
    const onSearchWrapper = useDebouncedCallback((currentSearchString: string) => {
        if (currentSearchString !== '') {
            dispatch({
                type: 'sousClient/setmainSearch',
                payload: {
                    search: currentSearchString,
                },
            });
            dispatch(getSousClients({}) as unknown as AnyAction);
        } else {
            dispatch({
                type: 'sousClient/resetmainSearch',
            });
            dispatch(getSousClients({}) as unknown as AnyAction);
        }
    }, 500);

    const onChangeWrapper = (arraySelected: string[]) => {
        setSocieteSelected(arraySelected);
    };

    const handleSuccess = (sousClient: TiersSousclientJsonldSousclientCreated) => {
        setModalOpen(false);
        // attente 1 seconde pour récupération du sous-client
        setTimeout(() => {
            dispatch({
                type: 'sousClient/setmainPagination',
                payload: {
                    itemsPerPage: 500,
                    page: 1,
                },
            });
            dispatch({
                type: 'sousClient/setmainFilter',
                payload: {
                    key: 'prescripteurId',
                    value: prescripteur.id,
                },
            });
            dispatch(getSousClients({}) as unknown as AnyAction);
        }, 1000);
        if (sousClient.id !== undefined) {
            setSocieteSelected((currentUpdatedValues) => {
                const value = sousClient.id.toString();
                let updatedValues: Array<string>;
                if (currentUpdatedValues.includes(value)) {
                    updatedValues = currentUpdatedValues.filter((v) => v !== value); // Déselectionne si déjà sélectionné
                } else if (multiple) {
                    updatedValues = [...currentUpdatedValues, value]; // ajout multiple
                } else {
                    updatedValues = [value]; // ajout simple
                }
                return updatedValues;
            });
        }
    };

    return (
        <>
            <ChoiceList
                title="Pour quelle(s) société(s) est destinée la commande ?"
                description="Sélectionnez plusieurs structures pour créer un dossier Multi-structures avec des données de formalité identiques et une facturation unique."
                submitButtonLabel="Continuer"
                placeholderLabelSearch="Nom ou Siren de la société concernée"
                listChoice={[
                    {
                        label: 'Ma société',
                        listChoice: listPrescripteur,
                        multiple,
                    },
                    {
                        label: 'Mes clients',
                        listChoice: listFiltered,
                        multiple,
                        topSelected: true,
                    },
                ]}
                multiple={multiple}
                onSubmit={onSubmitWrapper}
                search={onSearchWrapper}
                onChange={onChangeWrapper}
                componentAdd={<AddPrescriberClientButton onClick={() => setModalOpen(true)} />}
                defaultValues={societeSelected}
            />
            <AddPrescriberClientModal
                open={isModalOpen}
                onSuccess={handleSuccess}
                onCancel={() => {
                    setModalOpen(false);
                    console.log('onCancel');
                }}
            />
        </>
    );
};

export default SelectSociete;
