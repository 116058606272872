import React, { useEffect } from 'react';
import { FaOptionIcon, plusIcon } from '@europrocurement/l2d-icons';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import FlexyTextField from '../../atoms/FlexyTextField';
import { ObjectGroupProps, ObjectGroupType } from '../../atoms/ObjectGroup';
import { ObjectGroupList } from '../../molecules';
import './ObjectGroupsModal.css';

export type ObjectGroupsModalProps = {
    title: string;
    createButtonLabel: string;
    submitButtonLabel: string;
    placeholderLabel?: string;
    objectGroups: Array<ObjectGroupType>;
    loading?: boolean;
    multiple?: boolean;
    enableSubmit?: boolean;
    selectedIds?: Array<number>;
    onCreate?: () => void;
    onSubmit: (ObjectGroup?: ObjectGroupType | Array<ObjectGroupType>) => void;
    onClick?: (objectGroup: ObjectGroupType) => void;
    search: (string: string) => void;
};
const ObjectGroupsModal: React.FunctionComponent<ObjectGroupsModalProps> = function ({
    title,
    createButtonLabel,
    submitButtonLabel,
    placeholderLabel,
    objectGroups,
    loading = false,
    multiple = false,
    enableSubmit = true,
    selectedIds,
    onCreate,
    onSubmit,
    onClick,
    search,
}) {
    const [searchValue, setSearchValue] = React.useState<string>('');
    const [selected, setSelected] = React.useState<Array<ObjectGroupType>>([]);

    useEffect(() => {
        if (undefined === selectedIds) {
            return;
        }

        const tmp: Array<ObjectGroupType> = objectGroups.filter((objectGroup) =>
            selectedIds.includes(objectGroup.id),
        );

        setSelected(tmp);
    }, [objectGroups, selectedIds]);

    const searchWrapper: React.ChangeEventHandler<HTMLInputElement> = (searchString) => {
        search(searchString.target.value);
        setSearchValue(searchString.target.value);
    };

    const onCreateWrapper = () => {
        if (onCreate) {
            onCreate();
        }
    };

    const onClickWrapper: ObjectGroupProps['onClick'] = (objectGroup) => {
        const tmp = [...selected];
        const hasItem = tmp.find((item) => item.id === objectGroup.id) !== undefined;

        // // condition pour déselectionner
        if (multiple) {
            if (hasItem) {
                const idx = tmp.findIndex((item) => item.id === objectGroup.id);
                tmp.splice(idx, 1);
            } else {
                tmp.push(objectGroup);
            }

            setSelected(tmp);
        } else if (hasItem) {
            setSelected([]);
        } else {
            setSelected([objectGroup]);
        }

        if (onClick) {
            onClick(objectGroup);
        }
    };

    const onSubmitWrapper = () => {
        if (multiple) {
            onSubmit(selected);
        } else {
            onSubmit(selected.find(() => true));
        }
    };

    return (
        <Box className="groupment-modal">
            <Box className="body">
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Box className="title">{title}</Box>
                    <Box sx={{ width: '100%', borderRadius: '10px' }}>
                        <FlexyTextField
                            className="search"
                            variant="outlined"
                            value={searchValue}
                            onChange={searchWrapper}
                            placeholder={placeholderLabel || 'Rechercher'}
                            sx={{ width: '100%' }}
                            inputMode="search"
                            InputProps={{ sx: { borderRadius: '10px' } }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    {!loading && (
                        <ObjectGroupList
                            selected={selected.map((item) => item.id)}
                            objectGroups={objectGroups}
                            onClick={onClickWrapper}
                        />
                    )}
                    {loading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px 0 0' }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </Box>
            <Box className="actions">
                <Button
                    className="plus-btn"
                    variant="text"
                    onClick={onCreateWrapper}
                    sx={{
                        color: 'primary.dark',
                    }}
                >
                    <FaOptionIcon
                        {...plusIcon.props}
                        sx={{ lineHeight: '0' }}
                    />
                    <Typography className="plus-label">{createButtonLabel}</Typography>
                </Button>
                <Button
                    type="submit"
                    className="submit-btn"
                    variant="contained"
                    onClick={onSubmitWrapper}
                    disabled={!enableSubmit}
                    sx={{ backgroundColor: 'primary.dark' }}
                >
                    {submitButtonLabel || 'Déplacer'}
                </Button>
            </Box>
        </Box>
    );
};

export default ObjectGroupsModal;
