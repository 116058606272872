import React, { useEffect, useState, useContext } from 'react';
import {
    ColumnDatatable,
    FiltersDatatableList,
    PreFilterDatatableList,
    RowSelectionAction,
} from '@europrocurement/flexy-datatable';
import { DossierOpenSearch } from '@europrocurement/l2d-domain';
import {
    DossierActionContext,
    DossierFormaliste,
    DossierPrescriber,
    useDossierTable as useDefaultDossierTable,
    useDossierFormaliste,
    useDossierStatut,
} from '@europrocurement/l2d-modules';
import { useUtilisateur } from '@b2d/hooks';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import ActionMenu from '../views/liste/ActionMenu/ActionMenu';

const useDossierTable = () => {
    const { columns } = useDefaultDossierTable();
    const keycloakService = UseKeycloakService();
    const { getUserId } = useUtilisateur();
    const { searchDossierStatut } = useDossierStatut();
    const { searchDossierFormaliste } = useDossierFormaliste();
    const [preFilters, setPreFilters] = useState<PreFilterDatatableList>();
    const [userId, setUserId] = useState<number>();

    useEffect(() => {
        getUserId()
            .then((id) => setUserId(id))
            .catch(() => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const prescriberColumn: ColumnDatatable<DossierOpenSearch> = {
        label: 'Prescripteur',
        render: ({ prescripteurLibelle }) => (
            <DossierPrescriber label={prescripteurLibelle as string} />
        ),
        isDisplayed: true,
        sortable: true,
        field: 'prescripteurLibelle',
    };
    const { triggerAction } = useContext(DossierActionContext);

    columns.push({
        label: 'Actions',
        displayLabel: false,
        render: (dossier) => <ActionMenu dossier={dossier} />,
        isDisplayed: true,
        sortable: false,
        sx: {
            display: {
                xs: 'none',
                sm: 'none',
                md: 'table-cell',
                lg: 'table-cell',
                xl: 'table-cell',
            },
        },
    });
    columns.splice(2, 0, prescriberColumn);

    const filters: FiltersDatatableList = [
        {
            label: 'En attente de pièces',
            field: 'hasMissingRequiredDocuments',
            icon: 'hourglass',
            type: 'boolean',
        },
    ];

    if (keycloakService.checkRole('realm:responsable formaliste')) {
        filters.push({
            label: 'Formaliste',
            field: 'formalisteId',
            icon: 'circle-user',
            type: 'selectApi',
            searchFunction: searchDossierFormaliste,
            renderItem: (item: DossierFormaliste) => item.nomComplet as string,
            valueMap: (item: DossierFormaliste) => item.id,
        });

        if (userId) {
            filters.push({
                label: 'Voir les dossiers de mon équipe',
                field: 'managerId',
                icon: 'people-group',
                type: 'boolean',
                valueMap: (v) => (v === true ? userId : null),
            });
        }
    }

    const rowActions: RowSelectionAction[] = [
        {
            icon: 'fa-folders',
            label: 'Déplacer dans un groupe',
            onClick: (items: Array<DossierOpenSearch>) => triggerAction('manageGroups', items),
        },
        {
            icon: 'fa-paper-plane',
            label: 'Envoyer un récapitulatif',
            onClick: (items: Array<DossierOpenSearch>) => triggerAction('export', items),
        },
    ];

    useEffect(() => {
        searchDossierStatut().then((statuses) => {
            const pf: PreFilterDatatableList = {
                label: 'Filtrer par statuts',
                items: [
                    {
                        label: 'Tout',
                        filters: [],
                    },
                    {
                        label: 'Urgent',
                        filters: [{ field: 'critical', value: true }],
                    },
                ],
            };

            statuses.forEach((status) => {
                pf.items.push({
                    label: status.libelle as string,
                    filters: [{ field: 'statutId', value: status.id as unknown as string }],
                });
            });

            setPreFilters(pf);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { columns, filters, preFilters, rowActions };
};

export default useDossierTable;
