import { ObjectGroupsModal, ObjectGroupType } from '@europrocurement/flexy-components';
import React, { useEffect, useState } from 'react';
import useDossierGroup from '../../../hooks/useDossierGroup';

let searchTimeout: number | undefined;

export type DossierSearchGroupProps = {
    selectedItems: Array<ObjectGroupType>;
    allowSubmit: boolean;
    onSubmit: (data: ObjectGroupType) => void;
    onCreate: () => void;
};

const DossierSearchGroup: React.FunctionComponent<DossierSearchGroupProps> = function (props) {
    const { selectedItems, allowSubmit, onSubmit: handleSubmit, onCreate: handleCreate } = props;
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [objectGroups, setObjectGroups] = useState<Array<ObjectGroupType>>([]);
    const { getDossierGroups } = useDossierGroup();

    useEffect(() => {
        setLoading(true);
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            getDossierGroups(searchTerm)
                .then((items: Array<ObjectGroupType>) => {
                    setObjectGroups(items);
                })
                .catch(() => {})
                .finally(() => setLoading(false));
        }, 300);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    return (
        <ObjectGroupsModal
            title="Déplacer le dossier dans un groupe"
            createButtonLabel="Créer un nouveau groupe de dossiers"
            submitButtonLabel="Déplacer"
            onSubmit={handleSubmit}
            search={(term) => setSearchTerm(term)}
            objectGroups={objectGroups}
            onCreate={handleCreate}
            loading={loading}
            selectedIds={selectedItems.map((item) => item.id)}
            enableSubmit={allowSubmit}
        />
    );
};

export default DossierSearchGroup;
