import React from 'react';
import { DossierOpenSearch } from '@europrocurement/l2d-domain';
import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import {
    PreviewColumn,
    DossierReferences,
    DossierCompanyFormality,
    PeoplePreview,
    DossierStepCompletion,
    DossierDateCreation,
    DossierNotifications,
    DossierStatus,
} from '../components/liste';

const useDossierTable = () => {
    const columns: ColumnDatatable<DossierOpenSearch>[] = [
        {
            label: 'Mises à jour',
            render: ({
                contactPrenom,
                contactNom,
                societeRef,
                formalites,
                numero,
                documentsCount,
            }) => (
                <PreviewColumn
                    contactPrenom={contactPrenom}
                    contactNom={contactNom}
                    formalites={formalites?.map((formalite) => ({
                        id: formalite?.id as number,
                        libelle: formalite?.libelle as string,
                    }))}
                    societeRef={societeRef}
                    numero={numero}
                    documentsCount={documentsCount}
                />
            ),
            isDisplayed: true,
            sx: {
                display: {
                    xs: 'table-cell',
                    sm: 'table-cell',
                    md: 'none',
                    lg: 'none',
                    xl: 'none',
                },
            },
        },
        {
            label: 'Références dossiers',
            render: ({ societeRef, numero, collectionNumero }) => (
                <DossierReferences
                    itemReference={numero as string}
                    companyReference={societeRef as string}
                    collectionReference={collectionNumero as string}
                />
            ),
            isDisplayed: true,
            sortable: true,
            field: 'numero',
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
        {
            label: 'Sociétés',
            render: ({ societeDenomination, formalites = [] }) => (
                <DossierCompanyFormality
                    libelle={societeDenomination as string}
                    formalites={formalites?.map((formalite) => ({
                        id: formalite?.id as number,
                        libelle: formalite?.libelle as string,
                    }))}
                />
            ),
            isDisplayed: true,
            sortable: true,
            field: 'societeDenomination',
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
        {
            label: 'Col...',
            render: ({ contactNom, contactPrenom }) => (
                <PeoplePreview
                    firstName={contactPrenom as string}
                    lastName={contactNom as string}
                />
            ),
            isDisplayed: true,
            sortable: true,
            field: 'contactNom',
            sx: {
                width: '64px',

                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
            headerTooltip: 'Collaborateur',
        },
        {
            label: 'Étapes',
            render: ({ stepLabel, stepCompletion }) => (
                <DossierStepCompletion
                    step={stepLabel as string}
                    currentCompletion={stepCompletion as number}
                />
            ),
            isDisplayed: true,
            sortable: false,
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
        {
            label: 'Dates',
            render: ({ dateCreation }) => {
                if (!dateCreation) {
                    return null;
                }

                return <DossierDateCreation date={new Date(dateCreation)} />;
            },
            isDisplayed: true,
            sortable: true,
            field: 'dateCreation',
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
        {
            label: 'Notifications',
            render: ({ documentsCount }) => (
                <DossierNotifications documentsCount={documentsCount as number} />
            ),
            isDisplayed: true,
            sortable: false,
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
        {
            label: 'Statut',
            render: ({ statutLibelle }) => (
                <DossierStatus
                    label={statutLibelle as string}
                    color="primary"
                />
            ),
            isDisplayed: true,
            sortable: true,
            field: 'statutLibelle',
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
        {
            label: 'For...',
            render: ({ formalisteNom, formalistePrenom }) => (
                <PeoplePreview
                    firstName={formalistePrenom as string}
                    lastName={formalisteNom as string}
                />
            ),
            isDisplayed: true,
            sortable: true,
            headerTooltip: 'Formaliste',
            field: 'formaliste',
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
                width: '64px',
            },
        },
    ];

    return { columns };
};

export default useDossierTable;
