import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { Button, IconButton, Menu, MenuItem, Modal, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import {
    ConfirmationModal,
    ObjectGroupsCreate,
    ObjectGroupsModal,
    ObjectGroupType,
    useModal,
} from '@europrocurement/flexy-components';
import {
    selectedPrescripteursSelector,
    SousClient,
    TiersAppDispatch,
    useTiersService,
    useTiersServiceSelector,
} from '@europrocurement/l2d-domain';
import { ObjectGroupJsonldObjectgroupRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { useDispatch, useSelector } from 'react-redux';
import { customizerSelector } from '@europrocurement/flexy-components/redux/storeConfig/selectors';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { AnyAction } from '@reduxjs/toolkit';
import { Color } from '@europrocurement/flexy-components/components/organisms/ColorSelector';
import { useAddSocietesObjectGroup, useSocietesObjectGroup } from '../hooks';
import { AddPrescriberClientModal } from '../../PrescriberClient';

type ComponentProps = {
    sousClient: SousClient;
};
const ActionMenuSocietes: React.FunctionComponent<ComponentProps> = function ({ sousClient }) {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const idTiers = sousClient.id;
    const prescripteur = useTiersServiceSelector(selectedPrescripteursSelector);

    function formatDataToObjectGroup(
        data: Array<ObjectGroupJsonldObjectgroupRead> | null = null,
    ): Array<ObjectGroupType> | [] {
        if (data === null) {
            return [];
        }
        const ObjectGroupArray: ObjectGroupType[] = data
            .filter((item) => item.label)
            .map((item) => ({
                id: item.id as number,
                label: item.label as string,
                type: item.type as string,
                color: item.color as Color | undefined,
                icone: item.iconName as string,
            }));
        return ObjectGroupArray;
    }
    const [searchString, setSearchString] = useState('');
    const { searchTiersObjectGroup } = useSocietesObjectGroup();
    const { addObjectGroup } = useAddSocietesObjectGroup();
    const [listObjectGroup, setListObjectGroup] = useState([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
    const open = Boolean(anchorEl);
    const { objectGroupApi, sousClientsApi } = useTiersService();
    const { xIdSociete } = useSelector(customizerSelector);
    const dispatch = useDispatch<TiersAppDispatch>();
    const { getSousClients } = useTiersService();

    useEffect(() => {
        if (isMoveModalOpen !== false && isCreateModalOpen === false) {
            searchTiersObjectGroup(searchString)
                .then((result) => {
                    const resultFormatted = formatDataToObjectGroup(result);
                    setListObjectGroup(resultFormatted);
                })
                .catch((err) => {
                    console.error('Erreur :', err);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMoveModalOpen, searchString, isCreateModalOpen]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleModifySouClient = () => {
        setIsModifyModalOpen(true);
    };

    const handleOpenMoveModal = () => {
        setIsMoveModalOpen(true);
        handleClose();
    };

    const handleCloseMoveModal = () => {
        setIsMoveModalOpen(false);
    };

    const handleCloseCreateModal = () => {
        setIsCreateModalOpen(false);
    };

    const handleOpenCreateModal = () => {
        setIsCreateModalOpen(true);
    };

    const handleSubmitObjectGroup = async (item: ObjectGroupType): Promise<AxiosResponse> => {
        const payload = {
            objectgroups: [item.id],
        };

        objectGroupApi
            .updateRelSousClientObjectGroupTiersItem({
                xIdSociete,
                id: idTiers as unknown as string,
                tiersJsonldTiersEditedObjectGroup: payload,
            })
            .then(() => {
                setIsMoveModalOpen(false);
                enqueueSnackbar('La société a bien été ajouté au groupe !', {
                    autoHideDuration: 2000,
                });
            })
            .catch((error) => {
                console.error('Erreur :', error);
                setIsMoveModalOpen(false);
                enqueueSnackbar('Une erreure est survenue, merci de réessayer ultérieurement', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });
    };

    const saveNewGroup = async (label: string, color: string) => {
        try {
            const newGroup = await addObjectGroup(label, color, idTiers);
            enqueueSnackbar(
                `Le groupe ${newGroup?.label} a bien été créé et à été associé à la société`,
                {
                    autoHideDuration: 2000,
                },
            );
        } catch (error) {
            enqueueSnackbar(
                'Une erreur est survenue lors de la cration du groupe, merci de réessayer ultérieurement',
                {
                    variant: 'error',
                    autoHideDuration: 2000,
                },
            );
            console.error(error);
        }

        setIsCreateModalOpen(false);
        setIsMoveModalOpen(false);
    };

    const { modalActions } = useModal();

    const handleDeleteSousClient = () => {
        modalActions.call(
            <ConfirmationModal
                messages={{
                    question: 'Confirmation ?',
                    detail: {
                        message: 'Êtes vous sur de vouloir supprimer cette société ?',
                        severity: 'info',
                    },
                }}
                actionOnValidation={() => {
                    if (
                        sousClient.fCollectionDossier === undefined ||
                        sousClient.fCollectionDossier?.length === 0
                    ) {
                        sousClientsApi
                            .deleteSousclientTiersItem({
                                xIdSociete,
                                id: idTiers as unknown as string,
                            })
                            .then(() => {
                                enqueueSnackbar('La société a bien été supprimé', {
                                    autoHideDuration: 2000,
                                });
                                setTimeout(() => {
                                    dispatch(getSousClients({}) as unknown as AnyAction);
                                }, 1000);
                                modalActions.close();
                            })
                            .catch((error) => {
                                console.error('Erreur :', error);
                                setIsMoveModalOpen(false);
                                enqueueSnackbar(
                                    'Une erreure est survenue, merci de réessayer ultérieurement',
                                    {
                                        variant: 'error',
                                        autoHideDuration: 2000,
                                    },
                                );
                                modalActions.close();
                            });
                    } else {
                        setIsMoveModalOpen(false);
                        enqueueSnackbar(
                            'Vous ne pouvez pas supprimer une société avec des dossiers',
                            {
                                variant: 'error',
                                autoHideDuration: 2000,
                            },
                        );
                    }
                }}
                actionOnCancellation={() => {
                    modalActions.close();
                }}
                color={theme.palette.primary}
            />,
        );
        handleClose();
    };

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
        >
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <FaOptionIcon
                    icon={['fasl', 'ellipsis-vertical']}
                    sx={{ fontSize: '1rem' }}
                />
            </IconButton>
            <Menu
                id={`${idTiers}-long-menu`}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    key={`${idTiers}-add-group`}
                    onClick={handleOpenMoveModal}
                >
                    Déplacer dans un groupe
                </MenuItem>
                <MenuItem
                    key={`${idTiers}-modify`}
                    onClick={handleModifySouClient}
                >
                    Modifier
                </MenuItem>
                <MenuItem
                    key={`${idTiers}-open`}
                    onClick={handleClose}
                >
                    Ouvrir dans un nouvel onglet
                </MenuItem>
                <MenuItem
                    key={`${idTiers}-delete`}
                    onClick={handleDeleteSousClient}
                >
                    Supprimer
                </MenuItem>
            </Menu>
            <Modal
                open={isMoveModalOpen}
                onClose={handleCloseMoveModal}
                aria-labelledby="move-modal-title"
                aria-describedby="move-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        minWidth: 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <ObjectGroupsModal
                        title="Déplacer la société dans un groupe"
                        createButtonLabel="Créer un groupe"
                        submitButtonLabel="Ajouter au groupe"
                        onSubmit={handleSubmitObjectGroup}
                        search={(searchTerm) => setSearchString(searchTerm)}
                        objectGroups={listObjectGroup}
                        onCreate={handleOpenCreateModal}
                    />
                    <Button
                        onClick={handleCloseMoveModal}
                        sx={{ mt: 2 }}
                        variant="contained"
                    >
                        Fermer
                    </Button>
                </Box>
            </Modal>
            <Modal
                open={isCreateModalOpen}
                onClose={handleCloseCreateModal}
                aria-labelledby="create-modal-title"
                aria-describedby="create-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        minWidth: 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <ObjectGroupsCreate
                        title="Créer un groupe"
                        fieldLabel="nom du groupe"
                        submitButtonLabel="Créer le groupe et l'associer à la société"
                        onSubmit={(data) => {
                            saveNewGroup(data.namegroup, data.color ?? 'blue');
                        }}
                        onCancel={() => setIsCreateModalOpen(false)}
                        colors={[
                            'lightGreen',
                            'red',
                            'pink',
                            'purple',
                            'deepPurple',
                            'amber',
                            'blueGrey',
                            'teal',
                            'deepOrange',
                            'brown',
                            'cyan',
                            'green',
                            'indigo',
                            'lightBlue',
                            'lime',
                            'orange',
                            'yellow',
                            'blue',
                        ]}
                    />
                </Box>
            </Modal>
            <AddPrescriberClientModal
                open={isModifyModalOpen}
                onSuccess={() => {
                    setTimeout(() => {
                        dispatch({
                            type: 'sousClient/setmainPagination',
                            payload: {
                                page: 1,
                                itemsPerPage: 10,
                            },
                        });
                        if (prescripteur !== undefined) {
                            dispatch({
                                type: 'sousClient/setmainFilter',
                                payload: {
                                    key: 'prescripteurId',
                                    value: prescripteur.id,
                                },
                            });
                        }
                        dispatch(getSousClients({}) as unknown as AnyAction);
                    }, 1000);
                }}
                onCancel={() => {
                    setIsModifyModalOpen(false);
                }}
                sousClient={sousClient}
            />
        </div>
    );
};

export default ActionMenuSocietes;
