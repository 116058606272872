/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Formalite
 * API de gestion des formalite
 *
 * The version of the OpenAPI document: 1.10.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ActionDetailJsonldActionDetailRead
 */
export interface ActionDetailJsonldActionDetailRead {
    /**
     * 
     * @type {string}
     * @memberof ActionDetailJsonldActionDetailRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionDetailJsonldActionDetailRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionDetailJsonldActionDetailRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
}
/**
 * @type ActionDetailJsonldActionDetailReadContext
 * @export
 */
export type ActionDetailJsonldActionDetailReadContext = ActionDetailJsonldActionDetailReadContextOneOf | string;

/**
 * 
 * @export
 * @interface ActionDetailJsonldActionDetailReadContextOneOf
 */
export interface ActionDetailJsonldActionDetailReadContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ActionDetailJsonldActionDetailReadContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof ActionDetailJsonldActionDetailReadContextOneOf
     */
    'hydra': ActionDetailJsonldActionDetailReadContextOneOfHydraEnum;
}

export const ActionDetailJsonldActionDetailReadContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type ActionDetailJsonldActionDetailReadContextOneOfHydraEnum = typeof ActionDetailJsonldActionDetailReadContextOneOfHydraEnum[keyof typeof ActionDetailJsonldActionDetailReadContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface ActionsEtActivitSActionCreate
 */
export interface ActionsEtActivitSActionCreate {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionCreate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionCreate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActionCreated
 */
export interface ActionsEtActivitSActionCreated {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionCreated
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSActionCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionCreated
     */
    'user'?: number | null;
    /**
     * 
     * @type {TblActionDetailActionCreated}
     * @memberof ActionsEtActivitSActionCreated
     */
    'action'?: TblActionDetailActionCreated | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionCreated
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActionRead
 */
export interface ActionsEtActivitSActionRead {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionRead
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSActionRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionRead
     */
    'user'?: number | null;
    /**
     * 
     * @type {TblActionDetailActionRead}
     * @memberof ActionsEtActivitSActionRead
     */
    'action'?: TblActionDetailActionRead | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionRead
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActionUpdate
 */
export interface ActionsEtActivitSActionUpdate {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionUpdate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionUpdate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionUpdate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActiviteCreate
 */
export interface ActionsEtActivitSActiviteCreate {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteCreate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteCreate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActiviteCreated
 */
export interface ActionsEtActivitSActiviteCreated {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSActiviteCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteCreated
     */
    'user'?: number | null;
    /**
     * 
     * @type {TblActionDetailActiviteCreated}
     * @memberof ActionsEtActivitSActiviteCreated
     */
    'action'?: TblActionDetailActiviteCreated | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {FproDossierActiviteCreated}
     * @memberof ActionsEtActivitSActiviteCreated
     */
    'dossier'?: FproDossierActiviteCreated | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActiviteRead
 */
export interface ActionsEtActivitSActiviteRead {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteRead
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSActiviteRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteRead
     */
    'user'?: number | null;
    /**
     * 
     * @type {TblActionDetailActiviteRead}
     * @memberof ActionsEtActivitSActiviteRead
     */
    'action'?: TblActionDetailActiviteRead | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {FproDossierActiviteRead}
     * @memberof ActionsEtActivitSActiviteRead
     */
    'dossier'?: FproDossierActiviteRead | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActiviteUpdate
 */
export interface ActionsEtActivitSActiviteUpdate {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteUpdate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteUpdate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteUpdate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActionCreate
 */
export interface ActionsEtActivitSJsonldActionCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActionCreated
 */
export interface ActionsEtActivitSJsonldActionCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    'user'?: number | null;
    /**
     * 
     * @type {TblActionDetailJsonldActionCreated}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    'action'?: TblActionDetailJsonldActionCreated | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActionRead
 */
export interface ActionsEtActivitSJsonldActionRead {
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    'user'?: number | null;
    /**
     * 
     * @type {TblActionDetailJsonldActionRead}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    'action'?: TblActionDetailJsonldActionRead | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActionUpdate
 */
export interface ActionsEtActivitSJsonldActionUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActiviteCreate
 */
export interface ActionsEtActivitSJsonldActiviteCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActiviteCreated
 */
export interface ActionsEtActivitSJsonldActiviteCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    'user'?: number | null;
    /**
     * 
     * @type {TblActionDetailJsonldActiviteCreated}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    'action'?: TblActionDetailJsonldActiviteCreated | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {FproDossierJsonldActiviteCreated}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    'dossier'?: FproDossierJsonldActiviteCreated | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActiviteRead
 */
export interface ActionsEtActivitSJsonldActiviteRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    'user'?: number | null;
    /**
     * 
     * @type {TblActionDetailJsonldActiviteRead}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    'action'?: TblActionDetailJsonldActiviteRead | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {FproDossierJsonldActiviteRead}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    'dossier'?: FproDossierJsonldActiviteRead | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActiviteUpdate
 */
export interface ActionsEtActivitSJsonldActiviteUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesJsonldRelationalCreate
 */
export interface AnnoncesLGalesJsonldRelationalCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof AnnoncesLGalesJsonldRelationalCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesJsonldRelationalCreate
     */
    'pdf': boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesJsonldRelationalCreate
     */
    'dossierAL': number;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalCreate
     */
    'dossierF'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesJsonldRelationalCreated
 */
export interface AnnoncesLGalesJsonldRelationalCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    'pdf': boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    'dossierAL': number;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    'dossierF'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesJsonldRelationalRead
 */
export interface AnnoncesLGalesJsonldRelationalRead {
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    'pdf'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    'dossierAL'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    'dossierF'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesJsonldRelationalUpdate
 */
export interface AnnoncesLGalesJsonldRelationalUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof AnnoncesLGalesJsonldRelationalUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesJsonldRelationalUpdate
     */
    'pdf'?: boolean;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesRelationalCreate
 */
export interface AnnoncesLGalesRelationalCreate {
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesRelationalCreate
     */
    'pdf': boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesRelationalCreate
     */
    'dossierAL': number;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesRelationalCreate
     */
    'dossierF'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesRelationalCreated
 */
export interface AnnoncesLGalesRelationalCreated {
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesRelationalCreated
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesRelationalCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesRelationalCreated
     */
    'pdf': boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesRelationalCreated
     */
    'dossierAL': number;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesRelationalCreated
     */
    'dossierF'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesRelationalRead
 */
export interface AnnoncesLGalesRelationalRead {
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesRelationalRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesRelationalRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesRelationalRead
     */
    'pdf'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesRelationalRead
     */
    'dossierAL'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesRelationalRead
     */
    'dossierF'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesRelationalUpdate
 */
export interface AnnoncesLGalesRelationalUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesRelationalUpdate
     */
    'pdf'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiAcomptesIdGet404Response
 */
export interface ApiAcomptesIdGet404Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesIdGet404Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiAcomptesIdGet404ResponseException}
     * @memberof ApiAcomptesIdGet404Response
     */
    'exception'?: ApiAcomptesIdGet404ResponseException;
}
/**
 * 
 * @export
 * @interface ApiAcomptesIdGet404ResponseException
 */
export interface ApiAcomptesIdGet404ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesIdGet404ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAcomptesIdGet404ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiAcomptesIdPut400Response
 */
export interface ApiAcomptesIdPut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesIdPut400Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiAcomptesIdPut400ResponseException}
     * @memberof ApiAcomptesIdPut400Response
     */
    'exception'?: ApiAcomptesIdPut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiAcomptesIdPut400ResponseException
 */
export interface ApiAcomptesIdPut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesIdPut400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesIdPut400ResponseException
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface ApiAcomptesPost400Response
 */
export interface ApiAcomptesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesPost400Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiAcomptesPost400ResponseException}
     * @memberof ApiAcomptesPost400Response
     */
    'exception'?: ApiAcomptesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiAcomptesPost400ResponseException
 */
export interface ApiAcomptesPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesPost400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesPost400ResponseException
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface ApiAcomptesPost401Response
 */
export interface ApiAcomptesPost401Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesPost401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiAcomptesPost403Response
 */
export interface ApiAcomptesPost403Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesPost403Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiAcomptesPost403ResponseException}
     * @memberof ApiAcomptesPost403Response
     */
    'exception'?: ApiAcomptesPost403ResponseException;
}
/**
 * 
 * @export
 * @interface ApiAcomptesPost403ResponseException
 */
export interface ApiAcomptesPost403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesPost403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAcomptesPost403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection200Response
 */
export interface ApiActionDetailsGetCollection200Response {
    /**
     * 
     * @type {Array<ActionDetailJsonldActionDetailRead>}
     * @memberof ApiActionDetailsGetCollection200Response
     */
    'hydra:member': Array<ActionDetailJsonldActionDetailRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiActionDetailsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiActionDetailsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiActionDetailsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection200ResponseHydraSearch
 */
export interface ApiActionDetailsGetCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
 */
export interface ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection200ResponseHydraView
 */
export interface ApiActionDetailsGetCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface ApiArticlesPrestationGetCollection200Response
 */
export interface ApiArticlesPrestationGetCollection200Response {
    /**
     * 
     * @type {Array<PrestationArticlesJsonldPrestationrelationRead>}
     * @memberof ApiArticlesPrestationGetCollection200Response
     */
    'hydra:member': Array<PrestationArticlesJsonldPrestationrelationRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiArticlesPrestationGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiArticlesPrestationGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiArticlesPrestationGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiCategoriesGetCollection200Response
 */
export interface ApiCategoriesGetCollection200Response {
    /**
     * 
     * @type {Array<CategorieJsonldCategorieRead>}
     * @memberof ApiCategoriesGetCollection200Response
     */
    'hydra:member': Array<CategorieJsonldCategorieRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiCategoriesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiCategoriesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiCategoriesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiCategoriesPost400Response
 */
export interface ApiCategoriesPost400Response {
    /**
     * 
     * @type {ApiCategoriesPost400ResponseError}
     * @memberof ApiCategoriesPost400Response
     */
    'error'?: ApiCategoriesPost400ResponseError;
}
/**
 * 
 * @export
 * @interface ApiCategoriesPost400ResponseError
 */
export interface ApiCategoriesPost400ResponseError {
    /**
     * 
     * @type {string}
     * @memberof ApiCategoriesPost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCategoriesPost400ResponseError
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface ApiCollectionsDossiersGetCollection200Response
 */
export interface ApiCollectionsDossiersGetCollection200Response {
    /**
     * 
     * @type {Array<CollectionDeDossierJsonldCollectiondossierRead>}
     * @memberof ApiCollectionsDossiersGetCollection200Response
     */
    'hydra:member': Array<CollectionDeDossierJsonldCollectiondossierRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiCollectionsDossiersGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiCollectionsDossiersGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiCollectionsDossiersGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiCollectionsDossiersIdCalculatePut400Response
 */
export interface ApiCollectionsDossiersIdCalculatePut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiCollectionsDossiersIdCalculatePut400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiCollectionsDossiersIdCalculatePut400ResponseException}
     * @memberof ApiCollectionsDossiersIdCalculatePut400Response
     */
    'exception': ApiCollectionsDossiersIdCalculatePut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiCollectionsDossiersIdCalculatePut400ResponseException
 */
export interface ApiCollectionsDossiersIdCalculatePut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiCollectionsDossiersIdCalculatePut400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiCollectionsDossiersIdCalculatePut400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiCollectionsDossiersIdCalculatePut400ResponseExceptionViolationsInner>}
     * @memberof ApiCollectionsDossiersIdCalculatePut400ResponseException
     */
    'violations'?: Array<ApiCollectionsDossiersIdCalculatePut400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiCollectionsDossiersIdCalculatePut400ResponseExceptionViolationsInner
 */
export interface ApiCollectionsDossiersIdCalculatePut400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiCollectionsDossiersIdCalculatePut400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiCollectionsDossiersIdCalculatePut400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiCollectionsDossiersPost400Response
 */
export interface ApiCollectionsDossiersPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiCollectionsDossiersPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiCollectionsDossiersPost400ResponseException}
     * @memberof ApiCollectionsDossiersPost400Response
     */
    'exception': ApiCollectionsDossiersPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiCollectionsDossiersPost400ResponseException
 */
export interface ApiCollectionsDossiersPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiCollectionsDossiersPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiCollectionsDossiersPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiCollectionsDossiersPost400ResponseExceptionViolationsInner>}
     * @memberof ApiCollectionsDossiersPost400ResponseException
     */
    'violations'?: Array<ApiCollectionsDossiersPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiCollectionsDossiersPost400ResponseExceptionViolationsInner
 */
export interface ApiCollectionsDossiersPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiCollectionsDossiersPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiCollectionsDossiersPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDevisGetCollection200Response
 */
export interface ApiDevisGetCollection200Response {
    /**
     * 
     * @type {Array<DevisJsonldDevisRead>}
     * @memberof ApiDevisGetCollection200Response
     */
    'hydra:member': Array<DevisJsonldDevisRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDevisGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDevisGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDevisGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDocumentgedTypeApplicationtagGetCollection200Response
 */
export interface ApiDocumentgedTypeApplicationtagGetCollection200Response {
    /**
     * 
     * @type {Array<DocumentGedJsonld>}
     * @memberof ApiDocumentgedTypeApplicationtagGetCollection200Response
     */
    'hydra:member': Array<DocumentGedJsonld>;
    /**
     * 
     * @type {number}
     * @memberof ApiDocumentgedTypeApplicationtagGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDocumentgedTypeApplicationtagGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDocumentgedTypeApplicationtagGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner
 */
export interface ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner {
    /**
     * 
     * @type {string}
     * @memberof ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner
     */
    'tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ApiDomainesGetCollection200Response
 */
export interface ApiDomainesGetCollection200Response {
    /**
     * 
     * @type {Array<DomainesJsonldDomaineRead>}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:member': Array<DomainesJsonldDomaineRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierAcomptesGetCollection200Response
 */
export interface ApiDossiersDossierAcomptesGetCollection200Response {
    /**
     * 
     * @type {Array<ReglementsEtAcomptesCBJsonldAcompteRead>}
     * @memberof ApiDossiersDossierAcomptesGetCollection200Response
     */
    'hydra:member': Array<ReglementsEtAcomptesCBJsonldAcompteRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierAcomptesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierAcomptesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierAcomptesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierActionsGetCollection200Response
 */
export interface ApiDossiersDossierActionsGetCollection200Response {
    /**
     * 
     * @type {Array<ActionsEtActivitSJsonldActionRead>}
     * @memberof ApiDossiersDossierActionsGetCollection200Response
     */
    'hydra:member': Array<ActionsEtActivitSJsonldActionRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierActionsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierActionsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierActionsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFichiersGetCollection200Response
 */
export interface ApiDossiersDossierFichiersGetCollection200Response {
    /**
     * 
     * @type {Array<FichiersDossierJsonldDocumentfileRead>}
     * @memberof ApiDossiersDossierFichiersGetCollection200Response
     */
    'hydra:member': Array<FichiersDossierJsonldDocumentfileRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierFichiersGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierFichiersGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierFichiersGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFichiersPost400Response
 */
export interface ApiDossiersDossierFichiersPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFichiersPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiDossiersDossierFichiersPost400ResponseException}
     * @memberof ApiDossiersDossierFichiersPost400Response
     */
    'exception': ApiDossiersDossierFichiersPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFichiersPost400ResponseException
 */
export interface ApiDossiersDossierFichiersPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFichiersPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierFichiersPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiDossiersDossierFichiersPost400ResponseExceptionViolationsInner>}
     * @memberof ApiDossiersDossierFichiersPost400ResponseException
     */
    'violations'?: Array<ApiDossiersDossierFichiersPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFichiersPost400ResponseExceptionViolationsInner
 */
export interface ApiDossiersDossierFichiersPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiDossiersDossierFichiersPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiDossiersDossierFichiersPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierMessagesGetCollection200Response
 */
export interface ApiDossiersDossierMessagesGetCollection200Response {
    /**
     * 
     * @type {Array<MessageJsonldMessageRead>}
     * @memberof ApiDossiersDossierMessagesGetCollection200Response
     */
    'hydra:member': Array<MessageJsonldMessageRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierMessagesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierMessagesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierMessagesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierNotesGetCollection200Response
 */
export interface ApiDossiersDossierNotesGetCollection200Response {
    /**
     * 
     * @type {Array<NotesEtRappelsJsonldNoteRead>}
     * @memberof ApiDossiersDossierNotesGetCollection200Response
     */
    'hydra:member': Array<NotesEtRappelsJsonldNoteRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierNotesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierNotesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierNotesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierPrestationsGetCollection200Response
 */
export interface ApiDossiersDossierPrestationsGetCollection200Response {
    /**
     * 
     * @type {Array<PrestationJsonldPrestationRead>}
     * @memberof ApiDossiersDossierPrestationsGetCollection200Response
     */
    'hydra:member': Array<PrestationJsonldPrestationRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierPrestationsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierPrestationsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierPrestationsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierRappelsGetCollection200Response
 */
export interface ApiDossiersDossierRappelsGetCollection200Response {
    /**
     * 
     * @type {Array<NotesEtRappelsJsonldNoterappelReadCollection>}
     * @memberof ApiDossiersDossierRappelsGetCollection200Response
     */
    'hydra:member': Array<NotesEtRappelsJsonldNoterappelReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierRappelsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierRappelsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierRappelsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierRelationAnnoncesGetCollection200Response
 */
export interface ApiDossiersDossierRelationAnnoncesGetCollection200Response {
    /**
     * 
     * @type {Array<AnnoncesLGalesJsonldRelationalRead>}
     * @memberof ApiDossiersDossierRelationAnnoncesGetCollection200Response
     */
    'hydra:member': Array<AnnoncesLGalesJsonldRelationalRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierRelationAnnoncesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierRelationAnnoncesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierRelationAnnoncesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierTypesDocDossierGetCollection200Response
 */
export interface ApiDossiersDossierTypesDocDossierGetCollection200Response {
    /**
     * 
     * @type {Array<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead>}
     * @memberof ApiDossiersDossierTypesDocDossierGetCollection200Response
     */
    'hydra:member': Array<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierTypesDocDossierGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierTypesDocDossierGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierTypesDocDossierGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierValidationGetCollection200Response
 */
export interface ApiDossiersDossierValidationGetCollection200Response {
    /**
     * 
     * @type {Array<ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead>}
     * @memberof ApiDossiersDossierValidationGetCollection200Response
     */
    'hydra:member': Array<ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierValidationGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierValidationGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierValidationGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersGetCollection200Response
 */
export interface ApiDossiersGetCollection200Response {
    /**
     * 
     * @type {Array<DossierJsonldDossierRead>}
     * @memberof ApiDossiersGetCollection200Response
     */
    'hydra:member': Array<DossierJsonldDossierRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdDematFacilePut400Response
 */
export interface ApiDossiersIdDematFacilePut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersIdDematFacilePut400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiDossiersIdDematFacilePut400ResponseException}
     * @memberof ApiDossiersIdDematFacilePut400Response
     */
    'exception': ApiDossiersIdDematFacilePut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdDematFacilePut400ResponseException
 */
export interface ApiDossiersIdDematFacilePut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersIdDematFacilePut400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersIdDematFacilePut400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiDossiersIdDematFacilePut400ResponseExceptionViolationsInner>}
     * @memberof ApiDossiersIdDematFacilePut400ResponseException
     */
    'violations'?: Array<ApiDossiersIdDematFacilePut400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdDematFacilePut400ResponseExceptionViolationsInner
 */
export interface ApiDossiersIdDematFacilePut400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiDossiersIdDematFacilePut400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiDossiersIdDematFacilePut400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdPut400Response
 */
export interface ApiDossiersIdPut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersIdPut400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiDossiersIdPut400ResponseException}
     * @memberof ApiDossiersIdPut400Response
     */
    'exception': ApiDossiersIdPut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdPut400ResponseException
 */
export interface ApiDossiersIdPut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersIdPut400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersIdPut400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiDossiersIdPut400ResponseExceptionViolationsInner>}
     * @memberof ApiDossiersIdPut400ResponseException
     */
    'violations'?: Array<ApiDossiersIdPut400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdPut400ResponseExceptionViolationsInner
 */
export interface ApiDossiersIdPut400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiDossiersIdPut400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiDossiersIdPut400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdTransitionsGetCollection200Response
 */
export interface ApiDossiersIdTransitionsGetCollection200Response {
    /**
     * 
     * @type {Array<DossierJsonldDossierTransitionRead>}
     * @memberof ApiDossiersIdTransitionsGetCollection200Response
     */
    'hydra:member': Array<DossierJsonldDossierTransitionRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersIdTransitionsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersIdTransitionsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersIdTransitionsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersValidationPost400Response
 */
export interface ApiDossiersValidationPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersValidationPost400Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiDossiersValidationPost400ResponseException}
     * @memberof ApiDossiersValidationPost400Response
     */
    'exception'?: ApiDossiersValidationPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDossiersValidationPost400ResponseException
 */
export interface ApiDossiersValidationPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersValidationPost400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersValidationPost400ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiDossiersValideursGetCollection200Response
 */
export interface ApiDossiersValideursGetCollection200Response {
    /**
     * 
     * @type {Array<ValideursDesDossiersJsonldValidatorsDossierRead>}
     * @memberof ApiDossiersValideursGetCollection200Response
     */
    'hydra:member': Array<ValideursDesDossiersJsonldValidatorsDossierRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersValideursGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersValideursGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersValideursGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiEtapesDossiersGetCollection200Response
 */
export interface ApiEtapesDossiersGetCollection200Response {
    /**
     * 
     * @type {Array<TapesDeTraitementDesDossiersJsonldStepsDossierRead>}
     * @memberof ApiEtapesDossiersGetCollection200Response
     */
    'hydra:member': Array<TapesDeTraitementDesDossiersJsonldStepsDossierRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiEtapesDossiersGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiEtapesDossiersGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiEtapesDossiersGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiFormalistesGetCollection200Response
 */
export interface ApiFormalistesGetCollection200Response {
    /**
     * 
     * @type {Array<FormalisteJsonldFormalisteRead>}
     * @memberof ApiFormalistesGetCollection200Response
     */
    'hydra:member': Array<FormalisteJsonldFormalisteRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiFormalistesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiFormalistesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiFormalistesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiFormalitesGetCollection200Response
 */
export interface ApiFormalitesGetCollection200Response {
    /**
     * 
     * @type {Array<FormaliteJsonldFormaliteRead>}
     * @memberof ApiFormalitesGetCollection200Response
     */
    'hydra:member': Array<FormaliteJsonldFormaliteRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiFormalitesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiFormalitesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiFormalitesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiFormalitesPost400Response
 */
export interface ApiFormalitesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiFormalitesPost400Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiFormalitesPost400ResponseException}
     * @memberof ApiFormalitesPost400Response
     */
    'exception'?: ApiFormalitesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiFormalitesPost400ResponseException
 */
export interface ApiFormalitesPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiFormalitesPost400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFormalitesPost400ResponseException
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface ApiFormesJuridiquesGetCollection200Response
 */
export interface ApiFormesJuridiquesGetCollection200Response {
    /**
     * 
     * @type {Array<FormeJuridiqueJsonldFormejuridiqueRead>}
     * @memberof ApiFormesJuridiquesGetCollection200Response
     */
    'hydra:member': Array<FormeJuridiqueJsonldFormejuridiqueRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiFormesJuridiquesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiFormesJuridiquesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiFormesJuridiquesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiGreffesGetCollection200Response
 */
export interface ApiGreffesGetCollection200Response {
    /**
     * 
     * @type {Array<GreffeJsonldGreffeRead>}
     * @memberof ApiGreffesGetCollection200Response
     */
    'hydra:member': Array<GreffeJsonldGreffeRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiGreffesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiGreffesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiGreffesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiObjectGroupsGetCollection200Response
 */
export interface ApiObjectGroupsGetCollection200Response {
    /**
     * 
     * @type {Array<ObjectGroupsJsonldObjectGroupRead>}
     * @memberof ApiObjectGroupsGetCollection200Response
     */
    'hydra:member': Array<ObjectGroupsJsonldObjectGroupRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiObjectGroupsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiObjectGroupsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiObjectGroupsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiObjectGroupsPost400Response
 */
export interface ApiObjectGroupsPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiObjectGroupsPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiObjectGroupsPost400ResponseException}
     * @memberof ApiObjectGroupsPost400Response
     */
    'exception': ApiObjectGroupsPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiObjectGroupsPost400ResponseException
 */
export interface ApiObjectGroupsPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiObjectGroupsPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiObjectGroupsPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiObjectGroupsPost400ResponseExceptionViolationsInner>}
     * @memberof ApiObjectGroupsPost400ResponseException
     */
    'violations'?: Array<ApiObjectGroupsPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiObjectGroupsPost400ResponseExceptionViolationsInner
 */
export interface ApiObjectGroupsPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiObjectGroupsPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiObjectGroupsPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiOpensearchClientsGetCollection200Response
 */
export interface ApiOpensearchClientsGetCollection200Response {
    /**
     * 
     * @type {Array<DossierOpenSearchJsonldOpensearchDossierClientRead>}
     * @memberof ApiOpensearchClientsGetCollection200Response
     */
    'hydra:member': Array<DossierOpenSearchJsonldOpensearchDossierClientRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOpensearchClientsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOpensearchClientsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOpensearchClientsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOpensearchCollectionsGetCollection200Response
 */
export interface ApiOpensearchCollectionsGetCollection200Response {
    /**
     * 
     * @type {Array<DossierOpenSearchJsonldOpensearchDossierCollectionRead>}
     * @memberof ApiOpensearchCollectionsGetCollection200Response
     */
    'hydra:member': Array<DossierOpenSearchJsonldOpensearchDossierCollectionRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOpensearchCollectionsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOpensearchCollectionsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOpensearchCollectionsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOpensearchDossiersGetCollection200Response
 */
export interface ApiOpensearchDossiersGetCollection200Response {
    /**
     * 
     * @type {Array<DossierOpenSearchJsonldOpensearchDossierRead>}
     * @memberof ApiOpensearchDossiersGetCollection200Response
     */
    'hydra:member': Array<DossierOpenSearchJsonldOpensearchDossierRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOpensearchDossiersGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOpensearchDossiersGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOpensearchDossiersGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOpensearchFormalistesGetCollection200Response
 */
export interface ApiOpensearchFormalistesGetCollection200Response {
    /**
     * 
     * @type {Array<DossierOpenSearchJsonldOpensearchDossierFormalisteRead>}
     * @memberof ApiOpensearchFormalistesGetCollection200Response
     */
    'hydra:member': Array<DossierOpenSearchJsonldOpensearchDossierFormalisteRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOpensearchFormalistesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOpensearchFormalistesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOpensearchFormalistesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOpensearchFormalitesGetCollection200Response
 */
export interface ApiOpensearchFormalitesGetCollection200Response {
    /**
     * 
     * @type {Array<DossierOpenSearchJsonldOpensearchDossierFormaliteRead>}
     * @memberof ApiOpensearchFormalitesGetCollection200Response
     */
    'hydra:member': Array<DossierOpenSearchJsonldOpensearchDossierFormaliteRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOpensearchFormalitesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOpensearchFormalitesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOpensearchFormalitesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOpensearchObjectGroupsGetCollection200Response
 */
export interface ApiOpensearchObjectGroupsGetCollection200Response {
    /**
     * 
     * @type {Array<DossierOpenSearchJsonldOpensearchDossierObjectGroupRead>}
     * @memberof ApiOpensearchObjectGroupsGetCollection200Response
     */
    'hydra:member': Array<DossierOpenSearchJsonldOpensearchDossierObjectGroupRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOpensearchObjectGroupsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOpensearchObjectGroupsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOpensearchObjectGroupsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOpensearchSocieteReferenceGetCollection200Response
 */
export interface ApiOpensearchSocieteReferenceGetCollection200Response {
    /**
     * 
     * @type {Array<DossierOpenSearchJsonldOpensearchDossierSocieteRefRead>}
     * @memberof ApiOpensearchSocieteReferenceGetCollection200Response
     */
    'hydra:member': Array<DossierOpenSearchJsonldOpensearchDossierSocieteRefRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOpensearchSocieteReferenceGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOpensearchSocieteReferenceGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOpensearchSocieteReferenceGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOpensearchStatusesGetCollection200Response
 */
export interface ApiOpensearchStatusesGetCollection200Response {
    /**
     * 
     * @type {Array<DossierOpenSearchJsonldOpensearchDossierStatutRead>}
     * @memberof ApiOpensearchStatusesGetCollection200Response
     */
    'hydra:member': Array<DossierOpenSearchJsonldOpensearchDossierStatutRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOpensearchStatusesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOpensearchStatusesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOpensearchStatusesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection200Response
 */
export interface ApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection200Response {
    /**
     * 
     * @type {Array<TiersJsonldDossierRead>}
     * @memberof ApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection200Response
     */
    'hydra:member': Array<TiersJsonldDossierRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiStatutsGetCollection200Response
 */
export interface ApiStatutsGetCollection200Response {
    /**
     * 
     * @type {Array<StatutJsonldStatutRead>}
     * @memberof ApiStatutsGetCollection200Response
     */
    'hydra:member': Array<StatutJsonldStatutRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiStatutsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiStatutsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiStatutsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiTypesDocDossierIdPut400Response
 */
export interface ApiTypesDocDossierIdPut400Response {
    /**
     * 
     * @type {ApiAcomptesIdPut400ResponseException}
     * @memberof ApiTypesDocDossierIdPut400Response
     */
    'error'?: ApiAcomptesIdPut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiTypesDocDossierPost400Response
 */
export interface ApiTypesDocDossierPost400Response {
    /**
     * 
     * @type {ApiAcomptesPost400ResponseException}
     * @memberof ApiTypesDocDossierPost400Response
     */
    'error'?: ApiAcomptesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiTypesDocumentsGetCollection200Response
 */
export interface ApiTypesDocumentsGetCollection200Response {
    /**
     * 
     * @type {Array<TypeDeDocumentJsonldTypedocumentRead>}
     * @memberof ApiTypesDocumentsGetCollection200Response
     */
    'hydra:member': Array<TypeDeDocumentJsonldTypedocumentRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiTypesDocumentsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiTypesDocumentsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiTypesDocumentsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ArticlesDeFacturationFactArticleRead
 */
export interface ArticlesDeFacturationFactArticleRead {
    /**
     * 
     * @type {number}
     * @memberof ArticlesDeFacturationFactArticleRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ArticlesDeFacturationJsonldFactArticleRead
 */
export interface ArticlesDeFacturationJsonldFactArticleRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ArticlesDeFacturationJsonldFactArticleRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ArticlesDeFacturationJsonldFactArticleRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesDeFacturationJsonldFactArticleRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticlesDeFacturationJsonldFactArticleRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface CallbackDematFacile
 */
export interface CallbackDematFacile {
    /**
     * type du callback
     * @type {string}
     * @memberof CallbackDematFacile
     */
    'type'?: string;
    /**
     * 
     * @type {CallbackRecu}
     * @memberof CallbackDematFacile
     */
    'dossier'?: CallbackRecu;
}
/**
 * 
 * @export
 * @interface CallbackDematFacileJsonld
 */
export interface CallbackDematFacileJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CallbackDematFacileJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CallbackDematFacileJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackDematFacileJsonld
     */
    '@type'?: string;
    /**
     * type du callback
     * @type {string}
     * @memberof CallbackDematFacileJsonld
     */
    'type'?: string;
    /**
     * 
     * @type {CallbackRecuJsonld}
     * @memberof CallbackDematFacileJsonld
     */
    'dossier'?: CallbackRecuJsonld;
}
/**
 * 
 * @export
 * @interface CallbackDocument
 */
export interface CallbackDocument {
    /**
     * identifiant du document
     * @type {number}
     * @memberof CallbackDocument
     */
    'id'?: number;
    /**
     * type du document
     * @type {string}
     * @memberof CallbackDocument
     */
    'type'?: string;
    /**
     * contenu du document (en base64)
     * @type {string}
     * @memberof CallbackDocument
     */
    'fichier'?: string;
    /**
     * nom du document
     * @type {string}
     * @memberof CallbackDocument
     */
    'nom'?: string;
    /**
     * cheksum du document
     * @type {string}
     * @memberof CallbackDocument
     */
    'somme_controle'?: string;
    /**
     * Identifiant inpi
     * @type {number}
     * @memberof CallbackDocument
     */
    'id_inpi'?: number;
}
/**
 * 
 * @export
 * @interface CallbackDocumentJsonld
 */
export interface CallbackDocumentJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CallbackDocumentJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CallbackDocumentJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackDocumentJsonld
     */
    '@type'?: string;
    /**
     * identifiant du document
     * @type {number}
     * @memberof CallbackDocumentJsonld
     */
    'id'?: number;
    /**
     * type du document
     * @type {string}
     * @memberof CallbackDocumentJsonld
     */
    'type'?: string;
    /**
     * contenu du document (en base64)
     * @type {string}
     * @memberof CallbackDocumentJsonld
     */
    'fichier'?: string;
    /**
     * nom du document
     * @type {string}
     * @memberof CallbackDocumentJsonld
     */
    'nom'?: string;
    /**
     * cheksum du document
     * @type {string}
     * @memberof CallbackDocumentJsonld
     */
    'somme_controle'?: string;
    /**
     * Identifiant inpi
     * @type {number}
     * @memberof CallbackDocumentJsonld
     */
    'id_inpi'?: number;
}
/**
 * 
 * @export
 * @interface CallbackPaiement
 */
export interface CallbackPaiement {
    /**
     * Get the value of id
     * @type {number}
     * @memberof CallbackPaiement
     */
    'id'?: number;
    /**
     * 
     * @type {Array<CallbackPaiementCartRate>}
     * @memberof CallbackPaiement
     */
    'cartRates'?: Array<CallbackPaiementCartRate>;
    /**
     * Get the value of total
     * @type {number}
     * @memberof CallbackPaiement
     */
    'total'?: number;
    /**
     * Get the value of mipOrderNum
     * @type {number}
     * @memberof CallbackPaiement
     */
    'mipOrderNum'?: number;
    /**
     * Get the value of paymentDate
     * @type {string}
     * @memberof CallbackPaiement
     */
    'paymentDate'?: string;
    /**
     * Get the value of paymentType
     * @type {string}
     * @memberof CallbackPaiement
     */
    'paymentType'?: string;
    /**
     * Get the value of status
     * @type {string}
     * @memberof CallbackPaiement
     */
    'status'?: string;
    /**
     * Get the value of created
     * @type {string}
     * @memberof CallbackPaiement
     */
    'created'?: string;
    /**
     * Get the value of updated
     * @type {string}
     * @memberof CallbackPaiement
     */
    'updated'?: string;
}
/**
 * 
 * @export
 * @interface CallbackPaiementCartRate
 */
export interface CallbackPaiementCartRate {
    /**
     * Get the value of id
     * @type {number}
     * @memberof CallbackPaiementCartRate
     */
    'id'?: number;
    /**
     * 
     * @type {CallbackPaiementRate}
     * @memberof CallbackPaiementCartRate
     */
    'rate'?: CallbackPaiementRate;
    /**
     * Get the value of quantity
     * @type {number}
     * @memberof CallbackPaiementCartRate
     */
    'quantity'?: number;
    /**
     * Get the value of amount
     * @type {number}
     * @memberof CallbackPaiementCartRate
     */
    'amount'?: number;
    /**
     * Get the value of isForcedManually
     * @type {boolean}
     * @memberof CallbackPaiementCartRate
     */
    'isForcedManually'?: boolean;
    /**
     * Get the value of htAmount
     * @type {number}
     * @memberof CallbackPaiementCartRate
     */
    'htAmount'?: number;
    /**
     * Get the value of recipientCode
     * @type {string}
     * @memberof CallbackPaiementCartRate
     */
    'recipientCode'?: string | null;
    /**
     * Get the value of recipientName
     * @type {string}
     * @memberof CallbackPaiementCartRate
     */
    'recipientName'?: string | null;
    /**
     * Get the value of subTotal
     * @type {number}
     * @memberof CallbackPaiementCartRate
     */
    'subTotal'?: number;
}
/**
 * 
 * @export
 * @interface CallbackPaiementCartRateJsonld
 */
export interface CallbackPaiementCartRateJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CallbackPaiementCartRateJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CallbackPaiementCartRateJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackPaiementCartRateJsonld
     */
    '@type'?: string;
    /**
     * Get the value of id
     * @type {number}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {CallbackPaiementRateJsonld}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'rate'?: CallbackPaiementRateJsonld;
    /**
     * Get the value of quantity
     * @type {number}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'quantity'?: number;
    /**
     * Get the value of amount
     * @type {number}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'amount'?: number;
    /**
     * Get the value of isForcedManually
     * @type {boolean}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'isForcedManually'?: boolean;
    /**
     * Get the value of htAmount
     * @type {number}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'htAmount'?: number;
    /**
     * Get the value of recipientCode
     * @type {string}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'recipientCode'?: string | null;
    /**
     * Get the value of recipientName
     * @type {string}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'recipientName'?: string | null;
    /**
     * Get the value of subTotal
     * @type {number}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'subTotal'?: number;
}
/**
 * 
 * @export
 * @interface CallbackPaiementJsonld
 */
export interface CallbackPaiementJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CallbackPaiementJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    '@type'?: string;
    /**
     * Get the value of id
     * @type {number}
     * @memberof CallbackPaiementJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {Array<CallbackPaiementCartRateJsonld>}
     * @memberof CallbackPaiementJsonld
     */
    'cartRates'?: Array<CallbackPaiementCartRateJsonld>;
    /**
     * Get the value of total
     * @type {number}
     * @memberof CallbackPaiementJsonld
     */
    'total'?: number;
    /**
     * Get the value of mipOrderNum
     * @type {number}
     * @memberof CallbackPaiementJsonld
     */
    'mipOrderNum'?: number;
    /**
     * Get the value of paymentDate
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    'paymentDate'?: string;
    /**
     * Get the value of paymentType
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    'paymentType'?: string;
    /**
     * Get the value of status
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    'status'?: string;
    /**
     * Get the value of created
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    'created'?: string;
    /**
     * Get the value of updated
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    'updated'?: string;
}
/**
 * 
 * @export
 * @interface CallbackPaiementRate
 */
export interface CallbackPaiementRate {
    /**
     * Get the value of htAmount
     * @type {number}
     * @memberof CallbackPaiementRate
     */
    'htAmount'?: number;
    /**
     * Get the value of code
     * @type {string}
     * @memberof CallbackPaiementRate
     */
    'code'?: string;
    /**
     * Get the value of label
     * @type {string}
     * @memberof CallbackPaiementRate
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface CallbackPaiementRateJsonld
 */
export interface CallbackPaiementRateJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CallbackPaiementRateJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CallbackPaiementRateJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackPaiementRateJsonld
     */
    '@type'?: string;
    /**
     * Get the value of htAmount
     * @type {number}
     * @memberof CallbackPaiementRateJsonld
     */
    'htAmount'?: number;
    /**
     * Get the value of code
     * @type {string}
     * @memberof CallbackPaiementRateJsonld
     */
    'code'?: string;
    /**
     * Get the value of label
     * @type {string}
     * @memberof CallbackPaiementRateJsonld
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface CallbackRecu
 */
export interface CallbackRecu {
    /**
     * identifiant du dossier côté demat facile
     * @type {number}
     * @memberof CallbackRecu
     */
    'id'?: number;
    /**
     * 
     * @type {CallbackDocument}
     * @memberof CallbackRecu
     */
    'documents'?: CallbackDocument;
    /**
     * 
     * @type {CallbackPaiement}
     * @memberof CallbackRecu
     */
    'paiement'?: CallbackPaiement;
    /**
     * 
     * @type {CallbackDocument}
     * @memberof CallbackRecu
     */
    'document'?: CallbackDocument;
}
/**
 * 
 * @export
 * @interface CallbackRecuJsonld
 */
export interface CallbackRecuJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CallbackRecuJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CallbackRecuJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackRecuJsonld
     */
    '@type'?: string;
    /**
     * identifiant du dossier côté demat facile
     * @type {number}
     * @memberof CallbackRecuJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {CallbackDocumentJsonld}
     * @memberof CallbackRecuJsonld
     */
    'documents'?: CallbackDocumentJsonld;
    /**
     * 
     * @type {CallbackPaiementJsonld}
     * @memberof CallbackRecuJsonld
     */
    'paiement'?: CallbackPaiementJsonld;
    /**
     * 
     * @type {CallbackDocumentJsonld}
     * @memberof CallbackRecuJsonld
     */
    'document'?: CallbackDocumentJsonld;
}
/**
 * 
 * @export
 * @interface CategorieCategorieCreate
 */
export interface CategorieCategorieCreate {
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieCreate
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieCreate
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieCategorieCreated
 */
export interface CategorieCategorieCreated {
    /**
     * 
     * @type {number}
     * @memberof CategorieCategorieCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieCreated
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieCreated
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieCategorieRead
 */
export interface CategorieCategorieRead {
    /**
     * 
     * @type {number}
     * @memberof CategorieCategorieRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieRead
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieCategorieUpdate
 */
export interface CategorieCategorieUpdate {
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieUpdate
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieUpdate
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieJsonldCategorieCreate
 */
export interface CategorieJsonldCategorieCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CategorieJsonldCategorieCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreate
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreate
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieJsonldCategorieCreated
 */
export interface CategorieJsonldCategorieCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CategorieJsonldCategorieCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategorieJsonldCategorieCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreated
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreated
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieJsonldCategorieRead
 */
export interface CategorieJsonldCategorieRead {
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CategorieJsonldCategorieRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof CategorieJsonldCategorieRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieRead
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieJsonldCategorieUpdate
 */
export interface CategorieJsonldCategorieUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CategorieJsonldCategorieUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieUpdate
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieUpdate
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierCollectiondossierCreate
 */
export interface CollectionDeDossierCollectiondossierCreate {
    /**
     * 
     * @type {Array<FproDossierCollectiondossierCreate>}
     * @memberof CollectionDeDossierCollectiondossierCreate
     */
    'dossiers'?: Array<FproDossierCollectiondossierCreate>;
    /**
     * Intitulé de la collection
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierCreate
     */
    'libelle'?: string | null;
    /**
     * Information complémentaire pour la gestion de la collection
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierCreate
     */
    'infoComp'?: string | null;
    /**
     * Identifiant du prescripteur
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierCreate
     */
    'prescripteur'?: string;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierCreate
     */
    'client'?: string;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CollectionDeDossierCollectiondossierCreate
     */
    'societe'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionDeDossierCollectiondossierCreate
     */
    'urgent'?: boolean;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierCollectiondossierCreated
 */
export interface CollectionDeDossierCollectiondossierCreated {
    /**
     * 
     * @type {number}
     * @memberof CollectionDeDossierCollectiondossierCreated
     */
    'id'?: number;
    /**
     * 
     * @type {Array<FproDossierCollectiondossierCreated>}
     * @memberof CollectionDeDossierCollectiondossierCreated
     */
    'dossiers'?: Array<FproDossierCollectiondossierCreated>;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierCreated
     */
    'numero'?: string | null;
    /**
     * Intitulé de la collection
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierCreated
     */
    'libelle'?: string | null;
    /**
     * Information complémentaire pour la gestion de la collection
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierCreated
     */
    'infoComp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierCreated
     */
    'domaine'?: string;
    /**
     * Identifiant du prescripteur
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierCreated
     */
    'prescripteur'?: string;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierCreated
     */
    'client'?: string;
    /**
     * 
     * @type {object}
     * @memberof CollectionDeDossierCollectiondossierCreated
     */
    'statut'?: object;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CollectionDeDossierCollectiondossierCreated
     */
    'societe'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionDeDossierCollectiondossierCreated
     */
    'urgent'?: boolean;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierCollectiondossierRead
 */
export interface CollectionDeDossierCollectiondossierRead {
    /**
     * 
     * @type {number}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {Array<FproDossierCollectiondossierRead>}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'dossiers'?: Array<FproDossierCollectiondossierRead>;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'numero'?: string | null;
    /**
     * Intitulé de la collection
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'libelle'?: string | null;
    /**
     * Information complémentaire pour la gestion de la collection
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'infoComp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'domaine'?: string;
    /**
     * Identifiant du prescripteur
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'prescripteur'?: string;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'client'?: string;
    /**
     * 
     * @type {object}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'statut'?: object;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'societe'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'urgent'?: boolean;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierCollectiondossierUpdate
 */
export interface CollectionDeDossierCollectiondossierUpdate {
    /**
     * 
     * @type {Array<FproDossierCollectiondossierUpdate>}
     * @memberof CollectionDeDossierCollectiondossierUpdate
     */
    'dossiers'?: Array<FproDossierCollectiondossierUpdate>;
    /**
     * Intitulé de la collection
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierUpdate
     */
    'libelle'?: string | null;
    /**
     * Information complémentaire pour la gestion de la collection
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierUpdate
     */
    'infoComp'?: string | null;
    /**
     * Identifiant du prescripteur
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierUpdate
     */
    'prescripteur'?: string;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierUpdate
     */
    'client'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionDeDossierCollectiondossierUpdate
     */
    'urgent'?: boolean;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierCollectiondossierValidated
 */
export interface CollectionDeDossierCollectiondossierValidated {
    /**
     * 
     * @type {number}
     * @memberof CollectionDeDossierCollectiondossierValidated
     */
    'id'?: number;
    /**
     * 
     * @type {Array<FproDossierCollectiondossierValidated>}
     * @memberof CollectionDeDossierCollectiondossierValidated
     */
    'dossiers'?: Array<FproDossierCollectiondossierValidated>;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierValidated
     */
    'numero'?: string | null;
    /**
     * Intitulé de la collection
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierValidated
     */
    'libelle'?: string | null;
    /**
     * Information complémentaire pour la gestion de la collection
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierValidated
     */
    'infoComp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierValidated
     */
    'domaine'?: string;
    /**
     * Identifiant du prescripteur
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierValidated
     */
    'prescripteur'?: string;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierValidated
     */
    'client'?: string;
    /**
     * 
     * @type {object}
     * @memberof CollectionDeDossierCollectiondossierValidated
     */
    'statut'?: object;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CollectionDeDossierCollectiondossierValidated
     */
    'societe'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionDeDossierCollectiondossierValidated
     */
    'urgent'?: boolean;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierJsonld
 */
export interface CollectionDeDossierJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CollectionDeDossierJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonld
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierJsonldCollectiondossierCreate
 */
export interface CollectionDeDossierJsonldCollectiondossierCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<FproDossierJsonldCollectiondossierCreate>}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreate
     */
    'dossiers'?: Array<FproDossierJsonldCollectiondossierCreate>;
    /**
     * Intitulé de la collection
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreate
     */
    'libelle'?: string | null;
    /**
     * Information complémentaire pour la gestion de la collection
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreate
     */
    'infoComp'?: string | null;
    /**
     * Identifiant du prescripteur
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreate
     */
    'prescripteur'?: string;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreate
     */
    'client'?: string;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreate
     */
    'societe'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreate
     */
    'urgent'?: boolean;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierJsonldCollectiondossierCreated
 */
export interface CollectionDeDossierJsonldCollectiondossierCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    'id'?: number;
    /**
     * 
     * @type {Array<FproDossierJsonldCollectiondossierCreated>}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    'dossiers'?: Array<FproDossierJsonldCollectiondossierCreated>;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    'numero'?: string | null;
    /**
     * Intitulé de la collection
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    'libelle'?: string | null;
    /**
     * Information complémentaire pour la gestion de la collection
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    'infoComp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    'domaine'?: string;
    /**
     * Identifiant du prescripteur
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    'prescripteur'?: string;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    'client'?: string;
    /**
     * 
     * @type {TblDefStatutDossierJsonldCollectiondossierCreated}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    'statut'?: TblDefStatutDossierJsonldCollectiondossierCreated;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    'societe'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionDeDossierJsonldCollectiondossierCreated
     */
    'urgent'?: boolean;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierJsonldCollectiondossierRead
 */
export interface CollectionDeDossierJsonldCollectiondossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {Array<FproDossierJsonldCollectiondossierRead>}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'dossiers'?: Array<FproDossierJsonldCollectiondossierRead>;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'numero'?: string | null;
    /**
     * Intitulé de la collection
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'libelle'?: string | null;
    /**
     * Information complémentaire pour la gestion de la collection
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'infoComp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'domaine'?: string;
    /**
     * Identifiant du prescripteur
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'prescripteur'?: string;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'client'?: string;
    /**
     * 
     * @type {TblDefStatutDossierJsonldCollectiondossierRead}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'statut'?: TblDefStatutDossierJsonldCollectiondossierRead;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'societe'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'urgent'?: boolean;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierJsonldCollectiondossierUpdate
 */
export interface CollectionDeDossierJsonldCollectiondossierUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<FproDossierJsonldCollectiondossierUpdate>}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    'dossiers'?: Array<FproDossierJsonldCollectiondossierUpdate>;
    /**
     * Intitulé de la collection
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    'libelle'?: string | null;
    /**
     * Information complémentaire pour la gestion de la collection
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    'infoComp'?: string | null;
    /**
     * Identifiant du prescripteur
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    'prescripteur'?: string;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    'client'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    'urgent'?: boolean;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierJsonldCollectiondossierValidated
 */
export interface CollectionDeDossierJsonldCollectiondossierValidated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    'id'?: number;
    /**
     * 
     * @type {Array<FproDossierJsonldCollectiondossierValidated>}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    'dossiers'?: Array<FproDossierJsonldCollectiondossierValidated>;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    'numero'?: string | null;
    /**
     * Intitulé de la collection
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    'libelle'?: string | null;
    /**
     * Information complémentaire pour la gestion de la collection
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    'infoComp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    'domaine'?: string;
    /**
     * Identifiant du prescripteur
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    'prescripteur'?: string;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    'client'?: string;
    /**
     * 
     * @type {TblDefStatutDossierJsonldCollectiondossierValidated}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    'statut'?: TblDefStatutDossierJsonldCollectiondossierValidated;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    'societe'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionDeDossierJsonldCollectiondossierValidated
     */
    'urgent'?: boolean;
}
/**
 * 
 * @export
 * @interface DevisDevisRead
 */
export interface DevisDevisRead {
    /**
     * 
     * @type {number}
     * @memberof DevisDevisRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'totalTtc'?: string | null;
}
/**
 * 
 * @export
 * @interface DevisJsonldDevisRead
 */
export interface DevisJsonldDevisRead {
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DevisJsonldDevisRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof DevisJsonldDevisRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'totalTtc'?: string | null;
}
/**
 * Créer une route get item pour un doc cette route a besoin d\'un id
 * @export
 * @interface DocumentGedJsonld
 */
export interface DocumentGedJsonld {
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    '@type'?: string;
    /**
     * identifiant du document
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'idDocument'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentGedJsonld
     */
    '_ged_visibilty'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'api_application_tag'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentGedJsonld
     */
    'fgDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'update'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'urlDoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'filename'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof DocumentGedJsonld
     */
    'tags'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface DomainesJsonldDomaineRead
 */
export interface DomainesJsonldDomaineRead {
    /**
     * 
     * @type {string}
     * @memberof DomainesJsonldDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainesJsonldDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DomainesJsonldDomaineRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
}
/**
 * 
 * @export
 * @interface Dossier
 */
export interface Dossier {
    /**
     * 
     * @type {string}
     * @memberof Dossier
     */
    'message'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Dossier
     */
    'dossiers'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Dossier
     */
    'contacts'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DossierDossierCreate
 */
export interface DossierDossierCreate {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'contact'?: number | null;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof DossierDossierCreate
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof DossierDossierCreate
     */
    'societeDenomination'?: string | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof DossierDossierCreate
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'origine'?: number | null;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof DossierDossierCreate
     */
    'societeSiren'?: string | null;
    /**
     * ID du client
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'client'?: number | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'clientOpere'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'formaliste'?: number | null;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof DossierDossierCreate
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierCreate
     */
    'affaire'?: string | null;
    /**
     * Identifiant de la collection
     * @type {string}
     * @memberof DossierDossierCreate
     */
    'collection'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationDossierCreate>}
     * @memberof DossierDossierCreate
     */
    'prestations'?: Array<FproPrestationDossierCreate>;
}
/**
 * 
 * @export
 * @interface DossierDossierCreateJsonObject
 */
export interface DossierDossierCreateJsonObject {
    /**
     * 
     * @type {number}
     * @memberof DossierDossierCreateJsonObject
     */
    'dos_cap'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierCreateJsonObject
     */
    'dos_rbe'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierCreateJsonObject
     */
    'dos_cdm'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierCreateJsonObject
     */
    'dos_rpi'?: boolean;
    /**
     * 0: hors ressort, 1: même ressort
     * @type {boolean}
     * @memberof DossierDossierCreateJsonObject
     */
    'dos_res'?: boolean;
}
/**
 * 
 * @export
 * @interface DossierDossierRead
 */
export interface DossierDossierRead {
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'id'?: number;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DossierDossierRead
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof DossierDossierRead
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof DossierDossierRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'numero'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'dateMaj'?: string;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof DossierDossierRead
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof DossierDossierRead
     */
    'societeDenomination'?: string | null;
    /**
     * Forme juridique de la société
     * @type {string}
     * @memberof DossierDossierRead
     */
    'societeFormeJuridique'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'ttc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'cheminDoc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof DossierDossierRead
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof DossierDossierRead
     */
    'origine'?: number | null;
    /**
     * Greffe du dossier
     * @type {string}
     * @memberof DossierDossierRead
     */
    'greffe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'dateFinalise'?: string | null;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof DossierDossierRead
     */
    'societeSiren'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'cegidRegimetva'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'factureMultiple'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'recuPaiement'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idFacturemultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idDematfacile'?: number;
    /**
     * ID du client
     * @type {number}
     * @memberof DossierDossierRead
     */
    'client'?: number | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof DossierDossierRead
     */
    'clientOpere'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'factureActive'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'factureBrouillon'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'factActiveSansAvoir'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'factureBrouillonMultiple'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant de la formalite
     * @type {number}
     * @memberof DossierDossierRead
     */
    'formalite'?: number | null;
    /**
     * Statut du dossier
     * @type {number}
     * @memberof DossierDossierRead
     */
    'statut'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof DossierDossierRead
     */
    'formaliste'?: number | null;
    /**
     * Adresse de facturation du dossier
     * @type {Array<object>}
     * @memberof DossierDossierRead
     */
    'adresses'?: Array<object>;
    /**
     * 
     * @type {Array<FproDossierDocDossierRead>}
     * @memberof DossierDossierRead
     */
    'documents'?: Array<FproDossierDocDossierRead>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierDossierRead
     */
    'fichiers'?: Array<string>;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof DossierDossierRead
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'marque'?: number | null;
    /**
     * Identifiant de la collection
     * @type {string}
     * @memberof DossierDossierRead
     */
    'collection'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationDossierRead>}
     * @memberof DossierDossierRead
     */
    'prestations'?: Array<FproPrestationDossierRead>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierDossierRead
     */
    'objectGroups'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DossierDossierUpdate
 */
export interface DossierDossierUpdate {
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof DossierDossierUpdate
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof DossierDossierUpdate
     */
    'societeDenomination'?: string | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof DossierDossierUpdate
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof DossierDossierUpdate
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof DossierDossierUpdate
     */
    'societeSiren'?: string | null;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof DossierDossierUpdate
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierUpdate
     */
    'affaire'?: string | null;
    /**
     * Identifiant de la collection
     * @type {string}
     * @memberof DossierDossierUpdate
     */
    'collection'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierDossierUpdatePartial
 */
export interface DossierDossierUpdatePartial {
    /**
     * ID du client
     * @type {number}
     * @memberof DossierDossierUpdatePartial
     */
    'client'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierUpdatePartial
     */
    'typeFacturation'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierDossierUpdatePartial
     */
    'objectGroups'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DossierDossiersGroupesPatchRequestDto
 */
export interface DossierDossiersGroupesPatchRequestDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof DossierDossiersGroupesPatchRequestDto
     */
    'dossiers'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DossierDossiersGroupesPatchRequestDto
     */
    'groups'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DossierDossiersGroupesPatchResponseDtoDossierRead
 */
export interface DossierDossiersGroupesPatchResponseDtoDossierRead {
    /**
     * 
     * @type {Array<number>}
     * @memberof DossierDossiersGroupesPatchResponseDtoDossierRead
     */
    'dossiers'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DossierDossiersGroupesPatchResponseDtoDossierRead
     */
    'groups'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DossierDossiersGroupesPatchResponseDtoJsonldDossierRead
 */
export interface DossierDossiersGroupesPatchResponseDtoJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierDossiersGroupesPatchResponseDtoJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierDossiersGroupesPatchResponseDtoJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierDossiersGroupesPatchResponseDtoJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DossierDossiersGroupesPatchResponseDtoJsonldDossierRead
     */
    'dossiers'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DossierDossiersGroupesPatchResponseDtoJsonldDossierRead
     */
    'groups'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DossierJsonld
 */
export interface DossierJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonld
     */
    'message'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DossierJsonld
     */
    'dossiers'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DossierJsonld
     */
    'contacts'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DossierJsonldDossierCreate
 */
export interface DossierJsonldDossierCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierJsonldDossierCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    '@type'?: string;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'contact'?: number | null;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    'societeDenomination'?: string | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof DossierJsonldDossierCreate
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'origine'?: number | null;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    'societeSiren'?: string | null;
    /**
     * ID du client
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'client'?: number | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'clientOpere'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'formaliste'?: number | null;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    'affaire'?: string | null;
    /**
     * Identifiant de la collection
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    'collection'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationJsonldDossierCreate>}
     * @memberof DossierJsonldDossierCreate
     */
    'prestations'?: Array<FproPrestationJsonldDossierCreate>;
}
/**
 * 
 * @export
 * @interface DossierJsonldDossierRead
 */
export interface DossierJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'id'?: number;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'numero'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'dateMaj'?: string;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'societeDenomination'?: string | null;
    /**
     * Forme juridique de la société
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'societeFormeJuridique'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'ttc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'cheminDoc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof DossierJsonldDossierRead
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'origine'?: number | null;
    /**
     * Greffe du dossier
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'greffe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'dateFinalise'?: string | null;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'societeSiren'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'cegidRegimetva'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'factureMultiple'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'recuPaiement'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idFacturemultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idDematfacile'?: number;
    /**
     * ID du client
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'client'?: number | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'clientOpere'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'factureActive'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'factureBrouillon'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'factActiveSansAvoir'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'factureBrouillonMultiple'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant de la formalite
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'formalite'?: number | null;
    /**
     * Statut du dossier
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'statut'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'formaliste'?: number | null;
    /**
     * Adresse de facturation du dossier
     * @type {Array<FproAdresseFactJsonldDossierRead>}
     * @memberof DossierJsonldDossierRead
     */
    'adresses'?: Array<FproAdresseFactJsonldDossierRead>;
    /**
     * 
     * @type {Array<FproDossierDocJsonldDossierRead>}
     * @memberof DossierJsonldDossierRead
     */
    'documents'?: Array<FproDossierDocJsonldDossierRead>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierJsonldDossierRead
     */
    'fichiers'?: Array<string>;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'marque'?: number | null;
    /**
     * Identifiant de la collection
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'collection'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationJsonldDossierRead>}
     * @memberof DossierJsonldDossierRead
     */
    'prestations'?: Array<FproPrestationJsonldDossierRead>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierJsonldDossierRead
     */
    'objectGroups'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DossierJsonldDossierTransitionRead
 */
export interface DossierJsonldDossierTransitionRead {
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierTransitionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierTransitionRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface DossierJsonldDossierUpdate
 */
export interface DossierJsonldDossierUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierJsonldDossierUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    '@type'?: string;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    'societeDenomination'?: string | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof DossierJsonldDossierUpdate
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof DossierJsonldDossierUpdate
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    'societeSiren'?: string | null;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    'affaire'?: string | null;
    /**
     * Identifiant de la collection
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    'collection'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchIndexBatchDossierResponse
 */
export interface DossierOpenSearchIndexBatchDossierResponse {
    /**
     * 
     * @type {Array<DossierOpenSearchIndexBatchDossierResponseItemsInner>}
     * @memberof DossierOpenSearchIndexBatchDossierResponse
     */
    'items'?: Array<DossierOpenSearchIndexBatchDossierResponseItemsInner>;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchIndexBatchDossierResponseItemsInner
 */
export interface DossierOpenSearchIndexBatchDossierResponseItemsInner {
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchIndexBatchDossierResponseItemsInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchIndexBatchDossierResponseItemsInner
     */
    'status'?: DossierOpenSearchIndexBatchDossierResponseItemsInnerStatusEnum;
}

export const DossierOpenSearchIndexBatchDossierResponseItemsInnerStatusEnum = {
    Ok: 'OK',
    NotFound: 'NOT FOUND'
} as const;

export type DossierOpenSearchIndexBatchDossierResponseItemsInnerStatusEnum = typeof DossierOpenSearchIndexBatchDossierResponseItemsInnerStatusEnum[keyof typeof DossierOpenSearchIndexBatchDossierResponseItemsInnerStatusEnum];

/**
 * 
 * @export
 * @interface DossierOpenSearchIndexBatchDossierResponseJsonld
 */
export interface DossierOpenSearchIndexBatchDossierResponseJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierOpenSearchIndexBatchDossierResponseJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchIndexBatchDossierResponseJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchIndexBatchDossierResponseJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<DossierOpenSearchIndexBatchDossierResponseItemsInner>}
     * @memberof DossierOpenSearchIndexBatchDossierResponseJsonld
     */
    'items'?: Array<DossierOpenSearchIndexBatchDossierResponseItemsInner>;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchJsonldOpensearchDossierClientRead
 */
export interface DossierOpenSearchJsonldOpensearchDossierClientRead {
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierClientRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierClientRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierClientRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierClientRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierClientRead
     */
    'prescripteurId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierClientRead
     */
    'prescripteurLibelle'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchJsonldOpensearchDossierCollectionRead
 */
export interface DossierOpenSearchJsonldOpensearchDossierCollectionRead {
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierCollectionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierCollectionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierCollectionRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierCollectionRead
     */
    'numero'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchJsonldOpensearchDossierFormalisteRead
 */
export interface DossierOpenSearchJsonldOpensearchDossierFormalisteRead {
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierFormalisteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierFormalisteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierFormalisteRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierFormalisteRead
     */
    'nomComplet'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchJsonldOpensearchDossierFormaliteRead
 */
export interface DossierOpenSearchJsonldOpensearchDossierFormaliteRead {
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierFormaliteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierFormaliteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierFormaliteRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierFormaliteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierFormaliteRead
     */
    'groupementId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierFormaliteRead
     */
    'groupementLibelle'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchJsonldOpensearchDossierIndexBatch
 */
export interface DossierOpenSearchJsonldOpensearchDossierIndexBatch {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierOpenSearchJsonldOpensearchDossierIndexBatch
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierIndexBatch
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierIndexBatch
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DossierOpenSearchJsonldOpensearchDossierIndexBatch
     */
    'ids'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchJsonldOpensearchDossierObjectGroupRead
 */
export interface DossierOpenSearchJsonldOpensearchDossierObjectGroupRead {
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierObjectGroupRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierObjectGroupRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierObjectGroupRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierObjectGroupRead
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierObjectGroupRead
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierObjectGroupRead
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierObjectGroupRead
     */
    'icon'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchJsonldOpensearchDossierRead
 */
export interface DossierOpenSearchJsonldOpensearchDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'prescripteurId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'prescripteurLibelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'prescripteurSiren'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'collectionId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'collectionNumero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'clientOpereId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'clientOpereLibelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'clientOpereSiren'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'clientOpereAdresseComplete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'clientId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'clientLibelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'clientSiren'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'clientAdresseComplete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'contactId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'contactNom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'contactPrenom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'dateUpdate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'documentsCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'missingRequiredDocumentsCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'statutId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'statutLibelle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'stepId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'stepLabel'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'stepCompletion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'formalisteId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'formalisteNom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'formalistePrenom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'formalisteNomComplet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'societeRef'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'societeDenomination'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'critical'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'typeFacturation'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'formalites'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'groups'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierOpenSearchJsonldOpensearchDossierRead
     */
    'transitions'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchJsonldOpensearchDossierSocieteRefRead
 */
export interface DossierOpenSearchJsonldOpensearchDossierSocieteRefRead {
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierSocieteRefRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierSocieteRefRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierSocieteRefRead
     */
    'reference'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchJsonldOpensearchDossierStatutRead
 */
export interface DossierOpenSearchJsonldOpensearchDossierStatutRead {
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierStatutRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierStatutRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchJsonldOpensearchDossierStatutRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchJsonldOpensearchDossierStatutRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchOpensearchDossierClientRead
 */
export interface DossierOpenSearchOpensearchDossierClientRead {
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierClientRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierClientRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierClientRead
     */
    'prescripteurId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierClientRead
     */
    'prescripteurLibelle'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchOpensearchDossierCollectionRead
 */
export interface DossierOpenSearchOpensearchDossierCollectionRead {
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierCollectionRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierCollectionRead
     */
    'numero'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchOpensearchDossierFormalisteRead
 */
export interface DossierOpenSearchOpensearchDossierFormalisteRead {
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierFormalisteRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierFormalisteRead
     */
    'nomComplet'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchOpensearchDossierFormaliteRead
 */
export interface DossierOpenSearchOpensearchDossierFormaliteRead {
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierFormaliteRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierFormaliteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierFormaliteRead
     */
    'groupementId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierFormaliteRead
     */
    'groupementLibelle'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchOpensearchDossierIndexBatch
 */
export interface DossierOpenSearchOpensearchDossierIndexBatch {
    /**
     * 
     * @type {Array<number>}
     * @memberof DossierOpenSearchOpensearchDossierIndexBatch
     */
    'ids'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchOpensearchDossierObjectGroupRead
 */
export interface DossierOpenSearchOpensearchDossierObjectGroupRead {
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierObjectGroupRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierObjectGroupRead
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierObjectGroupRead
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierObjectGroupRead
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierObjectGroupRead
     */
    'icon'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchOpensearchDossierRead
 */
export interface DossierOpenSearchOpensearchDossierRead {
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'prescripteurId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'prescripteurLibelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'prescripteurSiren'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'collectionId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'collectionNumero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'clientOpereId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'clientOpereLibelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'clientOpereSiren'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'clientOpereAdresseComplete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'clientId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'clientLibelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'clientSiren'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'clientAdresseComplete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'contactId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'contactNom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'contactPrenom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'dateUpdate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'documentsCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'missingRequiredDocumentsCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'statutId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'statutLibelle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'stepId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'stepLabel'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'stepCompletion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'formalisteId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'formalisteNom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'formalistePrenom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'formalisteNomComplet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'societeRef'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'societeDenomination'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'critical'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'typeFacturation'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'formalites'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'groups'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierOpenSearchOpensearchDossierRead
     */
    'transitions'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchOpensearchDossierSocieteRefRead
 */
export interface DossierOpenSearchOpensearchDossierSocieteRefRead {
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierSocieteRefRead
     */
    'reference'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierOpenSearchOpensearchDossierStatutRead
 */
export interface DossierOpenSearchOpensearchDossierStatutRead {
    /**
     * 
     * @type {number}
     * @memberof DossierOpenSearchOpensearchDossierStatutRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierOpenSearchOpensearchDossierStatutRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierSendDossierReportResponse
 */
export interface DossierSendDossierReportResponse {
    /**
     * 
     * @type {Array<DossierSendDossierReportResponseItemsInner>}
     * @memberof DossierSendDossierReportResponse
     */
    'items'?: Array<DossierSendDossierReportResponseItemsInner> | null;
}
/**
 * 
 * @export
 * @interface DossierSendDossierReportResponseItemsInner
 */
export interface DossierSendDossierReportResponseItemsInner {
    /**
     * 
     * @type {number}
     * @memberof DossierSendDossierReportResponseItemsInner
     */
    'dossier'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierSendDossierReportResponseItemsInner
     */
    'contact'?: number;
    /**
     * 
     * @type {string}
     * @memberof DossierSendDossierReportResponseItemsInner
     */
    'status'?: DossierSendDossierReportResponseItemsInnerStatusEnum;
}

export const DossierSendDossierReportResponseItemsInnerStatusEnum = {
    Ok: 'OK',
    Ko: 'KO'
} as const;

export type DossierSendDossierReportResponseItemsInnerStatusEnum = typeof DossierSendDossierReportResponseItemsInnerStatusEnum[keyof typeof DossierSendDossierReportResponseItemsInnerStatusEnum];

/**
 * 
 * @export
 * @interface DossierSendDossierReportResponseJsonld
 */
export interface DossierSendDossierReportResponseJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierSendDossierReportResponseJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierSendDossierReportResponseJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierSendDossierReportResponseJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<DossierSendDossierReportResponseItemsInner>}
     * @memberof DossierSendDossierReportResponseJsonld
     */
    'items'?: Array<DossierSendDossierReportResponseItemsInner> | null;
}
/**
 * 
 * @export
 * @interface FichiersDossierDocumentfileRead
 */
export interface FichiersDossierDocumentfileRead {
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'fichier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'chemin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'idGed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'dateAjout'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'dateUpdate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileRead
     */
    'expediteur'?: FichiersDossierDocumentfileReadExpediteurEnum | null;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileRead
     */
    'expedition'?: FichiersDossierDocumentfileReadExpeditionEnum | null;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileRead
     */
    'idDocDf'?: number;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileRead
     */
    'signed'?: number;
    /**
     * 
     * @type {FproDossierDocDocumentfileRead}
     * @memberof FichiersDossierDocumentfileRead
     */
    'typeDocDossier'?: FproDossierDocDocumentfileRead;
    /**
     * 
     * @type {FproDosStatutDocumentfileRead}
     * @memberof FichiersDossierDocumentfileRead
     */
    'statut'?: FproDosStatutDocumentfileRead | null;
    /**
     * 
     * @type {FproDosDocFileDocumentfileRead}
     * @memberof FichiersDossierDocumentfileRead
     */
    'docSigned'?: FproDosDocFileDocumentfileRead | null;
    /**
     * 
     * @type {FproDosDocFileDocumentfileRead}
     * @memberof FichiersDossierDocumentfileRead
     */
    'docOriginal'?: FproDosDocFileDocumentfileRead | null;
    /**
     * 
     * @type {TblTypeDocumentDocumentfileRead}
     * @memberof FichiersDossierDocumentfileRead
     */
    'typedocIADoc'?: TblTypeDocumentDocumentfileRead | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'jsonIADoc'?: string | null;
}

export const FichiersDossierDocumentfileReadExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FichiersDossierDocumentfileReadExpediteurEnum = typeof FichiersDossierDocumentfileReadExpediteurEnum[keyof typeof FichiersDossierDocumentfileReadExpediteurEnum];
export const FichiersDossierDocumentfileReadExpeditionEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FichiersDossierDocumentfileReadExpeditionEnum = typeof FichiersDossierDocumentfileReadExpeditionEnum[keyof typeof FichiersDossierDocumentfileReadExpeditionEnum];

/**
 * 
 * @export
 * @interface FichiersDossierDocumentfileUpdate
 */
export interface FichiersDossierDocumentfileUpdate {
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileUpdate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface FichiersDossierJsonldDocumentfileRead
 */
export interface FichiersDossierJsonldDocumentfileRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'fichier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'chemin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'idGed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'dateAjout'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'dateUpdate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'expediteur'?: FichiersDossierJsonldDocumentfileReadExpediteurEnum | null;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'expedition'?: FichiersDossierJsonldDocumentfileReadExpeditionEnum | null;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'idDocDf'?: number;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'signed'?: number;
    /**
     * 
     * @type {FproDossierDocJsonldDocumentfileRead}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'typeDocDossier'?: FproDossierDocJsonldDocumentfileRead;
    /**
     * 
     * @type {FproDosStatutJsonldDocumentfileRead}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'statut'?: FproDosStatutJsonldDocumentfileRead | null;
    /**
     * 
     * @type {FproDosDocFileJsonldDocumentfileRead}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'docSigned'?: FproDosDocFileJsonldDocumentfileRead | null;
    /**
     * 
     * @type {FproDosDocFileJsonldDocumentfileRead}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'docOriginal'?: FproDosDocFileJsonldDocumentfileRead | null;
    /**
     * 
     * @type {TblTypeDocumentJsonldDocumentfileRead}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'typedocIADoc'?: TblTypeDocumentJsonldDocumentfileRead | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'jsonIADoc'?: string | null;
}

export const FichiersDossierJsonldDocumentfileReadExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FichiersDossierJsonldDocumentfileReadExpediteurEnum = typeof FichiersDossierJsonldDocumentfileReadExpediteurEnum[keyof typeof FichiersDossierJsonldDocumentfileReadExpediteurEnum];
export const FichiersDossierJsonldDocumentfileReadExpeditionEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FichiersDossierJsonldDocumentfileReadExpeditionEnum = typeof FichiersDossierJsonldDocumentfileReadExpeditionEnum[keyof typeof FichiersDossierJsonldDocumentfileReadExpeditionEnum];

/**
 * 
 * @export
 * @interface FormalisteFormalisteRead
 */
export interface FormalisteFormalisteRead {
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormalisteFormalisteRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteFormalisteRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteFormalisteRead
     */
    'initiales'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteFormalisteRead
     */
    'mail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteFormalisteRead
     */
    'fmistDemo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteFormalisteRead
     */
    'fmistAdmin'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'fmistDroitDosMod'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'fmistDroitDosLect'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'fmistDroitGestMod'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'fmistDroitGestLect'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'fmistDroitDosSup'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteFormalisteRead
     */
    'fmistFormaliste'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteFormalisteRead
     */
    'accesFpro'?: boolean;
}
/**
 * 
 * @export
 * @interface FormalisteJsonldFormalisteRead
 */
export interface FormalisteJsonldFormalisteRead {
    /**
     * 
     * @type {string}
     * @memberof FormalisteJsonldFormalisteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteJsonldFormalisteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormalisteJsonldFormalisteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'initiales'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'mail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistDemo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistAdmin'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistDroitDosMod'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistDroitDosLect'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistDroitGestMod'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistDroitGestLect'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistDroitDosSup'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistFormaliste'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'accesFpro'?: boolean;
}
/**
 * 
 * @export
 * @interface FormaliteFormaliteCreate
 */
export interface FormaliteFormaliteCreate {
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreate
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteFormaliteCreate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreate
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteCreate
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteCreate
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteCreate
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreate
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreate
     */
    'formulaire'?: string | null;
    /**
     * Ordonnancement des formalités
     * @type {number}
     * @memberof FormaliteFormaliteCreate
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface FormaliteFormaliteCreated
 */
export interface FormaliteFormaliteCreated {
    /**
     * 
     * @type {number}
     * @memberof FormaliteFormaliteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreated
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreated
     */
    'libelle': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteFormaliteCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreated
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteCreated
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteCreated
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteCreated
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreated
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreated
     */
    'formulaire'?: string | null;
    /**
     * Ordonnancement des formalités
     * @type {number}
     * @memberof FormaliteFormaliteCreated
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface FormaliteFormaliteRead
 */
export interface FormaliteFormaliteRead {
    /**
     * 
     * @type {number}
     * @memberof FormaliteFormaliteRead
     */
    'id'?: number;
    /**
     * 
     * @type {FproFormaliteCategorieFormaliteRead}
     * @memberof FormaliteFormaliteRead
     */
    'categorie'?: FproFormaliteCategorieFormaliteRead | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteFormaliteRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteRead
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteRead
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteRead
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteRead
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteRead
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteRead
     */
    'formulaire'?: string | null;
    /**
     * Ordonnancement des formalités
     * @type {number}
     * @memberof FormaliteFormaliteRead
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface FormaliteFormaliteUpdate
 */
export interface FormaliteFormaliteUpdate {
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteUpdate
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteFormaliteUpdate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteUpdate
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteUpdate
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteUpdate
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteUpdate
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteUpdate
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteUpdate
     */
    'formulaire'?: string | null;
    /**
     * Ordonnancement des formalités
     * @type {number}
     * @memberof FormaliteFormaliteUpdate
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface FormaliteJsonldFormaliteCreate
 */
export interface FormaliteJsonldFormaliteCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'formulaire'?: string | null;
    /**
     * Ordonnancement des formalités
     * @type {number}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface FormaliteJsonldFormaliteCreated
 */
export interface FormaliteJsonldFormaliteCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'libelle': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'formulaire'?: string | null;
    /**
     * Ordonnancement des formalités
     * @type {number}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface FormaliteJsonldFormaliteRead
 */
export interface FormaliteJsonldFormaliteRead {
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormaliteJsonldFormaliteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'id'?: number;
    /**
     * 
     * @type {FproFormaliteCategorieJsonldFormaliteRead}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'categorie'?: FproFormaliteCategorieJsonldFormaliteRead | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'formulaire'?: string | null;
    /**
     * Ordonnancement des formalités
     * @type {number}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface FormaliteJsonldFormaliteUpdate
 */
export interface FormaliteJsonldFormaliteUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'formulaire'?: string | null;
    /**
     * Ordonnancement des formalités
     * @type {number}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface FormeJuridiqueFormejuridiqueRead
 */
export interface FormeJuridiqueFormejuridiqueRead {
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'abreviation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'dirigeant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'dirigeance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'fObjetDuree'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'fCapvar'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'fAgrement'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'fSci'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'nomenclature'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'idparent'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'fAfficheform'?: boolean;
}
/**
 * 
 * @export
 * @interface FormeJuridiqueJsonldFormejuridiqueRead
 */
export interface FormeJuridiqueJsonldFormejuridiqueRead {
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'abreviation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'dirigeant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'dirigeance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'fObjetDuree'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'fCapvar'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'fAgrement'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'fSci'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'nomenclature'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'idparent'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'fAfficheform'?: boolean;
}
/**
 * 
 * @export
 * @interface FproAdresseFactJsonldCollectiondossierRead
 */
export interface FproAdresseFactJsonldCollectiondossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproAdresseFactJsonldCollectiondossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproAdresseFactJsonldCollectiondossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproAdresseFactJsonldCollectiondossierRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FproAdresseFactJsonldDossierRead
 */
export interface FproAdresseFactJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproAdresseFactJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproAdresseFactJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproAdresseFactJsonldDossierRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FproDosDocFileDocumentfileCreate
 */
export interface FproDosDocFileDocumentfileCreate {
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileDocumentfileCreate
     */
    'expediteur'?: FproDosDocFileDocumentfileCreateExpediteurEnum | null;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileDocumentfileCreate
     */
    'expedition'?: FproDosDocFileDocumentfileCreateExpeditionEnum | null;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileDocumentfileCreate
     */
    'idDocDf'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileCreate
     */
    'typeDoc'?: string;
    /**
     * 
     * @type {FproDosDocFileDocumentfileCreate}
     * @memberof FproDosDocFileDocumentfileCreate
     */
    'docSigned'?: FproDosDocFileDocumentfileCreate | null;
    /**
     * 
     * @type {FproDosDocFileDocumentfileCreate}
     * @memberof FproDosDocFileDocumentfileCreate
     */
    'docOriginal'?: FproDosDocFileDocumentfileCreate | null;
    /**
     * 
     * @type {File}
     * @memberof FproDosDocFileDocumentfileCreate
     */
    'file'?: File | null;
    /**
     * 
     * @type {boolean}
     * @memberof FproDosDocFileDocumentfileCreate
     */
    'typageAuto'?: boolean;
}

export const FproDosDocFileDocumentfileCreateExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FproDosDocFileDocumentfileCreateExpediteurEnum = typeof FproDosDocFileDocumentfileCreateExpediteurEnum[keyof typeof FproDosDocFileDocumentfileCreateExpediteurEnum];
export const FproDosDocFileDocumentfileCreateExpeditionEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FproDosDocFileDocumentfileCreateExpeditionEnum = typeof FproDosDocFileDocumentfileCreateExpeditionEnum[keyof typeof FproDosDocFileDocumentfileCreateExpeditionEnum];

/**
 * 
 * @export
 * @interface FproDosDocFileDocumentfileRead
 */
export interface FproDosDocFileDocumentfileRead {
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'fichier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'chemin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'idGed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'dateAjout'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'dateUpdate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'expediteur'?: FproDosDocFileDocumentfileReadExpediteurEnum | null;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'expedition'?: FproDosDocFileDocumentfileReadExpeditionEnum | null;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'idDocDf'?: number;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'signed'?: number;
    /**
     * 
     * @type {FproDossierDocDocumentfileRead}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'typeDocDossier'?: FproDossierDocDocumentfileRead;
    /**
     * 
     * @type {FproDosStatutDocumentfileRead}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'statut'?: FproDosStatutDocumentfileRead | null;
    /**
     * 
     * @type {FproDosDocFileDocumentfileRead}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'docSigned'?: FproDosDocFileDocumentfileRead | null;
    /**
     * 
     * @type {FproDosDocFileDocumentfileRead}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'docOriginal'?: FproDosDocFileDocumentfileRead | null;
    /**
     * 
     * @type {TblTypeDocumentDocumentfileRead}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'typedocIADoc'?: TblTypeDocumentDocumentfileRead | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'jsonIADoc'?: string | null;
}

export const FproDosDocFileDocumentfileReadExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FproDosDocFileDocumentfileReadExpediteurEnum = typeof FproDosDocFileDocumentfileReadExpediteurEnum[keyof typeof FproDosDocFileDocumentfileReadExpediteurEnum];
export const FproDosDocFileDocumentfileReadExpeditionEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FproDosDocFileDocumentfileReadExpeditionEnum = typeof FproDosDocFileDocumentfileReadExpeditionEnum[keyof typeof FproDosDocFileDocumentfileReadExpeditionEnum];

/**
 * 
 * @export
 * @interface FproDosDocFileJsonldDocumentfileRead
 */
export interface FproDosDocFileJsonldDocumentfileRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'fichier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'chemin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'idGed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'dateAjout'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'dateUpdate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'expediteur'?: FproDosDocFileJsonldDocumentfileReadExpediteurEnum | null;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'expedition'?: FproDosDocFileJsonldDocumentfileReadExpeditionEnum | null;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'idDocDf'?: number;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'signed'?: number;
    /**
     * 
     * @type {FproDossierDocJsonldDocumentfileRead}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'typeDocDossier'?: FproDossierDocJsonldDocumentfileRead;
    /**
     * 
     * @type {FproDosStatutJsonldDocumentfileRead}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'statut'?: FproDosStatutJsonldDocumentfileRead | null;
    /**
     * 
     * @type {FproDosDocFileJsonldDocumentfileRead}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'docSigned'?: FproDosDocFileJsonldDocumentfileRead | null;
    /**
     * 
     * @type {FproDosDocFileJsonldDocumentfileRead}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'docOriginal'?: FproDosDocFileJsonldDocumentfileRead | null;
    /**
     * 
     * @type {TblTypeDocumentJsonldDocumentfileRead}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'typedocIADoc'?: TblTypeDocumentJsonldDocumentfileRead | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'jsonIADoc'?: string | null;
}

export const FproDosDocFileJsonldDocumentfileReadExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FproDosDocFileJsonldDocumentfileReadExpediteurEnum = typeof FproDosDocFileJsonldDocumentfileReadExpediteurEnum[keyof typeof FproDosDocFileJsonldDocumentfileReadExpediteurEnum];
export const FproDosDocFileJsonldDocumentfileReadExpeditionEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FproDosDocFileJsonldDocumentfileReadExpeditionEnum = typeof FproDosDocFileJsonldDocumentfileReadExpeditionEnum[keyof typeof FproDosDocFileJsonldDocumentfileReadExpeditionEnum];

/**
 * 
 * @export
 * @interface FproDosStatutDocumentfileRead
 */
export interface FproDosStatutDocumentfileRead {
    /**
     * 
     * @type {number}
     * @memberof FproDosStatutDocumentfileRead
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof FproDosStatutDocumentfileRead
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutDocumentfileRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutDocumentfileRead
     */
    'class'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutDocumentfileRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FproDosStatutDocumentfileRead
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FproDosStatutDocumentfileRead
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FproDosStatutDocumentfileRead
     */
    'affichefront'?: boolean;
}
/**
 * 
 * @export
 * @interface FproDosStatutJsonldDocumentfileRead
 */
export interface FproDosStatutJsonldDocumentfileRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'class'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'affichefront'?: boolean;
}
/**
 * 
 * @export
 * @interface FproDossierActiviteCreated
 */
export interface FproDossierActiviteCreated {
    /**
     * 
     * @type {number}
     * @memberof FproDossierActiviteCreated
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface FproDossierActiviteRead
 */
export interface FproDossierActiviteRead {
    /**
     * 
     * @type {number}
     * @memberof FproDossierActiviteRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface FproDossierCollectiondossierCreate
 */
export interface FproDossierCollectiondossierCreate {
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof FproDossierCollectiondossierCreate
     */
    'contact'?: number | null;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof FproDossierCollectiondossierCreate
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof FproDossierCollectiondossierCreate
     */
    'societeDenomination'?: string | null;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof FproDossierCollectiondossierCreate
     */
    'origine'?: number | null;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof FproDossierCollectiondossierCreate
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof FproDossierCollectiondossierCreate
     */
    'societeSiren'?: string | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof FproDossierCollectiondossierCreate
     */
    'clientOpere'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof FproDossierCollectiondossierCreate
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof FproDossierCollectiondossierCreate
     */
    'formaliste'?: number | null;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof FproDossierCollectiondossierCreate
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierCreate
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationCollectiondossierCreate>}
     * @memberof FproDossierCollectiondossierCreate
     */
    'prestations'?: Array<FproPrestationCollectiondossierCreate>;
}
/**
 * 
 * @export
 * @interface FproDossierCollectiondossierCreated
 */
export interface FproDossierCollectiondossierCreated {
    /**
     * 
     * @type {number}
     * @memberof FproDossierCollectiondossierCreated
     */
    'id'?: number;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FproDossierCollectiondossierCreated
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof FproDossierCollectiondossierCreated
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof FproDossierCollectiondossierCreated
     */
    'contact'?: number | null;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof FproDossierCollectiondossierCreated
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof FproDossierCollectiondossierCreated
     */
    'societeDenomination'?: string | null;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof FproDossierCollectiondossierCreated
     */
    'origine'?: number | null;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof FproDossierCollectiondossierCreated
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof FproDossierCollectiondossierCreated
     */
    'societeSiren'?: string | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof FproDossierCollectiondossierCreated
     */
    'clientOpere'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof FproDossierCollectiondossierCreated
     */
    'idFormeJuridique'?: number | null;
    /**
     * Statut du dossier
     * @type {number}
     * @memberof FproDossierCollectiondossierCreated
     */
    'statut'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof FproDossierCollectiondossierCreated
     */
    'formaliste'?: number | null;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof FproDossierCollectiondossierCreated
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierCreated
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationCollectiondossierCreated>}
     * @memberof FproDossierCollectiondossierCreated
     */
    'prestations'?: Array<FproPrestationCollectiondossierCreated>;
}
/**
 * 
 * @export
 * @interface FproDossierCollectiondossierRead
 */
export interface FproDossierCollectiondossierRead {
    /**
     * 
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'id'?: number;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'numero'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FproDossierCollectiondossierRead
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'dateMaj'?: string;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'societeDenomination'?: string | null;
    /**
     * Forme juridique de la société
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'societeFormeJuridique'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'ttc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FproDossierCollectiondossierRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'cheminDoc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FproDossierCollectiondossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof FproDossierCollectiondossierRead
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'origine'?: number | null;
    /**
     * Greffe du dossier
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'greffe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'dateFinalise'?: string | null;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'societeSiren'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'cegidRegimetva'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FproDossierCollectiondossierRead
     */
    'factureMultiple'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FproDossierCollectiondossierRead
     */
    'recuPaiement'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'idFacturemultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'idDematfacile'?: number;
    /**
     * ID du client
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'client'?: number | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'clientOpere'?: number;
    /**
     * 
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'factureActive'?: number;
    /**
     * 
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'factureBrouillon'?: number;
    /**
     * 
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'factActiveSansAvoir'?: number;
    /**
     * 
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'factureBrouillonMultiple'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant de la formalite
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'formalite'?: number | null;
    /**
     * Statut du dossier
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'statut'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'formaliste'?: number | null;
    /**
     * Adresse de facturation du dossier
     * @type {Array<object>}
     * @memberof FproDossierCollectiondossierRead
     */
    'adresses'?: Array<object>;
    /**
     * 
     * @type {Array<FproDossierDocCollectiondossierRead>}
     * @memberof FproDossierCollectiondossierRead
     */
    'documents'?: Array<FproDossierDocCollectiondossierRead>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FproDossierCollectiondossierRead
     */
    'fichiers'?: Array<string>;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierRead
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FproDossierCollectiondossierRead
     */
    'marque'?: number | null;
    /**
     * 
     * @type {Array<FproPrestationCollectiondossierRead>}
     * @memberof FproDossierCollectiondossierRead
     */
    'prestations'?: Array<FproPrestationCollectiondossierRead>;
}
/**
 * 
 * @export
 * @interface FproDossierCollectiondossierUpdate
 */
export interface FproDossierCollectiondossierUpdate {
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof FproDossierCollectiondossierUpdate
     */
    'contact'?: number | null;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof FproDossierCollectiondossierUpdate
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof FproDossierCollectiondossierUpdate
     */
    'societeDenomination'?: string | null;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof FproDossierCollectiondossierUpdate
     */
    'origine'?: number | null;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof FproDossierCollectiondossierUpdate
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof FproDossierCollectiondossierUpdate
     */
    'societeSiren'?: string | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof FproDossierCollectiondossierUpdate
     */
    'clientOpere'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof FproDossierCollectiondossierUpdate
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof FproDossierCollectiondossierUpdate
     */
    'formaliste'?: number | null;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof FproDossierCollectiondossierUpdate
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierUpdate
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationCollectiondossierUpdate>}
     * @memberof FproDossierCollectiondossierUpdate
     */
    'prestations'?: Array<FproPrestationCollectiondossierUpdate>;
}
/**
 * 
 * @export
 * @interface FproDossierCollectiondossierValidated
 */
export interface FproDossierCollectiondossierValidated {
    /**
     * 
     * @type {number}
     * @memberof FproDossierCollectiondossierValidated
     */
    'id'?: number;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FproDossierCollectiondossierValidated
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof FproDossierCollectiondossierValidated
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof FproDossierCollectiondossierValidated
     */
    'contact'?: number | null;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof FproDossierCollectiondossierValidated
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof FproDossierCollectiondossierValidated
     */
    'societeDenomination'?: string | null;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof FproDossierCollectiondossierValidated
     */
    'origine'?: number | null;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof FproDossierCollectiondossierValidated
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof FproDossierCollectiondossierValidated
     */
    'societeSiren'?: string | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof FproDossierCollectiondossierValidated
     */
    'clientOpere'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof FproDossierCollectiondossierValidated
     */
    'idFormeJuridique'?: number | null;
    /**
     * Statut du dossier
     * @type {number}
     * @memberof FproDossierCollectiondossierValidated
     */
    'statut'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof FproDossierCollectiondossierValidated
     */
    'formaliste'?: number | null;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof FproDossierCollectiondossierValidated
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierCollectiondossierValidated
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationCollectiondossierValidated>}
     * @memberof FproDossierCollectiondossierValidated
     */
    'prestations'?: Array<FproPrestationCollectiondossierValidated>;
}
/**
 * 
 * @export
 * @interface FproDossierDocCollectiondossierRead
 */
export interface FproDossierDocCollectiondossierRead {
    /**
     * 
     * @type {number}
     * @memberof FproDossierDocCollectiondossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocCollectiondossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocCollectiondossierRead
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocCollectiondossierRead
     */
    'statut'?: string | null;
}
/**
 * 
 * @export
 * @interface FproDossierDocDocumentfileRead
 */
export interface FproDossierDocDocumentfileRead {
    /**
     * 
     * @type {number}
     * @memberof FproDossierDocDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocDocumentfileRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocDocumentfileRead
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {TblTypeDocumentDocumentfileRead}
     * @memberof FproDossierDocDocumentfileRead
     */
    'document'?: TblTypeDocumentDocumentfileRead | null;
    /**
     * 
     * @type {FproDosStatutDocumentfileRead}
     * @memberof FproDossierDocDocumentfileRead
     */
    'statut'?: FproDosStatutDocumentfileRead | null;
}
/**
 * 
 * @export
 * @interface FproDossierDocDossierRead
 */
export interface FproDossierDocDossierRead {
    /**
     * 
     * @type {number}
     * @memberof FproDossierDocDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocDossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocDossierRead
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocDossierRead
     */
    'statut'?: string | null;
}
/**
 * 
 * @export
 * @interface FproDossierDocJsonldCollectiondossierRead
 */
export interface FproDossierDocJsonldCollectiondossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierDocJsonldCollectiondossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldCollectiondossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldCollectiondossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierDocJsonldCollectiondossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldCollectiondossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldCollectiondossierRead
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldCollectiondossierRead
     */
    'statut'?: string | null;
}
/**
 * 
 * @export
 * @interface FproDossierDocJsonldDocumentfileRead
 */
export interface FproDossierDocJsonldDocumentfileRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {TblTypeDocumentJsonldDocumentfileRead}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    'document'?: TblTypeDocumentJsonldDocumentfileRead | null;
    /**
     * 
     * @type {FproDosStatutJsonldDocumentfileRead}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    'statut'?: FproDosStatutJsonldDocumentfileRead | null;
}
/**
 * 
 * @export
 * @interface FproDossierDocJsonldDossierRead
 */
export interface FproDossierDocJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierDocJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierDocJsonldDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDossierRead
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDossierRead
     */
    'statut'?: string | null;
}
/**
 * 
 * @export
 * @interface FproDossierJsonldActiviteCreated
 */
export interface FproDossierJsonldActiviteCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierJsonldActiviteCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldActiviteCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldActiviteCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldActiviteCreated
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface FproDossierJsonldActiviteRead
 */
export interface FproDossierJsonldActiviteRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierJsonldActiviteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldActiviteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldActiviteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldActiviteRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface FproDossierJsonldCollectiondossierCreate
 */
export interface FproDossierJsonldCollectiondossierCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    '@type'?: string;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    'contact'?: number | null;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    'societeDenomination'?: string | null;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    'origine'?: number | null;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    'societeSiren'?: string | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    'clientOpere'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    'formaliste'?: number | null;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationJsonldCollectiondossierCreate>}
     * @memberof FproDossierJsonldCollectiondossierCreate
     */
    'prestations'?: Array<FproPrestationJsonldCollectiondossierCreate>;
}
/**
 * 
 * @export
 * @interface FproDossierJsonldCollectiondossierCreated
 */
export interface FproDossierJsonldCollectiondossierCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'id'?: number;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'contact'?: number | null;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'societeDenomination'?: string | null;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'origine'?: number | null;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'societeSiren'?: string | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'clientOpere'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'idFormeJuridique'?: number | null;
    /**
     * Statut du dossier
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'statut'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'formaliste'?: number | null;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationJsonldCollectiondossierCreated>}
     * @memberof FproDossierJsonldCollectiondossierCreated
     */
    'prestations'?: Array<FproPrestationJsonldCollectiondossierCreated>;
}
/**
 * 
 * @export
 * @interface FproDossierJsonldCollectiondossierRead
 */
export interface FproDossierJsonldCollectiondossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'id'?: number;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'numero'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'dateMaj'?: string;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'societeDenomination'?: string | null;
    /**
     * Forme juridique de la société
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'societeFormeJuridique'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'ttc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'cheminDoc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'origine'?: number | null;
    /**
     * Greffe du dossier
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'greffe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'dateFinalise'?: string | null;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'societeSiren'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'cegidRegimetva'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'factureMultiple'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'recuPaiement'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'idFacturemultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'idDematfacile'?: number;
    /**
     * ID du client
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'client'?: number | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'clientOpere'?: number;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'factureActive'?: number;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'factureBrouillon'?: number;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'factActiveSansAvoir'?: number;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'factureBrouillonMultiple'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant de la formalite
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'formalite'?: number | null;
    /**
     * Statut du dossier
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'statut'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'formaliste'?: number | null;
    /**
     * Adresse de facturation du dossier
     * @type {Array<FproAdresseFactJsonldCollectiondossierRead>}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'adresses'?: Array<FproAdresseFactJsonldCollectiondossierRead>;
    /**
     * 
     * @type {Array<FproDossierDocJsonldCollectiondossierRead>}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'documents'?: Array<FproDossierDocJsonldCollectiondossierRead>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'fichiers'?: Array<string>;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'marque'?: number | null;
    /**
     * 
     * @type {Array<FproPrestationJsonldCollectiondossierRead>}
     * @memberof FproDossierJsonldCollectiondossierRead
     */
    'prestations'?: Array<FproPrestationJsonldCollectiondossierRead>;
}
/**
 * 
 * @export
 * @interface FproDossierJsonldCollectiondossierUpdate
 */
export interface FproDossierJsonldCollectiondossierUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    '@type'?: string;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    'contact'?: number | null;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    'societeDenomination'?: string | null;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    'origine'?: number | null;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    'societeSiren'?: string | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    'clientOpere'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    'formaliste'?: number | null;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationJsonldCollectiondossierUpdate>}
     * @memberof FproDossierJsonldCollectiondossierUpdate
     */
    'prestations'?: Array<FproPrestationJsonldCollectiondossierUpdate>;
}
/**
 * 
 * @export
 * @interface FproDossierJsonldCollectiondossierValidated
 */
export interface FproDossierJsonldCollectiondossierValidated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'id'?: number;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'contact'?: number | null;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'societeDenomination'?: string | null;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'origine'?: number | null;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'societeSiren'?: string | null;
    /**
     * ID du client opéré
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'clientOpere'?: number;
    /**
     * ID dde la forme juridique de la société concernée par la formalité
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'idFormeJuridique'?: number | null;
    /**
     * Statut du dossier
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'statut'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'formaliste'?: number | null;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationJsonldCollectiondossierValidated>}
     * @memberof FproDossierJsonldCollectiondossierValidated
     */
    'prestations'?: Array<FproPrestationJsonldCollectiondossierValidated>;
}
/**
 * 
 * @export
 * @interface FproDossierValidatorTypeStatusJsonldValidatorsProcessRead
 */
export interface FproDossierValidatorTypeStatusJsonldValidatorsProcessRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierValidatorTypeStatusJsonldValidatorsProcessRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierValidatorTypeStatusJsonldValidatorsProcessRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierValidatorTypeStatusJsonldValidatorsProcessRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FproFormaliteCategorieFormaliteRead
 */
export interface FproFormaliteCategorieFormaliteRead {
    /**
     * 
     * @type {number}
     * @memberof FproFormaliteCategorieFormaliteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieFormaliteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieFormaliteRead
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieFormaliteRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface FproFormaliteCategorieJsonldFormaliteRead
 */
export interface FproFormaliteCategorieJsonldFormaliteRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleCollectiondossierCreated
 */
export interface FproPrestationArticleCollectiondossierCreated {
    /**
     * Id de l\'article de facturation lié
     * @type {string}
     * @memberof FproPrestationArticleCollectiondossierCreated
     */
    'factArticle': string;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticleCollectiondossierCreated
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticleCollectiondossierCreated
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticleCollectiondossierCreated
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticleCollectiondossierCreated
     */
    'rang'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubCollectiondossierCreated}
     * @memberof FproPrestationArticleCollectiondossierCreated
     */
    'detailpubs'?: FproPrestationArticleDetailPubCollectiondossierCreated | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleCollectiondossierRead
 */
export interface FproPrestationArticleCollectiondossierRead {
    /**
     * 
     * @type {TblFactArticlesCollectiondossierRead}
     * @memberof FproPrestationArticleCollectiondossierRead
     */
    'factArticle': TblFactArticlesCollectiondossierRead;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticleCollectiondossierRead
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticleCollectiondossierRead
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticleCollectiondossierRead
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticleCollectiondossierRead
     */
    'rang'?: number;
    /**
     * Article vérifié, prêt pour la facturation
     * @type {boolean}
     * @memberof FproPrestationArticleCollectiondossierRead
     */
    'fDonneesVerifiees'?: boolean;
    /**
     * Article masqué par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticleCollectiondossierRead
     */
    'fMasqueCalcul'?: boolean;
    /**
     * Article ajouté par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticleCollectiondossierRead
     */
    'fAjouteCalcul'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FproPrestationArticleCollectiondossierRead
     */
    'tauxReduction'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubCollectiondossierRead}
     * @memberof FproPrestationArticleCollectiondossierRead
     */
    'detailpubs'?: FproPrestationArticleDetailPubCollectiondossierRead | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleCollectiondossierUpdate
 */
export interface FproPrestationArticleCollectiondossierUpdate {
    /**
     * Id de l\'article de facturation lié
     * @type {string}
     * @memberof FproPrestationArticleCollectiondossierUpdate
     */
    'factArticle': string;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticleCollectiondossierUpdate
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticleCollectiondossierUpdate
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticleCollectiondossierUpdate
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticleCollectiondossierUpdate
     */
    'rang'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubCollectiondossierUpdate}
     * @memberof FproPrestationArticleCollectiondossierUpdate
     */
    'detailpubs'?: FproPrestationArticleDetailPubCollectiondossierUpdate | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleCollectiondossierValidated
 */
export interface FproPrestationArticleCollectiondossierValidated {
    /**
     * Id de l\'article de facturation lié
     * @type {string}
     * @memberof FproPrestationArticleCollectiondossierValidated
     */
    'factArticle': string;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticleCollectiondossierValidated
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticleCollectiondossierValidated
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticleCollectiondossierValidated
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticleCollectiondossierValidated
     */
    'rang'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubCollectiondossierValidated}
     * @memberof FproPrestationArticleCollectiondossierValidated
     */
    'detailpubs'?: FproPrestationArticleDetailPubCollectiondossierValidated | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubCollectiondossierCreated
 */
export interface FproPrestationArticleDetailPubCollectiondossierCreated {
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubCollectiondossierCreated
     */
    'idstype'?: string;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubCollectiondossierRead
 */
export interface FproPrestationArticleDetailPubCollectiondossierRead {
    /**
     * Id de l\'article liée
     * @type {string}
     * @memberof FproPrestationArticleDetailPubCollectiondossierRead
     */
    'idPrestaArt'?: string;
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubCollectiondossierRead
     */
    'idstype'?: string;
    /**
     * 
     * @type {object}
     * @memberof FproPrestationArticleDetailPubCollectiondossierRead
     */
    'idpresta'?: object;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubCollectiondossierUpdate
 */
export interface FproPrestationArticleDetailPubCollectiondossierUpdate {
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubCollectiondossierUpdate
     */
    'idstype'?: string;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubCollectiondossierValidated
 */
export interface FproPrestationArticleDetailPubCollectiondossierValidated {
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubCollectiondossierValidated
     */
    'idstype'?: string;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubDossierRead
 */
export interface FproPrestationArticleDetailPubDossierRead {
    /**
     * Id de l\'article liée
     * @type {string}
     * @memberof FproPrestationArticleDetailPubDossierRead
     */
    'idPrestaArt'?: string;
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubDossierRead
     */
    'idstype'?: string;
    /**
     * 
     * @type {object}
     * @memberof FproPrestationArticleDetailPubDossierRead
     */
    'idpresta'?: object;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubJsonldCollectiondossierCreated
 */
export interface FproPrestationArticleDetailPubJsonldCollectiondossierCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierCreated
     */
    '@type'?: string;
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierCreated
     */
    'idstype'?: string;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubJsonldCollectiondossierRead
 */
export interface FproPrestationArticleDetailPubJsonldCollectiondossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierRead
     */
    '@type'?: string;
    /**
     * Id de l\'article liée
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierRead
     */
    'idPrestaArt'?: string;
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierRead
     */
    'idstype'?: string;
    /**
     * 
     * @type {TblPrestaJsonldCollectiondossierRead}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierRead
     */
    'idpresta'?: TblPrestaJsonldCollectiondossierRead | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubJsonldCollectiondossierUpdate
 */
export interface FproPrestationArticleDetailPubJsonldCollectiondossierUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierUpdate
     */
    '@type'?: string;
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierUpdate
     */
    'idstype'?: string;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubJsonldCollectiondossierValidated
 */
export interface FproPrestationArticleDetailPubJsonldCollectiondossierValidated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierValidated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierValidated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierValidated
     */
    '@type'?: string;
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldCollectiondossierValidated
     */
    'idstype'?: string;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubJsonldDossierRead
 */
export interface FproPrestationArticleDetailPubJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleDetailPubJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldDossierRead
     */
    '@type'?: string;
    /**
     * Id de l\'article liée
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldDossierRead
     */
    'idPrestaArt'?: string;
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldDossierRead
     */
    'idstype'?: string;
    /**
     * 
     * @type {TblPrestaJsonldDossierRead}
     * @memberof FproPrestationArticleDetailPubJsonldDossierRead
     */
    'idpresta'?: TblPrestaJsonldDossierRead | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubJsonldPrestationRead
 */
export interface FproPrestationArticleDetailPubJsonldPrestationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleDetailPubJsonldPrestationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldPrestationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldPrestationRead
     */
    '@type'?: string;
    /**
     * Id de l\'article liée
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldPrestationRead
     */
    'idPrestaArt'?: string;
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldPrestationRead
     */
    'idstype'?: string;
    /**
     * 
     * @type {TblPrestaJsonldPrestationRead}
     * @memberof FproPrestationArticleDetailPubJsonldPrestationRead
     */
    'idpresta'?: TblPrestaJsonldPrestationRead | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubJsonldPrestationrelationRead
 */
export interface FproPrestationArticleDetailPubJsonldPrestationrelationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleDetailPubJsonldPrestationrelationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldPrestationrelationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldPrestationrelationRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproPrestationArticleDetailPubJsonldPrestationrelationRead
     */
    'id'?: number;
    /**
     * Id de l\'article liée
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldPrestationrelationRead
     */
    'idPrestaArt'?: string;
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubJsonldPrestationrelationRead
     */
    'idstype'?: string;
    /**
     * 
     * @type {TblPrestaJsonldPrestationrelationRead}
     * @memberof FproPrestationArticleDetailPubJsonldPrestationrelationRead
     */
    'idpresta'?: TblPrestaJsonldPrestationrelationRead | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubPrestationRead
 */
export interface FproPrestationArticleDetailPubPrestationRead {
    /**
     * Id de l\'article liée
     * @type {string}
     * @memberof FproPrestationArticleDetailPubPrestationRead
     */
    'idPrestaArt'?: string;
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubPrestationRead
     */
    'idstype'?: string;
    /**
     * 
     * @type {object}
     * @memberof FproPrestationArticleDetailPubPrestationRead
     */
    'idpresta'?: object;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDetailPubPrestationrelationRead
 */
export interface FproPrestationArticleDetailPubPrestationrelationRead {
    /**
     * 
     * @type {number}
     * @memberof FproPrestationArticleDetailPubPrestationrelationRead
     */
    'id'?: number;
    /**
     * Id de l\'article liée
     * @type {string}
     * @memberof FproPrestationArticleDetailPubPrestationrelationRead
     */
    'idPrestaArt'?: string;
    /**
     * Sous type d\'annonce de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDetailPubPrestationrelationRead
     */
    'idstype'?: string;
    /**
     * 
     * @type {object}
     * @memberof FproPrestationArticleDetailPubPrestationrelationRead
     */
    'idpresta'?: object;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleDossierRead
 */
export interface FproPrestationArticleDossierRead {
    /**
     * 
     * @type {TblFactArticlesDossierRead}
     * @memberof FproPrestationArticleDossierRead
     */
    'factArticle': TblFactArticlesDossierRead;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDossierRead
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticleDossierRead
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticleDossierRead
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticleDossierRead
     */
    'rang'?: number;
    /**
     * Article vérifié, prêt pour la facturation
     * @type {boolean}
     * @memberof FproPrestationArticleDossierRead
     */
    'fDonneesVerifiees'?: boolean;
    /**
     * Article masqué par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticleDossierRead
     */
    'fMasqueCalcul'?: boolean;
    /**
     * Article ajouté par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticleDossierRead
     */
    'fAjouteCalcul'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FproPrestationArticleDossierRead
     */
    'tauxReduction'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubDossierRead}
     * @memberof FproPrestationArticleDossierRead
     */
    'detailpubs'?: FproPrestationArticleDetailPubDossierRead | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleJsonldCollectiondossierCreated
 */
export interface FproPrestationArticleJsonldCollectiondossierCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleJsonldCollectiondossierCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierCreated
     */
    '@type'?: string;
    /**
     * Id de l\'article de facturation lié
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierCreated
     */
    'factArticle': string;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierCreated
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierCreated
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticleJsonldCollectiondossierCreated
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticleJsonldCollectiondossierCreated
     */
    'rang'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubJsonldCollectiondossierCreated}
     * @memberof FproPrestationArticleJsonldCollectiondossierCreated
     */
    'detailpubs'?: FproPrestationArticleDetailPubJsonldCollectiondossierCreated | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleJsonldCollectiondossierRead
 */
export interface FproPrestationArticleJsonldCollectiondossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleJsonldCollectiondossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblFactArticlesJsonldCollectiondossierRead}
     * @memberof FproPrestationArticleJsonldCollectiondossierRead
     */
    'factArticle': TblFactArticlesJsonldCollectiondossierRead;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierRead
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierRead
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticleJsonldCollectiondossierRead
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticleJsonldCollectiondossierRead
     */
    'rang'?: number;
    /**
     * Article vérifié, prêt pour la facturation
     * @type {boolean}
     * @memberof FproPrestationArticleJsonldCollectiondossierRead
     */
    'fDonneesVerifiees'?: boolean;
    /**
     * Article masqué par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticleJsonldCollectiondossierRead
     */
    'fMasqueCalcul'?: boolean;
    /**
     * Article ajouté par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticleJsonldCollectiondossierRead
     */
    'fAjouteCalcul'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FproPrestationArticleJsonldCollectiondossierRead
     */
    'tauxReduction'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubJsonldCollectiondossierRead}
     * @memberof FproPrestationArticleJsonldCollectiondossierRead
     */
    'detailpubs'?: FproPrestationArticleDetailPubJsonldCollectiondossierRead | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleJsonldCollectiondossierUpdate
 */
export interface FproPrestationArticleJsonldCollectiondossierUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleJsonldCollectiondossierUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierUpdate
     */
    '@type'?: string;
    /**
     * Id de l\'article de facturation lié
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierUpdate
     */
    'factArticle': string;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierUpdate
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierUpdate
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticleJsonldCollectiondossierUpdate
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticleJsonldCollectiondossierUpdate
     */
    'rang'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubJsonldCollectiondossierUpdate}
     * @memberof FproPrestationArticleJsonldCollectiondossierUpdate
     */
    'detailpubs'?: FproPrestationArticleDetailPubJsonldCollectiondossierUpdate | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleJsonldCollectiondossierValidated
 */
export interface FproPrestationArticleJsonldCollectiondossierValidated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleJsonldCollectiondossierValidated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierValidated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierValidated
     */
    '@type'?: string;
    /**
     * Id de l\'article de facturation lié
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierValidated
     */
    'factArticle': string;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierValidated
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldCollectiondossierValidated
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticleJsonldCollectiondossierValidated
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticleJsonldCollectiondossierValidated
     */
    'rang'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubJsonldCollectiondossierValidated}
     * @memberof FproPrestationArticleJsonldCollectiondossierValidated
     */
    'detailpubs'?: FproPrestationArticleDetailPubJsonldCollectiondossierValidated | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleJsonldDossierRead
 */
export interface FproPrestationArticleJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblFactArticlesJsonldDossierRead}
     * @memberof FproPrestationArticleJsonldDossierRead
     */
    'factArticle': TblFactArticlesJsonldDossierRead;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldDossierRead
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldDossierRead
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticleJsonldDossierRead
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticleJsonldDossierRead
     */
    'rang'?: number;
    /**
     * Article vérifié, prêt pour la facturation
     * @type {boolean}
     * @memberof FproPrestationArticleJsonldDossierRead
     */
    'fDonneesVerifiees'?: boolean;
    /**
     * Article masqué par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticleJsonldDossierRead
     */
    'fMasqueCalcul'?: boolean;
    /**
     * Article ajouté par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticleJsonldDossierRead
     */
    'fAjouteCalcul'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FproPrestationArticleJsonldDossierRead
     */
    'tauxReduction'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubJsonldDossierRead}
     * @memberof FproPrestationArticleJsonldDossierRead
     */
    'detailpubs'?: FproPrestationArticleDetailPubJsonldDossierRead | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleJsonldPrestationRead
 */
export interface FproPrestationArticleJsonldPrestationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleJsonldPrestationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldPrestationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldPrestationRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblFactArticlesJsonldPrestationRead}
     * @memberof FproPrestationArticleJsonldPrestationRead
     */
    'factArticle': TblFactArticlesJsonldPrestationRead;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldPrestationRead
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldPrestationRead
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticleJsonldPrestationRead
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticleJsonldPrestationRead
     */
    'rang'?: number;
    /**
     * Article vérifié, prêt pour la facturation
     * @type {boolean}
     * @memberof FproPrestationArticleJsonldPrestationRead
     */
    'fDonneesVerifiees'?: boolean;
    /**
     * Article masqué par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticleJsonldPrestationRead
     */
    'fMasqueCalcul'?: boolean;
    /**
     * Article ajouté par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticleJsonldPrestationRead
     */
    'fAjouteCalcul'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FproPrestationArticleJsonldPrestationRead
     */
    'tauxReduction'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubJsonldPrestationRead}
     * @memberof FproPrestationArticleJsonldPrestationRead
     */
    'detailpubs'?: FproPrestationArticleDetailPubJsonldPrestationRead | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticleJsonldPrestationrelationRead
 */
export interface FproPrestationArticleJsonldPrestationrelationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    'idPresta': string;
    /**
     * 
     * @type {TblFactArticlesJsonldPrestationrelationRead}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    'factArticle': TblFactArticlesJsonldPrestationrelationRead;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    'rang'?: number;
    /**
     * Article vérifié, prêt pour la facturation
     * @type {boolean}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    'fDonneesVerifiees'?: boolean;
    /**
     * Article masqué par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    'fMasqueCalcul'?: boolean;
    /**
     * Article ajouté par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    'fAjouteCalcul'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    'tauxReduction'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubJsonldPrestationrelationRead}
     * @memberof FproPrestationArticleJsonldPrestationrelationRead
     */
    'detailpubs'?: FproPrestationArticleDetailPubJsonldPrestationrelationRead | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticlePrestationRead
 */
export interface FproPrestationArticlePrestationRead {
    /**
     * 
     * @type {TblFactArticlesPrestationRead}
     * @memberof FproPrestationArticlePrestationRead
     */
    'factArticle': TblFactArticlesPrestationRead;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticlePrestationRead
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticlePrestationRead
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticlePrestationRead
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticlePrestationRead
     */
    'rang'?: number;
    /**
     * Article vérifié, prêt pour la facturation
     * @type {boolean}
     * @memberof FproPrestationArticlePrestationRead
     */
    'fDonneesVerifiees'?: boolean;
    /**
     * Article masqué par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticlePrestationRead
     */
    'fMasqueCalcul'?: boolean;
    /**
     * Article ajouté par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticlePrestationRead
     */
    'fAjouteCalcul'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FproPrestationArticlePrestationRead
     */
    'tauxReduction'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubPrestationRead}
     * @memberof FproPrestationArticlePrestationRead
     */
    'detailpubs'?: FproPrestationArticleDetailPubPrestationRead | null;
}
/**
 * 
 * @export
 * @interface FproPrestationArticlePrestationrelationRead
 */
export interface FproPrestationArticlePrestationrelationRead {
    /**
     * 
     * @type {string}
     * @memberof FproPrestationArticlePrestationrelationRead
     */
    'idPresta': string;
    /**
     * 
     * @type {TblFactArticlesPrestationrelationRead}
     * @memberof FproPrestationArticlePrestationrelationRead
     */
    'factArticle': TblFactArticlesPrestationrelationRead;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof FproPrestationArticlePrestationrelationRead
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof FproPrestationArticlePrestationrelationRead
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof FproPrestationArticlePrestationrelationRead
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof FproPrestationArticlePrestationrelationRead
     */
    'rang'?: number;
    /**
     * Article vérifié, prêt pour la facturation
     * @type {boolean}
     * @memberof FproPrestationArticlePrestationrelationRead
     */
    'fDonneesVerifiees'?: boolean;
    /**
     * Article masqué par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticlePrestationrelationRead
     */
    'fMasqueCalcul'?: boolean;
    /**
     * Article ajouté par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof FproPrestationArticlePrestationrelationRead
     */
    'fAjouteCalcul'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FproPrestationArticlePrestationrelationRead
     */
    'tauxReduction'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubPrestationrelationRead}
     * @memberof FproPrestationArticlePrestationrelationRead
     */
    'detailpubs'?: FproPrestationArticleDetailPubPrestationrelationRead | null;
}
/**
 * 
 * @export
 * @interface FproPrestationCollectiondossierCreate
 */
export interface FproPrestationCollectiondossierCreate {
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationCollectiondossierCreate
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationCollectiondossierCreate
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationCollectiondossierCreate
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierCreate
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierCreate
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierCreate
     */
    'json'?: string | null;
}
/**
 * 
 * @export
 * @interface FproPrestationCollectiondossierCreated
 */
export interface FproPrestationCollectiondossierCreated {
    /**
     * 
     * @type {number}
     * @memberof FproPrestationCollectiondossierCreated
     */
    'id'?: number;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationCollectiondossierCreated
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationCollectiondossierCreated
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationCollectiondossierCreated
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierCreated
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierCreated
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierCreated
     */
    'json'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationArticleCollectiondossierCreated>}
     * @memberof FproPrestationCollectiondossierCreated
     */
    'articles'?: Array<FproPrestationArticleCollectiondossierCreated>;
}
/**
 * 
 * @export
 * @interface FproPrestationCollectiondossierRead
 */
export interface FproPrestationCollectiondossierRead {
    /**
     * 
     * @type {number}
     * @memberof FproPrestationCollectiondossierRead
     */
    'id'?: number;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationCollectiondossierRead
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationCollectiondossierRead
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationCollectiondossierRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierRead
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierRead
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierRead
     */
    'json'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationArticleCollectiondossierRead>}
     * @memberof FproPrestationCollectiondossierRead
     */
    'articles'?: Array<FproPrestationArticleCollectiondossierRead>;
}
/**
 * 
 * @export
 * @interface FproPrestationCollectiondossierUpdate
 */
export interface FproPrestationCollectiondossierUpdate {
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationCollectiondossierUpdate
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationCollectiondossierUpdate
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationCollectiondossierUpdate
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierUpdate
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierUpdate
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierUpdate
     */
    'json'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationArticleCollectiondossierUpdate>}
     * @memberof FproPrestationCollectiondossierUpdate
     */
    'articles'?: Array<FproPrestationArticleCollectiondossierUpdate>;
}
/**
 * 
 * @export
 * @interface FproPrestationCollectiondossierValidated
 */
export interface FproPrestationCollectiondossierValidated {
    /**
     * 
     * @type {number}
     * @memberof FproPrestationCollectiondossierValidated
     */
    'id'?: number;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationCollectiondossierValidated
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationCollectiondossierValidated
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationCollectiondossierValidated
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierValidated
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierValidated
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationCollectiondossierValidated
     */
    'json'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationArticleCollectiondossierValidated>}
     * @memberof FproPrestationCollectiondossierValidated
     */
    'articles'?: Array<FproPrestationArticleCollectiondossierValidated>;
}
/**
 * 
 * @export
 * @interface FproPrestationDossierCreate
 */
export interface FproPrestationDossierCreate {
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationDossierCreate
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationDossierCreate
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationDossierCreate
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationDossierCreate
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationDossierCreate
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationDossierCreate
     */
    'json'?: string | null;
}
/**
 * 
 * @export
 * @interface FproPrestationDossierRead
 */
export interface FproPrestationDossierRead {
    /**
     * 
     * @type {number}
     * @memberof FproPrestationDossierRead
     */
    'id'?: number;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationDossierRead
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationDossierRead
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationDossierRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {TblCatalogueDossierRead}
     * @memberof FproPrestationDossierRead
     */
    'catalogue'?: TblCatalogueDossierRead | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationDossierRead
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationDossierRead
     */
    'json'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationArticleDossierRead>}
     * @memberof FproPrestationDossierRead
     */
    'articles'?: Array<FproPrestationArticleDossierRead>;
}
/**
 * 
 * @export
 * @interface FproPrestationJsonldCollectiondossierCreate
 */
export interface FproPrestationJsonldCollectiondossierCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationJsonldCollectiondossierCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreate
     */
    '@type'?: string;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreate
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreate
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationJsonldCollectiondossierCreate
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreate
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreate
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreate
     */
    'json'?: string | null;
}
/**
 * 
 * @export
 * @interface FproPrestationJsonldCollectiondossierCreated
 */
export interface FproPrestationJsonldCollectiondossierCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationJsonldCollectiondossierCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproPrestationJsonldCollectiondossierCreated
     */
    'id'?: number;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreated
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreated
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationJsonldCollectiondossierCreated
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreated
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreated
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierCreated
     */
    'json'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationArticleJsonldCollectiondossierCreated>}
     * @memberof FproPrestationJsonldCollectiondossierCreated
     */
    'articles'?: Array<FproPrestationArticleJsonldCollectiondossierCreated>;
}
/**
 * 
 * @export
 * @interface FproPrestationJsonldCollectiondossierRead
 */
export interface FproPrestationJsonldCollectiondossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationJsonldCollectiondossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproPrestationJsonldCollectiondossierRead
     */
    'id'?: number;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierRead
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierRead
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationJsonldCollectiondossierRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierRead
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierRead
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierRead
     */
    'json'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationArticleJsonldCollectiondossierRead>}
     * @memberof FproPrestationJsonldCollectiondossierRead
     */
    'articles'?: Array<FproPrestationArticleJsonldCollectiondossierRead>;
}
/**
 * 
 * @export
 * @interface FproPrestationJsonldCollectiondossierUpdate
 */
export interface FproPrestationJsonldCollectiondossierUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationJsonldCollectiondossierUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierUpdate
     */
    '@type'?: string;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierUpdate
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierUpdate
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationJsonldCollectiondossierUpdate
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierUpdate
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierUpdate
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierUpdate
     */
    'json'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationArticleJsonldCollectiondossierUpdate>}
     * @memberof FproPrestationJsonldCollectiondossierUpdate
     */
    'articles'?: Array<FproPrestationArticleJsonldCollectiondossierUpdate>;
}
/**
 * 
 * @export
 * @interface FproPrestationJsonldCollectiondossierValidated
 */
export interface FproPrestationJsonldCollectiondossierValidated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationJsonldCollectiondossierValidated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierValidated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierValidated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproPrestationJsonldCollectiondossierValidated
     */
    'id'?: number;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierValidated
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierValidated
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationJsonldCollectiondossierValidated
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierValidated
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierValidated
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldCollectiondossierValidated
     */
    'json'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationArticleJsonldCollectiondossierValidated>}
     * @memberof FproPrestationJsonldCollectiondossierValidated
     */
    'articles'?: Array<FproPrestationArticleJsonldCollectiondossierValidated>;
}
/**
 * 
 * @export
 * @interface FproPrestationJsonldDossierCreate
 */
export interface FproPrestationJsonldDossierCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationJsonldDossierCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldDossierCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldDossierCreate
     */
    '@type'?: string;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldDossierCreate
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldDossierCreate
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationJsonldDossierCreate
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldDossierCreate
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldDossierCreate
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldDossierCreate
     */
    'json'?: string | null;
}
/**
 * 
 * @export
 * @interface FproPrestationJsonldDossierRead
 */
export interface FproPrestationJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproPrestationJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproPrestationJsonldDossierRead
     */
    'id'?: number;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldDossierRead
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof FproPrestationJsonldDossierRead
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof FproPrestationJsonldDossierRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {TblCatalogueJsonldDossierRead}
     * @memberof FproPrestationJsonldDossierRead
     */
    'catalogue'?: TblCatalogueJsonldDossierRead | null;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldDossierRead
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldDossierRead
     */
    'json'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationArticleJsonldDossierRead>}
     * @memberof FproPrestationJsonldDossierRead
     */
    'articles'?: Array<FproPrestationArticleJsonldDossierRead>;
}
/**
 * 
 * @export
 * @interface GreffeGreffeRead
 */
export interface GreffeGreffeRead {
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeRead
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeRead
     */
    'art'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeRead
     */
    'dep'?: string;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefAp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefEp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefGen'?: number | null;
}
/**
 * 
 * @export
 * @interface GreffeJsonldGreffeRead
 */
export interface GreffeJsonldGreffeRead {
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof GreffeJsonldGreffeRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    'art'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    'dep'?: string;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefAp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefEp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefGen'?: number | null;
}
/**
 * 
 * @export
 * @interface MessageJsonld
 */
export interface MessageJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof MessageJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonld
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface MessageJsonldMessageCreate
 */
export interface MessageJsonldMessageCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof MessageJsonldMessageCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageJsonldMessageCreate
     */
    'expediteur': MessageJsonldMessageCreateExpediteurEnum | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreate
     */
    'expediteurNom': string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreate
     */
    'message': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageCreate
     */
    'readByPrescri'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageCreate
     */
    'readByFrmlist'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreate
     */
    'dossier'?: string | null;
}

export const MessageJsonldMessageCreateExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type MessageJsonldMessageCreateExpediteurEnum = typeof MessageJsonldMessageCreateExpediteurEnum[keyof typeof MessageJsonldMessageCreateExpediteurEnum];

/**
 * 
 * @export
 * @interface MessageJsonldMessageCreated
 */
export interface MessageJsonldMessageCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof MessageJsonldMessageCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageJsonldMessageCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageJsonldMessageCreated
     */
    'expediteur': MessageJsonldMessageCreatedExpediteurEnum | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreated
     */
    'expediteurNom': string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreated
     */
    'message': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageCreated
     */
    'readByPrescri'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageCreated
     */
    'readByFrmlist'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreated
     */
    'dossier'?: string | null;
}

export const MessageJsonldMessageCreatedExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type MessageJsonldMessageCreatedExpediteurEnum = typeof MessageJsonldMessageCreatedExpediteurEnum[keyof typeof MessageJsonldMessageCreatedExpediteurEnum];

/**
 * 
 * @export
 * @interface MessageJsonldMessageRead
 */
export interface MessageJsonldMessageRead {
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof MessageJsonldMessageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof MessageJsonldMessageRead
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof MessageJsonldMessageRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof MessageJsonldMessageRead
     */
    'expediteur'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageRead
     */
    'expediteurNom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageRead
     */
    'message'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageRead
     */
    'readByPrescri'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageRead
     */
    'readByFrmlist'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageRead
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface MessageMessageCreate
 */
export interface MessageMessageCreate {
    /**
     * 
     * @type {number}
     * @memberof MessageMessageCreate
     */
    'expediteur': MessageMessageCreateExpediteurEnum | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageCreate
     */
    'expediteurNom': string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageCreate
     */
    'message': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageCreate
     */
    'readByPrescri'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageCreate
     */
    'readByFrmlist'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageCreate
     */
    'dossier'?: string | null;
}

export const MessageMessageCreateExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type MessageMessageCreateExpediteurEnum = typeof MessageMessageCreateExpediteurEnum[keyof typeof MessageMessageCreateExpediteurEnum];

/**
 * 
 * @export
 * @interface MessageMessageCreated
 */
export interface MessageMessageCreated {
    /**
     * 
     * @type {number}
     * @memberof MessageMessageCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageMessageCreated
     */
    'expediteur': MessageMessageCreatedExpediteurEnum | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageCreated
     */
    'expediteurNom': string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageCreated
     */
    'message': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageCreated
     */
    'readByPrescri'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageCreated
     */
    'readByFrmlist'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageCreated
     */
    'dossier'?: string | null;
}

export const MessageMessageCreatedExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type MessageMessageCreatedExpediteurEnum = typeof MessageMessageCreatedExpediteurEnum[keyof typeof MessageMessageCreatedExpediteurEnum];

/**
 * 
 * @export
 * @interface MessageMessageRead
 */
export interface MessageMessageRead {
    /**
     * 
     * @type {number}
     * @memberof MessageMessageRead
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof MessageMessageRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof MessageMessageRead
     */
    'expediteur'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageRead
     */
    'expediteurNom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageRead
     */
    'message'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageRead
     */
    'readByPrescri'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageRead
     */
    'readByFrmlist'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageRead
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoteCreate
 */
export interface NotesEtRappelsJsonldNoteCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoteCreated
 */
export interface NotesEtRappelsJsonldNoteCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'clos'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoteRead
 */
export interface NotesEtRappelsJsonldNoteRead {
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {Datetime}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'dateProgramme'?: Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'clos'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Datetime}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'dateCreation'?: Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {Datetime}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'dateProgrammeFin'?: Datetime | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoteUpdate
 */
export interface NotesEtRappelsJsonldNoteUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'clos'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'userCreation'?: number;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoterappelCreate
 */
export interface NotesEtRappelsJsonldNoterappelCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelCreate}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'evenement'?: TblEvenementJsonldNoterappelCreate;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoterappelCreated
 */
export interface NotesEtRappelsJsonldNoterappelCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelCreated}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'evenement'?: TblEvenementJsonldNoterappelCreated;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoterappelRead
 */
export interface NotesEtRappelsJsonldNoterappelRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelRead}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'evenement'?: TblEvenementJsonldNoterappelRead;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoterappelReadCollection
 */
export interface NotesEtRappelsJsonldNoterappelReadCollection {
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelReadCollection
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelJsonldNoterappelReadCollection>}
     * @memberof NotesEtRappelsJsonldNoterappelReadCollection
     */
    'rappels'?: Array<TblEvenementRappelJsonldNoterappelReadCollection>;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoterappelUpdate
 */
export interface NotesEtRappelsJsonldNoterappelUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelUpdate}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'evenement'?: TblEvenementJsonldNoterappelUpdate;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoteCreate
 */
export interface NotesEtRappelsNoteCreate {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreate
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreate
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreate
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteCreate
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreate
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoteCreated
 */
export interface NotesEtRappelsNoteCreated {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreated
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreated
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreated
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteCreated
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoteCreated
     */
    'clos'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoteCreated
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreated
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteCreated
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoteRead
 */
export interface NotesEtRappelsNoteRead {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteRead
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteRead
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {Datetime}
     * @memberof NotesEtRappelsNoteRead
     */
    'dateProgramme'?: Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteRead
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteRead
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoteRead
     */
    'clos'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoteRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Datetime}
     * @memberof NotesEtRappelsNoteRead
     */
    'dateCreation'?: Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteRead
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {Datetime}
     * @memberof NotesEtRappelsNoteRead
     */
    'dateProgrammeFin'?: Datetime | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoteUpdate
 */
export interface NotesEtRappelsNoteUpdate {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'clos'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'userCreation'?: number;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoterappelCreate
 */
export interface NotesEtRappelsNoterappelCreate {
    /**
     * 
     * @type {TblEvenementNoterappelCreate}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'evenement'?: TblEvenementNoterappelCreate;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoterappelCreated
 */
export interface NotesEtRappelsNoterappelCreated {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementNoterappelCreated}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'evenement'?: TblEvenementNoterappelCreated;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoterappelRead
 */
export interface NotesEtRappelsNoterappelRead {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementNoterappelRead}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'evenement'?: TblEvenementNoterappelRead;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoterappelReadCollection
 */
export interface NotesEtRappelsNoterappelReadCollection {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelReadCollection
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelNoterappelReadCollection>}
     * @memberof NotesEtRappelsNoterappelReadCollection
     */
    'rappels'?: Array<TblEvenementRappelNoterappelReadCollection>;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoterappelUpdate
 */
export interface NotesEtRappelsNoterappelUpdate {
    /**
     * 
     * @type {TblEvenementNoterappelUpdate}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'evenement'?: TblEvenementNoterappelUpdate;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ObjectGroupsJsonldObjectGroupCreate
 */
export interface ObjectGroupsJsonldObjectGroupCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ObjectGroupsJsonldObjectGroupCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsJsonldObjectGroupCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsJsonldObjectGroupCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsJsonldObjectGroupCreate
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsJsonldObjectGroupCreate
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsJsonldObjectGroupCreate
     */
    'icon'?: string | null;
}
/**
 * 
 * @export
 * @interface ObjectGroupsJsonldObjectGroupRead
 */
export interface ObjectGroupsJsonldObjectGroupRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ObjectGroupsJsonldObjectGroupRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsJsonldObjectGroupRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsJsonldObjectGroupRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ObjectGroupsJsonldObjectGroupRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsJsonldObjectGroupRead
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsJsonldObjectGroupRead
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsJsonldObjectGroupRead
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsJsonldObjectGroupRead
     */
    'icon'?: string | null;
}
/**
 * 
 * @export
 * @interface ObjectGroupsObjectGroupCreate
 */
export interface ObjectGroupsObjectGroupCreate {
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsObjectGroupCreate
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsObjectGroupCreate
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsObjectGroupCreate
     */
    'icon'?: string | null;
}
/**
 * 
 * @export
 * @interface ObjectGroupsObjectGroupRead
 */
export interface ObjectGroupsObjectGroupRead {
    /**
     * 
     * @type {number}
     * @memberof ObjectGroupsObjectGroupRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsObjectGroupRead
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsObjectGroupRead
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsObjectGroupRead
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsObjectGroupRead
     */
    'icon'?: string | null;
}
/**
 * 
 * @export
 * @interface PrestationArticlesJsonldPrestationrelationRead
 */
export interface PrestationArticlesJsonldPrestationrelationRead {
    /**
     * 
     * @type {string}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    'idPresta': string;
    /**
     * 
     * @type {TblFactArticlesJsonldPrestationrelationRead}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    'factArticle': TblFactArticlesJsonldPrestationrelationRead;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    'rang'?: number;
    /**
     * Article vérifié, prêt pour la facturation
     * @type {boolean}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    'fDonneesVerifiees'?: boolean;
    /**
     * Article masqué par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    'fMasqueCalcul'?: boolean;
    /**
     * Article ajouté par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    'fAjouteCalcul'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    'tauxReduction'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubJsonldPrestationrelationRead}
     * @memberof PrestationArticlesJsonldPrestationrelationRead
     */
    'detailpubs'?: FproPrestationArticleDetailPubJsonldPrestationrelationRead | null;
}
/**
 * 
 * @export
 * @interface PrestationArticlesPrestationrelationRead
 */
export interface PrestationArticlesPrestationrelationRead {
    /**
     * 
     * @type {string}
     * @memberof PrestationArticlesPrestationrelationRead
     */
    'idPresta': string;
    /**
     * 
     * @type {TblFactArticlesPrestationrelationRead}
     * @memberof PrestationArticlesPrestationrelationRead
     */
    'factArticle': TblFactArticlesPrestationrelationRead;
    /**
     * Libellé de l\'article
     * @type {string}
     * @memberof PrestationArticlesPrestationrelationRead
     */
    'libelle': string | null;
    /**
     * Tarif de l\'article
     * @type {string}
     * @memberof PrestationArticlesPrestationrelationRead
     */
    'tarif'?: string | null;
    /**
     * Quantité de l\'article
     * @type {number}
     * @memberof PrestationArticlesPrestationrelationRead
     */
    'quantite'?: number;
    /**
     * Ordonnancement des articles
     * @type {number}
     * @memberof PrestationArticlesPrestationrelationRead
     */
    'rang'?: number;
    /**
     * Article vérifié, prêt pour la facturation
     * @type {boolean}
     * @memberof PrestationArticlesPrestationrelationRead
     */
    'fDonneesVerifiees'?: boolean;
    /**
     * Article masqué par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof PrestationArticlesPrestationrelationRead
     */
    'fMasqueCalcul'?: boolean;
    /**
     * Article ajouté par le calcul du tarif de la collection
     * @type {boolean}
     * @memberof PrestationArticlesPrestationrelationRead
     */
    'fAjouteCalcul'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PrestationArticlesPrestationrelationRead
     */
    'tauxReduction'?: number;
    /**
     * 
     * @type {FproPrestationArticleDetailPubPrestationrelationRead}
     * @memberof PrestationArticlesPrestationrelationRead
     */
    'detailpubs'?: FproPrestationArticleDetailPubPrestationrelationRead | null;
}
/**
 * 
 * @export
 * @interface PrestationJsonldPrestationRead
 */
export interface PrestationJsonldPrestationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof PrestationJsonldPrestationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof PrestationJsonldPrestationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrestationJsonldPrestationRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PrestationJsonldPrestationRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PrestationJsonldPrestationRead
     */
    'dossier'?: string;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof PrestationJsonldPrestationRead
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof PrestationJsonldPrestationRead
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof PrestationJsonldPrestationRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {TblCatalogueJsonldPrestationRead}
     * @memberof PrestationJsonldPrestationRead
     */
    'catalogue'?: TblCatalogueJsonldPrestationRead | null;
    /**
     * 
     * @type {string}
     * @memberof PrestationJsonldPrestationRead
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrestationJsonldPrestationRead
     */
    'json'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationArticleJsonldPrestationRead>}
     * @memberof PrestationJsonldPrestationRead
     */
    'articles'?: Array<FproPrestationArticleJsonldPrestationRead>;
}
/**
 * 
 * @export
 * @interface PrestationJsonldPrestationUpdate
 */
export interface PrestationJsonldPrestationUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof PrestationJsonldPrestationUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof PrestationJsonldPrestationUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrestationJsonldPrestationUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrestationJsonldPrestationUpdate
     */
    'dossier'?: string;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof PrestationJsonldPrestationUpdate
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof PrestationJsonldPrestationUpdate
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof PrestationJsonldPrestationUpdate
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PrestationJsonldPrestationUpdate
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PrestationJsonldPrestationUpdate
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrestationJsonldPrestationUpdate
     */
    'json'?: string | null;
}
/**
 * 
 * @export
 * @interface PrestationPrestationRead
 */
export interface PrestationPrestationRead {
    /**
     * 
     * @type {number}
     * @memberof PrestationPrestationRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PrestationPrestationRead
     */
    'dossier'?: string;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof PrestationPrestationRead
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof PrestationPrestationRead
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof PrestationPrestationRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {TblCataloguePrestationRead}
     * @memberof PrestationPrestationRead
     */
    'catalogue'?: TblCataloguePrestationRead | null;
    /**
     * 
     * @type {string}
     * @memberof PrestationPrestationRead
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrestationPrestationRead
     */
    'json'?: string | null;
    /**
     * 
     * @type {Array<FproPrestationArticlePrestationRead>}
     * @memberof PrestationPrestationRead
     */
    'articles'?: Array<FproPrestationArticlePrestationRead>;
}
/**
 * 
 * @export
 * @interface PrestationPrestationUpdate
 */
export interface PrestationPrestationUpdate {
    /**
     * 
     * @type {string}
     * @memberof PrestationPrestationUpdate
     */
    'dossier'?: string;
    /**
     * Libellé de la prestation
     * @type {string}
     * @memberof PrestationPrestationUpdate
     */
    'libelle'?: string;
    /**
     * statut de la prestation
     * @type {string}
     * @memberof PrestationPrestationUpdate
     */
    'statut'?: string;
    /**
     * Article supprimé
     * @type {boolean}
     * @memberof PrestationPrestationUpdate
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PrestationPrestationUpdate
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PrestationPrestationUpdate
     */
    'formalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrestationPrestationUpdate
     */
    'json'?: string | null;
}
/**
 * 
 * @export
 * @interface ProcDureDeValidationDesDossiersJsonldValidatorsProcessCreate
 */
export interface ProcDureDeValidationDesDossiersJsonldValidatorsProcessCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ProcDureDeValidationDesDossiersJsonldValidatorsProcessCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ProcDureDeValidationDesDossiersJsonldValidatorsProcessCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcDureDeValidationDesDossiersJsonldValidatorsProcessCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcDureDeValidationDesDossiersJsonldValidatorsProcessCreate
     */
    'dossier'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcDureDeValidationDesDossiersJsonldValidatorsProcessCreate
     */
    'validator'?: string;
}
/**
 * 
 * @export
 * @interface ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead
 */
export interface ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead
     */
    'dossier'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead
     */
    'validator'?: string;
    /**
     * 
     * @type {FproDossierValidatorTypeStatusJsonldValidatorsProcessRead}
     * @memberof ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead
     */
    'status'?: FproDossierValidatorTypeStatusJsonldValidatorsProcessRead;
}
/**
 * 
 * @export
 * @interface ProcDureDeValidationDesDossiersValidatorsProcessCreate
 */
export interface ProcDureDeValidationDesDossiersValidatorsProcessCreate {
    /**
     * 
     * @type {string}
     * @memberof ProcDureDeValidationDesDossiersValidatorsProcessCreate
     */
    'dossier'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcDureDeValidationDesDossiersValidatorsProcessCreate
     */
    'validator'?: string;
}
/**
 * 
 * @export
 * @interface ProcDureDeValidationDesDossiersValidatorsProcessRead
 */
export interface ProcDureDeValidationDesDossiersValidatorsProcessRead {
    /**
     * 
     * @type {number}
     * @memberof ProcDureDeValidationDesDossiersValidatorsProcessRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcDureDeValidationDesDossiersValidatorsProcessRead
     */
    'dossier'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcDureDeValidationDesDossiersValidatorsProcessRead
     */
    'validator'?: string;
    /**
     * 
     * @type {object}
     * @memberof ProcDureDeValidationDesDossiersValidatorsProcessRead
     */
    'status'?: object;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBAcompteCreate
 */
export interface ReglementsEtAcomptesCBAcompteCreate {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'origine'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBAcompteCreated
 */
export interface ReglementsEtAcomptesCBAcompteCreated {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'dremisebanque'?: string | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBAcompteRead
 */
export interface ReglementsEtAcomptesCBAcompteRead {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'dremisebanque'?: string | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBAcompteUpdate
 */
export interface ReglementsEtAcomptesCBAcompteUpdate {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldAcompteCreate
 */
export interface ReglementsEtAcomptesCBJsonldAcompteCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'origine'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldAcompteCreated
 */
export interface ReglementsEtAcomptesCBJsonldAcompteCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'dremisebanque'?: string | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldAcompteRead
 */
export interface ReglementsEtAcomptesCBJsonldAcompteRead {
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'dremisebanque'?: string | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldAcompteUpdate
 */
export interface ReglementsEtAcomptesCBJsonldAcompteUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldReglementCreate
 */
export interface ReglementsEtAcomptesCBJsonldReglementCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'origine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldReglementCreated
 */
export interface ReglementsEtAcomptesCBJsonldReglementCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'dremisebanque'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldReglementRead
 */
export interface ReglementsEtAcomptesCBJsonldReglementRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'dremisebanque'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldReglementUpdate
 */
export interface ReglementsEtAcomptesCBJsonldReglementUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBReglementCreate
 */
export interface ReglementsEtAcomptesCBReglementCreate {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'origine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBReglementCreated
 */
export interface ReglementsEtAcomptesCBReglementCreated {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'dremisebanque'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBReglementRead
 */
export interface ReglementsEtAcomptesCBReglementRead {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'dremisebanque'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBReglementUpdate
 */
export interface ReglementsEtAcomptesCBReglementUpdate {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface SousTypesAnnonceJsonldSousTypeAnnonceRead
 */
export interface SousTypesAnnonceJsonldSousTypeAnnonceRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof SousTypesAnnonceJsonldSousTypeAnnonceRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof SousTypesAnnonceJsonldSousTypeAnnonceRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SousTypesAnnonceJsonldSousTypeAnnonceRead
     */
    '@type'?: string;
    /**
     * Get the value of id
     * @type {number}
     * @memberof SousTypesAnnonceJsonldSousTypeAnnonceRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface SousTypesAnnonceSousTypeAnnonceRead
 */
export interface SousTypesAnnonceSousTypeAnnonceRead {
    /**
     * Get the value of id
     * @type {number}
     * @memberof SousTypesAnnonceSousTypeAnnonceRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface StatutJsonldStatutCreate
 */
export interface StatutJsonldStatutCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof StatutJsonldStatutCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreate
     */
    '@type'?: string;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutJsonldStatutCreate
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreate
     */
    'class': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreate
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreate
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreate
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreate
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreate
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutJsonldStatutCreated
 */
export interface StatutJsonldStatutCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof StatutJsonldStatutCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatutJsonldStatutCreated
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutJsonldStatutCreated
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreated
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreated
     */
    'class': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreated
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreated
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreated
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreated
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreated
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreated
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutJsonldStatutRead
 */
export interface StatutJsonldStatutRead {
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof StatutJsonldStatutRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof StatutJsonldStatutRead
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutJsonldStatutRead
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutRead
     */
    'class'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutRead
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutRead
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutRead
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutRead
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutRead
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutJsonldStatutUpdate
 */
export interface StatutJsonldStatutUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof StatutJsonldStatutUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutUpdate
     */
    '@type'?: string;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutJsonldStatutUpdate
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutUpdate
     */
    'class': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutUpdate
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutUpdate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutUpdate
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutUpdate
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutUpdate
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutUpdate
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutStatutCreate
 */
export interface StatutStatutCreate {
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutStatutCreate
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutCreate
     */
    'class': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreate
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutCreate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreate
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreate
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreate
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreate
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutStatutCreated
 */
export interface StatutStatutCreated {
    /**
     * 
     * @type {number}
     * @memberof StatutStatutCreated
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutStatutCreated
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutCreated
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutCreated
     */
    'class': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreated
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutCreated
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreated
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreated
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreated
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreated
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutStatutRead
 */
export interface StatutStatutRead {
    /**
     * 
     * @type {number}
     * @memberof StatutStatutRead
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutStatutRead
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutRead
     */
    'class'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutRead
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutRead
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutRead
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutRead
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutRead
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutStatutUpdate
 */
export interface StatutStatutUpdate {
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutStatutUpdate
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutUpdate
     */
    'class': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutUpdate
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutUpdate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutUpdate
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutUpdate
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutUpdate
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutUpdate
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface TapesDeTraitementDesDossiersJsonldStepsDossierRead
 */
export interface TapesDeTraitementDesDossiersJsonldStepsDossierRead {
    /**
     * 
     * @type {string}
     * @memberof TapesDeTraitementDesDossiersJsonldStepsDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TapesDeTraitementDesDossiersJsonldStepsDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TapesDeTraitementDesDossiersJsonldStepsDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TapesDeTraitementDesDossiersJsonldStepsDossierRead
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof TapesDeTraitementDesDossiersJsonldStepsDossierRead
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface TapesDeTraitementDesDossiersStepsDossierRead
 */
export interface TapesDeTraitementDesDossiersStepsDossierRead {
    /**
     * 
     * @type {number}
     * @memberof TapesDeTraitementDesDossiersStepsDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TapesDeTraitementDesDossiersStepsDossierRead
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof TapesDeTraitementDesDossiersStepsDossierRead
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface TblActionDetailActionCreated
 */
export interface TblActionDetailActionCreated {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailActionCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailActionCreated
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailActionRead
 */
export interface TblActionDetailActionRead {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailActionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailActionRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailActiviteCreated
 */
export interface TblActionDetailActiviteCreated {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailActiviteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailActiviteCreated
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailActiviteRead
 */
export interface TblActionDetailActiviteRead {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailActiviteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailActiviteRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldActionCreated
 */
export interface TblActionDetailJsonldActionCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldActionCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldActionCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionCreated
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldActionRead
 */
export interface TblActionDetailJsonldActionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldActionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldActionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldActiviteCreated
 */
export interface TblActionDetailJsonldActiviteCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldActiviteCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActiviteCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActiviteCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldActiviteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActiviteCreated
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldActiviteRead
 */
export interface TblActionDetailJsonldActiviteRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldActiviteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActiviteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActiviteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldActiviteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActiviteRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeCollectiondossierRead
 */
export interface TblAnnonceSTypeCollectiondossierRead {
    /**
     * Get the value of id
     * @type {number}
     * @memberof TblAnnonceSTypeCollectiondossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeCollectiondossierRead
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeDossierRead
 */
export interface TblAnnonceSTypeDossierRead {
    /**
     * Get the value of id
     * @type {number}
     * @memberof TblAnnonceSTypeDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeDossierRead
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldCollectiondossierRead
 */
export interface TblAnnonceSTypeJsonldCollectiondossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldCollectiondossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldCollectiondossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldCollectiondossierRead
     */
    '@type'?: string;
    /**
     * Get the value of id
     * @type {number}
     * @memberof TblAnnonceSTypeJsonldCollectiondossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldCollectiondossierRead
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldDossierRead
 */
export interface TblAnnonceSTypeJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldDossierRead
     */
    '@type'?: string;
    /**
     * Get the value of id
     * @type {number}
     * @memberof TblAnnonceSTypeJsonldDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldDossierRead
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldPrestationRead
 */
export interface TblAnnonceSTypeJsonldPrestationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldPrestationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPrestationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPrestationRead
     */
    '@type'?: string;
    /**
     * Get the value of id
     * @type {number}
     * @memberof TblAnnonceSTypeJsonldPrestationRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPrestationRead
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldPrestationrelationRead
 */
export interface TblAnnonceSTypeJsonldPrestationrelationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldPrestationrelationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPrestationrelationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPrestationrelationRead
     */
    '@type'?: string;
    /**
     * Get the value of id
     * @type {number}
     * @memberof TblAnnonceSTypeJsonldPrestationrelationRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPrestationrelationRead
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypePrestationRead
 */
export interface TblAnnonceSTypePrestationRead {
    /**
     * Get the value of id
     * @type {number}
     * @memberof TblAnnonceSTypePrestationRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypePrestationRead
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypePrestationrelationRead
 */
export interface TblAnnonceSTypePrestationrelationRead {
    /**
     * Get the value of id
     * @type {number}
     * @memberof TblAnnonceSTypePrestationrelationRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypePrestationrelationRead
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface TblCatalogueDossierRead
 */
export interface TblCatalogueDossierRead {
    /**
     * 
     * @type {number}
     * @memberof TblCatalogueDossierRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblCatalogueJsonldDossierRead
 */
export interface TblCatalogueJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblCatalogueJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblCatalogueJsonldDossierRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblCatalogueJsonldPrestationRead
 */
export interface TblCatalogueJsonldPrestationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblCatalogueJsonldPrestationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueJsonldPrestationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueJsonldPrestationRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblCatalogueJsonldPrestationRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblCataloguePrestationRead
 */
export interface TblCataloguePrestationRead {
    /**
     * 
     * @type {number}
     * @memberof TblCataloguePrestationRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblDefStatutDossierJsonldCollectiondossierCreated
 */
export interface TblDefStatutDossierJsonldCollectiondossierCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblDefStatutDossierJsonldCollectiondossierCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefStatutDossierJsonldCollectiondossierCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefStatutDossierJsonldCollectiondossierCreated
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblDefStatutDossierJsonldCollectiondossierRead
 */
export interface TblDefStatutDossierJsonldCollectiondossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblDefStatutDossierJsonldCollectiondossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefStatutDossierJsonldCollectiondossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefStatutDossierJsonldCollectiondossierRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblDefStatutDossierJsonldCollectiondossierValidated
 */
export interface TblDefStatutDossierJsonldCollectiondossierValidated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblDefStatutDossierJsonldCollectiondossierValidated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefStatutDossierJsonldCollectiondossierValidated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefStatutDossierJsonldCollectiondossierValidated
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblEvenementJsonldNoterappelCreate
 */
export interface TblEvenementJsonldNoterappelCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementJsonldNoterappelCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementJsonldNoterappelCreate
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelJsonldNoterappelCreate>}
     * @memberof TblEvenementJsonldNoterappelCreate
     */
    'rappels'?: Array<TblEvenementRappelJsonldNoterappelCreate>;
}
/**
 * 
 * @export
 * @interface TblEvenementJsonldNoterappelCreated
 */
export interface TblEvenementJsonldNoterappelCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementJsonldNoterappelCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementJsonldNoterappelCreated
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelJsonldNoterappelCreated>}
     * @memberof TblEvenementJsonldNoterappelCreated
     */
    'rappels'?: Array<TblEvenementRappelJsonldNoterappelCreated>;
}
/**
 * 
 * @export
 * @interface TblEvenementJsonldNoterappelRead
 */
export interface TblEvenementJsonldNoterappelRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementJsonldNoterappelRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementJsonldNoterappelRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementJsonldNoterappelRead
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelJsonldNoterappelRead>}
     * @memberof TblEvenementJsonldNoterappelRead
     */
    'rappels'?: Array<TblEvenementRappelJsonldNoterappelRead>;
}
/**
 * 
 * @export
 * @interface TblEvenementJsonldNoterappelUpdate
 */
export interface TblEvenementJsonldNoterappelUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementJsonldNoterappelUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementJsonldNoterappelUpdate
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelJsonldNoterappelUpdate>}
     * @memberof TblEvenementJsonldNoterappelUpdate
     */
    'rappels'?: Array<TblEvenementRappelJsonldNoterappelUpdate>;
}
/**
 * 
 * @export
 * @interface TblEvenementNoterappelCreate
 */
export interface TblEvenementNoterappelCreate {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementNoterappelCreate
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelNoterappelCreate>}
     * @memberof TblEvenementNoterappelCreate
     */
    'rappels'?: Array<TblEvenementRappelNoterappelCreate>;
}
/**
 * 
 * @export
 * @interface TblEvenementNoterappelCreated
 */
export interface TblEvenementNoterappelCreated {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementNoterappelCreated
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelNoterappelCreated>}
     * @memberof TblEvenementNoterappelCreated
     */
    'rappels'?: Array<TblEvenementRappelNoterappelCreated>;
}
/**
 * 
 * @export
 * @interface TblEvenementNoterappelRead
 */
export interface TblEvenementNoterappelRead {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementNoterappelRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementNoterappelRead
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelNoterappelRead>}
     * @memberof TblEvenementNoterappelRead
     */
    'rappels'?: Array<TblEvenementRappelNoterappelRead>;
}
/**
 * 
 * @export
 * @interface TblEvenementNoterappelUpdate
 */
export interface TblEvenementNoterappelUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementNoterappelUpdate
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelNoterappelUpdate>}
     * @memberof TblEvenementNoterappelUpdate
     */
    'rappels'?: Array<TblEvenementRappelNoterappelUpdate>;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelJsonldNoterappelCreate
 */
export interface TblEvenementRappelJsonldNoterappelCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelCreate}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'evenement'?: TblEvenementJsonldNoterappelCreate;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelJsonldNoterappelCreated
 */
export interface TblEvenementRappelJsonldNoterappelCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelCreated}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'evenement'?: TblEvenementJsonldNoterappelCreated;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelJsonldNoterappelRead
 */
export interface TblEvenementRappelJsonldNoterappelRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelRead}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'evenement'?: TblEvenementJsonldNoterappelRead;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelJsonldNoterappelReadCollection
 */
export interface TblEvenementRappelJsonldNoterappelReadCollection {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelJsonldNoterappelUpdate
 */
export interface TblEvenementRappelJsonldNoterappelUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelUpdate}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'evenement'?: TblEvenementJsonldNoterappelUpdate;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelNoterappelCreate
 */
export interface TblEvenementRappelNoterappelCreate {
    /**
     * 
     * @type {TblEvenementNoterappelCreate}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'evenement'?: TblEvenementNoterappelCreate;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelNoterappelCreated
 */
export interface TblEvenementRappelNoterappelCreated {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementNoterappelCreated}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'evenement'?: TblEvenementNoterappelCreated;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelNoterappelRead
 */
export interface TblEvenementRappelNoterappelRead {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementNoterappelRead}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'evenement'?: TblEvenementNoterappelRead;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelNoterappelReadCollection
 */
export interface TblEvenementRappelNoterappelReadCollection {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelNoterappelUpdate
 */
export interface TblEvenementRappelNoterappelUpdate {
    /**
     * 
     * @type {TblEvenementNoterappelUpdate}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'evenement'?: TblEvenementNoterappelUpdate;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesCollectiondossierRead
 */
export interface TblFactArticlesCollectiondossierRead {
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesCollectiondossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesCollectiondossierRead
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesCollectiondossierRead
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface TblFactArticlesDossierRead
 */
export interface TblFactArticlesDossierRead {
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesDossierRead
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesDossierRead
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldCollectiondossierRead
 */
export interface TblFactArticlesJsonldCollectiondossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldCollectiondossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldCollectiondossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldCollectiondossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesJsonldCollectiondossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldCollectiondossierRead
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldCollectiondossierRead
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldDossierRead
 */
export interface TblFactArticlesJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesJsonldDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldDossierRead
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldDossierRead
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldPrestationRead
 */
export interface TblFactArticlesJsonldPrestationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldPrestationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPrestationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPrestationRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesJsonldPrestationRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPrestationRead
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPrestationRead
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldPrestationrelationRead
 */
export interface TblFactArticlesJsonldPrestationrelationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldPrestationrelationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPrestationrelationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPrestationrelationRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesJsonldPrestationrelationRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPrestationrelationRead
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPrestationrelationRead
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface TblFactArticlesPrestationRead
 */
export interface TblFactArticlesPrestationRead {
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesPrestationRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesPrestationRead
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesPrestationRead
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface TblFactArticlesPrestationrelationRead
 */
export interface TblFactArticlesPrestationrelationRead {
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesPrestationrelationRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesPrestationrelationRead
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesPrestationrelationRead
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface TblPrestaJsonldCollectiondossierRead
 */
export interface TblPrestaJsonldCollectiondossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblPrestaJsonldCollectiondossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldCollectiondossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldCollectiondossierRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblPrestaJsonldDossierRead
 */
export interface TblPrestaJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblPrestaJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblPrestaJsonldPrestationRead
 */
export interface TblPrestaJsonldPrestationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblPrestaJsonldPrestationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldPrestationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldPrestationRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblPrestaJsonldPrestationrelationRead
 */
export interface TblPrestaJsonldPrestationrelationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblPrestaJsonldPrestationrelationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldPrestationrelationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldPrestationrelationRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentDocumentfileRead
 */
export interface TblTypeDocumentDocumentfileRead {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentDocumentfileRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentDocumentfileRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentDocumentfileRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentDocumentfileRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentDocumentfileRead
     */
    'signable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentDocumentfileRead
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldDocumentfileRead
 */
export interface TblTypeDocumentJsonldDocumentfileRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    'signable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldTypedocumentdossierRead
 */
export interface TblTypeDocumentJsonldTypedocumentdossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldTypedocumentdossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldTypedocumentdossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldTypedocumentdossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldTypedocumentdossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldTypedocumentdossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldTypedocumentdossierRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldTypedocumentdossierRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldTypedocumentdossierRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldTypedocumentdossierRead
     */
    'signable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldTypedocumentdossierRead
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentTypedocumentdossierRead
 */
export interface TblTypeDocumentTypedocumentdossierRead {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentTypedocumentdossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentTypedocumentdossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentTypedocumentdossierRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentTypedocumentdossierRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentTypedocumentdossierRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentTypedocumentdossierRead
     */
    'signable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentTypedocumentdossierRead
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TiersJsonldDossierRead
 */
export interface TiersJsonldDossierRead {
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldDossierRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
 */
export interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    'statut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
 */
export interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'statut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
 */
export interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {TblTypeDocumentJsonldTypedocumentdossierRead}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'document'?: TblTypeDocumentJsonldTypedocumentdossierRead | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'statut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
 */
export interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    'statut'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersTypedocumentPatch
 */
export interface TypeDeDocumentDesDossiersTypedocumentPatch {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentPatch
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentPatch
     */
    'document'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersTypedocumentdossierCreate
 */
export interface TypeDeDocumentDesDossiersTypedocumentdossierCreate {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreate
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreate
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreate
     */
    'statut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersTypedocumentdossierCreated
 */
export interface TypeDeDocumentDesDossiersTypedocumentdossierCreated {
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'statut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersTypedocumentdossierRead
 */
export interface TypeDeDocumentDesDossiersTypedocumentdossierRead {
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {TblTypeDocumentTypedocumentdossierRead}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'document'?: TblTypeDocumentTypedocumentdossierRead | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'statut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersTypedocumentdossierUpdate
 */
export interface TypeDeDocumentDesDossiersTypedocumentdossierUpdate {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierUpdate
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierUpdate
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierUpdate
     */
    'statut'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentJsonldTypedocumentCreate
 */
export interface TypeDeDocumentJsonldTypedocumentCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'signable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentJsonldTypedocumentCreated
 */
export interface TypeDeDocumentJsonldTypedocumentCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'signable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentJsonldTypedocumentRead
 */
export interface TypeDeDocumentJsonldTypedocumentRead {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'signable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentJsonldTypedocumentUpdate
 */
export interface TypeDeDocumentJsonldTypedocumentUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'statut'?: number | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentTypedocumentCreate
 */
export interface TypeDeDocumentTypedocumentCreate {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'signable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentTypedocumentCreated
 */
export interface TypeDeDocumentTypedocumentCreated {
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'signable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentTypedocumentRead
 */
export interface TypeDeDocumentTypedocumentRead {
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'signable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentTypedocumentUpdate
 */
export interface TypeDeDocumentTypedocumentUpdate {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'statut'?: number | null;
}
/**
 * 
 * @export
 * @interface ValideursDesDossiersJsonldValidatorsDossierRead
 */
export interface ValideursDesDossiersJsonldValidatorsDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ValideursDesDossiersJsonldValidatorsDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ValideursDesDossiersJsonldValidatorsDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValideursDesDossiersJsonldValidatorsDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ValideursDesDossiersJsonldValidatorsDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ValideursDesDossiersJsonldValidatorsDossierRead
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface ValideursDesDossiersValidatorsDossierRead
 */
export interface ValideursDesDossiersValidatorsDossierRead {
    /**
     * 
     * @type {number}
     * @memberof ValideursDesDossiersValidatorsDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ValideursDesDossiersValidatorsDossierRead
     */
    'label'?: string;
}

/**
 * AcomptesApi - axios parameter creator
 * @export
 */
export const AcomptesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'un acompte
         * @summary Récupérer les informations d\'un acompte
         * @param {string} id TblReglement2 identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcomptesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAcomptesIdGet', 'id', id)
            const localVarPath = `/formalite/acomptes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Reglements et acomptes CB resource.
         * @summary Modifier un acompte
         * @param {string} id TblReglement2 identifier
         * @param {ReglementsEtAcomptesCBJsonldAcompteUpdate} reglementsEtAcomptesCBJsonldAcompteUpdate The updated Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcomptesIdPut: async (id: string, reglementsEtAcomptesCBJsonldAcompteUpdate: ReglementsEtAcomptesCBJsonldAcompteUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAcomptesIdPut', 'id', id)
            // verify required parameter 'reglementsEtAcomptesCBJsonldAcompteUpdate' is not null or undefined
            assertParamExists('apiAcomptesIdPut', 'reglementsEtAcomptesCBJsonldAcompteUpdate', reglementsEtAcomptesCBJsonldAcompteUpdate)
            const localVarPath = `/formalite/acomptes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reglementsEtAcomptesCBJsonldAcompteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Reglements et acomptes CB resource.
         * @summary Créer un acompte
         * @param {ReglementsEtAcomptesCBJsonldAcompteCreate} reglementsEtAcomptesCBJsonldAcompteCreate The new Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcomptesPost: async (reglementsEtAcomptesCBJsonldAcompteCreate: ReglementsEtAcomptesCBJsonldAcompteCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reglementsEtAcomptesCBJsonldAcompteCreate' is not null or undefined
            assertParamExists('apiAcomptesPost', 'reglementsEtAcomptesCBJsonldAcompteCreate', reglementsEtAcomptesCBJsonldAcompteCreate)
            const localVarPath = `/formalite/acomptes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reglementsEtAcomptesCBJsonldAcompteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des acomptes
         * @summary Obtenir la liste des acomptes
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierAcomptesGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierAcomptesGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/acomptes`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AcomptesApi - functional programming interface
 * @export
 */
export const AcomptesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AcomptesApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'un acompte
         * @summary Récupérer les informations d\'un acompte
         * @param {string} id TblReglement2 identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcomptesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAcomptesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AcomptesApi.apiAcomptesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Reglements et acomptes CB resource.
         * @summary Modifier un acompte
         * @param {string} id TblReglement2 identifier
         * @param {ReglementsEtAcomptesCBJsonldAcompteUpdate} reglementsEtAcomptesCBJsonldAcompteUpdate The updated Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcomptesIdPut(id: string, reglementsEtAcomptesCBJsonldAcompteUpdate: ReglementsEtAcomptesCBJsonldAcompteUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAcomptesIdPut(id, reglementsEtAcomptesCBJsonldAcompteUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AcomptesApi.apiAcomptesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Reglements et acomptes CB resource.
         * @summary Créer un acompte
         * @param {ReglementsEtAcomptesCBJsonldAcompteCreate} reglementsEtAcomptesCBJsonldAcompteCreate The new Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcomptesPost(reglementsEtAcomptesCBJsonldAcompteCreate: ReglementsEtAcomptesCBJsonldAcompteCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAcomptesPost(reglementsEtAcomptesCBJsonldAcompteCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AcomptesApi.apiAcomptesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des acomptes
         * @summary Obtenir la liste des acomptes
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierAcomptesGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierAcomptesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierAcomptesGetCollection(dossier, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AcomptesApi.apiDossiersDossierAcomptesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AcomptesApi - factory interface
 * @export
 */
export const AcomptesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AcomptesApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'un acompte
         * @summary Récupérer les informations d\'un acompte
         * @param {AcomptesApiApiAcomptesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcomptesIdGet(requestParameters: AcomptesApiApiAcomptesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteRead> {
            return localVarFp.apiAcomptesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Reglements et acomptes CB resource.
         * @summary Modifier un acompte
         * @param {AcomptesApiApiAcomptesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcomptesIdPut(requestParameters: AcomptesApiApiAcomptesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteRead> {
            return localVarFp.apiAcomptesIdPut(requestParameters.id, requestParameters.reglementsEtAcomptesCBJsonldAcompteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Reglements et acomptes CB resource.
         * @summary Créer un acompte
         * @param {AcomptesApiApiAcomptesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcomptesPost(requestParameters: AcomptesApiApiAcomptesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteCreated> {
            return localVarFp.apiAcomptesPost(requestParameters.reglementsEtAcomptesCBJsonldAcompteCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des acomptes
         * @summary Obtenir la liste des acomptes
         * @param {AcomptesApiApiDossiersDossierAcomptesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierAcomptesGetCollection(requestParameters: AcomptesApiApiDossiersDossierAcomptesGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersDossierAcomptesGetCollection200Response> {
            return localVarFp.apiDossiersDossierAcomptesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiAcomptesIdGet operation in AcomptesApi.
 * @export
 * @interface AcomptesApiApiAcomptesIdGetRequest
 */
export interface AcomptesApiApiAcomptesIdGetRequest {
    /**
     * TblReglement2 identifier
     * @type {string}
     * @memberof AcomptesApiApiAcomptesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiAcomptesIdPut operation in AcomptesApi.
 * @export
 * @interface AcomptesApiApiAcomptesIdPutRequest
 */
export interface AcomptesApiApiAcomptesIdPutRequest {
    /**
     * TblReglement2 identifier
     * @type {string}
     * @memberof AcomptesApiApiAcomptesIdPut
     */
    readonly id: string

    /**
     * The updated Reglements et acomptes CB resource
     * @type {ReglementsEtAcomptesCBJsonldAcompteUpdate}
     * @memberof AcomptesApiApiAcomptesIdPut
     */
    readonly reglementsEtAcomptesCBJsonldAcompteUpdate: ReglementsEtAcomptesCBJsonldAcompteUpdate
}

/**
 * Request parameters for apiAcomptesPost operation in AcomptesApi.
 * @export
 * @interface AcomptesApiApiAcomptesPostRequest
 */
export interface AcomptesApiApiAcomptesPostRequest {
    /**
     * The new Reglements et acomptes CB resource
     * @type {ReglementsEtAcomptesCBJsonldAcompteCreate}
     * @memberof AcomptesApiApiAcomptesPost
     */
    readonly reglementsEtAcomptesCBJsonldAcompteCreate: ReglementsEtAcomptesCBJsonldAcompteCreate
}

/**
 * Request parameters for apiDossiersDossierAcomptesGetCollection operation in AcomptesApi.
 * @export
 * @interface AcomptesApiApiDossiersDossierAcomptesGetCollectionRequest
 */
export interface AcomptesApiApiDossiersDossierAcomptesGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof AcomptesApiApiDossiersDossierAcomptesGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof AcomptesApiApiDossiersDossierAcomptesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof AcomptesApiApiDossiersDossierAcomptesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * AcomptesApi - object-oriented interface
 * @export
 * @class AcomptesApi
 * @extends {BaseAPI}
 */
export class AcomptesApi extends BaseAPI {
    /**
     * Récupérer les informations d\'un acompte
     * @summary Récupérer les informations d\'un acompte
     * @param {AcomptesApiApiAcomptesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcomptesApi
     */
    public apiAcomptesIdGet(requestParameters: AcomptesApiApiAcomptesIdGetRequest, options?: RawAxiosRequestConfig) {
        return AcomptesApiFp(this.configuration).apiAcomptesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Reglements et acomptes CB resource.
     * @summary Modifier un acompte
     * @param {AcomptesApiApiAcomptesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcomptesApi
     */
    public apiAcomptesIdPut(requestParameters: AcomptesApiApiAcomptesIdPutRequest, options?: RawAxiosRequestConfig) {
        return AcomptesApiFp(this.configuration).apiAcomptesIdPut(requestParameters.id, requestParameters.reglementsEtAcomptesCBJsonldAcompteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Reglements et acomptes CB resource.
     * @summary Créer un acompte
     * @param {AcomptesApiApiAcomptesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcomptesApi
     */
    public apiAcomptesPost(requestParameters: AcomptesApiApiAcomptesPostRequest, options?: RawAxiosRequestConfig) {
        return AcomptesApiFp(this.configuration).apiAcomptesPost(requestParameters.reglementsEtAcomptesCBJsonldAcompteCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des acomptes
     * @summary Obtenir la liste des acomptes
     * @param {AcomptesApiApiDossiersDossierAcomptesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcomptesApi
     */
    public apiDossiersDossierAcomptesGetCollection(requestParameters: AcomptesApiApiDossiersDossierAcomptesGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return AcomptesApiFp(this.configuration).apiDossiersDossierAcomptesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ActionDetailApi - axios parameter creator
 * @export
 */
export const ActionDetailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des action-details
         * @summary Récupérer la liste des action-details
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionDetailsGetCollection: async (page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/action-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une action-detail
         * @summary Récupérer les informations d\'une action-detail
         * @param {string} id TblActionDetail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionDetailsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActionDetailsIdGet', 'id', id)
            const localVarPath = `/formalite/action-details/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionDetailApi - functional programming interface
 * @export
 */
export const ActionDetailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionDetailApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des action-details
         * @summary Récupérer la liste des action-details
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActionDetailsGetCollection(page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiActionDetailsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActionDetailsGetCollection(page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionDetailApi.apiActionDetailsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une action-detail
         * @summary Récupérer les informations d\'une action-detail
         * @param {string} id TblActionDetail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActionDetailsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionDetailJsonldActionDetailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActionDetailsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionDetailApi.apiActionDetailsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActionDetailApi - factory interface
 * @export
 */
export const ActionDetailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionDetailApiFp(configuration)
    return {
        /**
         * Récupérer la liste des action-details
         * @summary Récupérer la liste des action-details
         * @param {ActionDetailApiApiActionDetailsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionDetailsGetCollection(requestParameters: ActionDetailApiApiActionDetailsGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiActionDetailsGetCollection200Response> {
            return localVarFp.apiActionDetailsGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une action-detail
         * @summary Récupérer les informations d\'une action-detail
         * @param {ActionDetailApiApiActionDetailsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionDetailsIdGet(requestParameters: ActionDetailApiApiActionDetailsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ActionDetailJsonldActionDetailRead> {
            return localVarFp.apiActionDetailsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiActionDetailsGetCollection operation in ActionDetailApi.
 * @export
 * @interface ActionDetailApiApiActionDetailsGetCollectionRequest
 */
export interface ActionDetailApiApiActionDetailsGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof ActionDetailApiApiActionDetailsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ActionDetailApiApiActionDetailsGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiActionDetailsIdGet operation in ActionDetailApi.
 * @export
 * @interface ActionDetailApiApiActionDetailsIdGetRequest
 */
export interface ActionDetailApiApiActionDetailsIdGetRequest {
    /**
     * TblActionDetail identifier
     * @type {string}
     * @memberof ActionDetailApiApiActionDetailsIdGet
     */
    readonly id: string
}

/**
 * ActionDetailApi - object-oriented interface
 * @export
 * @class ActionDetailApi
 * @extends {BaseAPI}
 */
export class ActionDetailApi extends BaseAPI {
    /**
     * Récupérer la liste des action-details
     * @summary Récupérer la liste des action-details
     * @param {ActionDetailApiApiActionDetailsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionDetailApi
     */
    public apiActionDetailsGetCollection(requestParameters: ActionDetailApiApiActionDetailsGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return ActionDetailApiFp(this.configuration).apiActionDetailsGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une action-detail
     * @summary Récupérer les informations d\'une action-detail
     * @param {ActionDetailApiApiActionDetailsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionDetailApi
     */
    public apiActionDetailsIdGet(requestParameters: ActionDetailApiApiActionDetailsIdGetRequest, options?: RawAxiosRequestConfig) {
        return ActionDetailApiFp(this.configuration).apiActionDetailsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ActionsApi - axios parameter creator
 * @export
 */
export const ActionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'une action
         * @summary Récupérer les informations d\'une action
         * @param {string} id TblAction identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActionsIdGet', 'id', id)
            const localVarPath = `/formalite/actions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Actions et activités resource.
         * @summary Modifier une action
         * @param {string} id TblAction identifier
         * @param {ActionsEtActivitSJsonldActionUpdate} actionsEtActivitSJsonldActionUpdate The updated Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionsIdPut: async (id: string, actionsEtActivitSJsonldActionUpdate: ActionsEtActivitSJsonldActionUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActionsIdPut', 'id', id)
            // verify required parameter 'actionsEtActivitSJsonldActionUpdate' is not null or undefined
            assertParamExists('apiActionsIdPut', 'actionsEtActivitSJsonldActionUpdate', actionsEtActivitSJsonldActionUpdate)
            const localVarPath = `/formalite/actions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionsEtActivitSJsonldActionUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Actions et activités resource.
         * @summary Créer une action
         * @param {ActionsEtActivitSJsonldActionCreate} actionsEtActivitSJsonldActionCreate The new Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionsPost: async (actionsEtActivitSJsonldActionCreate: ActionsEtActivitSJsonldActionCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionsEtActivitSJsonldActionCreate' is not null or undefined
            assertParamExists('apiActionsPost', 'actionsEtActivitSJsonldActionCreate', actionsEtActivitSJsonldActionCreate)
            const localVarPath = `/formalite/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionsEtActivitSJsonldActionCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des actions
         * @summary Obtenir la liste des actions
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierActionsGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierActionsGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/actions`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionsApi - functional programming interface
 * @export
 */
export const ActionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'une action
         * @summary Récupérer les informations d\'une action
         * @param {string} id TblAction identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActionsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionsEtActivitSJsonldActionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActionsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionsApi.apiActionsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Actions et activités resource.
         * @summary Modifier une action
         * @param {string} id TblAction identifier
         * @param {ActionsEtActivitSJsonldActionUpdate} actionsEtActivitSJsonldActionUpdate The updated Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActionsIdPut(id: string, actionsEtActivitSJsonldActionUpdate: ActionsEtActivitSJsonldActionUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionsEtActivitSJsonldActionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActionsIdPut(id, actionsEtActivitSJsonldActionUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionsApi.apiActionsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Actions et activités resource.
         * @summary Créer une action
         * @param {ActionsEtActivitSJsonldActionCreate} actionsEtActivitSJsonldActionCreate The new Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActionsPost(actionsEtActivitSJsonldActionCreate: ActionsEtActivitSJsonldActionCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionsEtActivitSJsonldActionCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActionsPost(actionsEtActivitSJsonldActionCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionsApi.apiActionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des actions
         * @summary Obtenir la liste des actions
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierActionsGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierActionsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierActionsGetCollection(dossier, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionsApi.apiDossiersDossierActionsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActionsApi - factory interface
 * @export
 */
export const ActionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionsApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'une action
         * @summary Récupérer les informations d\'une action
         * @param {ActionsApiApiActionsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionsIdGet(requestParameters: ActionsApiApiActionsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ActionsEtActivitSJsonldActionRead> {
            return localVarFp.apiActionsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Actions et activités resource.
         * @summary Modifier une action
         * @param {ActionsApiApiActionsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionsIdPut(requestParameters: ActionsApiApiActionsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ActionsEtActivitSJsonldActionRead> {
            return localVarFp.apiActionsIdPut(requestParameters.id, requestParameters.actionsEtActivitSJsonldActionUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Actions et activités resource.
         * @summary Créer une action
         * @param {ActionsApiApiActionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionsPost(requestParameters: ActionsApiApiActionsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ActionsEtActivitSJsonldActionCreated> {
            return localVarFp.apiActionsPost(requestParameters.actionsEtActivitSJsonldActionCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des actions
         * @summary Obtenir la liste des actions
         * @param {ActionsApiApiDossiersDossierActionsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierActionsGetCollection(requestParameters: ActionsApiApiDossiersDossierActionsGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersDossierActionsGetCollection200Response> {
            return localVarFp.apiDossiersDossierActionsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiActionsIdGet operation in ActionsApi.
 * @export
 * @interface ActionsApiApiActionsIdGetRequest
 */
export interface ActionsApiApiActionsIdGetRequest {
    /**
     * TblAction identifier
     * @type {string}
     * @memberof ActionsApiApiActionsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiActionsIdPut operation in ActionsApi.
 * @export
 * @interface ActionsApiApiActionsIdPutRequest
 */
export interface ActionsApiApiActionsIdPutRequest {
    /**
     * TblAction identifier
     * @type {string}
     * @memberof ActionsApiApiActionsIdPut
     */
    readonly id: string

    /**
     * The updated Actions et activités resource
     * @type {ActionsEtActivitSJsonldActionUpdate}
     * @memberof ActionsApiApiActionsIdPut
     */
    readonly actionsEtActivitSJsonldActionUpdate: ActionsEtActivitSJsonldActionUpdate
}

/**
 * Request parameters for apiActionsPost operation in ActionsApi.
 * @export
 * @interface ActionsApiApiActionsPostRequest
 */
export interface ActionsApiApiActionsPostRequest {
    /**
     * The new Actions et activités resource
     * @type {ActionsEtActivitSJsonldActionCreate}
     * @memberof ActionsApiApiActionsPost
     */
    readonly actionsEtActivitSJsonldActionCreate: ActionsEtActivitSJsonldActionCreate
}

/**
 * Request parameters for apiDossiersDossierActionsGetCollection operation in ActionsApi.
 * @export
 * @interface ActionsApiApiDossiersDossierActionsGetCollectionRequest
 */
export interface ActionsApiApiDossiersDossierActionsGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof ActionsApiApiDossiersDossierActionsGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof ActionsApiApiDossiersDossierActionsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ActionsApiApiDossiersDossierActionsGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * ActionsApi - object-oriented interface
 * @export
 * @class ActionsApi
 * @extends {BaseAPI}
 */
export class ActionsApi extends BaseAPI {
    /**
     * Récupérer les informations d\'une action
     * @summary Récupérer les informations d\'une action
     * @param {ActionsApiApiActionsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public apiActionsIdGet(requestParameters: ActionsApiApiActionsIdGetRequest, options?: RawAxiosRequestConfig) {
        return ActionsApiFp(this.configuration).apiActionsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Actions et activités resource.
     * @summary Modifier une action
     * @param {ActionsApiApiActionsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public apiActionsIdPut(requestParameters: ActionsApiApiActionsIdPutRequest, options?: RawAxiosRequestConfig) {
        return ActionsApiFp(this.configuration).apiActionsIdPut(requestParameters.id, requestParameters.actionsEtActivitSJsonldActionUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Actions et activités resource.
     * @summary Créer une action
     * @param {ActionsApiApiActionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public apiActionsPost(requestParameters: ActionsApiApiActionsPostRequest, options?: RawAxiosRequestConfig) {
        return ActionsApiFp(this.configuration).apiActionsPost(requestParameters.actionsEtActivitSJsonldActionCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des actions
     * @summary Obtenir la liste des actions
     * @param {ActionsApiApiDossiersDossierActionsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public apiDossiersDossierActionsGetCollection(requestParameters: ActionsApiApiDossiersDossierActionsGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return ActionsApiFp(this.configuration).apiDossiersDossierActionsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ActivitsApi - axios parameter creator
 * @export
 */
export const ActivitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'une activité
         * @summary Récupérer les informations d\'une action
         * @param {string} id TblAction identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivitesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActivitesIdGet', 'id', id)
            const localVarPath = `/formalite/activites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Actions et activités resource.
         * @summary Modifier une activité
         * @param {string} id TblAction identifier
         * @param {ActionsEtActivitSJsonldActiviteUpdate} actionsEtActivitSJsonldActiviteUpdate The updated Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivitesIdPut: async (id: string, actionsEtActivitSJsonldActiviteUpdate: ActionsEtActivitSJsonldActiviteUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActivitesIdPut', 'id', id)
            // verify required parameter 'actionsEtActivitSJsonldActiviteUpdate' is not null or undefined
            assertParamExists('apiActivitesIdPut', 'actionsEtActivitSJsonldActiviteUpdate', actionsEtActivitSJsonldActiviteUpdate)
            const localVarPath = `/formalite/activites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionsEtActivitSJsonldActiviteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Actions et activités resource.
         * @summary Créer une activité
         * @param {ActionsEtActivitSJsonldActiviteCreate} actionsEtActivitSJsonldActiviteCreate The new Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivitesPost: async (actionsEtActivitSJsonldActiviteCreate: ActionsEtActivitSJsonldActiviteCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionsEtActivitSJsonldActiviteCreate' is not null or undefined
            assertParamExists('apiActivitesPost', 'actionsEtActivitSJsonldActiviteCreate', actionsEtActivitSJsonldActiviteCreate)
            const localVarPath = `/formalite/activites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionsEtActivitSJsonldActiviteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des activités
         * @summary Obtenir la liste des activités
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierActivitesGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierActivitesGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/activites`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivitsApi - functional programming interface
 * @export
 */
export const ActivitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivitsApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'une activité
         * @summary Récupérer les informations d\'une action
         * @param {string} id TblAction identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivitesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionsEtActivitSJsonldActionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivitesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivitsApi.apiActivitesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Actions et activités resource.
         * @summary Modifier une activité
         * @param {string} id TblAction identifier
         * @param {ActionsEtActivitSJsonldActiviteUpdate} actionsEtActivitSJsonldActiviteUpdate The updated Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivitesIdPut(id: string, actionsEtActivitSJsonldActiviteUpdate: ActionsEtActivitSJsonldActiviteUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionsEtActivitSJsonldActiviteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivitesIdPut(id, actionsEtActivitSJsonldActiviteUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivitsApi.apiActivitesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Actions et activités resource.
         * @summary Créer une activité
         * @param {ActionsEtActivitSJsonldActiviteCreate} actionsEtActivitSJsonldActiviteCreate The new Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivitesPost(actionsEtActivitSJsonldActiviteCreate: ActionsEtActivitSJsonldActiviteCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionsEtActivitSJsonldActiviteCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivitesPost(actionsEtActivitSJsonldActiviteCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivitsApi.apiActivitesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des activités
         * @summary Obtenir la liste des activités
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierActivitesGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierActionsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierActivitesGetCollection(dossier, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivitsApi.apiDossiersDossierActivitesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActivitsApi - factory interface
 * @export
 */
export const ActivitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivitsApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'une activité
         * @summary Récupérer les informations d\'une action
         * @param {ActivitsApiApiActivitesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivitesIdGet(requestParameters: ActivitsApiApiActivitesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ActionsEtActivitSJsonldActionRead> {
            return localVarFp.apiActivitesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Actions et activités resource.
         * @summary Modifier une activité
         * @param {ActivitsApiApiActivitesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivitesIdPut(requestParameters: ActivitsApiApiActivitesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ActionsEtActivitSJsonldActiviteRead> {
            return localVarFp.apiActivitesIdPut(requestParameters.id, requestParameters.actionsEtActivitSJsonldActiviteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Actions et activités resource.
         * @summary Créer une activité
         * @param {ActivitsApiApiActivitesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivitesPost(requestParameters: ActivitsApiApiActivitesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ActionsEtActivitSJsonldActiviteCreated> {
            return localVarFp.apiActivitesPost(requestParameters.actionsEtActivitSJsonldActiviteCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des activités
         * @summary Obtenir la liste des activités
         * @param {ActivitsApiApiDossiersDossierActivitesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierActivitesGetCollection(requestParameters: ActivitsApiApiDossiersDossierActivitesGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersDossierActionsGetCollection200Response> {
            return localVarFp.apiDossiersDossierActivitesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiActivitesIdGet operation in ActivitsApi.
 * @export
 * @interface ActivitsApiApiActivitesIdGetRequest
 */
export interface ActivitsApiApiActivitesIdGetRequest {
    /**
     * TblAction identifier
     * @type {string}
     * @memberof ActivitsApiApiActivitesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiActivitesIdPut operation in ActivitsApi.
 * @export
 * @interface ActivitsApiApiActivitesIdPutRequest
 */
export interface ActivitsApiApiActivitesIdPutRequest {
    /**
     * TblAction identifier
     * @type {string}
     * @memberof ActivitsApiApiActivitesIdPut
     */
    readonly id: string

    /**
     * The updated Actions et activités resource
     * @type {ActionsEtActivitSJsonldActiviteUpdate}
     * @memberof ActivitsApiApiActivitesIdPut
     */
    readonly actionsEtActivitSJsonldActiviteUpdate: ActionsEtActivitSJsonldActiviteUpdate
}

/**
 * Request parameters for apiActivitesPost operation in ActivitsApi.
 * @export
 * @interface ActivitsApiApiActivitesPostRequest
 */
export interface ActivitsApiApiActivitesPostRequest {
    /**
     * The new Actions et activités resource
     * @type {ActionsEtActivitSJsonldActiviteCreate}
     * @memberof ActivitsApiApiActivitesPost
     */
    readonly actionsEtActivitSJsonldActiviteCreate: ActionsEtActivitSJsonldActiviteCreate
}

/**
 * Request parameters for apiDossiersDossierActivitesGetCollection operation in ActivitsApi.
 * @export
 * @interface ActivitsApiApiDossiersDossierActivitesGetCollectionRequest
 */
export interface ActivitsApiApiDossiersDossierActivitesGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof ActivitsApiApiDossiersDossierActivitesGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof ActivitsApiApiDossiersDossierActivitesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ActivitsApiApiDossiersDossierActivitesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * ActivitsApi - object-oriented interface
 * @export
 * @class ActivitsApi
 * @extends {BaseAPI}
 */
export class ActivitsApi extends BaseAPI {
    /**
     * Récupérer les informations d\'une activité
     * @summary Récupérer les informations d\'une action
     * @param {ActivitsApiApiActivitesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitsApi
     */
    public apiActivitesIdGet(requestParameters: ActivitsApiApiActivitesIdGetRequest, options?: RawAxiosRequestConfig) {
        return ActivitsApiFp(this.configuration).apiActivitesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Actions et activités resource.
     * @summary Modifier une activité
     * @param {ActivitsApiApiActivitesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitsApi
     */
    public apiActivitesIdPut(requestParameters: ActivitsApiApiActivitesIdPutRequest, options?: RawAxiosRequestConfig) {
        return ActivitsApiFp(this.configuration).apiActivitesIdPut(requestParameters.id, requestParameters.actionsEtActivitSJsonldActiviteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Actions et activités resource.
     * @summary Créer une activité
     * @param {ActivitsApiApiActivitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitsApi
     */
    public apiActivitesPost(requestParameters: ActivitsApiApiActivitesPostRequest, options?: RawAxiosRequestConfig) {
        return ActivitsApiFp(this.configuration).apiActivitesPost(requestParameters.actionsEtActivitSJsonldActiviteCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des activités
     * @summary Obtenir la liste des activités
     * @param {ActivitsApiApiDossiersDossierActivitesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitsApi
     */
    public apiDossiersDossierActivitesGetCollection(requestParameters: ActivitsApiApiDossiersDossierActivitesGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return ActivitsApiFp(this.configuration).apiDossiersDossierActivitesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AnnoncesLgalesApi - axios parameter creator
 * @export
 */
export const AnnoncesLgalesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des relation dossier / annonce légale
         * @summary Obtenir la liste des relation dossier / annonce légale
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierRelationAnnoncesGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierRelationAnnoncesGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/relation-annonces`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une relation dossier / annonce légale
         * @summary Supprimer une relation dossier / annonce légale
         * @param {string} id FproRelationFdosAldos identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRelationAnnoncesIdDelete', 'id', id)
            const localVarPath = `/formalite/relation-annonces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une relation dossier / annonce légale
         * @summary Récupérer les informations d\'une relation dossier / annonce légale
         * @param {string} id FproRelationFdosAldos identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRelationAnnoncesIdGet', 'id', id)
            const localVarPath = `/formalite/relation-annonces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Annonces légales resource.
         * @summary Modifier une relation dossier / annonce légale
         * @param {string} id FproRelationFdosAldos identifier
         * @param {AnnoncesLGalesJsonldRelationalUpdate} annoncesLGalesJsonldRelationalUpdate The updated Annonces légales resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesIdPut: async (id: string, annoncesLGalesJsonldRelationalUpdate: AnnoncesLGalesJsonldRelationalUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRelationAnnoncesIdPut', 'id', id)
            // verify required parameter 'annoncesLGalesJsonldRelationalUpdate' is not null or undefined
            assertParamExists('apiRelationAnnoncesIdPut', 'annoncesLGalesJsonldRelationalUpdate', annoncesLGalesJsonldRelationalUpdate)
            const localVarPath = `/formalite/relation-annonces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annoncesLGalesJsonldRelationalUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Annonces légales resource.
         * @summary Créer une relation dossier / annonce légale
         * @param {AnnoncesLGalesJsonldRelationalCreate} annoncesLGalesJsonldRelationalCreate The new Annonces légales resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesPost: async (annoncesLGalesJsonldRelationalCreate: AnnoncesLGalesJsonldRelationalCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annoncesLGalesJsonldRelationalCreate' is not null or undefined
            assertParamExists('apiRelationAnnoncesPost', 'annoncesLGalesJsonldRelationalCreate', annoncesLGalesJsonldRelationalCreate)
            const localVarPath = `/formalite/relation-annonces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annoncesLGalesJsonldRelationalCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnoncesLgalesApi - functional programming interface
 * @export
 */
export const AnnoncesLgalesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnoncesLgalesApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des relation dossier / annonce légale
         * @summary Obtenir la liste des relation dossier / annonce légale
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierRelationAnnoncesGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierRelationAnnoncesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierRelationAnnoncesGetCollection(dossier, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnoncesLgalesApi.apiDossiersDossierRelationAnnoncesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer une relation dossier / annonce légale
         * @summary Supprimer une relation dossier / annonce légale
         * @param {string} id FproRelationFdosAldos identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRelationAnnoncesIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRelationAnnoncesIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnoncesLgalesApi.apiRelationAnnoncesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une relation dossier / annonce légale
         * @summary Récupérer les informations d\'une relation dossier / annonce légale
         * @param {string} id FproRelationFdosAldos identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRelationAnnoncesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnoncesLGalesJsonldRelationalRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRelationAnnoncesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnoncesLgalesApi.apiRelationAnnoncesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Annonces légales resource.
         * @summary Modifier une relation dossier / annonce légale
         * @param {string} id FproRelationFdosAldos identifier
         * @param {AnnoncesLGalesJsonldRelationalUpdate} annoncesLGalesJsonldRelationalUpdate The updated Annonces légales resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRelationAnnoncesIdPut(id: string, annoncesLGalesJsonldRelationalUpdate: AnnoncesLGalesJsonldRelationalUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnoncesLGalesJsonldRelationalRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRelationAnnoncesIdPut(id, annoncesLGalesJsonldRelationalUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnoncesLgalesApi.apiRelationAnnoncesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Annonces légales resource.
         * @summary Créer une relation dossier / annonce légale
         * @param {AnnoncesLGalesJsonldRelationalCreate} annoncesLGalesJsonldRelationalCreate The new Annonces légales resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRelationAnnoncesPost(annoncesLGalesJsonldRelationalCreate: AnnoncesLGalesJsonldRelationalCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnoncesLGalesJsonldRelationalCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRelationAnnoncesPost(annoncesLGalesJsonldRelationalCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnoncesLgalesApi.apiRelationAnnoncesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AnnoncesLgalesApi - factory interface
 * @export
 */
export const AnnoncesLgalesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnoncesLgalesApiFp(configuration)
    return {
        /**
         * Obtenir la liste des relation dossier / annonce légale
         * @summary Obtenir la liste des relation dossier / annonce légale
         * @param {AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierRelationAnnoncesGetCollection(requestParameters: AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersDossierRelationAnnoncesGetCollection200Response> {
            return localVarFp.apiDossiersDossierRelationAnnoncesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une relation dossier / annonce légale
         * @summary Supprimer une relation dossier / annonce légale
         * @param {AnnoncesLgalesApiApiRelationAnnoncesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesIdDelete(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiRelationAnnoncesIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une relation dossier / annonce légale
         * @summary Récupérer les informations d\'une relation dossier / annonce légale
         * @param {AnnoncesLgalesApiApiRelationAnnoncesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesIdGet(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AnnoncesLGalesJsonldRelationalRead> {
            return localVarFp.apiRelationAnnoncesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Annonces légales resource.
         * @summary Modifier une relation dossier / annonce légale
         * @param {AnnoncesLgalesApiApiRelationAnnoncesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesIdPut(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AnnoncesLGalesJsonldRelationalRead> {
            return localVarFp.apiRelationAnnoncesIdPut(requestParameters.id, requestParameters.annoncesLGalesJsonldRelationalUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Annonces légales resource.
         * @summary Créer une relation dossier / annonce légale
         * @param {AnnoncesLgalesApiApiRelationAnnoncesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesPost(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AnnoncesLGalesJsonldRelationalCreated> {
            return localVarFp.apiRelationAnnoncesPost(requestParameters.annoncesLGalesJsonldRelationalCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierRelationAnnoncesGetCollection operation in AnnoncesLgalesApi.
 * @export
 * @interface AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollectionRequest
 */
export interface AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiRelationAnnoncesIdDelete operation in AnnoncesLgalesApi.
 * @export
 * @interface AnnoncesLgalesApiApiRelationAnnoncesIdDeleteRequest
 */
export interface AnnoncesLgalesApiApiRelationAnnoncesIdDeleteRequest {
    /**
     * FproRelationFdosAldos identifier
     * @type {string}
     * @memberof AnnoncesLgalesApiApiRelationAnnoncesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiRelationAnnoncesIdGet operation in AnnoncesLgalesApi.
 * @export
 * @interface AnnoncesLgalesApiApiRelationAnnoncesIdGetRequest
 */
export interface AnnoncesLgalesApiApiRelationAnnoncesIdGetRequest {
    /**
     * FproRelationFdosAldos identifier
     * @type {string}
     * @memberof AnnoncesLgalesApiApiRelationAnnoncesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiRelationAnnoncesIdPut operation in AnnoncesLgalesApi.
 * @export
 * @interface AnnoncesLgalesApiApiRelationAnnoncesIdPutRequest
 */
export interface AnnoncesLgalesApiApiRelationAnnoncesIdPutRequest {
    /**
     * FproRelationFdosAldos identifier
     * @type {string}
     * @memberof AnnoncesLgalesApiApiRelationAnnoncesIdPut
     */
    readonly id: string

    /**
     * The updated Annonces légales resource
     * @type {AnnoncesLGalesJsonldRelationalUpdate}
     * @memberof AnnoncesLgalesApiApiRelationAnnoncesIdPut
     */
    readonly annoncesLGalesJsonldRelationalUpdate: AnnoncesLGalesJsonldRelationalUpdate
}

/**
 * Request parameters for apiRelationAnnoncesPost operation in AnnoncesLgalesApi.
 * @export
 * @interface AnnoncesLgalesApiApiRelationAnnoncesPostRequest
 */
export interface AnnoncesLgalesApiApiRelationAnnoncesPostRequest {
    /**
     * The new Annonces légales resource
     * @type {AnnoncesLGalesJsonldRelationalCreate}
     * @memberof AnnoncesLgalesApiApiRelationAnnoncesPost
     */
    readonly annoncesLGalesJsonldRelationalCreate: AnnoncesLGalesJsonldRelationalCreate
}

/**
 * AnnoncesLgalesApi - object-oriented interface
 * @export
 * @class AnnoncesLgalesApi
 * @extends {BaseAPI}
 */
export class AnnoncesLgalesApi extends BaseAPI {
    /**
     * Obtenir la liste des relation dossier / annonce légale
     * @summary Obtenir la liste des relation dossier / annonce légale
     * @param {AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnoncesLgalesApi
     */
    public apiDossiersDossierRelationAnnoncesGetCollection(requestParameters: AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return AnnoncesLgalesApiFp(this.configuration).apiDossiersDossierRelationAnnoncesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une relation dossier / annonce légale
     * @summary Supprimer une relation dossier / annonce légale
     * @param {AnnoncesLgalesApiApiRelationAnnoncesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnoncesLgalesApi
     */
    public apiRelationAnnoncesIdDelete(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AnnoncesLgalesApiFp(this.configuration).apiRelationAnnoncesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une relation dossier / annonce légale
     * @summary Récupérer les informations d\'une relation dossier / annonce légale
     * @param {AnnoncesLgalesApiApiRelationAnnoncesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnoncesLgalesApi
     */
    public apiRelationAnnoncesIdGet(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesIdGetRequest, options?: RawAxiosRequestConfig) {
        return AnnoncesLgalesApiFp(this.configuration).apiRelationAnnoncesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Annonces légales resource.
     * @summary Modifier une relation dossier / annonce légale
     * @param {AnnoncesLgalesApiApiRelationAnnoncesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnoncesLgalesApi
     */
    public apiRelationAnnoncesIdPut(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesIdPutRequest, options?: RawAxiosRequestConfig) {
        return AnnoncesLgalesApiFp(this.configuration).apiRelationAnnoncesIdPut(requestParameters.id, requestParameters.annoncesLGalesJsonldRelationalUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Annonces légales resource.
     * @summary Créer une relation dossier / annonce légale
     * @param {AnnoncesLgalesApiApiRelationAnnoncesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnoncesLgalesApi
     */
    public apiRelationAnnoncesPost(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesPostRequest, options?: RawAxiosRequestConfig) {
        return AnnoncesLgalesApiFp(this.configuration).apiRelationAnnoncesPost(requestParameters.annoncesLGalesJsonldRelationalCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ArticlesDeFacturationApi - axios parameter creator
 * @export
 */
export const ArticlesDeFacturationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'un article
         * @summary Récupérer les informations d\'un article
         * @param {string} id TblFactArticles identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesFacturationIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiArticlesFacturationIdGet', 'id', id)
            const localVarPath = `/formalite/articles-facturation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticlesDeFacturationApi - functional programming interface
 * @export
 */
export const ArticlesDeFacturationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticlesDeFacturationApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'un article
         * @summary Récupérer les informations d\'un article
         * @param {string} id TblFactArticles identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiArticlesFacturationIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesDeFacturationJsonldFactArticleRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticlesFacturationIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticlesDeFacturationApi.apiArticlesFacturationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ArticlesDeFacturationApi - factory interface
 * @export
 */
export const ArticlesDeFacturationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticlesDeFacturationApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'un article
         * @summary Récupérer les informations d\'un article
         * @param {ArticlesDeFacturationApiApiArticlesFacturationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesFacturationIdGet(requestParameters: ArticlesDeFacturationApiApiArticlesFacturationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ArticlesDeFacturationJsonldFactArticleRead> {
            return localVarFp.apiArticlesFacturationIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiArticlesFacturationIdGet operation in ArticlesDeFacturationApi.
 * @export
 * @interface ArticlesDeFacturationApiApiArticlesFacturationIdGetRequest
 */
export interface ArticlesDeFacturationApiApiArticlesFacturationIdGetRequest {
    /**
     * TblFactArticles identifier
     * @type {string}
     * @memberof ArticlesDeFacturationApiApiArticlesFacturationIdGet
     */
    readonly id: string
}

/**
 * ArticlesDeFacturationApi - object-oriented interface
 * @export
 * @class ArticlesDeFacturationApi
 * @extends {BaseAPI}
 */
export class ArticlesDeFacturationApi extends BaseAPI {
    /**
     * Récupérer les informations d\'un article
     * @summary Récupérer les informations d\'un article
     * @param {ArticlesDeFacturationApiApiArticlesFacturationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesDeFacturationApi
     */
    public apiArticlesFacturationIdGet(requestParameters: ArticlesDeFacturationApiApiArticlesFacturationIdGetRequest, options?: RawAxiosRequestConfig) {
        return ArticlesDeFacturationApiFp(this.configuration).apiArticlesFacturationIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CallbackDematFacileApi - axios parameter creator
 * @export
 */
export const CallbackDematFacileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Callback DematFacile
         * @summary Callback DematFacile
         * @param {CallbackDematFacileJsonld} callbackDematFacileJsonld The new Callback DematFacile resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDematFacileCallbackPost: async (callbackDematFacileJsonld: CallbackDematFacileJsonld, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callbackDematFacileJsonld' is not null or undefined
            assertParamExists('apiDematFacileCallbackPost', 'callbackDematFacileJsonld', callbackDematFacileJsonld)
            const localVarPath = `/formalite/demat-facile/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(callbackDematFacileJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CallbackDematFacileApi - functional programming interface
 * @export
 */
export const CallbackDematFacileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CallbackDematFacileApiAxiosParamCreator(configuration)
    return {
        /**
         * Callback DematFacile
         * @summary Callback DematFacile
         * @param {CallbackDematFacileJsonld} callbackDematFacileJsonld The new Callback DematFacile resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDematFacileCallbackPost(callbackDematFacileJsonld: CallbackDematFacileJsonld, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallbackDematFacileJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDematFacileCallbackPost(callbackDematFacileJsonld, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CallbackDematFacileApi.apiDematFacileCallbackPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CallbackDematFacileApi - factory interface
 * @export
 */
export const CallbackDematFacileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CallbackDematFacileApiFp(configuration)
    return {
        /**
         * Callback DematFacile
         * @summary Callback DematFacile
         * @param {CallbackDematFacileApiApiDematFacileCallbackPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDematFacileCallbackPost(requestParameters: CallbackDematFacileApiApiDematFacileCallbackPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<CallbackDematFacileJsonld> {
            return localVarFp.apiDematFacileCallbackPost(requestParameters.callbackDematFacileJsonld, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDematFacileCallbackPost operation in CallbackDematFacileApi.
 * @export
 * @interface CallbackDematFacileApiApiDematFacileCallbackPostRequest
 */
export interface CallbackDematFacileApiApiDematFacileCallbackPostRequest {
    /**
     * The new Callback DematFacile resource
     * @type {CallbackDematFacileJsonld}
     * @memberof CallbackDematFacileApiApiDematFacileCallbackPost
     */
    readonly callbackDematFacileJsonld: CallbackDematFacileJsonld
}

/**
 * CallbackDematFacileApi - object-oriented interface
 * @export
 * @class CallbackDematFacileApi
 * @extends {BaseAPI}
 */
export class CallbackDematFacileApi extends BaseAPI {
    /**
     * Callback DematFacile
     * @summary Callback DematFacile
     * @param {CallbackDematFacileApiApiDematFacileCallbackPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallbackDematFacileApi
     */
    public apiDematFacileCallbackPost(requestParameters: CallbackDematFacileApiApiDematFacileCallbackPostRequest, options?: RawAxiosRequestConfig) {
        return CallbackDematFacileApiFp(this.configuration).apiDematFacileCallbackPost(requestParameters.callbackDematFacileJsonld, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CataloguesApi - axios parameter creator
 * @export
 */
export const CataloguesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * hidden
         * @summary Retrieves a Catalogues resource.
         * @param {string} id TblCatalogue identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdFormatGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCataloguesIdFormatGet', 'id', id)
            const localVarPath = `/formalite/catalogues/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CataloguesApi - functional programming interface
 * @export
 */
export const CataloguesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CataloguesApiAxiosParamCreator(configuration)
    return {
        /**
         * hidden
         * @summary Retrieves a Catalogues resource.
         * @param {string} id TblCatalogue identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCataloguesIdFormatGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCataloguesIdFormatGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CataloguesApi.apiCataloguesIdFormatGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CataloguesApi - factory interface
 * @export
 */
export const CataloguesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CataloguesApiFp(configuration)
    return {
        /**
         * hidden
         * @summary Retrieves a Catalogues resource.
         * @param {CataloguesApiApiCataloguesIdFormatGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdFormatGet(requestParameters: CataloguesApiApiCataloguesIdFormatGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.apiCataloguesIdFormatGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCataloguesIdFormatGet operation in CataloguesApi.
 * @export
 * @interface CataloguesApiApiCataloguesIdFormatGetRequest
 */
export interface CataloguesApiApiCataloguesIdFormatGetRequest {
    /**
     * TblCatalogue identifier
     * @type {string}
     * @memberof CataloguesApiApiCataloguesIdFormatGet
     */
    readonly id: string
}

/**
 * CataloguesApi - object-oriented interface
 * @export
 * @class CataloguesApi
 * @extends {BaseAPI}
 */
export class CataloguesApi extends BaseAPI {
    /**
     * hidden
     * @summary Retrieves a Catalogues resource.
     * @param {CataloguesApiApiCataloguesIdFormatGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CataloguesApi
     */
    public apiCataloguesIdFormatGet(requestParameters: CataloguesApiApiCataloguesIdFormatGetRequest, options?: RawAxiosRequestConfig) {
        return CataloguesApiFp(this.configuration).apiCataloguesIdFormatGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CategorieApi - axios parameter creator
 * @export
 */
export const CategorieApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des categories
         * @summary Obtenir la liste des categories
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesGetCollection: async (page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une categorie
         * @summary Récupérer les informations d\'une categorie
         * @param {string} id FproFormaliteCategorie identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoriesIdGet', 'id', id)
            const localVarPath = `/formalite/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Categorie resource.
         * @summary Modifier une catégorie
         * @param {string} id FproFormaliteCategorie identifier
         * @param {CategorieJsonldCategorieUpdate} categorieJsonldCategorieUpdate The updated Categorie resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesIdPut: async (id: string, categorieJsonldCategorieUpdate: CategorieJsonldCategorieUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoriesIdPut', 'id', id)
            // verify required parameter 'categorieJsonldCategorieUpdate' is not null or undefined
            assertParamExists('apiCategoriesIdPut', 'categorieJsonldCategorieUpdate', categorieJsonldCategorieUpdate)
            const localVarPath = `/formalite/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categorieJsonldCategorieUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Categorie resource.
         * @summary Créer une categorie
         * @param {CategorieJsonldCategorieCreate} categorieJsonldCategorieCreate The new Categorie resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesPost: async (categorieJsonldCategorieCreate: CategorieJsonldCategorieCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categorieJsonldCategorieCreate' is not null or undefined
            assertParamExists('apiCategoriesPost', 'categorieJsonldCategorieCreate', categorieJsonldCategorieCreate)
            const localVarPath = `/formalite/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categorieJsonldCategorieCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategorieApi - functional programming interface
 * @export
 */
export const CategorieApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategorieApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des categories
         * @summary Obtenir la liste des categories
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesGetCollection(page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCategoriesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesGetCollection(page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategorieApi.apiCategoriesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une categorie
         * @summary Récupérer les informations d\'une categorie
         * @param {string} id FproFormaliteCategorie identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategorieJsonldCategorieRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategorieApi.apiCategoriesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Categorie resource.
         * @summary Modifier une catégorie
         * @param {string} id FproFormaliteCategorie identifier
         * @param {CategorieJsonldCategorieUpdate} categorieJsonldCategorieUpdate The updated Categorie resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesIdPut(id: string, categorieJsonldCategorieUpdate: CategorieJsonldCategorieUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategorieJsonldCategorieRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesIdPut(id, categorieJsonldCategorieUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategorieApi.apiCategoriesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Categorie resource.
         * @summary Créer une categorie
         * @param {CategorieJsonldCategorieCreate} categorieJsonldCategorieCreate The new Categorie resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesPost(categorieJsonldCategorieCreate: CategorieJsonldCategorieCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategorieJsonldCategorieCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesPost(categorieJsonldCategorieCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategorieApi.apiCategoriesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CategorieApi - factory interface
 * @export
 */
export const CategorieApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategorieApiFp(configuration)
    return {
        /**
         * Obtenir la liste des categories
         * @summary Obtenir la liste des categories
         * @param {CategorieApiApiCategoriesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesGetCollection(requestParameters: CategorieApiApiCategoriesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiCategoriesGetCollection200Response> {
            return localVarFp.apiCategoriesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une categorie
         * @summary Récupérer les informations d\'une categorie
         * @param {CategorieApiApiCategoriesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesIdGet(requestParameters: CategorieApiApiCategoriesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CategorieJsonldCategorieRead> {
            return localVarFp.apiCategoriesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Categorie resource.
         * @summary Modifier une catégorie
         * @param {CategorieApiApiCategoriesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesIdPut(requestParameters: CategorieApiApiCategoriesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<CategorieJsonldCategorieRead> {
            return localVarFp.apiCategoriesIdPut(requestParameters.id, requestParameters.categorieJsonldCategorieUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Categorie resource.
         * @summary Créer une categorie
         * @param {CategorieApiApiCategoriesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesPost(requestParameters: CategorieApiApiCategoriesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<CategorieJsonldCategorieCreated> {
            return localVarFp.apiCategoriesPost(requestParameters.categorieJsonldCategorieCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCategoriesGetCollection operation in CategorieApi.
 * @export
 * @interface CategorieApiApiCategoriesGetCollectionRequest
 */
export interface CategorieApiApiCategoriesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof CategorieApiApiCategoriesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CategorieApiApiCategoriesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiCategoriesIdGet operation in CategorieApi.
 * @export
 * @interface CategorieApiApiCategoriesIdGetRequest
 */
export interface CategorieApiApiCategoriesIdGetRequest {
    /**
     * FproFormaliteCategorie identifier
     * @type {string}
     * @memberof CategorieApiApiCategoriesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiCategoriesIdPut operation in CategorieApi.
 * @export
 * @interface CategorieApiApiCategoriesIdPutRequest
 */
export interface CategorieApiApiCategoriesIdPutRequest {
    /**
     * FproFormaliteCategorie identifier
     * @type {string}
     * @memberof CategorieApiApiCategoriesIdPut
     */
    readonly id: string

    /**
     * The updated Categorie resource
     * @type {CategorieJsonldCategorieUpdate}
     * @memberof CategorieApiApiCategoriesIdPut
     */
    readonly categorieJsonldCategorieUpdate: CategorieJsonldCategorieUpdate
}

/**
 * Request parameters for apiCategoriesPost operation in CategorieApi.
 * @export
 * @interface CategorieApiApiCategoriesPostRequest
 */
export interface CategorieApiApiCategoriesPostRequest {
    /**
     * The new Categorie resource
     * @type {CategorieJsonldCategorieCreate}
     * @memberof CategorieApiApiCategoriesPost
     */
    readonly categorieJsonldCategorieCreate: CategorieJsonldCategorieCreate
}

/**
 * CategorieApi - object-oriented interface
 * @export
 * @class CategorieApi
 * @extends {BaseAPI}
 */
export class CategorieApi extends BaseAPI {
    /**
     * Obtenir la liste des categories
     * @summary Obtenir la liste des categories
     * @param {CategorieApiApiCategoriesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorieApi
     */
    public apiCategoriesGetCollection(requestParameters: CategorieApiApiCategoriesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return CategorieApiFp(this.configuration).apiCategoriesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une categorie
     * @summary Récupérer les informations d\'une categorie
     * @param {CategorieApiApiCategoriesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorieApi
     */
    public apiCategoriesIdGet(requestParameters: CategorieApiApiCategoriesIdGetRequest, options?: RawAxiosRequestConfig) {
        return CategorieApiFp(this.configuration).apiCategoriesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Categorie resource.
     * @summary Modifier une catégorie
     * @param {CategorieApiApiCategoriesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorieApi
     */
    public apiCategoriesIdPut(requestParameters: CategorieApiApiCategoriesIdPutRequest, options?: RawAxiosRequestConfig) {
        return CategorieApiFp(this.configuration).apiCategoriesIdPut(requestParameters.id, requestParameters.categorieJsonldCategorieUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Categorie resource.
     * @summary Créer une categorie
     * @param {CategorieApiApiCategoriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorieApi
     */
    public apiCategoriesPost(requestParameters: CategorieApiApiCategoriesPostRequest, options?: RawAxiosRequestConfig) {
        return CategorieApiFp(this.configuration).apiCategoriesPost(requestParameters.categorieJsonldCategorieCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CollectionDeDossierApi - axios parameter creator
 * @export
 */
export const CollectionDeDossierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les collections de dossiers
         * @summary Lister les collections de dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [numero] 
         * @param {string} [libelle] 
         * @param {string} [dossiersNumero] 
         * @param {number} [prescripteurId] 
         * @param {Array<number>} [prescripteurId2] 
         * @param {number} [clientId] 
         * @param {Array<number>} [clientId2] 
         * @param {number} [societeId] 
         * @param {Array<number>} [societeId2] 
         * @param {number} [statutId] 
         * @param {Array<number>} [statutId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersGetCollection: async (page?: number, itemsPerPage?: number, numero?: string, libelle?: string, dossiersNumero?: string, prescripteurId?: number, prescripteurId2?: Array<number>, clientId?: number, clientId2?: Array<number>, societeId?: number, societeId2?: Array<number>, statutId?: number, statutId2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/collections-dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (numero !== undefined) {
                localVarQueryParameter['numero'] = numero;
            }

            if (libelle !== undefined) {
                localVarQueryParameter['libelle'] = libelle;
            }

            if (dossiersNumero !== undefined) {
                localVarQueryParameter['dossiers.numero'] = dossiersNumero;
            }

            if (prescripteurId !== undefined) {
                localVarQueryParameter['prescripteur.id'] = prescripteurId;
            }

            if (prescripteurId2) {
                localVarQueryParameter['prescripteur.id[]'] = prescripteurId2;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client.id'] = clientId;
            }

            if (clientId2) {
                localVarQueryParameter['client.id[]'] = clientId2;
            }

            if (societeId !== undefined) {
                localVarQueryParameter['societe.id'] = societeId;
            }

            if (societeId2) {
                localVarQueryParameter['societe.id[]'] = societeId2;
            }

            if (statutId !== undefined) {
                localVarQueryParameter['statut.id'] = statutId;
            }

            if (statutId2) {
                localVarQueryParameter['statut.id[]'] = statutId2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Recalculer les articles des dossiers de la collection suivant les règles liées
         * @summary Recalculer les articles des dossiers de la collection
         * @param {string} id TblCollectionDossier identifier
         * @param {CollectionDeDossierJsonld} collectionDeDossierJsonld The updated Collection de dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdCalculatePut: async (id: string, collectionDeDossierJsonld: CollectionDeDossierJsonld, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdCalculatePut', 'id', id)
            // verify required parameter 'collectionDeDossierJsonld' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdCalculatePut', 'collectionDeDossierJsonld', collectionDeDossierJsonld)
            const localVarPath = `/formalite/collections-dossiers/{id}/calculate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(collectionDeDossierJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une collection de dossier
         * @summary Supprimer une collection de dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdDelete', 'id', id)
            const localVarPath = `/formalite/collections-dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une collection de dossier
         * @summary Récupérer les informations d\'une collection de dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdGet', 'id', id)
            const localVarPath = `/formalite/collections-dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier en partie les informations d\'une collection dossier
         * @summary Modifier en partie les informations d\'une collection dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {CollectionDeDossierCollectiondossierUpdate} collectionDeDossierCollectiondossierUpdate The updated Collection de dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdPatch: async (id: string, collectionDeDossierCollectiondossierUpdate: CollectionDeDossierCollectiondossierUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdPatch', 'id', id)
            // verify required parameter 'collectionDeDossierCollectiondossierUpdate' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdPatch', 'collectionDeDossierCollectiondossierUpdate', collectionDeDossierCollectiondossierUpdate)
            const localVarPath = `/formalite/collections-dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(collectionDeDossierCollectiondossierUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier les informations d\'une collection dossier
         * @summary Modifier les informations d\'une collection dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {CollectionDeDossierJsonldCollectiondossierUpdate} collectionDeDossierJsonldCollectiondossierUpdate The updated Collection de dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdPut: async (id: string, collectionDeDossierJsonldCollectiondossierUpdate: CollectionDeDossierJsonldCollectiondossierUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdPut', 'id', id)
            // verify required parameter 'collectionDeDossierJsonldCollectiondossierUpdate' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdPut', 'collectionDeDossierJsonldCollectiondossierUpdate', collectionDeDossierJsonldCollectiondossierUpdate)
            const localVarPath = `/formalite/collections-dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(collectionDeDossierJsonldCollectiondossierUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Valider une collection de dossiers
         * @summary Valider une collection de dossiers
         * @param {string} id TblCollectionDossier identifier
         * @param {CollectionDeDossierJsonldCollectiondossierUpdate} collectionDeDossierJsonldCollectiondossierUpdate The updated Collection de dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdValidatePut: async (id: string, collectionDeDossierJsonldCollectiondossierUpdate: CollectionDeDossierJsonldCollectiondossierUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdValidatePut', 'id', id)
            // verify required parameter 'collectionDeDossierJsonldCollectiondossierUpdate' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdValidatePut', 'collectionDeDossierJsonldCollectiondossierUpdate', collectionDeDossierJsonldCollectiondossierUpdate)
            const localVarPath = `/formalite/collections-dossiers/{id}/validate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(collectionDeDossierJsonldCollectiondossierUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer une collection de dossier
         * @summary Créer une collection de dossier
         * @param {CollectionDeDossierJsonldCollectiondossierCreate} collectionDeDossierJsonldCollectiondossierCreate The new Collection de dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersPost: async (collectionDeDossierJsonldCollectiondossierCreate: CollectionDeDossierJsonldCollectiondossierCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionDeDossierJsonldCollectiondossierCreate' is not null or undefined
            assertParamExists('apiCollectionsDossiersPost', 'collectionDeDossierJsonldCollectiondossierCreate', collectionDeDossierJsonldCollectiondossierCreate)
            const localVarPath = `/formalite/collections-dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(collectionDeDossierJsonldCollectiondossierCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollectionDeDossierApi - functional programming interface
 * @export
 */
export const CollectionDeDossierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CollectionDeDossierApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les collections de dossiers
         * @summary Lister les collections de dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [numero] 
         * @param {string} [libelle] 
         * @param {string} [dossiersNumero] 
         * @param {number} [prescripteurId] 
         * @param {Array<number>} [prescripteurId2] 
         * @param {number} [clientId] 
         * @param {Array<number>} [clientId2] 
         * @param {number} [societeId] 
         * @param {Array<number>} [societeId2] 
         * @param {number} [statutId] 
         * @param {Array<number>} [statutId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCollectionsDossiersGetCollection(page?: number, itemsPerPage?: number, numero?: string, libelle?: string, dossiersNumero?: string, prescripteurId?: number, prescripteurId2?: Array<number>, clientId?: number, clientId2?: Array<number>, societeId?: number, societeId2?: Array<number>, statutId?: number, statutId2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCollectionsDossiersGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCollectionsDossiersGetCollection(page, itemsPerPage, numero, libelle, dossiersNumero, prescripteurId, prescripteurId2, clientId, clientId2, societeId, societeId2, statutId, statutId2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CollectionDeDossierApi.apiCollectionsDossiersGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Recalculer les articles des dossiers de la collection suivant les règles liées
         * @summary Recalculer les articles des dossiers de la collection
         * @param {string} id TblCollectionDossier identifier
         * @param {CollectionDeDossierJsonld} collectionDeDossierJsonld The updated Collection de dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCollectionsDossiersIdCalculatePut(id: string, collectionDeDossierJsonld: CollectionDeDossierJsonld, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionDeDossierJsonldCollectiondossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCollectionsDossiersIdCalculatePut(id, collectionDeDossierJsonld, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CollectionDeDossierApi.apiCollectionsDossiersIdCalculatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer une collection de dossier
         * @summary Supprimer une collection de dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCollectionsDossiersIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCollectionsDossiersIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CollectionDeDossierApi.apiCollectionsDossiersIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une collection de dossier
         * @summary Récupérer les informations d\'une collection de dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCollectionsDossiersIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionDeDossierJsonldCollectiondossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCollectionsDossiersIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CollectionDeDossierApi.apiCollectionsDossiersIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modifier en partie les informations d\'une collection dossier
         * @summary Modifier en partie les informations d\'une collection dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {CollectionDeDossierCollectiondossierUpdate} collectionDeDossierCollectiondossierUpdate The updated Collection de dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCollectionsDossiersIdPatch(id: string, collectionDeDossierCollectiondossierUpdate: CollectionDeDossierCollectiondossierUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionDeDossierJsonldCollectiondossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCollectionsDossiersIdPatch(id, collectionDeDossierCollectiondossierUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CollectionDeDossierApi.apiCollectionsDossiersIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modifier les informations d\'une collection dossier
         * @summary Modifier les informations d\'une collection dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {CollectionDeDossierJsonldCollectiondossierUpdate} collectionDeDossierJsonldCollectiondossierUpdate The updated Collection de dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCollectionsDossiersIdPut(id: string, collectionDeDossierJsonldCollectiondossierUpdate: CollectionDeDossierJsonldCollectiondossierUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionDeDossierJsonldCollectiondossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCollectionsDossiersIdPut(id, collectionDeDossierJsonldCollectiondossierUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CollectionDeDossierApi.apiCollectionsDossiersIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Valider une collection de dossiers
         * @summary Valider une collection de dossiers
         * @param {string} id TblCollectionDossier identifier
         * @param {CollectionDeDossierJsonldCollectiondossierUpdate} collectionDeDossierJsonldCollectiondossierUpdate The updated Collection de dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCollectionsDossiersIdValidatePut(id: string, collectionDeDossierJsonldCollectiondossierUpdate: CollectionDeDossierJsonldCollectiondossierUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionDeDossierJsonldCollectiondossierValidated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCollectionsDossiersIdValidatePut(id, collectionDeDossierJsonldCollectiondossierUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CollectionDeDossierApi.apiCollectionsDossiersIdValidatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer une collection de dossier
         * @summary Créer une collection de dossier
         * @param {CollectionDeDossierJsonldCollectiondossierCreate} collectionDeDossierJsonldCollectiondossierCreate The new Collection de dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCollectionsDossiersPost(collectionDeDossierJsonldCollectiondossierCreate: CollectionDeDossierJsonldCollectiondossierCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionDeDossierJsonldCollectiondossierCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCollectionsDossiersPost(collectionDeDossierJsonldCollectiondossierCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CollectionDeDossierApi.apiCollectionsDossiersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CollectionDeDossierApi - factory interface
 * @export
 */
export const CollectionDeDossierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CollectionDeDossierApiFp(configuration)
    return {
        /**
         * Lister les collections de dossiers
         * @summary Lister les collections de dossiers
         * @param {CollectionDeDossierApiApiCollectionsDossiersGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersGetCollection(requestParameters: CollectionDeDossierApiApiCollectionsDossiersGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiCollectionsDossiersGetCollection200Response> {
            return localVarFp.apiCollectionsDossiersGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.numero, requestParameters.libelle, requestParameters.dossiersNumero, requestParameters.prescripteurId, requestParameters.prescripteurId2, requestParameters.clientId, requestParameters.clientId2, requestParameters.societeId, requestParameters.societeId2, requestParameters.statutId, requestParameters.statutId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Recalculer les articles des dossiers de la collection suivant les règles liées
         * @summary Recalculer les articles des dossiers de la collection
         * @param {CollectionDeDossierApiApiCollectionsDossiersIdCalculatePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdCalculatePut(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdCalculatePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<CollectionDeDossierJsonldCollectiondossierRead> {
            return localVarFp.apiCollectionsDossiersIdCalculatePut(requestParameters.id, requestParameters.collectionDeDossierJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une collection de dossier
         * @summary Supprimer une collection de dossier
         * @param {CollectionDeDossierApiApiCollectionsDossiersIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdDelete(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiCollectionsDossiersIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une collection de dossier
         * @summary Récupérer les informations d\'une collection de dossier
         * @param {CollectionDeDossierApiApiCollectionsDossiersIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdGet(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CollectionDeDossierJsonldCollectiondossierRead> {
            return localVarFp.apiCollectionsDossiersIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier en partie les informations d\'une collection dossier
         * @summary Modifier en partie les informations d\'une collection dossier
         * @param {CollectionDeDossierApiApiCollectionsDossiersIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdPatch(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<CollectionDeDossierJsonldCollectiondossierRead> {
            return localVarFp.apiCollectionsDossiersIdPatch(requestParameters.id, requestParameters.collectionDeDossierCollectiondossierUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier les informations d\'une collection dossier
         * @summary Modifier les informations d\'une collection dossier
         * @param {CollectionDeDossierApiApiCollectionsDossiersIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdPut(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<CollectionDeDossierJsonldCollectiondossierRead> {
            return localVarFp.apiCollectionsDossiersIdPut(requestParameters.id, requestParameters.collectionDeDossierJsonldCollectiondossierUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Valider une collection de dossiers
         * @summary Valider une collection de dossiers
         * @param {CollectionDeDossierApiApiCollectionsDossiersIdValidatePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdValidatePut(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdValidatePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<CollectionDeDossierJsonldCollectiondossierValidated> {
            return localVarFp.apiCollectionsDossiersIdValidatePut(requestParameters.id, requestParameters.collectionDeDossierJsonldCollectiondossierUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer une collection de dossier
         * @summary Créer une collection de dossier
         * @param {CollectionDeDossierApiApiCollectionsDossiersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersPost(requestParameters: CollectionDeDossierApiApiCollectionsDossiersPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<CollectionDeDossierJsonldCollectiondossierCreated> {
            return localVarFp.apiCollectionsDossiersPost(requestParameters.collectionDeDossierJsonldCollectiondossierCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCollectionsDossiersGetCollection operation in CollectionDeDossierApi.
 * @export
 * @interface CollectionDeDossierApiApiCollectionsDossiersGetCollectionRequest
 */
export interface CollectionDeDossierApiApiCollectionsDossiersGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly numero?: string

    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly libelle?: string

    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly dossiersNumero?: string

    /**
     * 
     * @type {number}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly prescripteurId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly prescripteurId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly clientId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly clientId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly societeId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly societeId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly statutId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly statutId2?: Array<number>
}

/**
 * Request parameters for apiCollectionsDossiersIdCalculatePut operation in CollectionDeDossierApi.
 * @export
 * @interface CollectionDeDossierApiApiCollectionsDossiersIdCalculatePutRequest
 */
export interface CollectionDeDossierApiApiCollectionsDossiersIdCalculatePutRequest {
    /**
     * TblCollectionDossier identifier
     * @type {string}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdCalculatePut
     */
    readonly id: string

    /**
     * The updated Collection de dossier resource
     * @type {CollectionDeDossierJsonld}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdCalculatePut
     */
    readonly collectionDeDossierJsonld: CollectionDeDossierJsonld
}

/**
 * Request parameters for apiCollectionsDossiersIdDelete operation in CollectionDeDossierApi.
 * @export
 * @interface CollectionDeDossierApiApiCollectionsDossiersIdDeleteRequest
 */
export interface CollectionDeDossierApiApiCollectionsDossiersIdDeleteRequest {
    /**
     * TblCollectionDossier identifier
     * @type {string}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiCollectionsDossiersIdGet operation in CollectionDeDossierApi.
 * @export
 * @interface CollectionDeDossierApiApiCollectionsDossiersIdGetRequest
 */
export interface CollectionDeDossierApiApiCollectionsDossiersIdGetRequest {
    /**
     * TblCollectionDossier identifier
     * @type {string}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiCollectionsDossiersIdPatch operation in CollectionDeDossierApi.
 * @export
 * @interface CollectionDeDossierApiApiCollectionsDossiersIdPatchRequest
 */
export interface CollectionDeDossierApiApiCollectionsDossiersIdPatchRequest {
    /**
     * TblCollectionDossier identifier
     * @type {string}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdPatch
     */
    readonly id: string

    /**
     * The updated Collection de dossier resource
     * @type {CollectionDeDossierCollectiondossierUpdate}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdPatch
     */
    readonly collectionDeDossierCollectiondossierUpdate: CollectionDeDossierCollectiondossierUpdate
}

/**
 * Request parameters for apiCollectionsDossiersIdPut operation in CollectionDeDossierApi.
 * @export
 * @interface CollectionDeDossierApiApiCollectionsDossiersIdPutRequest
 */
export interface CollectionDeDossierApiApiCollectionsDossiersIdPutRequest {
    /**
     * TblCollectionDossier identifier
     * @type {string}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdPut
     */
    readonly id: string

    /**
     * The updated Collection de dossier resource
     * @type {CollectionDeDossierJsonldCollectiondossierUpdate}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdPut
     */
    readonly collectionDeDossierJsonldCollectiondossierUpdate: CollectionDeDossierJsonldCollectiondossierUpdate
}

/**
 * Request parameters for apiCollectionsDossiersIdValidatePut operation in CollectionDeDossierApi.
 * @export
 * @interface CollectionDeDossierApiApiCollectionsDossiersIdValidatePutRequest
 */
export interface CollectionDeDossierApiApiCollectionsDossiersIdValidatePutRequest {
    /**
     * TblCollectionDossier identifier
     * @type {string}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdValidatePut
     */
    readonly id: string

    /**
     * The updated Collection de dossier resource
     * @type {CollectionDeDossierJsonldCollectiondossierUpdate}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdValidatePut
     */
    readonly collectionDeDossierJsonldCollectiondossierUpdate: CollectionDeDossierJsonldCollectiondossierUpdate
}

/**
 * Request parameters for apiCollectionsDossiersPost operation in CollectionDeDossierApi.
 * @export
 * @interface CollectionDeDossierApiApiCollectionsDossiersPostRequest
 */
export interface CollectionDeDossierApiApiCollectionsDossiersPostRequest {
    /**
     * The new Collection de dossier resource
     * @type {CollectionDeDossierJsonldCollectiondossierCreate}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersPost
     */
    readonly collectionDeDossierJsonldCollectiondossierCreate: CollectionDeDossierJsonldCollectiondossierCreate
}

/**
 * CollectionDeDossierApi - object-oriented interface
 * @export
 * @class CollectionDeDossierApi
 * @extends {BaseAPI}
 */
export class CollectionDeDossierApi extends BaseAPI {
    /**
     * Lister les collections de dossiers
     * @summary Lister les collections de dossiers
     * @param {CollectionDeDossierApiApiCollectionsDossiersGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionDeDossierApi
     */
    public apiCollectionsDossiersGetCollection(requestParameters: CollectionDeDossierApiApiCollectionsDossiersGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return CollectionDeDossierApiFp(this.configuration).apiCollectionsDossiersGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.numero, requestParameters.libelle, requestParameters.dossiersNumero, requestParameters.prescripteurId, requestParameters.prescripteurId2, requestParameters.clientId, requestParameters.clientId2, requestParameters.societeId, requestParameters.societeId2, requestParameters.statutId, requestParameters.statutId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Recalculer les articles des dossiers de la collection suivant les règles liées
     * @summary Recalculer les articles des dossiers de la collection
     * @param {CollectionDeDossierApiApiCollectionsDossiersIdCalculatePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionDeDossierApi
     */
    public apiCollectionsDossiersIdCalculatePut(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdCalculatePutRequest, options?: RawAxiosRequestConfig) {
        return CollectionDeDossierApiFp(this.configuration).apiCollectionsDossiersIdCalculatePut(requestParameters.id, requestParameters.collectionDeDossierJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une collection de dossier
     * @summary Supprimer une collection de dossier
     * @param {CollectionDeDossierApiApiCollectionsDossiersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionDeDossierApi
     */
    public apiCollectionsDossiersIdDelete(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return CollectionDeDossierApiFp(this.configuration).apiCollectionsDossiersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une collection de dossier
     * @summary Récupérer les informations d\'une collection de dossier
     * @param {CollectionDeDossierApiApiCollectionsDossiersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionDeDossierApi
     */
    public apiCollectionsDossiersIdGet(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdGetRequest, options?: RawAxiosRequestConfig) {
        return CollectionDeDossierApiFp(this.configuration).apiCollectionsDossiersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier en partie les informations d\'une collection dossier
     * @summary Modifier en partie les informations d\'une collection dossier
     * @param {CollectionDeDossierApiApiCollectionsDossiersIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionDeDossierApi
     */
    public apiCollectionsDossiersIdPatch(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdPatchRequest, options?: RawAxiosRequestConfig) {
        return CollectionDeDossierApiFp(this.configuration).apiCollectionsDossiersIdPatch(requestParameters.id, requestParameters.collectionDeDossierCollectiondossierUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier les informations d\'une collection dossier
     * @summary Modifier les informations d\'une collection dossier
     * @param {CollectionDeDossierApiApiCollectionsDossiersIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionDeDossierApi
     */
    public apiCollectionsDossiersIdPut(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdPutRequest, options?: RawAxiosRequestConfig) {
        return CollectionDeDossierApiFp(this.configuration).apiCollectionsDossiersIdPut(requestParameters.id, requestParameters.collectionDeDossierJsonldCollectiondossierUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Valider une collection de dossiers
     * @summary Valider une collection de dossiers
     * @param {CollectionDeDossierApiApiCollectionsDossiersIdValidatePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionDeDossierApi
     */
    public apiCollectionsDossiersIdValidatePut(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdValidatePutRequest, options?: RawAxiosRequestConfig) {
        return CollectionDeDossierApiFp(this.configuration).apiCollectionsDossiersIdValidatePut(requestParameters.id, requestParameters.collectionDeDossierJsonldCollectiondossierUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer une collection de dossier
     * @summary Créer une collection de dossier
     * @param {CollectionDeDossierApiApiCollectionsDossiersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionDeDossierApi
     */
    public apiCollectionsDossiersPost(requestParameters: CollectionDeDossierApiApiCollectionsDossiersPostRequest, options?: RawAxiosRequestConfig) {
        return CollectionDeDossierApiFp(this.configuration).apiCollectionsDossiersPost(requestParameters.collectionDeDossierJsonldCollectiondossierCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DevisApi - axios parameter creator
 * @export
 */
export const DevisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des devis
         * @summary Obtenir la liste des devis
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisGetCollection: async (page?: number, itemsPerPage?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/devis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (dossierNumero !== undefined) {
                localVarQueryParameter['dossier.numero'] = dossierNumero;
            }

            if (dossierId !== undefined) {
                localVarQueryParameter['dossier.id'] = dossierId;
            }

            if (dossierId2) {
                localVarQueryParameter['dossier.id[]'] = dossierId2;
            }

            if (dossierPrescripteurId !== undefined) {
                localVarQueryParameter['dossier.prescripteur.id'] = dossierPrescripteurId;
            }

            if (dossierPrescripteurId2) {
                localVarQueryParameter['dossier.prescripteur.id[]'] = dossierPrescripteurId2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un devis
         * @summary Récupérer les informations d\'un devis
         * @param {string} id TblDevis identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDevisIdGet', 'id', id)
            const localVarPath = `/formalite/devis/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des devis d\'un dossier
         * @summary Obtenir la liste des devis d\'un dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierDevisGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierDevisGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/devis`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevisApi - functional programming interface
 * @export
 */
export const DevisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevisApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des devis
         * @summary Obtenir la liste des devis
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDevisGetCollection(page?: number, itemsPerPage?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDevisGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDevisGetCollection(page, itemsPerPage, dossierNumero, dossierId, dossierId2, dossierPrescripteurId, dossierPrescripteurId2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevisApi.apiDevisGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un devis
         * @summary Récupérer les informations d\'un devis
         * @param {string} id TblDevis identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDevisIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevisJsonldDevisRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDevisIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevisApi.apiDevisIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des devis d\'un dossier
         * @summary Obtenir la liste des devis d\'un dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierDevisGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDevisGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierDevisGetCollection(dossier, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevisApi.apiDossiersDossierDevisGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DevisApi - factory interface
 * @export
 */
export const DevisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevisApiFp(configuration)
    return {
        /**
         * Obtenir la liste des devis
         * @summary Obtenir la liste des devis
         * @param {DevisApiApiDevisGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisGetCollection(requestParameters: DevisApiApiDevisGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiDevisGetCollection200Response> {
            return localVarFp.apiDevisGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un devis
         * @summary Récupérer les informations d\'un devis
         * @param {DevisApiApiDevisIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisIdGet(requestParameters: DevisApiApiDevisIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DevisJsonldDevisRead> {
            return localVarFp.apiDevisIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des devis d\'un dossier
         * @summary Obtenir la liste des devis d\'un dossier
         * @param {DevisApiApiDossiersDossierDevisGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierDevisGetCollection(requestParameters: DevisApiApiDossiersDossierDevisGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDevisGetCollection200Response> {
            return localVarFp.apiDossiersDossierDevisGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDevisGetCollection operation in DevisApi.
 * @export
 * @interface DevisApiApiDevisGetCollectionRequest
 */
export interface DevisApiApiDevisGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly dossierNumero?: string

    /**
     * 
     * @type {number}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly dossierId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly dossierId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly dossierPrescripteurId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly dossierPrescripteurId2?: Array<number>
}

/**
 * Request parameters for apiDevisIdGet operation in DevisApi.
 * @export
 * @interface DevisApiApiDevisIdGetRequest
 */
export interface DevisApiApiDevisIdGetRequest {
    /**
     * TblDevis identifier
     * @type {string}
     * @memberof DevisApiApiDevisIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiDossiersDossierDevisGetCollection operation in DevisApi.
 * @export
 * @interface DevisApiApiDossiersDossierDevisGetCollectionRequest
 */
export interface DevisApiApiDossiersDossierDevisGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof DevisApiApiDossiersDossierDevisGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof DevisApiApiDossiersDossierDevisGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DevisApiApiDossiersDossierDevisGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * DevisApi - object-oriented interface
 * @export
 * @class DevisApi
 * @extends {BaseAPI}
 */
export class DevisApi extends BaseAPI {
    /**
     * Obtenir la liste des devis
     * @summary Obtenir la liste des devis
     * @param {DevisApiApiDevisGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisApi
     */
    public apiDevisGetCollection(requestParameters: DevisApiApiDevisGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DevisApiFp(this.configuration).apiDevisGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un devis
     * @summary Récupérer les informations d\'un devis
     * @param {DevisApiApiDevisIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisApi
     */
    public apiDevisIdGet(requestParameters: DevisApiApiDevisIdGetRequest, options?: RawAxiosRequestConfig) {
        return DevisApiFp(this.configuration).apiDevisIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des devis d\'un dossier
     * @summary Obtenir la liste des devis d\'un dossier
     * @param {DevisApiApiDossiersDossierDevisGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisApi
     */
    public apiDossiersDossierDevisGetCollection(requestParameters: DevisApiApiDossiersDossierDevisGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return DevisApiFp(this.configuration).apiDossiersDossierDevisGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DocumentGedApi - axios parameter creator
 * @export
 */
export const DocumentGedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les documents de la ged parmis ceux autorisés et en filtrant sur l\'idPrescripteur si besoin
         * @summary Lister les documents de la ged
         * @param {string} type DocumentGed identifier
         * @param {string} applicationtag DocumentGed identifier
         * @param {Array<ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner>} [tags] Map of tags
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentgedTypeApplicationtagGetCollection: async (type: string, applicationtag: string, tags?: Array<ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner>, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiDocumentgedTypeApplicationtagGetCollection', 'type', type)
            // verify required parameter 'applicationtag' is not null or undefined
            assertParamExists('apiDocumentgedTypeApplicationtagGetCollection', 'applicationtag', applicationtag)
            const localVarPath = `/formalite/documentged/{type}/{applicationtag}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"applicationtag"}}`, encodeURIComponent(String(applicationtag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (tags) {
                localVarQueryParameter['tags'] = tags.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentGedApi - functional programming interface
 * @export
 */
export const DocumentGedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentGedApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les documents de la ged parmis ceux autorisés et en filtrant sur l\'idPrescripteur si besoin
         * @summary Lister les documents de la ged
         * @param {string} type DocumentGed identifier
         * @param {string} applicationtag DocumentGed identifier
         * @param {Array<ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner>} [tags] Map of tags
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentgedTypeApplicationtagGetCollection(type: string, applicationtag: string, tags?: Array<ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner>, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDocumentgedTypeApplicationtagGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentgedTypeApplicationtagGetCollection(type, applicationtag, tags, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentGedApi.apiDocumentgedTypeApplicationtagGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentGedApi - factory interface
 * @export
 */
export const DocumentGedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentGedApiFp(configuration)
    return {
        /**
         * Lister les documents de la ged parmis ceux autorisés et en filtrant sur l\'idPrescripteur si besoin
         * @summary Lister les documents de la ged
         * @param {DocumentGedApiApiDocumentgedTypeApplicationtagGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentgedTypeApplicationtagGetCollection(requestParameters: DocumentGedApiApiDocumentgedTypeApplicationtagGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDocumentgedTypeApplicationtagGetCollection200Response> {
            return localVarFp.apiDocumentgedTypeApplicationtagGetCollection(requestParameters.type, requestParameters.applicationtag, requestParameters.tags, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDocumentgedTypeApplicationtagGetCollection operation in DocumentGedApi.
 * @export
 * @interface DocumentGedApiApiDocumentgedTypeApplicationtagGetCollectionRequest
 */
export interface DocumentGedApiApiDocumentgedTypeApplicationtagGetCollectionRequest {
    /**
     * DocumentGed identifier
     * @type {string}
     * @memberof DocumentGedApiApiDocumentgedTypeApplicationtagGetCollection
     */
    readonly type: string

    /**
     * DocumentGed identifier
     * @type {string}
     * @memberof DocumentGedApiApiDocumentgedTypeApplicationtagGetCollection
     */
    readonly applicationtag: string

    /**
     * Map of tags
     * @type {Array<ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner>}
     * @memberof DocumentGedApiApiDocumentgedTypeApplicationtagGetCollection
     */
    readonly tags?: Array<ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner>

    /**
     * The collection page number
     * @type {number}
     * @memberof DocumentGedApiApiDocumentgedTypeApplicationtagGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DocumentGedApiApiDocumentgedTypeApplicationtagGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * DocumentGedApi - object-oriented interface
 * @export
 * @class DocumentGedApi
 * @extends {BaseAPI}
 */
export class DocumentGedApi extends BaseAPI {
    /**
     * Lister les documents de la ged parmis ceux autorisés et en filtrant sur l\'idPrescripteur si besoin
     * @summary Lister les documents de la ged
     * @param {DocumentGedApiApiDocumentgedTypeApplicationtagGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentGedApi
     */
    public apiDocumentgedTypeApplicationtagGetCollection(requestParameters: DocumentGedApiApiDocumentgedTypeApplicationtagGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return DocumentGedApiFp(this.configuration).apiDocumentgedTypeApplicationtagGetCollection(requestParameters.type, requestParameters.applicationtag, requestParameters.tags, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DomainesApi - axios parameter creator
 * @export
 */
export const DomainesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des domaines
         * @summary Récupérer la liste des domaines
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesGetCollection: async (page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/domaines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un domaine
         * @summary Récupérer les informations d\'un domaine
         * @param {string} id TblDefDomaine identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDomainesIdGet', 'id', id)
            const localVarPath = `/formalite/domaines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DomainesApi - functional programming interface
 * @export
 */
export const DomainesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DomainesApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des domaines
         * @summary Récupérer la liste des domaines
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDomainesGetCollection(page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDomainesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDomainesGetCollection(page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DomainesApi.apiDomainesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un domaine
         * @summary Récupérer les informations d\'un domaine
         * @param {string} id TblDefDomaine identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDomainesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomainesJsonldDomaineRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDomainesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DomainesApi.apiDomainesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DomainesApi - factory interface
 * @export
 */
export const DomainesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DomainesApiFp(configuration)
    return {
        /**
         * Récupérer la liste des domaines
         * @summary Récupérer la liste des domaines
         * @param {DomainesApiApiDomainesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesGetCollection(requestParameters: DomainesApiApiDomainesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiDomainesGetCollection200Response> {
            return localVarFp.apiDomainesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un domaine
         * @summary Récupérer les informations d\'un domaine
         * @param {DomainesApiApiDomainesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesIdGet(requestParameters: DomainesApiApiDomainesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DomainesJsonldDomaineRead> {
            return localVarFp.apiDomainesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDomainesGetCollection operation in DomainesApi.
 * @export
 * @interface DomainesApiApiDomainesGetCollectionRequest
 */
export interface DomainesApiApiDomainesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DomainesApiApiDomainesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DomainesApiApiDomainesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiDomainesIdGet operation in DomainesApi.
 * @export
 * @interface DomainesApiApiDomainesIdGetRequest
 */
export interface DomainesApiApiDomainesIdGetRequest {
    /**
     * TblDefDomaine identifier
     * @type {string}
     * @memberof DomainesApiApiDomainesIdGet
     */
    readonly id: string
}

/**
 * DomainesApi - object-oriented interface
 * @export
 * @class DomainesApi
 * @extends {BaseAPI}
 */
export class DomainesApi extends BaseAPI {
    /**
     * Récupérer la liste des domaines
     * @summary Récupérer la liste des domaines
     * @param {DomainesApiApiDomainesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainesApi
     */
    public apiDomainesGetCollection(requestParameters: DomainesApiApiDomainesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DomainesApiFp(this.configuration).apiDomainesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un domaine
     * @summary Récupérer les informations d\'un domaine
     * @param {DomainesApiApiDomainesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainesApi
     */
    public apiDomainesIdGet(requestParameters: DomainesApiApiDomainesIdGetRequest, options?: RawAxiosRequestConfig) {
        return DomainesApiFp(this.configuration).apiDomainesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DossierApi - axios parameter creator
 * @export
 */
export const DossierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les dossiers
         * @summary Lister les dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dateCreationBefore] 
         * @param {string} [dateCreationStrictlyBefore] 
         * @param {string} [dateCreationAfter] 
         * @param {string} [dateCreationStrictlyAfter] 
         * @param {string} [numero] 
         * @param {string} [societeDenomination] 
         * @param {string} [societeSiren] 
         * @param {string} [affaire] 
         * @param {number} [idDematfacile] 
         * @param {number} [statutId] 
         * @param {Array<number>} [statutId2] 
         * @param {number} [prestationsFormaliteId] 
         * @param {Array<number>} [prestationsFormaliteId2] 
         * @param {number} [collectionId] 
         * @param {Array<number>} [collectionId2] 
         * @param {ApiDossiersGetCollectionOrderNumeroEnum} [orderNumero] 
         * @param {ApiDossiersGetCollectionOrderSocieteDenominationEnum} [orderSocieteDenomination] 
         * @param {ApiDossiersGetCollectionOrderContactNomEnum} [orderContactNom] 
         * @param {ApiDossiersGetCollectionOrderDateCreationEnum} [orderDateCreation] 
         * @param {ApiDossiersGetCollectionOrderStatutLibelleEnum} [orderStatutLibelle] 
         * @param {ApiDossiersGetCollectionOrderFormalisteNomEnum} [orderFormalisteNom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersGetCollection: async (page?: number, itemsPerPage?: number, dateCreationBefore?: string, dateCreationStrictlyBefore?: string, dateCreationAfter?: string, dateCreationStrictlyAfter?: string, numero?: string, societeDenomination?: string, societeSiren?: string, affaire?: string, idDematfacile?: number, statutId?: number, statutId2?: Array<number>, prestationsFormaliteId?: number, prestationsFormaliteId2?: Array<number>, collectionId?: number, collectionId2?: Array<number>, orderNumero?: ApiDossiersGetCollectionOrderNumeroEnum, orderSocieteDenomination?: ApiDossiersGetCollectionOrderSocieteDenominationEnum, orderContactNom?: ApiDossiersGetCollectionOrderContactNomEnum, orderDateCreation?: ApiDossiersGetCollectionOrderDateCreationEnum, orderStatutLibelle?: ApiDossiersGetCollectionOrderStatutLibelleEnum, orderFormalisteNom?: ApiDossiersGetCollectionOrderFormalisteNomEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (dateCreationBefore !== undefined) {
                localVarQueryParameter['dateCreation[before]'] = dateCreationBefore;
            }

            if (dateCreationStrictlyBefore !== undefined) {
                localVarQueryParameter['dateCreation[strictly_before]'] = dateCreationStrictlyBefore;
            }

            if (dateCreationAfter !== undefined) {
                localVarQueryParameter['dateCreation[after]'] = dateCreationAfter;
            }

            if (dateCreationStrictlyAfter !== undefined) {
                localVarQueryParameter['dateCreation[strictly_after]'] = dateCreationStrictlyAfter;
            }

            if (numero !== undefined) {
                localVarQueryParameter['numero'] = numero;
            }

            if (societeDenomination !== undefined) {
                localVarQueryParameter['societeDenomination'] = societeDenomination;
            }

            if (societeSiren !== undefined) {
                localVarQueryParameter['societeSiren'] = societeSiren;
            }

            if (affaire !== undefined) {
                localVarQueryParameter['affaire'] = affaire;
            }

            if (idDematfacile !== undefined) {
                localVarQueryParameter['idDematfacile'] = idDematfacile;
            }

            if (statutId !== undefined) {
                localVarQueryParameter['statut.id'] = statutId;
            }

            if (statutId2) {
                localVarQueryParameter['statut.id[]'] = statutId2;
            }

            if (prestationsFormaliteId !== undefined) {
                localVarQueryParameter['prestations.formalite.id'] = prestationsFormaliteId;
            }

            if (prestationsFormaliteId2) {
                localVarQueryParameter['prestations.formalite.id[]'] = prestationsFormaliteId2;
            }

            if (collectionId !== undefined) {
                localVarQueryParameter['collection.id'] = collectionId;
            }

            if (collectionId2) {
                localVarQueryParameter['collection.id[]'] = collectionId2;
            }

            if (orderNumero !== undefined) {
                localVarQueryParameter['order[numero]'] = orderNumero;
            }

            if (orderSocieteDenomination !== undefined) {
                localVarQueryParameter['order[societeDenomination]'] = orderSocieteDenomination;
            }

            if (orderContactNom !== undefined) {
                localVarQueryParameter['order[contact.nom]'] = orderContactNom;
            }

            if (orderDateCreation !== undefined) {
                localVarQueryParameter['order[dateCreation]'] = orderDateCreation;
            }

            if (orderStatutLibelle !== undefined) {
                localVarQueryParameter['order[statut.libelle]'] = orderStatutLibelle;
            }

            if (orderFormalisteNom !== undefined) {
                localVarQueryParameter['order[formaliste.nom]'] = orderFormalisteNom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour une collection de dossier
         * @summary Mettre à jour une collection de dossier
         * @param {DossierDossiersGroupesPatchRequestDto} dossierDossiersGroupesPatchRequestDto The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersGroupesPatch: async (dossierDossiersGroupesPatchRequestDto: DossierDossiersGroupesPatchRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossierDossiersGroupesPatchRequestDto' is not null or undefined
            assertParamExists('apiDossiersGroupesPatch', 'dossierDossiersGroupesPatchRequestDto', dossierDossiersGroupesPatchRequestDto)
            const localVarPath = `/formalite/dossiers/groupes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossierDossiersGroupesPatchRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Déclencher une action pour mettre à jour le statut d\'un dossier
         * @summary Déclencher une action pour mettre à jour le statut d\'un dossier
         * @param {number} id L\&#39;identifiant du dossier
         * @param {ApiDossiersIdActionPutActionEnum} action L\&#39;action à déclencher
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdActionPut: async (id: number, action: ApiDossiersIdActionPutActionEnum, body?: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdActionPut', 'id', id)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('apiDossiersIdActionPut', 'action', action)
            const localVarPath = `/formalite/dossiers/{id}/{action}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"action"}}`, encodeURIComponent(String(action)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un dossier
         * @summary Supprimer un dossier
         * @param {string} id FproDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdDelete', 'id', id)
            const localVarPath = `/formalite/dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer le client et le dossier dematfacile
         * @summary Créer le client et le dossier dematfacile
         * @param {number} id L\&#39;identifiant du dossier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdDematFacilePut: async (id: number, body?: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdDematFacilePut', 'id', id)
            const localVarPath = `/formalite/dossiers/{id}/demat-facile`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the Dossier resource.
         * @summary supprimer les fichiers d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdFichiersDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdFichiersDelete', 'id', id)
            const localVarPath = `/formalite/dossiers/{id}/fichiers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un dossier
         * @summary Récupérer les informations d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdGet', 'id', id)
            const localVarPath = `/formalite/dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les informations d\'un dossier
         * @summary Mettre à jour les informations d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {DossierDossierUpdatePartial} dossierDossierUpdatePartial The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdPatch: async (id: string, dossierDossierUpdatePartial: DossierDossierUpdatePartial, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdPatch', 'id', id)
            // verify required parameter 'dossierDossierUpdatePartial' is not null or undefined
            assertParamExists('apiDossiersIdPatch', 'dossierDossierUpdatePartial', dossierDossierUpdatePartial)
            const localVarPath = `/formalite/dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossierDossierUpdatePartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les informations d\'un dossier
         * @summary Mettre à jour les informations d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {DossierJsonldDossierUpdate} dossierJsonldDossierUpdate The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdPut: async (id: string, dossierJsonldDossierUpdate: DossierJsonldDossierUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdPut', 'id', id)
            // verify required parameter 'dossierJsonldDossierUpdate' is not null or undefined
            assertParamExists('apiDossiersIdPut', 'dossierJsonldDossierUpdate', dossierJsonldDossierUpdate)
            const localVarPath = `/formalite/dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossierJsonldDossierUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les actions possibles pour un dossier
         * @summary Lister les actions possibles pour un dossier
         * @param {number} id L\&#39;identifiant du dossier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdTransitionsGetCollection: async (id: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdTransitionsGetCollection', 'id', id)
            const localVarPath = `/formalite/dossiers/{id}/transitions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un dossier
         * @summary Créer un dossier
         * @param {DossierJsonldDossierCreate} dossierJsonldDossierCreate The new Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersPost: async (dossierJsonldDossierCreate: DossierJsonldDossierCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossierJsonldDossierCreate' is not null or undefined
            assertParamExists('apiDossiersPost', 'dossierJsonldDossierCreate', dossierJsonldDossierCreate)
            const localVarPath = `/formalite/dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossierJsonldDossierCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Envoyer le récapitulatif d\'un ou plusieurs dossiers
         * @summary Envoyer le récapitulatif d\'un ou plusieurs dossiers
         * @param {DossierJsonld} dossierJsonld The new Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersReportPost: async (dossierJsonld: DossierJsonld, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossierJsonld' is not null or undefined
            assertParamExists('apiDossiersReportPost', 'dossierJsonld', dossierJsonld)
            const localVarPath = `/formalite/dossiers/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossierJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DossierApi - functional programming interface
 * @export
 */
export const DossierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DossierApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les dossiers
         * @summary Lister les dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dateCreationBefore] 
         * @param {string} [dateCreationStrictlyBefore] 
         * @param {string} [dateCreationAfter] 
         * @param {string} [dateCreationStrictlyAfter] 
         * @param {string} [numero] 
         * @param {string} [societeDenomination] 
         * @param {string} [societeSiren] 
         * @param {string} [affaire] 
         * @param {number} [idDematfacile] 
         * @param {number} [statutId] 
         * @param {Array<number>} [statutId2] 
         * @param {number} [prestationsFormaliteId] 
         * @param {Array<number>} [prestationsFormaliteId2] 
         * @param {number} [collectionId] 
         * @param {Array<number>} [collectionId2] 
         * @param {ApiDossiersGetCollectionOrderNumeroEnum} [orderNumero] 
         * @param {ApiDossiersGetCollectionOrderSocieteDenominationEnum} [orderSocieteDenomination] 
         * @param {ApiDossiersGetCollectionOrderContactNomEnum} [orderContactNom] 
         * @param {ApiDossiersGetCollectionOrderDateCreationEnum} [orderDateCreation] 
         * @param {ApiDossiersGetCollectionOrderStatutLibelleEnum} [orderStatutLibelle] 
         * @param {ApiDossiersGetCollectionOrderFormalisteNomEnum} [orderFormalisteNom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersGetCollection(page?: number, itemsPerPage?: number, dateCreationBefore?: string, dateCreationStrictlyBefore?: string, dateCreationAfter?: string, dateCreationStrictlyAfter?: string, numero?: string, societeDenomination?: string, societeSiren?: string, affaire?: string, idDematfacile?: number, statutId?: number, statutId2?: Array<number>, prestationsFormaliteId?: number, prestationsFormaliteId2?: Array<number>, collectionId?: number, collectionId2?: Array<number>, orderNumero?: ApiDossiersGetCollectionOrderNumeroEnum, orderSocieteDenomination?: ApiDossiersGetCollectionOrderSocieteDenominationEnum, orderContactNom?: ApiDossiersGetCollectionOrderContactNomEnum, orderDateCreation?: ApiDossiersGetCollectionOrderDateCreationEnum, orderStatutLibelle?: ApiDossiersGetCollectionOrderStatutLibelleEnum, orderFormalisteNom?: ApiDossiersGetCollectionOrderFormalisteNomEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersGetCollection(page, itemsPerPage, dateCreationBefore, dateCreationStrictlyBefore, dateCreationAfter, dateCreationStrictlyAfter, numero, societeDenomination, societeSiren, affaire, idDematfacile, statutId, statutId2, prestationsFormaliteId, prestationsFormaliteId2, collectionId, collectionId2, orderNumero, orderSocieteDenomination, orderContactNom, orderDateCreation, orderStatutLibelle, orderFormalisteNom, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierApi.apiDossiersGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour une collection de dossier
         * @summary Mettre à jour une collection de dossier
         * @param {DossierDossiersGroupesPatchRequestDto} dossierDossiersGroupesPatchRequestDto The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersGroupesPatch(dossierDossiersGroupesPatchRequestDto: DossierDossiersGroupesPatchRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierDossiersGroupesPatchResponseDtoJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersGroupesPatch(dossierDossiersGroupesPatchRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierApi.apiDossiersGroupesPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Déclencher une action pour mettre à jour le statut d\'un dossier
         * @summary Déclencher une action pour mettre à jour le statut d\'un dossier
         * @param {number} id L\&#39;identifiant du dossier
         * @param {ApiDossiersIdActionPutActionEnum} action L\&#39;action à déclencher
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdActionPut(id: number, action: ApiDossiersIdActionPutActionEnum, body?: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdActionPut(id, action, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierApi.apiDossiersIdActionPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer un dossier
         * @summary Supprimer un dossier
         * @param {string} id FproDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierApi.apiDossiersIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer le client et le dossier dematfacile
         * @summary Créer le client et le dossier dematfacile
         * @param {number} id L\&#39;identifiant du dossier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdDematFacilePut(id: number, body?: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdDematFacilePut(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierApi.apiDossiersIdDematFacilePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the Dossier resource.
         * @summary supprimer les fichiers d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdFichiersDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdFichiersDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierApi.apiDossiersIdFichiersDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un dossier
         * @summary Récupérer les informations d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierApi.apiDossiersIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les informations d\'un dossier
         * @summary Mettre à jour les informations d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {DossierDossierUpdatePartial} dossierDossierUpdatePartial The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdPatch(id: string, dossierDossierUpdatePartial: DossierDossierUpdatePartial, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdPatch(id, dossierDossierUpdatePartial, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierApi.apiDossiersIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les informations d\'un dossier
         * @summary Mettre à jour les informations d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {DossierJsonldDossierUpdate} dossierJsonldDossierUpdate The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdPut(id: string, dossierJsonldDossierUpdate: DossierJsonldDossierUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdPut(id, dossierJsonldDossierUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierApi.apiDossiersIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les actions possibles pour un dossier
         * @summary Lister les actions possibles pour un dossier
         * @param {number} id L\&#39;identifiant du dossier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdTransitionsGetCollection(id: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersIdTransitionsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdTransitionsGetCollection(id, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierApi.apiDossiersIdTransitionsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer un dossier
         * @summary Créer un dossier
         * @param {DossierJsonldDossierCreate} dossierJsonldDossierCreate The new Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersPost(dossierJsonldDossierCreate: DossierJsonldDossierCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersPost(dossierJsonldDossierCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierApi.apiDossiersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Envoyer le récapitulatif d\'un ou plusieurs dossiers
         * @summary Envoyer le récapitulatif d\'un ou plusieurs dossiers
         * @param {DossierJsonld} dossierJsonld The new Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersReportPost(dossierJsonld: DossierJsonld, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierSendDossierReportResponseJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersReportPost(dossierJsonld, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierApi.apiDossiersReportPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DossierApi - factory interface
 * @export
 */
export const DossierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DossierApiFp(configuration)
    return {
        /**
         * Lister les dossiers
         * @summary Lister les dossiers
         * @param {DossierApiApiDossiersGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersGetCollection(requestParameters: DossierApiApiDossiersGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersGetCollection200Response> {
            return localVarFp.apiDossiersGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dateCreationBefore, requestParameters.dateCreationStrictlyBefore, requestParameters.dateCreationAfter, requestParameters.dateCreationStrictlyAfter, requestParameters.numero, requestParameters.societeDenomination, requestParameters.societeSiren, requestParameters.affaire, requestParameters.idDematfacile, requestParameters.statutId, requestParameters.statutId2, requestParameters.prestationsFormaliteId, requestParameters.prestationsFormaliteId2, requestParameters.collectionId, requestParameters.collectionId2, requestParameters.orderNumero, requestParameters.orderSocieteDenomination, requestParameters.orderContactNom, requestParameters.orderDateCreation, requestParameters.orderStatutLibelle, requestParameters.orderFormalisteNom, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour une collection de dossier
         * @summary Mettre à jour une collection de dossier
         * @param {DossierApiApiDossiersGroupesPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersGroupesPatch(requestParameters: DossierApiApiDossiersGroupesPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<DossierDossiersGroupesPatchResponseDtoJsonldDossierRead> {
            return localVarFp.apiDossiersGroupesPatch(requestParameters.dossierDossiersGroupesPatchRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Déclencher une action pour mettre à jour le statut d\'un dossier
         * @summary Déclencher une action pour mettre à jour le statut d\'un dossier
         * @param {DossierApiApiDossiersIdActionPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdActionPut(requestParameters: DossierApiApiDossiersIdActionPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<DossierJsonldDossierRead> {
            return localVarFp.apiDossiersIdActionPut(requestParameters.id, requestParameters.action, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un dossier
         * @summary Supprimer un dossier
         * @param {DossierApiApiDossiersIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdDelete(requestParameters: DossierApiApiDossiersIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDossiersIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer le client et le dossier dematfacile
         * @summary Créer le client et le dossier dematfacile
         * @param {DossierApiApiDossiersIdDematFacilePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdDematFacilePut(requestParameters: DossierApiApiDossiersIdDematFacilePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<DossierJsonldDossierRead> {
            return localVarFp.apiDossiersIdDematFacilePut(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the Dossier resource.
         * @summary supprimer les fichiers d\'un dossier
         * @param {DossierApiApiDossiersIdFichiersDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdFichiersDelete(requestParameters: DossierApiApiDossiersIdFichiersDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDossiersIdFichiersDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un dossier
         * @summary Récupérer les informations d\'un dossier
         * @param {DossierApiApiDossiersIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdGet(requestParameters: DossierApiApiDossiersIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DossierJsonldDossierRead> {
            return localVarFp.apiDossiersIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les informations d\'un dossier
         * @summary Mettre à jour les informations d\'un dossier
         * @param {DossierApiApiDossiersIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdPatch(requestParameters: DossierApiApiDossiersIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<DossierJsonldDossierRead> {
            return localVarFp.apiDossiersIdPatch(requestParameters.id, requestParameters.dossierDossierUpdatePartial, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les informations d\'un dossier
         * @summary Mettre à jour les informations d\'un dossier
         * @param {DossierApiApiDossiersIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdPut(requestParameters: DossierApiApiDossiersIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<DossierJsonldDossierRead> {
            return localVarFp.apiDossiersIdPut(requestParameters.id, requestParameters.dossierJsonldDossierUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les actions possibles pour un dossier
         * @summary Lister les actions possibles pour un dossier
         * @param {DossierApiApiDossiersIdTransitionsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdTransitionsGetCollection(requestParameters: DossierApiApiDossiersIdTransitionsGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersIdTransitionsGetCollection200Response> {
            return localVarFp.apiDossiersIdTransitionsGetCollection(requestParameters.id, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un dossier
         * @summary Créer un dossier
         * @param {DossierApiApiDossiersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersPost(requestParameters: DossierApiApiDossiersPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DossierJsonldDossierRead> {
            return localVarFp.apiDossiersPost(requestParameters.dossierJsonldDossierCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Envoyer le récapitulatif d\'un ou plusieurs dossiers
         * @summary Envoyer le récapitulatif d\'un ou plusieurs dossiers
         * @param {DossierApiApiDossiersReportPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersReportPost(requestParameters: DossierApiApiDossiersReportPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DossierSendDossierReportResponseJsonld> {
            return localVarFp.apiDossiersReportPost(requestParameters.dossierJsonld, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersGetCollection operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersGetCollectionRequest
 */
export interface DossierApiApiDossiersGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly dateCreationBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly dateCreationStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly dateCreationAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly dateCreationStrictlyAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly numero?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly societeDenomination?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly societeSiren?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly affaire?: string

    /**
     * 
     * @type {number}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly idDematfacile?: number

    /**
     * 
     * @type {number}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly statutId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly statutId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly prestationsFormaliteId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly prestationsFormaliteId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly collectionId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly collectionId2?: Array<number>

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly orderNumero?: ApiDossiersGetCollectionOrderNumeroEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly orderSocieteDenomination?: ApiDossiersGetCollectionOrderSocieteDenominationEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly orderContactNom?: ApiDossiersGetCollectionOrderContactNomEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly orderDateCreation?: ApiDossiersGetCollectionOrderDateCreationEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly orderStatutLibelle?: ApiDossiersGetCollectionOrderStatutLibelleEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly orderFormalisteNom?: ApiDossiersGetCollectionOrderFormalisteNomEnum
}

/**
 * Request parameters for apiDossiersGroupesPatch operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersGroupesPatchRequest
 */
export interface DossierApiApiDossiersGroupesPatchRequest {
    /**
     * The updated Dossier resource
     * @type {DossierDossiersGroupesPatchRequestDto}
     * @memberof DossierApiApiDossiersGroupesPatch
     */
    readonly dossierDossiersGroupesPatchRequestDto: DossierDossiersGroupesPatchRequestDto
}

/**
 * Request parameters for apiDossiersIdActionPut operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdActionPutRequest
 */
export interface DossierApiApiDossiersIdActionPutRequest {
    /**
     * L\&#39;identifiant du dossier
     * @type {number}
     * @memberof DossierApiApiDossiersIdActionPut
     */
    readonly id: number

    /**
     * L\&#39;action à déclencher
     * @type {'validate' | 'cancel' | 'suspend' | 'reject' | 'relance' | 'regulate' | 'process-regulation' | 'invoice' | 'paid' | 'archive'}
     * @memberof DossierApiApiDossiersIdActionPut
     */
    readonly action: ApiDossiersIdActionPutActionEnum

    /**
     * 
     * @type {object}
     * @memberof DossierApiApiDossiersIdActionPut
     */
    readonly body?: object
}

/**
 * Request parameters for apiDossiersIdDelete operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdDeleteRequest
 */
export interface DossierApiApiDossiersIdDeleteRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof DossierApiApiDossiersIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiDossiersIdDematFacilePut operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdDematFacilePutRequest
 */
export interface DossierApiApiDossiersIdDematFacilePutRequest {
    /**
     * L\&#39;identifiant du dossier
     * @type {number}
     * @memberof DossierApiApiDossiersIdDematFacilePut
     */
    readonly id: number

    /**
     * 
     * @type {object}
     * @memberof DossierApiApiDossiersIdDematFacilePut
     */
    readonly body?: object
}

/**
 * Request parameters for apiDossiersIdFichiersDelete operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdFichiersDeleteRequest
 */
export interface DossierApiApiDossiersIdFichiersDeleteRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof DossierApiApiDossiersIdFichiersDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiDossiersIdGet operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdGetRequest
 */
export interface DossierApiApiDossiersIdGetRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof DossierApiApiDossiersIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiDossiersIdPatch operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdPatchRequest
 */
export interface DossierApiApiDossiersIdPatchRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof DossierApiApiDossiersIdPatch
     */
    readonly id: string

    /**
     * The updated Dossier resource
     * @type {DossierDossierUpdatePartial}
     * @memberof DossierApiApiDossiersIdPatch
     */
    readonly dossierDossierUpdatePartial: DossierDossierUpdatePartial
}

/**
 * Request parameters for apiDossiersIdPut operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdPutRequest
 */
export interface DossierApiApiDossiersIdPutRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof DossierApiApiDossiersIdPut
     */
    readonly id: string

    /**
     * The updated Dossier resource
     * @type {DossierJsonldDossierUpdate}
     * @memberof DossierApiApiDossiersIdPut
     */
    readonly dossierJsonldDossierUpdate: DossierJsonldDossierUpdate
}

/**
 * Request parameters for apiDossiersIdTransitionsGetCollection operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdTransitionsGetCollectionRequest
 */
export interface DossierApiApiDossiersIdTransitionsGetCollectionRequest {
    /**
     * L\&#39;identifiant du dossier
     * @type {number}
     * @memberof DossierApiApiDossiersIdTransitionsGetCollection
     */
    readonly id: number

    /**
     * The collection page number
     * @type {number}
     * @memberof DossierApiApiDossiersIdTransitionsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierApiApiDossiersIdTransitionsGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiDossiersPost operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersPostRequest
 */
export interface DossierApiApiDossiersPostRequest {
    /**
     * The new Dossier resource
     * @type {DossierJsonldDossierCreate}
     * @memberof DossierApiApiDossiersPost
     */
    readonly dossierJsonldDossierCreate: DossierJsonldDossierCreate
}

/**
 * Request parameters for apiDossiersReportPost operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersReportPostRequest
 */
export interface DossierApiApiDossiersReportPostRequest {
    /**
     * The new Dossier resource
     * @type {DossierJsonld}
     * @memberof DossierApiApiDossiersReportPost
     */
    readonly dossierJsonld: DossierJsonld
}

/**
 * DossierApi - object-oriented interface
 * @export
 * @class DossierApi
 * @extends {BaseAPI}
 */
export class DossierApi extends BaseAPI {
    /**
     * Lister les dossiers
     * @summary Lister les dossiers
     * @param {DossierApiApiDossiersGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersGetCollection(requestParameters: DossierApiApiDossiersGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dateCreationBefore, requestParameters.dateCreationStrictlyBefore, requestParameters.dateCreationAfter, requestParameters.dateCreationStrictlyAfter, requestParameters.numero, requestParameters.societeDenomination, requestParameters.societeSiren, requestParameters.affaire, requestParameters.idDematfacile, requestParameters.statutId, requestParameters.statutId2, requestParameters.prestationsFormaliteId, requestParameters.prestationsFormaliteId2, requestParameters.collectionId, requestParameters.collectionId2, requestParameters.orderNumero, requestParameters.orderSocieteDenomination, requestParameters.orderContactNom, requestParameters.orderDateCreation, requestParameters.orderStatutLibelle, requestParameters.orderFormalisteNom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour une collection de dossier
     * @summary Mettre à jour une collection de dossier
     * @param {DossierApiApiDossiersGroupesPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersGroupesPatch(requestParameters: DossierApiApiDossiersGroupesPatchRequest, options?: RawAxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersGroupesPatch(requestParameters.dossierDossiersGroupesPatchRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Déclencher une action pour mettre à jour le statut d\'un dossier
     * @summary Déclencher une action pour mettre à jour le statut d\'un dossier
     * @param {DossierApiApiDossiersIdActionPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdActionPut(requestParameters: DossierApiApiDossiersIdActionPutRequest, options?: RawAxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdActionPut(requestParameters.id, requestParameters.action, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un dossier
     * @summary Supprimer un dossier
     * @param {DossierApiApiDossiersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdDelete(requestParameters: DossierApiApiDossiersIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer le client et le dossier dematfacile
     * @summary Créer le client et le dossier dematfacile
     * @param {DossierApiApiDossiersIdDematFacilePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdDematFacilePut(requestParameters: DossierApiApiDossiersIdDematFacilePutRequest, options?: RawAxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdDematFacilePut(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the Dossier resource.
     * @summary supprimer les fichiers d\'un dossier
     * @param {DossierApiApiDossiersIdFichiersDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdFichiersDelete(requestParameters: DossierApiApiDossiersIdFichiersDeleteRequest, options?: RawAxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdFichiersDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un dossier
     * @summary Récupérer les informations d\'un dossier
     * @param {DossierApiApiDossiersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdGet(requestParameters: DossierApiApiDossiersIdGetRequest, options?: RawAxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les informations d\'un dossier
     * @summary Mettre à jour les informations d\'un dossier
     * @param {DossierApiApiDossiersIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdPatch(requestParameters: DossierApiApiDossiersIdPatchRequest, options?: RawAxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdPatch(requestParameters.id, requestParameters.dossierDossierUpdatePartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les informations d\'un dossier
     * @summary Mettre à jour les informations d\'un dossier
     * @param {DossierApiApiDossiersIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdPut(requestParameters: DossierApiApiDossiersIdPutRequest, options?: RawAxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdPut(requestParameters.id, requestParameters.dossierJsonldDossierUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les actions possibles pour un dossier
     * @summary Lister les actions possibles pour un dossier
     * @param {DossierApiApiDossiersIdTransitionsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdTransitionsGetCollection(requestParameters: DossierApiApiDossiersIdTransitionsGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdTransitionsGetCollection(requestParameters.id, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un dossier
     * @summary Créer un dossier
     * @param {DossierApiApiDossiersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersPost(requestParameters: DossierApiApiDossiersPostRequest, options?: RawAxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersPost(requestParameters.dossierJsonldDossierCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Envoyer le récapitulatif d\'un ou plusieurs dossiers
     * @summary Envoyer le récapitulatif d\'un ou plusieurs dossiers
     * @param {DossierApiApiDossiersReportPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersReportPost(requestParameters: DossierApiApiDossiersReportPostRequest, options?: RawAxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersReportPost(requestParameters.dossierJsonld, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiDossiersGetCollectionOrderNumeroEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiDossiersGetCollectionOrderNumeroEnum = typeof ApiDossiersGetCollectionOrderNumeroEnum[keyof typeof ApiDossiersGetCollectionOrderNumeroEnum];
/**
 * @export
 */
export const ApiDossiersGetCollectionOrderSocieteDenominationEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiDossiersGetCollectionOrderSocieteDenominationEnum = typeof ApiDossiersGetCollectionOrderSocieteDenominationEnum[keyof typeof ApiDossiersGetCollectionOrderSocieteDenominationEnum];
/**
 * @export
 */
export const ApiDossiersGetCollectionOrderContactNomEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiDossiersGetCollectionOrderContactNomEnum = typeof ApiDossiersGetCollectionOrderContactNomEnum[keyof typeof ApiDossiersGetCollectionOrderContactNomEnum];
/**
 * @export
 */
export const ApiDossiersGetCollectionOrderDateCreationEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiDossiersGetCollectionOrderDateCreationEnum = typeof ApiDossiersGetCollectionOrderDateCreationEnum[keyof typeof ApiDossiersGetCollectionOrderDateCreationEnum];
/**
 * @export
 */
export const ApiDossiersGetCollectionOrderStatutLibelleEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiDossiersGetCollectionOrderStatutLibelleEnum = typeof ApiDossiersGetCollectionOrderStatutLibelleEnum[keyof typeof ApiDossiersGetCollectionOrderStatutLibelleEnum];
/**
 * @export
 */
export const ApiDossiersGetCollectionOrderFormalisteNomEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiDossiersGetCollectionOrderFormalisteNomEnum = typeof ApiDossiersGetCollectionOrderFormalisteNomEnum[keyof typeof ApiDossiersGetCollectionOrderFormalisteNomEnum];
/**
 * @export
 */
export const ApiDossiersIdActionPutActionEnum = {
    Validate: 'validate',
    Cancel: 'cancel',
    Suspend: 'suspend',
    Reject: 'reject',
    Relance: 'relance',
    Regulate: 'regulate',
    ProcessRegulation: 'process-regulation',
    Invoice: 'invoice',
    Paid: 'paid',
    Archive: 'archive'
} as const;
export type ApiDossiersIdActionPutActionEnum = typeof ApiDossiersIdActionPutActionEnum[keyof typeof ApiDossiersIdActionPutActionEnum];


/**
 * DossierOpenSearchApi - axios parameter creator
 * @export
 */
export const DossierOpenSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les clients des dossiers indexés sur Open Search
         * @summary Lister les clients des dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [q] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchClientsGetCollection: async (page?: number, itemsPerPage?: number, q?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/opensearch/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les collections de dossiers indexés sur Open Search
         * @summary Lister les collections de dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [q] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchCollectionsGetCollection: async (page?: number, itemsPerPage?: number, q?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/opensearch/collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les dossiers indexés sur Open Search
         * @summary Lister les dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<number>} [collectionId] Filter dossiers list by collection
         * @param {Array<number>} [clientId] Filter dossiers list by client
         * @param {Array<number>} [formalisteId] Filter dossiers list by formaliste
         * @param {Array<number>} [statutId] Filter dossiers list by status
         * @param {Array<string>} [numero] Filter dossiers list by number
         * @param {Array<string>} [societeReference] Filter dossiers list by societeRef
         * @param {Array<number>} [formaliteId] Filter dossiers list by formalite
         * @param {Array<number>} [groupId] Filter dossiers list by object group
         * @param {string} [startDate] Filter dossiers list by creation date
         * @param {string} [endDate] Filter dossiers list by creation date
         * @param {boolean} [hasMissingRequiredDocuments] Filter list by only showing dossiers with missing required documents
         * @param {number} [managerId] Filter list by formalist\&#39;s manager
         * @param {boolean} [critical] Filter list by critical flag
         * @param {string} [orderNumero] Sort dossier by numero
         * @param {string} [orderSocieteDenomination] Sort dossier by societeDenomination
         * @param {string} [orderContactNom] Sort dossier by contact\&#39;s name
         * @param {string} [orderDateCreation] Sort dossier by dateCreation
         * @param {string} [orderStatutLibelle] Sort dossier by statutLibelle
         * @param {string} [orderFormalisteNom] Sort dossier by formaliste\&#39;s name
         * @param {string} [orderPrescripteurLibelle] Sort dossier by prescriber\&#39;s name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchDossiersGetCollection: async (page?: number, itemsPerPage?: number, collectionId?: Array<number>, clientId?: Array<number>, formalisteId?: Array<number>, statutId?: Array<number>, numero?: Array<string>, societeReference?: Array<string>, formaliteId?: Array<number>, groupId?: Array<number>, startDate?: string, endDate?: string, hasMissingRequiredDocuments?: boolean, managerId?: number, critical?: boolean, orderNumero?: string, orderSocieteDenomination?: string, orderContactNom?: string, orderDateCreation?: string, orderStatutLibelle?: string, orderFormalisteNom?: string, orderPrescripteurLibelle?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/opensearch/dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (collectionId) {
                localVarQueryParameter['collectionId[]'] = collectionId;
            }

            if (clientId) {
                localVarQueryParameter['clientId[]'] = clientId;
            }

            if (formalisteId) {
                localVarQueryParameter['formalisteId[]'] = formalisteId;
            }

            if (statutId) {
                localVarQueryParameter['statutId[]'] = statutId;
            }

            if (numero) {
                localVarQueryParameter['numero[]'] = numero;
            }

            if (societeReference) {
                localVarQueryParameter['societeReference[]'] = societeReference;
            }

            if (formaliteId) {
                localVarQueryParameter['formaliteId[]'] = formaliteId;
            }

            if (groupId) {
                localVarQueryParameter['groupId[]'] = groupId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (hasMissingRequiredDocuments !== undefined) {
                localVarQueryParameter['hasMissingRequiredDocuments'] = hasMissingRequiredDocuments;
            }

            if (managerId !== undefined) {
                localVarQueryParameter['managerId'] = managerId;
            }

            if (critical !== undefined) {
                localVarQueryParameter['critical'] = critical;
            }

            if (orderNumero !== undefined) {
                localVarQueryParameter['order[numero]'] = orderNumero;
            }

            if (orderSocieteDenomination !== undefined) {
                localVarQueryParameter['order[societeDenomination]'] = orderSocieteDenomination;
            }

            if (orderContactNom !== undefined) {
                localVarQueryParameter['order[contactNom]'] = orderContactNom;
            }

            if (orderDateCreation !== undefined) {
                localVarQueryParameter['order[dateCreation]'] = orderDateCreation;
            }

            if (orderStatutLibelle !== undefined) {
                localVarQueryParameter['order[statutLibelle]'] = orderStatutLibelle;
            }

            if (orderFormalisteNom !== undefined) {
                localVarQueryParameter['order[formalisteNom]'] = orderFormalisteNom;
            }

            if (orderPrescripteurLibelle !== undefined) {
                localVarQueryParameter['order[prescripteurLibelle]'] = orderPrescripteurLibelle;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un dossier indexé sur Open Search
         * @summary Récupérer les informations d\'un dossier indexé sur Open Search
         * @param {string} id Dossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchDossiersIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOpensearchDossiersIdGet', 'id', id)
            const localVarPath = `/formalite/opensearch/dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Indexer un lot de dossiers sur Open Search
         * @summary Indexer un lot de dossiers sur Open Search
         * @param {DossierOpenSearchJsonldOpensearchDossierIndexBatch} dossierOpenSearchJsonldOpensearchDossierIndexBatch The new Dossier Open Search resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchDossiersIndexByIdsPost: async (dossierOpenSearchJsonldOpensearchDossierIndexBatch: DossierOpenSearchJsonldOpensearchDossierIndexBatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossierOpenSearchJsonldOpensearchDossierIndexBatch' is not null or undefined
            assertParamExists('apiOpensearchDossiersIndexByIdsPost', 'dossierOpenSearchJsonldOpensearchDossierIndexBatch', dossierOpenSearchJsonldOpensearchDossierIndexBatch)
            const localVarPath = `/formalite/opensearch/dossiers/index-by-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossierOpenSearchJsonldOpensearchDossierIndexBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les formalistes des dossiers indexés sur Open Search
         * @summary Lister les formalistes des dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [q] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchFormalistesGetCollection: async (page?: number, itemsPerPage?: number, q?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/opensearch/formalistes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les formalités des dossiers indexés sur Open Search
         * @summary Lister les formalités des dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [q] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchFormalitesGetCollection: async (page?: number, itemsPerPage?: number, q?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/opensearch/formalites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les groupes des dossiers indexés sur Open Search
         * @summary Lister les groupes des dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [q] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchObjectGroupsGetCollection: async (page?: number, itemsPerPage?: number, q?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/opensearch/object-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les référence de sociétés des dossiers indexés sur Open Search
         * @summary Lister les référence de sociétés des dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [q] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchSocieteReferenceGetCollection: async (page?: number, itemsPerPage?: number, q?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/opensearch/societe-reference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les statuts des dossiers indexés sur Open Search
         * @summary Lister les statuts des dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchStatusesGetCollection: async (page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/opensearch/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DossierOpenSearchApi - functional programming interface
 * @export
 */
export const DossierOpenSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DossierOpenSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les clients des dossiers indexés sur Open Search
         * @summary Lister les clients des dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [q] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpensearchClientsGetCollection(page?: number, itemsPerPage?: number, q?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOpensearchClientsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpensearchClientsGetCollection(page, itemsPerPage, q, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierOpenSearchApi.apiOpensearchClientsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les collections de dossiers indexés sur Open Search
         * @summary Lister les collections de dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [q] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpensearchCollectionsGetCollection(page?: number, itemsPerPage?: number, q?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOpensearchCollectionsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpensearchCollectionsGetCollection(page, itemsPerPage, q, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierOpenSearchApi.apiOpensearchCollectionsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les dossiers indexés sur Open Search
         * @summary Lister les dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {Array<number>} [collectionId] Filter dossiers list by collection
         * @param {Array<number>} [clientId] Filter dossiers list by client
         * @param {Array<number>} [formalisteId] Filter dossiers list by formaliste
         * @param {Array<number>} [statutId] Filter dossiers list by status
         * @param {Array<string>} [numero] Filter dossiers list by number
         * @param {Array<string>} [societeReference] Filter dossiers list by societeRef
         * @param {Array<number>} [formaliteId] Filter dossiers list by formalite
         * @param {Array<number>} [groupId] Filter dossiers list by object group
         * @param {string} [startDate] Filter dossiers list by creation date
         * @param {string} [endDate] Filter dossiers list by creation date
         * @param {boolean} [hasMissingRequiredDocuments] Filter list by only showing dossiers with missing required documents
         * @param {number} [managerId] Filter list by formalist\&#39;s manager
         * @param {boolean} [critical] Filter list by critical flag
         * @param {string} [orderNumero] Sort dossier by numero
         * @param {string} [orderSocieteDenomination] Sort dossier by societeDenomination
         * @param {string} [orderContactNom] Sort dossier by contact\&#39;s name
         * @param {string} [orderDateCreation] Sort dossier by dateCreation
         * @param {string} [orderStatutLibelle] Sort dossier by statutLibelle
         * @param {string} [orderFormalisteNom] Sort dossier by formaliste\&#39;s name
         * @param {string} [orderPrescripteurLibelle] Sort dossier by prescriber\&#39;s name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpensearchDossiersGetCollection(page?: number, itemsPerPage?: number, collectionId?: Array<number>, clientId?: Array<number>, formalisteId?: Array<number>, statutId?: Array<number>, numero?: Array<string>, societeReference?: Array<string>, formaliteId?: Array<number>, groupId?: Array<number>, startDate?: string, endDate?: string, hasMissingRequiredDocuments?: boolean, managerId?: number, critical?: boolean, orderNumero?: string, orderSocieteDenomination?: string, orderContactNom?: string, orderDateCreation?: string, orderStatutLibelle?: string, orderFormalisteNom?: string, orderPrescripteurLibelle?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOpensearchDossiersGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpensearchDossiersGetCollection(page, itemsPerPage, collectionId, clientId, formalisteId, statutId, numero, societeReference, formaliteId, groupId, startDate, endDate, hasMissingRequiredDocuments, managerId, critical, orderNumero, orderSocieteDenomination, orderContactNom, orderDateCreation, orderStatutLibelle, orderFormalisteNom, orderPrescripteurLibelle, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierOpenSearchApi.apiOpensearchDossiersGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un dossier indexé sur Open Search
         * @summary Récupérer les informations d\'un dossier indexé sur Open Search
         * @param {string} id Dossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpensearchDossiersIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierOpenSearchJsonldOpensearchDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpensearchDossiersIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierOpenSearchApi.apiOpensearchDossiersIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Indexer un lot de dossiers sur Open Search
         * @summary Indexer un lot de dossiers sur Open Search
         * @param {DossierOpenSearchJsonldOpensearchDossierIndexBatch} dossierOpenSearchJsonldOpensearchDossierIndexBatch The new Dossier Open Search resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpensearchDossiersIndexByIdsPost(dossierOpenSearchJsonldOpensearchDossierIndexBatch: DossierOpenSearchJsonldOpensearchDossierIndexBatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierOpenSearchIndexBatchDossierResponseJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpensearchDossiersIndexByIdsPost(dossierOpenSearchJsonldOpensearchDossierIndexBatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierOpenSearchApi.apiOpensearchDossiersIndexByIdsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les formalistes des dossiers indexés sur Open Search
         * @summary Lister les formalistes des dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [q] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpensearchFormalistesGetCollection(page?: number, itemsPerPage?: number, q?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOpensearchFormalistesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpensearchFormalistesGetCollection(page, itemsPerPage, q, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierOpenSearchApi.apiOpensearchFormalistesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les formalités des dossiers indexés sur Open Search
         * @summary Lister les formalités des dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [q] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpensearchFormalitesGetCollection(page?: number, itemsPerPage?: number, q?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOpensearchFormalitesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpensearchFormalitesGetCollection(page, itemsPerPage, q, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierOpenSearchApi.apiOpensearchFormalitesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les groupes des dossiers indexés sur Open Search
         * @summary Lister les groupes des dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [q] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpensearchObjectGroupsGetCollection(page?: number, itemsPerPage?: number, q?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOpensearchObjectGroupsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpensearchObjectGroupsGetCollection(page, itemsPerPage, q, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierOpenSearchApi.apiOpensearchObjectGroupsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les référence de sociétés des dossiers indexés sur Open Search
         * @summary Lister les référence de sociétés des dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [q] Search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpensearchSocieteReferenceGetCollection(page?: number, itemsPerPage?: number, q?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOpensearchSocieteReferenceGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpensearchSocieteReferenceGetCollection(page, itemsPerPage, q, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierOpenSearchApi.apiOpensearchSocieteReferenceGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les statuts des dossiers indexés sur Open Search
         * @summary Lister les statuts des dossiers indexés sur Open Search
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpensearchStatusesGetCollection(page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOpensearchStatusesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpensearchStatusesGetCollection(page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DossierOpenSearchApi.apiOpensearchStatusesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DossierOpenSearchApi - factory interface
 * @export
 */
export const DossierOpenSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DossierOpenSearchApiFp(configuration)
    return {
        /**
         * Lister les clients des dossiers indexés sur Open Search
         * @summary Lister les clients des dossiers indexés sur Open Search
         * @param {DossierOpenSearchApiApiOpensearchClientsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchClientsGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchClientsGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOpensearchClientsGetCollection200Response> {
            return localVarFp.apiOpensearchClientsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les collections de dossiers indexés sur Open Search
         * @summary Lister les collections de dossiers indexés sur Open Search
         * @param {DossierOpenSearchApiApiOpensearchCollectionsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchCollectionsGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchCollectionsGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOpensearchCollectionsGetCollection200Response> {
            return localVarFp.apiOpensearchCollectionsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les dossiers indexés sur Open Search
         * @summary Lister les dossiers indexés sur Open Search
         * @param {DossierOpenSearchApiApiOpensearchDossiersGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchDossiersGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchDossiersGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOpensearchDossiersGetCollection200Response> {
            return localVarFp.apiOpensearchDossiersGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.collectionId, requestParameters.clientId, requestParameters.formalisteId, requestParameters.statutId, requestParameters.numero, requestParameters.societeReference, requestParameters.formaliteId, requestParameters.groupId, requestParameters.startDate, requestParameters.endDate, requestParameters.hasMissingRequiredDocuments, requestParameters.managerId, requestParameters.critical, requestParameters.orderNumero, requestParameters.orderSocieteDenomination, requestParameters.orderContactNom, requestParameters.orderDateCreation, requestParameters.orderStatutLibelle, requestParameters.orderFormalisteNom, requestParameters.orderPrescripteurLibelle, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un dossier indexé sur Open Search
         * @summary Récupérer les informations d\'un dossier indexé sur Open Search
         * @param {DossierOpenSearchApiApiOpensearchDossiersIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchDossiersIdGet(requestParameters: DossierOpenSearchApiApiOpensearchDossiersIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DossierOpenSearchJsonldOpensearchDossierRead> {
            return localVarFp.apiOpensearchDossiersIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Indexer un lot de dossiers sur Open Search
         * @summary Indexer un lot de dossiers sur Open Search
         * @param {DossierOpenSearchApiApiOpensearchDossiersIndexByIdsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchDossiersIndexByIdsPost(requestParameters: DossierOpenSearchApiApiOpensearchDossiersIndexByIdsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DossierOpenSearchIndexBatchDossierResponseJsonld> {
            return localVarFp.apiOpensearchDossiersIndexByIdsPost(requestParameters.dossierOpenSearchJsonldOpensearchDossierIndexBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les formalistes des dossiers indexés sur Open Search
         * @summary Lister les formalistes des dossiers indexés sur Open Search
         * @param {DossierOpenSearchApiApiOpensearchFormalistesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchFormalistesGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchFormalistesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOpensearchFormalistesGetCollection200Response> {
            return localVarFp.apiOpensearchFormalistesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les formalités des dossiers indexés sur Open Search
         * @summary Lister les formalités des dossiers indexés sur Open Search
         * @param {DossierOpenSearchApiApiOpensearchFormalitesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchFormalitesGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchFormalitesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOpensearchFormalitesGetCollection200Response> {
            return localVarFp.apiOpensearchFormalitesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les groupes des dossiers indexés sur Open Search
         * @summary Lister les groupes des dossiers indexés sur Open Search
         * @param {DossierOpenSearchApiApiOpensearchObjectGroupsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchObjectGroupsGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchObjectGroupsGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOpensearchObjectGroupsGetCollection200Response> {
            return localVarFp.apiOpensearchObjectGroupsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les référence de sociétés des dossiers indexés sur Open Search
         * @summary Lister les référence de sociétés des dossiers indexés sur Open Search
         * @param {DossierOpenSearchApiApiOpensearchSocieteReferenceGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchSocieteReferenceGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchSocieteReferenceGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOpensearchSocieteReferenceGetCollection200Response> {
            return localVarFp.apiOpensearchSocieteReferenceGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les statuts des dossiers indexés sur Open Search
         * @summary Lister les statuts des dossiers indexés sur Open Search
         * @param {DossierOpenSearchApiApiOpensearchStatusesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpensearchStatusesGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchStatusesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOpensearchStatusesGetCollection200Response> {
            return localVarFp.apiOpensearchStatusesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpensearchClientsGetCollection operation in DossierOpenSearchApi.
 * @export
 * @interface DossierOpenSearchApiApiOpensearchClientsGetCollectionRequest
 */
export interface DossierOpenSearchApiApiOpensearchClientsGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchClientsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchClientsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Search term
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchClientsGetCollection
     */
    readonly q?: string
}

/**
 * Request parameters for apiOpensearchCollectionsGetCollection operation in DossierOpenSearchApi.
 * @export
 * @interface DossierOpenSearchApiApiOpensearchCollectionsGetCollectionRequest
 */
export interface DossierOpenSearchApiApiOpensearchCollectionsGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchCollectionsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchCollectionsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Search term
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchCollectionsGetCollection
     */
    readonly q?: string
}

/**
 * Request parameters for apiOpensearchDossiersGetCollection operation in DossierOpenSearchApi.
 * @export
 * @interface DossierOpenSearchApiApiOpensearchDossiersGetCollectionRequest
 */
export interface DossierOpenSearchApiApiOpensearchDossiersGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Filter dossiers list by collection
     * @type {Array<number>}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly collectionId?: Array<number>

    /**
     * Filter dossiers list by client
     * @type {Array<number>}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly clientId?: Array<number>

    /**
     * Filter dossiers list by formaliste
     * @type {Array<number>}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly formalisteId?: Array<number>

    /**
     * Filter dossiers list by status
     * @type {Array<number>}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly statutId?: Array<number>

    /**
     * Filter dossiers list by number
     * @type {Array<string>}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly numero?: Array<string>

    /**
     * Filter dossiers list by societeRef
     * @type {Array<string>}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly societeReference?: Array<string>

    /**
     * Filter dossiers list by formalite
     * @type {Array<number>}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly formaliteId?: Array<number>

    /**
     * Filter dossiers list by object group
     * @type {Array<number>}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly groupId?: Array<number>

    /**
     * Filter dossiers list by creation date
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly startDate?: string

    /**
     * Filter dossiers list by creation date
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly endDate?: string

    /**
     * Filter list by only showing dossiers with missing required documents
     * @type {boolean}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly hasMissingRequiredDocuments?: boolean

    /**
     * Filter list by formalist\&#39;s manager
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly managerId?: number

    /**
     * Filter list by critical flag
     * @type {boolean}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly critical?: boolean

    /**
     * Sort dossier by numero
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly orderNumero?: string

    /**
     * Sort dossier by societeDenomination
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly orderSocieteDenomination?: string

    /**
     * Sort dossier by contact\&#39;s name
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly orderContactNom?: string

    /**
     * Sort dossier by dateCreation
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly orderDateCreation?: string

    /**
     * Sort dossier by statutLibelle
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly orderStatutLibelle?: string

    /**
     * Sort dossier by formaliste\&#39;s name
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly orderFormalisteNom?: string

    /**
     * Sort dossier by prescriber\&#39;s name
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersGetCollection
     */
    readonly orderPrescripteurLibelle?: string
}

/**
 * Request parameters for apiOpensearchDossiersIdGet operation in DossierOpenSearchApi.
 * @export
 * @interface DossierOpenSearchApiApiOpensearchDossiersIdGetRequest
 */
export interface DossierOpenSearchApiApiOpensearchDossiersIdGetRequest {
    /**
     * Dossier identifier
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiOpensearchDossiersIndexByIdsPost operation in DossierOpenSearchApi.
 * @export
 * @interface DossierOpenSearchApiApiOpensearchDossiersIndexByIdsPostRequest
 */
export interface DossierOpenSearchApiApiOpensearchDossiersIndexByIdsPostRequest {
    /**
     * The new Dossier Open Search resource
     * @type {DossierOpenSearchJsonldOpensearchDossierIndexBatch}
     * @memberof DossierOpenSearchApiApiOpensearchDossiersIndexByIdsPost
     */
    readonly dossierOpenSearchJsonldOpensearchDossierIndexBatch: DossierOpenSearchJsonldOpensearchDossierIndexBatch
}

/**
 * Request parameters for apiOpensearchFormalistesGetCollection operation in DossierOpenSearchApi.
 * @export
 * @interface DossierOpenSearchApiApiOpensearchFormalistesGetCollectionRequest
 */
export interface DossierOpenSearchApiApiOpensearchFormalistesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchFormalistesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchFormalistesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Search term
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchFormalistesGetCollection
     */
    readonly q?: string
}

/**
 * Request parameters for apiOpensearchFormalitesGetCollection operation in DossierOpenSearchApi.
 * @export
 * @interface DossierOpenSearchApiApiOpensearchFormalitesGetCollectionRequest
 */
export interface DossierOpenSearchApiApiOpensearchFormalitesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchFormalitesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchFormalitesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Search term
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchFormalitesGetCollection
     */
    readonly q?: string
}

/**
 * Request parameters for apiOpensearchObjectGroupsGetCollection operation in DossierOpenSearchApi.
 * @export
 * @interface DossierOpenSearchApiApiOpensearchObjectGroupsGetCollectionRequest
 */
export interface DossierOpenSearchApiApiOpensearchObjectGroupsGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchObjectGroupsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchObjectGroupsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Search term
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchObjectGroupsGetCollection
     */
    readonly q?: string
}

/**
 * Request parameters for apiOpensearchSocieteReferenceGetCollection operation in DossierOpenSearchApi.
 * @export
 * @interface DossierOpenSearchApiApiOpensearchSocieteReferenceGetCollectionRequest
 */
export interface DossierOpenSearchApiApiOpensearchSocieteReferenceGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchSocieteReferenceGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchSocieteReferenceGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Search term
     * @type {string}
     * @memberof DossierOpenSearchApiApiOpensearchSocieteReferenceGetCollection
     */
    readonly q?: string
}

/**
 * Request parameters for apiOpensearchStatusesGetCollection operation in DossierOpenSearchApi.
 * @export
 * @interface DossierOpenSearchApiApiOpensearchStatusesGetCollectionRequest
 */
export interface DossierOpenSearchApiApiOpensearchStatusesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchStatusesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierOpenSearchApiApiOpensearchStatusesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * DossierOpenSearchApi - object-oriented interface
 * @export
 * @class DossierOpenSearchApi
 * @extends {BaseAPI}
 */
export class DossierOpenSearchApi extends BaseAPI {
    /**
     * Lister les clients des dossiers indexés sur Open Search
     * @summary Lister les clients des dossiers indexés sur Open Search
     * @param {DossierOpenSearchApiApiOpensearchClientsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierOpenSearchApi
     */
    public apiOpensearchClientsGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchClientsGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DossierOpenSearchApiFp(this.configuration).apiOpensearchClientsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les collections de dossiers indexés sur Open Search
     * @summary Lister les collections de dossiers indexés sur Open Search
     * @param {DossierOpenSearchApiApiOpensearchCollectionsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierOpenSearchApi
     */
    public apiOpensearchCollectionsGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchCollectionsGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DossierOpenSearchApiFp(this.configuration).apiOpensearchCollectionsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les dossiers indexés sur Open Search
     * @summary Lister les dossiers indexés sur Open Search
     * @param {DossierOpenSearchApiApiOpensearchDossiersGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierOpenSearchApi
     */
    public apiOpensearchDossiersGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchDossiersGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DossierOpenSearchApiFp(this.configuration).apiOpensearchDossiersGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.collectionId, requestParameters.clientId, requestParameters.formalisteId, requestParameters.statutId, requestParameters.numero, requestParameters.societeReference, requestParameters.formaliteId, requestParameters.groupId, requestParameters.startDate, requestParameters.endDate, requestParameters.hasMissingRequiredDocuments, requestParameters.managerId, requestParameters.critical, requestParameters.orderNumero, requestParameters.orderSocieteDenomination, requestParameters.orderContactNom, requestParameters.orderDateCreation, requestParameters.orderStatutLibelle, requestParameters.orderFormalisteNom, requestParameters.orderPrescripteurLibelle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un dossier indexé sur Open Search
     * @summary Récupérer les informations d\'un dossier indexé sur Open Search
     * @param {DossierOpenSearchApiApiOpensearchDossiersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierOpenSearchApi
     */
    public apiOpensearchDossiersIdGet(requestParameters: DossierOpenSearchApiApiOpensearchDossiersIdGetRequest, options?: RawAxiosRequestConfig) {
        return DossierOpenSearchApiFp(this.configuration).apiOpensearchDossiersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Indexer un lot de dossiers sur Open Search
     * @summary Indexer un lot de dossiers sur Open Search
     * @param {DossierOpenSearchApiApiOpensearchDossiersIndexByIdsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierOpenSearchApi
     */
    public apiOpensearchDossiersIndexByIdsPost(requestParameters: DossierOpenSearchApiApiOpensearchDossiersIndexByIdsPostRequest, options?: RawAxiosRequestConfig) {
        return DossierOpenSearchApiFp(this.configuration).apiOpensearchDossiersIndexByIdsPost(requestParameters.dossierOpenSearchJsonldOpensearchDossierIndexBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les formalistes des dossiers indexés sur Open Search
     * @summary Lister les formalistes des dossiers indexés sur Open Search
     * @param {DossierOpenSearchApiApiOpensearchFormalistesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierOpenSearchApi
     */
    public apiOpensearchFormalistesGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchFormalistesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DossierOpenSearchApiFp(this.configuration).apiOpensearchFormalistesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les formalités des dossiers indexés sur Open Search
     * @summary Lister les formalités des dossiers indexés sur Open Search
     * @param {DossierOpenSearchApiApiOpensearchFormalitesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierOpenSearchApi
     */
    public apiOpensearchFormalitesGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchFormalitesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DossierOpenSearchApiFp(this.configuration).apiOpensearchFormalitesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les groupes des dossiers indexés sur Open Search
     * @summary Lister les groupes des dossiers indexés sur Open Search
     * @param {DossierOpenSearchApiApiOpensearchObjectGroupsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierOpenSearchApi
     */
    public apiOpensearchObjectGroupsGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchObjectGroupsGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DossierOpenSearchApiFp(this.configuration).apiOpensearchObjectGroupsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les référence de sociétés des dossiers indexés sur Open Search
     * @summary Lister les référence de sociétés des dossiers indexés sur Open Search
     * @param {DossierOpenSearchApiApiOpensearchSocieteReferenceGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierOpenSearchApi
     */
    public apiOpensearchSocieteReferenceGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchSocieteReferenceGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DossierOpenSearchApiFp(this.configuration).apiOpensearchSocieteReferenceGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les statuts des dossiers indexés sur Open Search
     * @summary Lister les statuts des dossiers indexés sur Open Search
     * @param {DossierOpenSearchApiApiOpensearchStatusesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierOpenSearchApi
     */
    public apiOpensearchStatusesGetCollection(requestParameters: DossierOpenSearchApiApiOpensearchStatusesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DossierOpenSearchApiFp(this.configuration).apiOpensearchStatusesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FichiersDossierApi - axios parameter creator
 * @export
 */
export const FichiersDossierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les fichiers liés au dossier
         * @summary Lister les fichiers liés au dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {number} [expediteur] 
         * @param {Array<number>} [expediteur2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierFichiersGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, expediteur?: number, expediteur2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierFichiersGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/fichiers`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (dossierNumero !== undefined) {
                localVarQueryParameter['dossier.numero'] = dossierNumero;
            }

            if (dossierId !== undefined) {
                localVarQueryParameter['dossier.id'] = dossierId;
            }

            if (dossierId2) {
                localVarQueryParameter['dossier.id[]'] = dossierId2;
            }

            if (dossierPrescripteurId !== undefined) {
                localVarQueryParameter['dossier.prescripteur.id'] = dossierPrescripteurId;
            }

            if (dossierPrescripteurId2) {
                localVarQueryParameter['dossier.prescripteur.id[]'] = dossierPrescripteurId2;
            }

            if (expediteur !== undefined) {
                localVarQueryParameter['expediteur'] = expediteur;
            }

            if (expediteur2) {
                localVarQueryParameter['expediteur[]'] = expediteur2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ajouter un fichier au dossier
         * @summary Ajouter un fichier au dossier
         * @param {string} dossier FproDossier identifier
         * @param {ApiDossiersDossierFichiersPostExpediteurEnum} [expediteur] 
         * @param {ApiDossiersDossierFichiersPostExpeditionEnum} [expedition] 
         * @param {number} [idDocDf] 
         * @param {string} [typeDoc] 
         * @param {FproDosDocFileDocumentfileCreate | null} [docSigned] 
         * @param {FproDosDocFileDocumentfileCreate | null} [docOriginal] 
         * @param {File | null} [file] 
         * @param {boolean} [typageAuto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierFichiersPost: async (dossier: string, expediteur?: ApiDossiersDossierFichiersPostExpediteurEnum, expedition?: ApiDossiersDossierFichiersPostExpeditionEnum, idDocDf?: number, typeDoc?: string, docSigned?: FproDosDocFileDocumentfileCreate | null, docOriginal?: FproDosDocFileDocumentfileCreate | null, file?: File | null, typageAuto?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierFichiersPost', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/fichiers`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


            if (expediteur !== undefined) { 
                localVarFormParams.append('expediteur', expediteur as any);
            }
    
            if (expedition !== undefined) { 
                localVarFormParams.append('expedition', expedition as any);
            }
    
            if (idDocDf !== undefined) { 
                localVarFormParams.append('idDocDf', idDocDf as any);
            }
    
            if (typeDoc !== undefined) { 
                localVarFormParams.append('typeDoc', typeDoc as any);
            }
    
            if (docSigned !== undefined) { 
                localVarFormParams.append('docSigned', new Blob([JSON.stringify(docSigned)], { type: "application/json", }));
            }
    
            if (docOriginal !== undefined) { 
                localVarFormParams.append('docOriginal', new Blob([JSON.stringify(docOriginal)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (typageAuto !== undefined) { 
                localVarFormParams.append('typageAuto', String(typageAuto) as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un fichier
         * @summary Supprimer un fichier
         * @param {string} id FproDosDocFile identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFichiersIdDelete', 'id', id)
            const localVarPath = `/formalite/fichiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Fichiers Dossier resource.
         * @summary Retrieves a Fichiers Dossier resource.
         * @param {string} id FproDosDocFile identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdFileGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFichiersIdFileGet', 'id', id)
            const localVarPath = `/formalite/fichiers/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Fichiers Dossier resource.
         * @summary Retrieves a Fichiers Dossier resource.
         * @param {string} id FproDosDocFile identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFichiersIdGet', 'id', id)
            const localVarPath = `/formalite/fichiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Fichiers Dossier resource.
         * @summary Updates the Fichiers Dossier resource.
         * @param {string} id FproDosDocFile identifier
         * @param {FichiersDossierDocumentfileUpdate} fichiersDossierDocumentfileUpdate The updated Fichiers Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdPatch: async (id: string, fichiersDossierDocumentfileUpdate: FichiersDossierDocumentfileUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFichiersIdPatch', 'id', id)
            // verify required parameter 'fichiersDossierDocumentfileUpdate' is not null or undefined
            assertParamExists('apiFichiersIdPatch', 'fichiersDossierDocumentfileUpdate', fichiersDossierDocumentfileUpdate)
            const localVarPath = `/formalite/fichiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fichiersDossierDocumentfileUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FichiersDossierApi - functional programming interface
 * @export
 */
export const FichiersDossierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FichiersDossierApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les fichiers liés au dossier
         * @summary Lister les fichiers liés au dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {number} [expediteur] 
         * @param {Array<number>} [expediteur2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierFichiersGetCollection(dossier: string, page?: number, itemsPerPage?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, expediteur?: number, expediteur2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierFichiersGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierFichiersGetCollection(dossier, page, itemsPerPage, dossierNumero, dossierId, dossierId2, dossierPrescripteurId, dossierPrescripteurId2, expediteur, expediteur2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FichiersDossierApi.apiDossiersDossierFichiersGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Ajouter un fichier au dossier
         * @summary Ajouter un fichier au dossier
         * @param {string} dossier FproDossier identifier
         * @param {ApiDossiersDossierFichiersPostExpediteurEnum} [expediteur] 
         * @param {ApiDossiersDossierFichiersPostExpeditionEnum} [expedition] 
         * @param {number} [idDocDf] 
         * @param {string} [typeDoc] 
         * @param {FproDosDocFileDocumentfileCreate | null} [docSigned] 
         * @param {FproDosDocFileDocumentfileCreate | null} [docOriginal] 
         * @param {File | null} [file] 
         * @param {boolean} [typageAuto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierFichiersPost(dossier: string, expediteur?: ApiDossiersDossierFichiersPostExpediteurEnum, expedition?: ApiDossiersDossierFichiersPostExpeditionEnum, idDocDf?: number, typeDoc?: string, docSigned?: FproDosDocFileDocumentfileCreate | null, docOriginal?: FproDosDocFileDocumentfileCreate | null, file?: File | null, typageAuto?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FichiersDossierJsonldDocumentfileRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierFichiersPost(dossier, expediteur, expedition, idDocDf, typeDoc, docSigned, docOriginal, file, typageAuto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FichiersDossierApi.apiDossiersDossierFichiersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer un fichier
         * @summary Supprimer un fichier
         * @param {string} id FproDosDocFile identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFichiersIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFichiersIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FichiersDossierApi.apiFichiersIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a Fichiers Dossier resource.
         * @summary Retrieves a Fichiers Dossier resource.
         * @param {string} id FproDosDocFile identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFichiersIdFileGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFichiersIdFileGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FichiersDossierApi.apiFichiersIdFileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a Fichiers Dossier resource.
         * @summary Retrieves a Fichiers Dossier resource.
         * @param {string} id FproDosDocFile identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFichiersIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FichiersDossierJsonldDocumentfileRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFichiersIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FichiersDossierApi.apiFichiersIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the Fichiers Dossier resource.
         * @summary Updates the Fichiers Dossier resource.
         * @param {string} id FproDosDocFile identifier
         * @param {FichiersDossierDocumentfileUpdate} fichiersDossierDocumentfileUpdate The updated Fichiers Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFichiersIdPatch(id: string, fichiersDossierDocumentfileUpdate: FichiersDossierDocumentfileUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FichiersDossierJsonldDocumentfileRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFichiersIdPatch(id, fichiersDossierDocumentfileUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FichiersDossierApi.apiFichiersIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FichiersDossierApi - factory interface
 * @export
 */
export const FichiersDossierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FichiersDossierApiFp(configuration)
    return {
        /**
         * Lister les fichiers liés au dossier
         * @summary Lister les fichiers liés au dossier
         * @param {FichiersDossierApiApiDossiersDossierFichiersGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierFichiersGetCollection(requestParameters: FichiersDossierApiApiDossiersDossierFichiersGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersDossierFichiersGetCollection200Response> {
            return localVarFp.apiDossiersDossierFichiersGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, requestParameters.expediteur, requestParameters.expediteur2, options).then((request) => request(axios, basePath));
        },
        /**
         * Ajouter un fichier au dossier
         * @summary Ajouter un fichier au dossier
         * @param {FichiersDossierApiApiDossiersDossierFichiersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierFichiersPost(requestParameters: FichiersDossierApiApiDossiersDossierFichiersPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<FichiersDossierJsonldDocumentfileRead> {
            return localVarFp.apiDossiersDossierFichiersPost(requestParameters.dossier, requestParameters.expediteur, requestParameters.expedition, requestParameters.idDocDf, requestParameters.typeDoc, requestParameters.docSigned, requestParameters.docOriginal, requestParameters.file, requestParameters.typageAuto, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un fichier
         * @summary Supprimer un fichier
         * @param {FichiersDossierApiApiFichiersIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdDelete(requestParameters: FichiersDossierApiApiFichiersIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiFichiersIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Fichiers Dossier resource.
         * @summary Retrieves a Fichiers Dossier resource.
         * @param {FichiersDossierApiApiFichiersIdFileGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdFileGet(requestParameters: FichiersDossierApiApiFichiersIdFileGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiFichiersIdFileGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Fichiers Dossier resource.
         * @summary Retrieves a Fichiers Dossier resource.
         * @param {FichiersDossierApiApiFichiersIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdGet(requestParameters: FichiersDossierApiApiFichiersIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FichiersDossierJsonldDocumentfileRead> {
            return localVarFp.apiFichiersIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Fichiers Dossier resource.
         * @summary Updates the Fichiers Dossier resource.
         * @param {FichiersDossierApiApiFichiersIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdPatch(requestParameters: FichiersDossierApiApiFichiersIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<FichiersDossierJsonldDocumentfileRead> {
            return localVarFp.apiFichiersIdPatch(requestParameters.id, requestParameters.fichiersDossierDocumentfileUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierFichiersGetCollection operation in FichiersDossierApi.
 * @export
 * @interface FichiersDossierApiApiDossiersDossierFichiersGetCollectionRequest
 */
export interface FichiersDossierApiApiDossiersDossierFichiersGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly dossierNumero?: string

    /**
     * 
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly dossierId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly dossierId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly dossierPrescripteurId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly dossierPrescripteurId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly expediteur?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly expediteur2?: Array<number>
}

/**
 * Request parameters for apiDossiersDossierFichiersPost operation in FichiersDossierApi.
 * @export
 * @interface FichiersDossierApiApiDossiersDossierFichiersPostRequest
 */
export interface FichiersDossierApiApiDossiersDossierFichiersPostRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly dossier: string

    /**
     * 
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly expediteur?: ApiDossiersDossierFichiersPostExpediteurEnum

    /**
     * 
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly expedition?: ApiDossiersDossierFichiersPostExpeditionEnum

    /**
     * 
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly idDocDf?: number

    /**
     * 
     * @type {string}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly typeDoc?: string

    /**
     * 
     * @type {FproDosDocFileDocumentfileCreate}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly docSigned?: FproDosDocFileDocumentfileCreate | null

    /**
     * 
     * @type {FproDosDocFileDocumentfileCreate}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly docOriginal?: FproDosDocFileDocumentfileCreate | null

    /**
     * 
     * @type {File}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly file?: File | null

    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly typageAuto?: boolean
}

/**
 * Request parameters for apiFichiersIdDelete operation in FichiersDossierApi.
 * @export
 * @interface FichiersDossierApiApiFichiersIdDeleteRequest
 */
export interface FichiersDossierApiApiFichiersIdDeleteRequest {
    /**
     * FproDosDocFile identifier
     * @type {string}
     * @memberof FichiersDossierApiApiFichiersIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiFichiersIdFileGet operation in FichiersDossierApi.
 * @export
 * @interface FichiersDossierApiApiFichiersIdFileGetRequest
 */
export interface FichiersDossierApiApiFichiersIdFileGetRequest {
    /**
     * FproDosDocFile identifier
     * @type {string}
     * @memberof FichiersDossierApiApiFichiersIdFileGet
     */
    readonly id: string
}

/**
 * Request parameters for apiFichiersIdGet operation in FichiersDossierApi.
 * @export
 * @interface FichiersDossierApiApiFichiersIdGetRequest
 */
export interface FichiersDossierApiApiFichiersIdGetRequest {
    /**
     * FproDosDocFile identifier
     * @type {string}
     * @memberof FichiersDossierApiApiFichiersIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiFichiersIdPatch operation in FichiersDossierApi.
 * @export
 * @interface FichiersDossierApiApiFichiersIdPatchRequest
 */
export interface FichiersDossierApiApiFichiersIdPatchRequest {
    /**
     * FproDosDocFile identifier
     * @type {string}
     * @memberof FichiersDossierApiApiFichiersIdPatch
     */
    readonly id: string

    /**
     * The updated Fichiers Dossier resource
     * @type {FichiersDossierDocumentfileUpdate}
     * @memberof FichiersDossierApiApiFichiersIdPatch
     */
    readonly fichiersDossierDocumentfileUpdate: FichiersDossierDocumentfileUpdate
}

/**
 * FichiersDossierApi - object-oriented interface
 * @export
 * @class FichiersDossierApi
 * @extends {BaseAPI}
 */
export class FichiersDossierApi extends BaseAPI {
    /**
     * Lister les fichiers liés au dossier
     * @summary Lister les fichiers liés au dossier
     * @param {FichiersDossierApiApiDossiersDossierFichiersGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FichiersDossierApi
     */
    public apiDossiersDossierFichiersGetCollection(requestParameters: FichiersDossierApiApiDossiersDossierFichiersGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return FichiersDossierApiFp(this.configuration).apiDossiersDossierFichiersGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, requestParameters.expediteur, requestParameters.expediteur2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ajouter un fichier au dossier
     * @summary Ajouter un fichier au dossier
     * @param {FichiersDossierApiApiDossiersDossierFichiersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FichiersDossierApi
     */
    public apiDossiersDossierFichiersPost(requestParameters: FichiersDossierApiApiDossiersDossierFichiersPostRequest, options?: RawAxiosRequestConfig) {
        return FichiersDossierApiFp(this.configuration).apiDossiersDossierFichiersPost(requestParameters.dossier, requestParameters.expediteur, requestParameters.expedition, requestParameters.idDocDf, requestParameters.typeDoc, requestParameters.docSigned, requestParameters.docOriginal, requestParameters.file, requestParameters.typageAuto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un fichier
     * @summary Supprimer un fichier
     * @param {FichiersDossierApiApiFichiersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FichiersDossierApi
     */
    public apiFichiersIdDelete(requestParameters: FichiersDossierApiApiFichiersIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return FichiersDossierApiFp(this.configuration).apiFichiersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Fichiers Dossier resource.
     * @summary Retrieves a Fichiers Dossier resource.
     * @param {FichiersDossierApiApiFichiersIdFileGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FichiersDossierApi
     */
    public apiFichiersIdFileGet(requestParameters: FichiersDossierApiApiFichiersIdFileGetRequest, options?: RawAxiosRequestConfig) {
        return FichiersDossierApiFp(this.configuration).apiFichiersIdFileGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Fichiers Dossier resource.
     * @summary Retrieves a Fichiers Dossier resource.
     * @param {FichiersDossierApiApiFichiersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FichiersDossierApi
     */
    public apiFichiersIdGet(requestParameters: FichiersDossierApiApiFichiersIdGetRequest, options?: RawAxiosRequestConfig) {
        return FichiersDossierApiFp(this.configuration).apiFichiersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Fichiers Dossier resource.
     * @summary Updates the Fichiers Dossier resource.
     * @param {FichiersDossierApiApiFichiersIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FichiersDossierApi
     */
    public apiFichiersIdPatch(requestParameters: FichiersDossierApiApiFichiersIdPatchRequest, options?: RawAxiosRequestConfig) {
        return FichiersDossierApiFp(this.configuration).apiFichiersIdPatch(requestParameters.id, requestParameters.fichiersDossierDocumentfileUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiDossiersDossierFichiersPostExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type ApiDossiersDossierFichiersPostExpediteurEnum = typeof ApiDossiersDossierFichiersPostExpediteurEnum[keyof typeof ApiDossiersDossierFichiersPostExpediteurEnum];
/**
 * @export
 */
export const ApiDossiersDossierFichiersPostExpeditionEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;
export type ApiDossiersDossierFichiersPostExpeditionEnum = typeof ApiDossiersDossierFichiersPostExpeditionEnum[keyof typeof ApiDossiersDossierFichiersPostExpeditionEnum];


/**
 * FormalisteApi - axios parameter creator
 * @export
 */
export const FormalisteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des formalistes
         * @summary Obtenir la liste des formalistes
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalistesGetCollection: async (page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/formalistes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un formaliste
         * @summary Récupérer les informations d\'un formaliste
         * @param {string} id TblUtilisateur identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalistesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormalistesIdGet', 'id', id)
            const localVarPath = `/formalite/formalistes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormalisteApi - functional programming interface
 * @export
 */
export const FormalisteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormalisteApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des formalistes
         * @summary Obtenir la liste des formalistes
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalistesGetCollection(page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFormalistesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalistesGetCollection(page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormalisteApi.apiFormalistesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un formaliste
         * @summary Récupérer les informations d\'un formaliste
         * @param {string} id TblUtilisateur identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalistesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormalisteJsonldFormalisteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalistesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormalisteApi.apiFormalistesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FormalisteApi - factory interface
 * @export
 */
export const FormalisteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormalisteApiFp(configuration)
    return {
        /**
         * Obtenir la liste des formalistes
         * @summary Obtenir la liste des formalistes
         * @param {FormalisteApiApiFormalistesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalistesGetCollection(requestParameters: FormalisteApiApiFormalistesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiFormalistesGetCollection200Response> {
            return localVarFp.apiFormalistesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un formaliste
         * @summary Récupérer les informations d\'un formaliste
         * @param {FormalisteApiApiFormalistesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalistesIdGet(requestParameters: FormalisteApiApiFormalistesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FormalisteJsonldFormalisteRead> {
            return localVarFp.apiFormalistesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFormalistesGetCollection operation in FormalisteApi.
 * @export
 * @interface FormalisteApiApiFormalistesGetCollectionRequest
 */
export interface FormalisteApiApiFormalistesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof FormalisteApiApiFormalistesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FormalisteApiApiFormalistesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiFormalistesIdGet operation in FormalisteApi.
 * @export
 * @interface FormalisteApiApiFormalistesIdGetRequest
 */
export interface FormalisteApiApiFormalistesIdGetRequest {
    /**
     * TblUtilisateur identifier
     * @type {string}
     * @memberof FormalisteApiApiFormalistesIdGet
     */
    readonly id: string
}

/**
 * FormalisteApi - object-oriented interface
 * @export
 * @class FormalisteApi
 * @extends {BaseAPI}
 */
export class FormalisteApi extends BaseAPI {
    /**
     * Obtenir la liste des formalistes
     * @summary Obtenir la liste des formalistes
     * @param {FormalisteApiApiFormalistesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormalisteApi
     */
    public apiFormalistesGetCollection(requestParameters: FormalisteApiApiFormalistesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return FormalisteApiFp(this.configuration).apiFormalistesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un formaliste
     * @summary Récupérer les informations d\'un formaliste
     * @param {FormalisteApiApiFormalistesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormalisteApi
     */
    public apiFormalistesIdGet(requestParameters: FormalisteApiApiFormalistesIdGetRequest, options?: RawAxiosRequestConfig) {
        return FormalisteApiFp(this.configuration).apiFormalistesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FormaliteApi - axios parameter creator
 * @export
 */
export const FormaliteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des formalites
         * @summary Obtenir la liste des formalites
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [categorieId] 
         * @param {Array<number>} [categorieId2] 
         * @param {ApiFormalitesGetCollectionOrderLibelleEnum} [orderLibelle] 
         * @param {ApiFormalitesGetCollectionOrderCategorieLibelleEnum} [orderCategorieLibelle] 
         * @param {boolean} [deleted] 
         * @param {number} [compatibleWith] Retrieve compatible formalities by formality ID
         * @param {number} [groupement] Filtre les formalités par groupement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesGetCollection: async (page?: number, itemsPerPage?: number, categorieId?: number, categorieId2?: Array<number>, orderLibelle?: ApiFormalitesGetCollectionOrderLibelleEnum, orderCategorieLibelle?: ApiFormalitesGetCollectionOrderCategorieLibelleEnum, deleted?: boolean, compatibleWith?: number, groupement?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/formalites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (categorieId !== undefined) {
                localVarQueryParameter['categorie.id'] = categorieId;
            }

            if (categorieId2) {
                localVarQueryParameter['categorie.id[]'] = categorieId2;
            }

            if (orderLibelle !== undefined) {
                localVarQueryParameter['order[libelle]'] = orderLibelle;
            }

            if (orderCategorieLibelle !== undefined) {
                localVarQueryParameter['order[categorie.libelle]'] = orderCategorieLibelle;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (compatibleWith !== undefined) {
                localVarQueryParameter['compatibleWith'] = compatibleWith;
            }

            if (groupement !== undefined) {
                localVarQueryParameter['groupement'] = groupement;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Suprimmer une formalité
         * @summary Suprimmer une formalité
         * @param {string} id FproFormaliteArticle identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormalitesIdDelete', 'id', id)
            const localVarPath = `/formalite/formalites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une formalite
         * @summary Récupérer les informations d\'une formalite
         * @param {string} id FproFormaliteArticle identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormalitesIdGet', 'id', id)
            const localVarPath = `/formalite/formalites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Formalite resource.
         * @summary Modifier une formalité
         * @param {string} id FproFormaliteArticle identifier
         * @param {FormaliteJsonldFormaliteUpdate} formaliteJsonldFormaliteUpdate The updated Formalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdPut: async (id: string, formaliteJsonldFormaliteUpdate: FormaliteJsonldFormaliteUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormalitesIdPut', 'id', id)
            // verify required parameter 'formaliteJsonldFormaliteUpdate' is not null or undefined
            assertParamExists('apiFormalitesIdPut', 'formaliteJsonldFormaliteUpdate', formaliteJsonldFormaliteUpdate)
            const localVarPath = `/formalite/formalites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formaliteJsonldFormaliteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Formalite resource.
         * @summary Créer une formalité
         * @param {FormaliteJsonldFormaliteCreate} formaliteJsonldFormaliteCreate The new Formalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesPost: async (formaliteJsonldFormaliteCreate: FormaliteJsonldFormaliteCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formaliteJsonldFormaliteCreate' is not null or undefined
            assertParamExists('apiFormalitesPost', 'formaliteJsonldFormaliteCreate', formaliteJsonldFormaliteCreate)
            const localVarPath = `/formalite/formalites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formaliteJsonldFormaliteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormaliteApi - functional programming interface
 * @export
 */
export const FormaliteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormaliteApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des formalites
         * @summary Obtenir la liste des formalites
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [categorieId] 
         * @param {Array<number>} [categorieId2] 
         * @param {ApiFormalitesGetCollectionOrderLibelleEnum} [orderLibelle] 
         * @param {ApiFormalitesGetCollectionOrderCategorieLibelleEnum} [orderCategorieLibelle] 
         * @param {boolean} [deleted] 
         * @param {number} [compatibleWith] Retrieve compatible formalities by formality ID
         * @param {number} [groupement] Filtre les formalités par groupement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalitesGetCollection(page?: number, itemsPerPage?: number, categorieId?: number, categorieId2?: Array<number>, orderLibelle?: ApiFormalitesGetCollectionOrderLibelleEnum, orderCategorieLibelle?: ApiFormalitesGetCollectionOrderCategorieLibelleEnum, deleted?: boolean, compatibleWith?: number, groupement?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFormalitesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalitesGetCollection(page, itemsPerPage, categorieId, categorieId2, orderLibelle, orderCategorieLibelle, deleted, compatibleWith, groupement, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormaliteApi.apiFormalitesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Suprimmer une formalité
         * @summary Suprimmer une formalité
         * @param {string} id FproFormaliteArticle identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalitesIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalitesIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormaliteApi.apiFormalitesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une formalite
         * @summary Récupérer les informations d\'une formalite
         * @param {string} id FproFormaliteArticle identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalitesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormaliteJsonldFormaliteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalitesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormaliteApi.apiFormalitesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Formalite resource.
         * @summary Modifier une formalité
         * @param {string} id FproFormaliteArticle identifier
         * @param {FormaliteJsonldFormaliteUpdate} formaliteJsonldFormaliteUpdate The updated Formalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalitesIdPut(id: string, formaliteJsonldFormaliteUpdate: FormaliteJsonldFormaliteUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormaliteJsonldFormaliteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalitesIdPut(id, formaliteJsonldFormaliteUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormaliteApi.apiFormalitesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Formalite resource.
         * @summary Créer une formalité
         * @param {FormaliteJsonldFormaliteCreate} formaliteJsonldFormaliteCreate The new Formalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalitesPost(formaliteJsonldFormaliteCreate: FormaliteJsonldFormaliteCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormaliteJsonldFormaliteCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalitesPost(formaliteJsonldFormaliteCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormaliteApi.apiFormalitesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FormaliteApi - factory interface
 * @export
 */
export const FormaliteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormaliteApiFp(configuration)
    return {
        /**
         * Obtenir la liste des formalites
         * @summary Obtenir la liste des formalites
         * @param {FormaliteApiApiFormalitesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesGetCollection(requestParameters: FormaliteApiApiFormalitesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiFormalitesGetCollection200Response> {
            return localVarFp.apiFormalitesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.categorieId, requestParameters.categorieId2, requestParameters.orderLibelle, requestParameters.orderCategorieLibelle, requestParameters.deleted, requestParameters.compatibleWith, requestParameters.groupement, options).then((request) => request(axios, basePath));
        },
        /**
         * Suprimmer une formalité
         * @summary Suprimmer une formalité
         * @param {FormaliteApiApiFormalitesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdDelete(requestParameters: FormaliteApiApiFormalitesIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiFormalitesIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une formalite
         * @summary Récupérer les informations d\'une formalite
         * @param {FormaliteApiApiFormalitesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdGet(requestParameters: FormaliteApiApiFormalitesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FormaliteJsonldFormaliteRead> {
            return localVarFp.apiFormalitesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Formalite resource.
         * @summary Modifier une formalité
         * @param {FormaliteApiApiFormalitesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdPut(requestParameters: FormaliteApiApiFormalitesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<FormaliteJsonldFormaliteRead> {
            return localVarFp.apiFormalitesIdPut(requestParameters.id, requestParameters.formaliteJsonldFormaliteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Formalite resource.
         * @summary Créer une formalité
         * @param {FormaliteApiApiFormalitesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesPost(requestParameters: FormaliteApiApiFormalitesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<FormaliteJsonldFormaliteCreated> {
            return localVarFp.apiFormalitesPost(requestParameters.formaliteJsonldFormaliteCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFormalitesGetCollection operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormalitesGetCollectionRequest
 */
export interface FormaliteApiApiFormalitesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly categorieId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly categorieId2?: Array<number>

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly orderLibelle?: ApiFormalitesGetCollectionOrderLibelleEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly orderCategorieLibelle?: ApiFormalitesGetCollectionOrderCategorieLibelleEnum

    /**
     * 
     * @type {boolean}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly deleted?: boolean

    /**
     * Retrieve compatible formalities by formality ID
     * @type {number}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly compatibleWith?: number

    /**
     * Filtre les formalités par groupement
     * @type {number}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly groupement?: number
}

/**
 * Request parameters for apiFormalitesIdDelete operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormalitesIdDeleteRequest
 */
export interface FormaliteApiApiFormalitesIdDeleteRequest {
    /**
     * FproFormaliteArticle identifier
     * @type {string}
     * @memberof FormaliteApiApiFormalitesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiFormalitesIdGet operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormalitesIdGetRequest
 */
export interface FormaliteApiApiFormalitesIdGetRequest {
    /**
     * FproFormaliteArticle identifier
     * @type {string}
     * @memberof FormaliteApiApiFormalitesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiFormalitesIdPut operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormalitesIdPutRequest
 */
export interface FormaliteApiApiFormalitesIdPutRequest {
    /**
     * FproFormaliteArticle identifier
     * @type {string}
     * @memberof FormaliteApiApiFormalitesIdPut
     */
    readonly id: string

    /**
     * The updated Formalite resource
     * @type {FormaliteJsonldFormaliteUpdate}
     * @memberof FormaliteApiApiFormalitesIdPut
     */
    readonly formaliteJsonldFormaliteUpdate: FormaliteJsonldFormaliteUpdate
}

/**
 * Request parameters for apiFormalitesPost operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormalitesPostRequest
 */
export interface FormaliteApiApiFormalitesPostRequest {
    /**
     * The new Formalite resource
     * @type {FormaliteJsonldFormaliteCreate}
     * @memberof FormaliteApiApiFormalitesPost
     */
    readonly formaliteJsonldFormaliteCreate: FormaliteJsonldFormaliteCreate
}

/**
 * FormaliteApi - object-oriented interface
 * @export
 * @class FormaliteApi
 * @extends {BaseAPI}
 */
export class FormaliteApi extends BaseAPI {
    /**
     * Obtenir la liste des formalites
     * @summary Obtenir la liste des formalites
     * @param {FormaliteApiApiFormalitesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormalitesGetCollection(requestParameters: FormaliteApiApiFormalitesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormalitesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.categorieId, requestParameters.categorieId2, requestParameters.orderLibelle, requestParameters.orderCategorieLibelle, requestParameters.deleted, requestParameters.compatibleWith, requestParameters.groupement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Suprimmer une formalité
     * @summary Suprimmer une formalité
     * @param {FormaliteApiApiFormalitesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormalitesIdDelete(requestParameters: FormaliteApiApiFormalitesIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormalitesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une formalite
     * @summary Récupérer les informations d\'une formalite
     * @param {FormaliteApiApiFormalitesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormalitesIdGet(requestParameters: FormaliteApiApiFormalitesIdGetRequest, options?: RawAxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormalitesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Formalite resource.
     * @summary Modifier une formalité
     * @param {FormaliteApiApiFormalitesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormalitesIdPut(requestParameters: FormaliteApiApiFormalitesIdPutRequest, options?: RawAxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormalitesIdPut(requestParameters.id, requestParameters.formaliteJsonldFormaliteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Formalite resource.
     * @summary Créer une formalité
     * @param {FormaliteApiApiFormalitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormalitesPost(requestParameters: FormaliteApiApiFormalitesPostRequest, options?: RawAxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormalitesPost(requestParameters.formaliteJsonldFormaliteCreate, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiFormalitesGetCollectionOrderLibelleEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiFormalitesGetCollectionOrderLibelleEnum = typeof ApiFormalitesGetCollectionOrderLibelleEnum[keyof typeof ApiFormalitesGetCollectionOrderLibelleEnum];
/**
 * @export
 */
export const ApiFormalitesGetCollectionOrderCategorieLibelleEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiFormalitesGetCollectionOrderCategorieLibelleEnum = typeof ApiFormalitesGetCollectionOrderCategorieLibelleEnum[keyof typeof ApiFormalitesGetCollectionOrderCategorieLibelleEnum];


/**
 * FormeJuridiqueApi - axios parameter creator
 * @export
 */
export const FormeJuridiqueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des formes juridiques
         * @summary Obtenir la liste des formes juridiques
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [nomenclature] 
         * @param {Array<string>} [nomenclature2] 
         * @param {string} [abreviation] 
         * @param {Array<string>} [abreviation2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormesJuridiquesGetCollection: async (page?: number, itemsPerPage?: number, nomenclature?: string, nomenclature2?: Array<string>, abreviation?: string, abreviation2?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/formes-juridiques`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (nomenclature !== undefined) {
                localVarQueryParameter['nomenclature'] = nomenclature;
            }

            if (nomenclature2) {
                localVarQueryParameter['nomenclature[]'] = nomenclature2;
            }

            if (abreviation !== undefined) {
                localVarQueryParameter['abreviation'] = abreviation;
            }

            if (abreviation2) {
                localVarQueryParameter['abreviation[]'] = abreviation2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une forme juridique
         * @summary Récupérer les informations d\'une forme juridique
         * @param {string} id TblAnnonceSTypeDetail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormesJuridiquesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormesJuridiquesIdGet', 'id', id)
            const localVarPath = `/formalite/formes-juridiques/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormeJuridiqueApi - functional programming interface
 * @export
 */
export const FormeJuridiqueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormeJuridiqueApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des formes juridiques
         * @summary Obtenir la liste des formes juridiques
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [nomenclature] 
         * @param {Array<string>} [nomenclature2] 
         * @param {string} [abreviation] 
         * @param {Array<string>} [abreviation2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormesJuridiquesGetCollection(page?: number, itemsPerPage?: number, nomenclature?: string, nomenclature2?: Array<string>, abreviation?: string, abreviation2?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFormesJuridiquesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormesJuridiquesGetCollection(page, itemsPerPage, nomenclature, nomenclature2, abreviation, abreviation2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormeJuridiqueApi.apiFormesJuridiquesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une forme juridique
         * @summary Récupérer les informations d\'une forme juridique
         * @param {string} id TblAnnonceSTypeDetail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormesJuridiquesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormeJuridiqueJsonldFormejuridiqueRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormesJuridiquesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormeJuridiqueApi.apiFormesJuridiquesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FormeJuridiqueApi - factory interface
 * @export
 */
export const FormeJuridiqueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormeJuridiqueApiFp(configuration)
    return {
        /**
         * Obtenir la liste des formes juridiques
         * @summary Obtenir la liste des formes juridiques
         * @param {FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormesJuridiquesGetCollection(requestParameters: FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiFormesJuridiquesGetCollection200Response> {
            return localVarFp.apiFormesJuridiquesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.nomenclature, requestParameters.nomenclature2, requestParameters.abreviation, requestParameters.abreviation2, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une forme juridique
         * @summary Récupérer les informations d\'une forme juridique
         * @param {FormeJuridiqueApiApiFormesJuridiquesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormesJuridiquesIdGet(requestParameters: FormeJuridiqueApiApiFormesJuridiquesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FormeJuridiqueJsonldFormejuridiqueRead> {
            return localVarFp.apiFormesJuridiquesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFormesJuridiquesGetCollection operation in FormeJuridiqueApi.
 * @export
 * @interface FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest
 */
export interface FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesGetCollection
     */
    readonly nomenclature?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesGetCollection
     */
    readonly nomenclature2?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesGetCollection
     */
    readonly abreviation?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesGetCollection
     */
    readonly abreviation2?: Array<string>
}

/**
 * Request parameters for apiFormesJuridiquesIdGet operation in FormeJuridiqueApi.
 * @export
 * @interface FormeJuridiqueApiApiFormesJuridiquesIdGetRequest
 */
export interface FormeJuridiqueApiApiFormesJuridiquesIdGetRequest {
    /**
     * TblAnnonceSTypeDetail identifier
     * @type {string}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesIdGet
     */
    readonly id: string
}

/**
 * FormeJuridiqueApi - object-oriented interface
 * @export
 * @class FormeJuridiqueApi
 * @extends {BaseAPI}
 */
export class FormeJuridiqueApi extends BaseAPI {
    /**
     * Obtenir la liste des formes juridiques
     * @summary Obtenir la liste des formes juridiques
     * @param {FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormeJuridiqueApi
     */
    public apiFormesJuridiquesGetCollection(requestParameters: FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return FormeJuridiqueApiFp(this.configuration).apiFormesJuridiquesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.nomenclature, requestParameters.nomenclature2, requestParameters.abreviation, requestParameters.abreviation2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une forme juridique
     * @summary Récupérer les informations d\'une forme juridique
     * @param {FormeJuridiqueApiApiFormesJuridiquesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormeJuridiqueApi
     */
    public apiFormesJuridiquesIdGet(requestParameters: FormeJuridiqueApiApiFormesJuridiquesIdGetRequest, options?: RawAxiosRequestConfig) {
        return FormeJuridiqueApiFp(this.configuration).apiFormesJuridiquesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GreffeApi - axios parameter creator
 * @export
 */
export const GreffeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des greffes
         * @summary Obtenir la liste des greffes
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dep] 
         * @param {Array<string>} [dep2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGreffesGetCollection: async (page?: number, itemsPerPage?: number, dep?: string, dep2?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/greffes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (dep !== undefined) {
                localVarQueryParameter['dep'] = dep;
            }

            if (dep2) {
                localVarQueryParameter['dep[]'] = dep2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un greffe
         * @summary Récupérer les informations d\'un greffe
         * @param {string} id TblGreffes identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGreffesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiGreffesIdGet', 'id', id)
            const localVarPath = `/formalite/greffes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GreffeApi - functional programming interface
 * @export
 */
export const GreffeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GreffeApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des greffes
         * @summary Obtenir la liste des greffes
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dep] 
         * @param {Array<string>} [dep2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGreffesGetCollection(page?: number, itemsPerPage?: number, dep?: string, dep2?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGreffesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGreffesGetCollection(page, itemsPerPage, dep, dep2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GreffeApi.apiGreffesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un greffe
         * @summary Récupérer les informations d\'un greffe
         * @param {string} id TblGreffes identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGreffesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GreffeJsonldGreffeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGreffesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GreffeApi.apiGreffesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GreffeApi - factory interface
 * @export
 */
export const GreffeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GreffeApiFp(configuration)
    return {
        /**
         * Obtenir la liste des greffes
         * @summary Obtenir la liste des greffes
         * @param {GreffeApiApiGreffesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGreffesGetCollection(requestParameters: GreffeApiApiGreffesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiGreffesGetCollection200Response> {
            return localVarFp.apiGreffesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dep, requestParameters.dep2, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un greffe
         * @summary Récupérer les informations d\'un greffe
         * @param {GreffeApiApiGreffesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGreffesIdGet(requestParameters: GreffeApiApiGreffesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GreffeJsonldGreffeRead> {
            return localVarFp.apiGreffesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiGreffesGetCollection operation in GreffeApi.
 * @export
 * @interface GreffeApiApiGreffesGetCollectionRequest
 */
export interface GreffeApiApiGreffesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof GreffeApiApiGreffesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof GreffeApiApiGreffesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof GreffeApiApiGreffesGetCollection
     */
    readonly dep?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof GreffeApiApiGreffesGetCollection
     */
    readonly dep2?: Array<string>
}

/**
 * Request parameters for apiGreffesIdGet operation in GreffeApi.
 * @export
 * @interface GreffeApiApiGreffesIdGetRequest
 */
export interface GreffeApiApiGreffesIdGetRequest {
    /**
     * TblGreffes identifier
     * @type {string}
     * @memberof GreffeApiApiGreffesIdGet
     */
    readonly id: string
}

/**
 * GreffeApi - object-oriented interface
 * @export
 * @class GreffeApi
 * @extends {BaseAPI}
 */
export class GreffeApi extends BaseAPI {
    /**
     * Obtenir la liste des greffes
     * @summary Obtenir la liste des greffes
     * @param {GreffeApiApiGreffesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GreffeApi
     */
    public apiGreffesGetCollection(requestParameters: GreffeApiApiGreffesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return GreffeApiFp(this.configuration).apiGreffesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dep, requestParameters.dep2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un greffe
     * @summary Récupérer les informations d\'un greffe
     * @param {GreffeApiApiGreffesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GreffeApi
     */
    public apiGreffesIdGet(requestParameters: GreffeApiApiGreffesIdGetRequest, options?: RawAxiosRequestConfig) {
        return GreffeApiFp(this.configuration).apiGreffesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des messages
         * @summary Obtenir la liste des messages
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [expediteur] 
         * @param {Array<number>} [expediteur2] 
         * @param {boolean} [readByPrescri] 
         * @param {boolean} [readByFrmlist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierMessagesGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, expediteur?: number, expediteur2?: Array<number>, readByPrescri?: boolean, readByFrmlist?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierMessagesGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/messages`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (expediteur !== undefined) {
                localVarQueryParameter['expediteur'] = expediteur;
            }

            if (expediteur2) {
                localVarQueryParameter['expediteur[]'] = expediteur2;
            }

            if (readByPrescri !== undefined) {
                localVarQueryParameter['readByPrescri'] = readByPrescri;
            }

            if (readByFrmlist !== undefined) {
                localVarQueryParameter['readByFrmlist'] = readByFrmlist;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Message resource.
         * @summary Modifier un message
         * @param {string} id FproDosMessage identifier
         * @param {MessageJsonld} messageJsonld The updated Message resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesIdAckPut: async (id: string, messageJsonld: MessageJsonld, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMessagesIdAckPut', 'id', id)
            // verify required parameter 'messageJsonld' is not null or undefined
            assertParamExists('apiMessagesIdAckPut', 'messageJsonld', messageJsonld)
            const localVarPath = `/formalite/messages/{id}/ack`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un message
         * @summary Supprimer un message
         * @param {string} id FproDosMessage identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMessagesIdDelete', 'id', id)
            const localVarPath = `/formalite/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un message
         * @summary Récupérer les informations d\'un message
         * @param {string} id FproDosMessage identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMessagesIdGet', 'id', id)
            const localVarPath = `/formalite/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Message resource.
         * @summary Créer un message
         * @param {MessageJsonldMessageCreate} messageJsonldMessageCreate The new Message resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPost: async (messageJsonldMessageCreate: MessageJsonldMessageCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageJsonldMessageCreate' is not null or undefined
            assertParamExists('apiMessagesPost', 'messageJsonldMessageCreate', messageJsonldMessageCreate)
            const localVarPath = `/formalite/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageJsonldMessageCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des messages
         * @summary Obtenir la liste des messages
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [expediteur] 
         * @param {Array<number>} [expediteur2] 
         * @param {boolean} [readByPrescri] 
         * @param {boolean} [readByFrmlist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierMessagesGetCollection(dossier: string, page?: number, itemsPerPage?: number, expediteur?: number, expediteur2?: Array<number>, readByPrescri?: boolean, readByFrmlist?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierMessagesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierMessagesGetCollection(dossier, page, itemsPerPage, expediteur, expediteur2, readByPrescri, readByFrmlist, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageApi.apiDossiersDossierMessagesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Message resource.
         * @summary Modifier un message
         * @param {string} id FproDosMessage identifier
         * @param {MessageJsonld} messageJsonld The updated Message resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesIdAckPut(id: string, messageJsonld: MessageJsonld, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageJsonldMessageRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesIdAckPut(id, messageJsonld, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageApi.apiMessagesIdAckPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer un message
         * @summary Supprimer un message
         * @param {string} id FproDosMessage identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageApi.apiMessagesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un message
         * @summary Récupérer les informations d\'un message
         * @param {string} id FproDosMessage identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageJsonldMessageRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageApi.apiMessagesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Message resource.
         * @summary Créer un message
         * @param {MessageJsonldMessageCreate} messageJsonldMessageCreate The new Message resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesPost(messageJsonldMessageCreate: MessageJsonldMessageCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageJsonldMessageCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesPost(messageJsonldMessageCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageApi.apiMessagesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration)
    return {
        /**
         * Obtenir la liste des messages
         * @summary Obtenir la liste des messages
         * @param {MessageApiApiDossiersDossierMessagesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierMessagesGetCollection(requestParameters: MessageApiApiDossiersDossierMessagesGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersDossierMessagesGetCollection200Response> {
            return localVarFp.apiDossiersDossierMessagesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.expediteur, requestParameters.expediteur2, requestParameters.readByPrescri, requestParameters.readByFrmlist, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Message resource.
         * @summary Modifier un message
         * @param {MessageApiApiMessagesIdAckPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesIdAckPut(requestParameters: MessageApiApiMessagesIdAckPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<MessageJsonldMessageRead> {
            return localVarFp.apiMessagesIdAckPut(requestParameters.id, requestParameters.messageJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un message
         * @summary Supprimer un message
         * @param {MessageApiApiMessagesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesIdDelete(requestParameters: MessageApiApiMessagesIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiMessagesIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un message
         * @summary Récupérer les informations d\'un message
         * @param {MessageApiApiMessagesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesIdGet(requestParameters: MessageApiApiMessagesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<MessageJsonldMessageRead> {
            return localVarFp.apiMessagesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Message resource.
         * @summary Créer un message
         * @param {MessageApiApiMessagesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPost(requestParameters: MessageApiApiMessagesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<MessageJsonldMessageCreated> {
            return localVarFp.apiMessagesPost(requestParameters.messageJsonldMessageCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierMessagesGetCollection operation in MessageApi.
 * @export
 * @interface MessageApiApiDossiersDossierMessagesGetCollectionRequest
 */
export interface MessageApiApiDossiersDossierMessagesGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly expediteur?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly expediteur2?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly readByPrescri?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly readByFrmlist?: boolean
}

/**
 * Request parameters for apiMessagesIdAckPut operation in MessageApi.
 * @export
 * @interface MessageApiApiMessagesIdAckPutRequest
 */
export interface MessageApiApiMessagesIdAckPutRequest {
    /**
     * FproDosMessage identifier
     * @type {string}
     * @memberof MessageApiApiMessagesIdAckPut
     */
    readonly id: string

    /**
     * The updated Message resource
     * @type {MessageJsonld}
     * @memberof MessageApiApiMessagesIdAckPut
     */
    readonly messageJsonld: MessageJsonld
}

/**
 * Request parameters for apiMessagesIdDelete operation in MessageApi.
 * @export
 * @interface MessageApiApiMessagesIdDeleteRequest
 */
export interface MessageApiApiMessagesIdDeleteRequest {
    /**
     * FproDosMessage identifier
     * @type {string}
     * @memberof MessageApiApiMessagesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiMessagesIdGet operation in MessageApi.
 * @export
 * @interface MessageApiApiMessagesIdGetRequest
 */
export interface MessageApiApiMessagesIdGetRequest {
    /**
     * FproDosMessage identifier
     * @type {string}
     * @memberof MessageApiApiMessagesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiMessagesPost operation in MessageApi.
 * @export
 * @interface MessageApiApiMessagesPostRequest
 */
export interface MessageApiApiMessagesPostRequest {
    /**
     * The new Message resource
     * @type {MessageJsonldMessageCreate}
     * @memberof MessageApiApiMessagesPost
     */
    readonly messageJsonldMessageCreate: MessageJsonldMessageCreate
}

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * Obtenir la liste des messages
     * @summary Obtenir la liste des messages
     * @param {MessageApiApiDossiersDossierMessagesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiDossiersDossierMessagesGetCollection(requestParameters: MessageApiApiDossiersDossierMessagesGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiDossiersDossierMessagesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.expediteur, requestParameters.expediteur2, requestParameters.readByPrescri, requestParameters.readByFrmlist, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Message resource.
     * @summary Modifier un message
     * @param {MessageApiApiMessagesIdAckPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesIdAckPut(requestParameters: MessageApiApiMessagesIdAckPutRequest, options?: RawAxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesIdAckPut(requestParameters.id, requestParameters.messageJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un message
     * @summary Supprimer un message
     * @param {MessageApiApiMessagesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesIdDelete(requestParameters: MessageApiApiMessagesIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un message
     * @summary Récupérer les informations d\'un message
     * @param {MessageApiApiMessagesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesIdGet(requestParameters: MessageApiApiMessagesIdGetRequest, options?: RawAxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Message resource.
     * @summary Créer un message
     * @param {MessageApiApiMessagesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesPost(requestParameters: MessageApiApiMessagesPostRequest, options?: RawAxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesPost(requestParameters.messageJsonldMessageCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotesApi - axios parameter creator
 * @export
 */
export const NotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des notes
         * @summary Obtenir la liste des notes
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierNotesGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierNotesGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/notes`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une note
         * @summary Récupérer les informations d\'une note
         * @param {string} id TblEvenement identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNotesIdGet', 'id', id)
            const localVarPath = `/formalite/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Notes et rappels resource.
         * @summary Modifier une note
         * @param {string} id TblEvenement identifier
         * @param {NotesEtRappelsJsonldNoteUpdate} notesEtRappelsJsonldNoteUpdate The updated Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesIdPut: async (id: string, notesEtRappelsJsonldNoteUpdate: NotesEtRappelsJsonldNoteUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNotesIdPut', 'id', id)
            // verify required parameter 'notesEtRappelsJsonldNoteUpdate' is not null or undefined
            assertParamExists('apiNotesIdPut', 'notesEtRappelsJsonldNoteUpdate', notesEtRappelsJsonldNoteUpdate)
            const localVarPath = `/formalite/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notesEtRappelsJsonldNoteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Notes et rappels resource.
         * @summary Créer une note
         * @param {NotesEtRappelsJsonldNoteCreate} notesEtRappelsJsonldNoteCreate The new Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesPost: async (notesEtRappelsJsonldNoteCreate: NotesEtRappelsJsonldNoteCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notesEtRappelsJsonldNoteCreate' is not null or undefined
            assertParamExists('apiNotesPost', 'notesEtRappelsJsonldNoteCreate', notesEtRappelsJsonldNoteCreate)
            const localVarPath = `/formalite/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notesEtRappelsJsonldNoteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotesApi - functional programming interface
 * @export
 */
export const NotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotesApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des notes
         * @summary Obtenir la liste des notes
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierNotesGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierNotesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierNotesGetCollection(dossier, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotesApi.apiDossiersDossierNotesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une note
         * @summary Récupérer les informations d\'une note
         * @param {string} id TblEvenement identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesEtRappelsJsonldNoteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotesApi.apiNotesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Notes et rappels resource.
         * @summary Modifier une note
         * @param {string} id TblEvenement identifier
         * @param {NotesEtRappelsJsonldNoteUpdate} notesEtRappelsJsonldNoteUpdate The updated Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotesIdPut(id: string, notesEtRappelsJsonldNoteUpdate: NotesEtRappelsJsonldNoteUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesEtRappelsJsonldNoteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotesIdPut(id, notesEtRappelsJsonldNoteUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotesApi.apiNotesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Notes et rappels resource.
         * @summary Créer une note
         * @param {NotesEtRappelsJsonldNoteCreate} notesEtRappelsJsonldNoteCreate The new Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotesPost(notesEtRappelsJsonldNoteCreate: NotesEtRappelsJsonldNoteCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesEtRappelsJsonldNoteCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotesPost(notesEtRappelsJsonldNoteCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotesApi.apiNotesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotesApi - factory interface
 * @export
 */
export const NotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotesApiFp(configuration)
    return {
        /**
         * Obtenir la liste des notes
         * @summary Obtenir la liste des notes
         * @param {NotesApiApiDossiersDossierNotesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierNotesGetCollection(requestParameters: NotesApiApiDossiersDossierNotesGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersDossierNotesGetCollection200Response> {
            return localVarFp.apiDossiersDossierNotesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une note
         * @summary Récupérer les informations d\'une note
         * @param {NotesApiApiNotesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesIdGet(requestParameters: NotesApiApiNotesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<NotesEtRappelsJsonldNoteRead> {
            return localVarFp.apiNotesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Notes et rappels resource.
         * @summary Modifier une note
         * @param {NotesApiApiNotesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesIdPut(requestParameters: NotesApiApiNotesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<NotesEtRappelsJsonldNoteRead> {
            return localVarFp.apiNotesIdPut(requestParameters.id, requestParameters.notesEtRappelsJsonldNoteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Notes et rappels resource.
         * @summary Créer une note
         * @param {NotesApiApiNotesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesPost(requestParameters: NotesApiApiNotesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<NotesEtRappelsJsonldNoteCreated> {
            return localVarFp.apiNotesPost(requestParameters.notesEtRappelsJsonldNoteCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierNotesGetCollection operation in NotesApi.
 * @export
 * @interface NotesApiApiDossiersDossierNotesGetCollectionRequest
 */
export interface NotesApiApiDossiersDossierNotesGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof NotesApiApiDossiersDossierNotesGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof NotesApiApiDossiersDossierNotesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof NotesApiApiDossiersDossierNotesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiNotesIdGet operation in NotesApi.
 * @export
 * @interface NotesApiApiNotesIdGetRequest
 */
export interface NotesApiApiNotesIdGetRequest {
    /**
     * TblEvenement identifier
     * @type {string}
     * @memberof NotesApiApiNotesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiNotesIdPut operation in NotesApi.
 * @export
 * @interface NotesApiApiNotesIdPutRequest
 */
export interface NotesApiApiNotesIdPutRequest {
    /**
     * TblEvenement identifier
     * @type {string}
     * @memberof NotesApiApiNotesIdPut
     */
    readonly id: string

    /**
     * The updated Notes et rappels resource
     * @type {NotesEtRappelsJsonldNoteUpdate}
     * @memberof NotesApiApiNotesIdPut
     */
    readonly notesEtRappelsJsonldNoteUpdate: NotesEtRappelsJsonldNoteUpdate
}

/**
 * Request parameters for apiNotesPost operation in NotesApi.
 * @export
 * @interface NotesApiApiNotesPostRequest
 */
export interface NotesApiApiNotesPostRequest {
    /**
     * The new Notes et rappels resource
     * @type {NotesEtRappelsJsonldNoteCreate}
     * @memberof NotesApiApiNotesPost
     */
    readonly notesEtRappelsJsonldNoteCreate: NotesEtRappelsJsonldNoteCreate
}

/**
 * NotesApi - object-oriented interface
 * @export
 * @class NotesApi
 * @extends {BaseAPI}
 */
export class NotesApi extends BaseAPI {
    /**
     * Obtenir la liste des notes
     * @summary Obtenir la liste des notes
     * @param {NotesApiApiDossiersDossierNotesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public apiDossiersDossierNotesGetCollection(requestParameters: NotesApiApiDossiersDossierNotesGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return NotesApiFp(this.configuration).apiDossiersDossierNotesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une note
     * @summary Récupérer les informations d\'une note
     * @param {NotesApiApiNotesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public apiNotesIdGet(requestParameters: NotesApiApiNotesIdGetRequest, options?: RawAxiosRequestConfig) {
        return NotesApiFp(this.configuration).apiNotesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Notes et rappels resource.
     * @summary Modifier une note
     * @param {NotesApiApiNotesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public apiNotesIdPut(requestParameters: NotesApiApiNotesIdPutRequest, options?: RawAxiosRequestConfig) {
        return NotesApiFp(this.configuration).apiNotesIdPut(requestParameters.id, requestParameters.notesEtRappelsJsonldNoteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Notes et rappels resource.
     * @summary Créer une note
     * @param {NotesApiApiNotesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public apiNotesPost(requestParameters: NotesApiApiNotesPostRequest, options?: RawAxiosRequestConfig) {
        return NotesApiFp(this.configuration).apiNotesPost(requestParameters.notesEtRappelsJsonldNoteCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ObjectGroupsApi - axios parameter creator
 * @export
 */
export const ObjectGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les dossiers
         * @summary Lister les dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [label] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectGroupsGetCollection: async (page?: number, itemsPerPage?: number, label?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/object-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un groupe de dossier
         * @summary Récupérer les informations d\'un groupe de dossier
         * @param {string} id TblObjectGroup identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectGroupsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiObjectGroupsIdGet', 'id', id)
            const localVarPath = `/formalite/object-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un groupe de dossier
         * @summary Créer un groupe de dossier
         * @param {ObjectGroupsJsonldObjectGroupCreate} objectGroupsJsonldObjectGroupCreate The new Object groups resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectGroupsPost: async (objectGroupsJsonldObjectGroupCreate: ObjectGroupsJsonldObjectGroupCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectGroupsJsonldObjectGroupCreate' is not null or undefined
            assertParamExists('apiObjectGroupsPost', 'objectGroupsJsonldObjectGroupCreate', objectGroupsJsonldObjectGroupCreate)
            const localVarPath = `/formalite/object-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectGroupsJsonldObjectGroupCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObjectGroupsApi - functional programming interface
 * @export
 */
export const ObjectGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObjectGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les dossiers
         * @summary Lister les dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [label] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiObjectGroupsGetCollection(page?: number, itemsPerPage?: number, label?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiObjectGroupsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiObjectGroupsGetCollection(page, itemsPerPage, label, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ObjectGroupsApi.apiObjectGroupsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un groupe de dossier
         * @summary Récupérer les informations d\'un groupe de dossier
         * @param {string} id TblObjectGroup identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiObjectGroupsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectGroupsJsonldObjectGroupRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiObjectGroupsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ObjectGroupsApi.apiObjectGroupsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer un groupe de dossier
         * @summary Créer un groupe de dossier
         * @param {ObjectGroupsJsonldObjectGroupCreate} objectGroupsJsonldObjectGroupCreate The new Object groups resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiObjectGroupsPost(objectGroupsJsonldObjectGroupCreate: ObjectGroupsJsonldObjectGroupCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectGroupsJsonldObjectGroupRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiObjectGroupsPost(objectGroupsJsonldObjectGroupCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ObjectGroupsApi.apiObjectGroupsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ObjectGroupsApi - factory interface
 * @export
 */
export const ObjectGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObjectGroupsApiFp(configuration)
    return {
        /**
         * Lister les dossiers
         * @summary Lister les dossiers
         * @param {ObjectGroupsApiApiObjectGroupsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectGroupsGetCollection(requestParameters: ObjectGroupsApiApiObjectGroupsGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiObjectGroupsGetCollection200Response> {
            return localVarFp.apiObjectGroupsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.label, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un groupe de dossier
         * @summary Récupérer les informations d\'un groupe de dossier
         * @param {ObjectGroupsApiApiObjectGroupsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectGroupsIdGet(requestParameters: ObjectGroupsApiApiObjectGroupsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ObjectGroupsJsonldObjectGroupRead> {
            return localVarFp.apiObjectGroupsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un groupe de dossier
         * @summary Créer un groupe de dossier
         * @param {ObjectGroupsApiApiObjectGroupsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectGroupsPost(requestParameters: ObjectGroupsApiApiObjectGroupsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ObjectGroupsJsonldObjectGroupRead> {
            return localVarFp.apiObjectGroupsPost(requestParameters.objectGroupsJsonldObjectGroupCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiObjectGroupsGetCollection operation in ObjectGroupsApi.
 * @export
 * @interface ObjectGroupsApiApiObjectGroupsGetCollectionRequest
 */
export interface ObjectGroupsApiApiObjectGroupsGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof ObjectGroupsApiApiObjectGroupsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ObjectGroupsApiApiObjectGroupsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof ObjectGroupsApiApiObjectGroupsGetCollection
     */
    readonly label?: string
}

/**
 * Request parameters for apiObjectGroupsIdGet operation in ObjectGroupsApi.
 * @export
 * @interface ObjectGroupsApiApiObjectGroupsIdGetRequest
 */
export interface ObjectGroupsApiApiObjectGroupsIdGetRequest {
    /**
     * TblObjectGroup identifier
     * @type {string}
     * @memberof ObjectGroupsApiApiObjectGroupsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiObjectGroupsPost operation in ObjectGroupsApi.
 * @export
 * @interface ObjectGroupsApiApiObjectGroupsPostRequest
 */
export interface ObjectGroupsApiApiObjectGroupsPostRequest {
    /**
     * The new Object groups resource
     * @type {ObjectGroupsJsonldObjectGroupCreate}
     * @memberof ObjectGroupsApiApiObjectGroupsPost
     */
    readonly objectGroupsJsonldObjectGroupCreate: ObjectGroupsJsonldObjectGroupCreate
}

/**
 * ObjectGroupsApi - object-oriented interface
 * @export
 * @class ObjectGroupsApi
 * @extends {BaseAPI}
 */
export class ObjectGroupsApi extends BaseAPI {
    /**
     * Lister les dossiers
     * @summary Lister les dossiers
     * @param {ObjectGroupsApiApiObjectGroupsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectGroupsApi
     */
    public apiObjectGroupsGetCollection(requestParameters: ObjectGroupsApiApiObjectGroupsGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return ObjectGroupsApiFp(this.configuration).apiObjectGroupsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.label, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un groupe de dossier
     * @summary Récupérer les informations d\'un groupe de dossier
     * @param {ObjectGroupsApiApiObjectGroupsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectGroupsApi
     */
    public apiObjectGroupsIdGet(requestParameters: ObjectGroupsApiApiObjectGroupsIdGetRequest, options?: RawAxiosRequestConfig) {
        return ObjectGroupsApiFp(this.configuration).apiObjectGroupsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un groupe de dossier
     * @summary Créer un groupe de dossier
     * @param {ObjectGroupsApiApiObjectGroupsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectGroupsApi
     */
    public apiObjectGroupsPost(requestParameters: ObjectGroupsApiApiObjectGroupsPostRequest, options?: RawAxiosRequestConfig) {
        return ObjectGroupsApiFp(this.configuration).apiObjectGroupsPost(requestParameters.objectGroupsJsonldObjectGroupCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrigineApi - axios parameter creator
 * @export
 */
export const OrigineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * hidden
         * @summary Retrieves a Origine resource.
         * @param {string} id TblOrigine identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesIdFormatGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOriginesIdFormatGet', 'id', id)
            const localVarPath = `/formalite/origines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrigineApi - functional programming interface
 * @export
 */
export const OrigineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrigineApiAxiosParamCreator(configuration)
    return {
        /**
         * hidden
         * @summary Retrieves a Origine resource.
         * @param {string} id TblOrigine identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOriginesIdFormatGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOriginesIdFormatGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrigineApi.apiOriginesIdFormatGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrigineApi - factory interface
 * @export
 */
export const OrigineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrigineApiFp(configuration)
    return {
        /**
         * hidden
         * @summary Retrieves a Origine resource.
         * @param {OrigineApiApiOriginesIdFormatGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesIdFormatGet(requestParameters: OrigineApiApiOriginesIdFormatGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.apiOriginesIdFormatGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOriginesIdFormatGet operation in OrigineApi.
 * @export
 * @interface OrigineApiApiOriginesIdFormatGetRequest
 */
export interface OrigineApiApiOriginesIdFormatGetRequest {
    /**
     * TblOrigine identifier
     * @type {string}
     * @memberof OrigineApiApiOriginesIdFormatGet
     */
    readonly id: string
}

/**
 * OrigineApi - object-oriented interface
 * @export
 * @class OrigineApi
 * @extends {BaseAPI}
 */
export class OrigineApi extends BaseAPI {
    /**
     * hidden
     * @summary Retrieves a Origine resource.
     * @param {OrigineApiApiOriginesIdFormatGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrigineApi
     */
    public apiOriginesIdFormatGet(requestParameters: OrigineApiApiOriginesIdFormatGetRequest, options?: RawAxiosRequestConfig) {
        return OrigineApiFp(this.configuration).apiOriginesIdFormatGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PrestationApi - axios parameter creator
 * @export
 */
export const PrestationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des prestations du dossier
         * @summary Obtenir la liste des prestations du dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {string} [libelle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierPrestationsGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, dossierNumero?: string, libelle?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierPrestationsGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/prestations`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (dossierNumero !== undefined) {
                localVarQueryParameter['dossier.numero'] = dossierNumero;
            }

            if (libelle !== undefined) {
                localVarQueryParameter['libelle'] = libelle;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les prestations
         * @summary Lister les prestations
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {string} [libelle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrestationsGetCollection: async (page?: number, itemsPerPage?: number, dossierNumero?: string, libelle?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/prestations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (dossierNumero !== undefined) {
                localVarQueryParameter['dossier.numero'] = dossierNumero;
            }

            if (libelle !== undefined) {
                localVarQueryParameter['libelle'] = libelle;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une prestation
         * @summary Récupérer les informations d\'une prestation
         * @param {string} id FproPrestation identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrestationsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPrestationsIdGet', 'id', id)
            const localVarPath = `/formalite/prestations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les informations d\'une prestation
         * @summary Mettre à jour les informations d\'une prestation
         * @param {string} id FproPrestation identifier
         * @param {PrestationJsonldPrestationUpdate} prestationJsonldPrestationUpdate The updated Prestation resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrestationsIdPut: async (id: string, prestationJsonldPrestationUpdate: PrestationJsonldPrestationUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPrestationsIdPut', 'id', id)
            // verify required parameter 'prestationJsonldPrestationUpdate' is not null or undefined
            assertParamExists('apiPrestationsIdPut', 'prestationJsonldPrestationUpdate', prestationJsonldPrestationUpdate)
            const localVarPath = `/formalite/prestations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(prestationJsonldPrestationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrestationApi - functional programming interface
 * @export
 */
export const PrestationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrestationApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des prestations du dossier
         * @summary Obtenir la liste des prestations du dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {string} [libelle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierPrestationsGetCollection(dossier: string, page?: number, itemsPerPage?: number, dossierNumero?: string, libelle?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierPrestationsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierPrestationsGetCollection(dossier, page, itemsPerPage, dossierNumero, libelle, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrestationApi.apiDossiersDossierPrestationsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les prestations
         * @summary Lister les prestations
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {string} [libelle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrestationsGetCollection(page?: number, itemsPerPage?: number, dossierNumero?: string, libelle?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierPrestationsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrestationsGetCollection(page, itemsPerPage, dossierNumero, libelle, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrestationApi.apiPrestationsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une prestation
         * @summary Récupérer les informations d\'une prestation
         * @param {string} id FproPrestation identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrestationsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrestationJsonldPrestationRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrestationsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrestationApi.apiPrestationsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les informations d\'une prestation
         * @summary Mettre à jour les informations d\'une prestation
         * @param {string} id FproPrestation identifier
         * @param {PrestationJsonldPrestationUpdate} prestationJsonldPrestationUpdate The updated Prestation resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrestationsIdPut(id: string, prestationJsonldPrestationUpdate: PrestationJsonldPrestationUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrestationJsonldPrestationRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrestationsIdPut(id, prestationJsonldPrestationUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrestationApi.apiPrestationsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PrestationApi - factory interface
 * @export
 */
export const PrestationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrestationApiFp(configuration)
    return {
        /**
         * Obtenir la liste des prestations du dossier
         * @summary Obtenir la liste des prestations du dossier
         * @param {PrestationApiApiDossiersDossierPrestationsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierPrestationsGetCollection(requestParameters: PrestationApiApiDossiersDossierPrestationsGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersDossierPrestationsGetCollection200Response> {
            return localVarFp.apiDossiersDossierPrestationsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.libelle, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les prestations
         * @summary Lister les prestations
         * @param {PrestationApiApiPrestationsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrestationsGetCollection(requestParameters: PrestationApiApiPrestationsGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersDossierPrestationsGetCollection200Response> {
            return localVarFp.apiPrestationsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.libelle, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une prestation
         * @summary Récupérer les informations d\'une prestation
         * @param {PrestationApiApiPrestationsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrestationsIdGet(requestParameters: PrestationApiApiPrestationsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PrestationJsonldPrestationRead> {
            return localVarFp.apiPrestationsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les informations d\'une prestation
         * @summary Mettre à jour les informations d\'une prestation
         * @param {PrestationApiApiPrestationsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrestationsIdPut(requestParameters: PrestationApiApiPrestationsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<PrestationJsonldPrestationRead> {
            return localVarFp.apiPrestationsIdPut(requestParameters.id, requestParameters.prestationJsonldPrestationUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierPrestationsGetCollection operation in PrestationApi.
 * @export
 * @interface PrestationApiApiDossiersDossierPrestationsGetCollectionRequest
 */
export interface PrestationApiApiDossiersDossierPrestationsGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof PrestationApiApiDossiersDossierPrestationsGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof PrestationApiApiDossiersDossierPrestationsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof PrestationApiApiDossiersDossierPrestationsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof PrestationApiApiDossiersDossierPrestationsGetCollection
     */
    readonly dossierNumero?: string

    /**
     * 
     * @type {string}
     * @memberof PrestationApiApiDossiersDossierPrestationsGetCollection
     */
    readonly libelle?: string
}

/**
 * Request parameters for apiPrestationsGetCollection operation in PrestationApi.
 * @export
 * @interface PrestationApiApiPrestationsGetCollectionRequest
 */
export interface PrestationApiApiPrestationsGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof PrestationApiApiPrestationsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof PrestationApiApiPrestationsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof PrestationApiApiPrestationsGetCollection
     */
    readonly dossierNumero?: string

    /**
     * 
     * @type {string}
     * @memberof PrestationApiApiPrestationsGetCollection
     */
    readonly libelle?: string
}

/**
 * Request parameters for apiPrestationsIdGet operation in PrestationApi.
 * @export
 * @interface PrestationApiApiPrestationsIdGetRequest
 */
export interface PrestationApiApiPrestationsIdGetRequest {
    /**
     * FproPrestation identifier
     * @type {string}
     * @memberof PrestationApiApiPrestationsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiPrestationsIdPut operation in PrestationApi.
 * @export
 * @interface PrestationApiApiPrestationsIdPutRequest
 */
export interface PrestationApiApiPrestationsIdPutRequest {
    /**
     * FproPrestation identifier
     * @type {string}
     * @memberof PrestationApiApiPrestationsIdPut
     */
    readonly id: string

    /**
     * The updated Prestation resource
     * @type {PrestationJsonldPrestationUpdate}
     * @memberof PrestationApiApiPrestationsIdPut
     */
    readonly prestationJsonldPrestationUpdate: PrestationJsonldPrestationUpdate
}

/**
 * PrestationApi - object-oriented interface
 * @export
 * @class PrestationApi
 * @extends {BaseAPI}
 */
export class PrestationApi extends BaseAPI {
    /**
     * Obtenir la liste des prestations du dossier
     * @summary Obtenir la liste des prestations du dossier
     * @param {PrestationApiApiDossiersDossierPrestationsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrestationApi
     */
    public apiDossiersDossierPrestationsGetCollection(requestParameters: PrestationApiApiDossiersDossierPrestationsGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return PrestationApiFp(this.configuration).apiDossiersDossierPrestationsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.libelle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les prestations
     * @summary Lister les prestations
     * @param {PrestationApiApiPrestationsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrestationApi
     */
    public apiPrestationsGetCollection(requestParameters: PrestationApiApiPrestationsGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return PrestationApiFp(this.configuration).apiPrestationsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.libelle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une prestation
     * @summary Récupérer les informations d\'une prestation
     * @param {PrestationApiApiPrestationsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrestationApi
     */
    public apiPrestationsIdGet(requestParameters: PrestationApiApiPrestationsIdGetRequest, options?: RawAxiosRequestConfig) {
        return PrestationApiFp(this.configuration).apiPrestationsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les informations d\'une prestation
     * @summary Mettre à jour les informations d\'une prestation
     * @param {PrestationApiApiPrestationsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrestationApi
     */
    public apiPrestationsIdPut(requestParameters: PrestationApiApiPrestationsIdPutRequest, options?: RawAxiosRequestConfig) {
        return PrestationApiFp(this.configuration).apiPrestationsIdPut(requestParameters.id, requestParameters.prestationJsonldPrestationUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PrestationArticlesApi - axios parameter creator
 * @export
 */
export const PrestationArticlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les articles de prestation
         * @summary Lister les articles de prestation
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {string} [factArticleRubArticle] 
         * @param {Array<string>} [factArticleRubArticle2] 
         * @param {string} [factArticleNatureTva] 
         * @param {Array<string>} [factArticleNatureTva2] 
         * @param {number} [idPrestaId] 
         * @param {Array<number>} [idPrestaId2] 
         * @param {number} [factArticleId] 
         * @param {Array<number>} [factArticleId2] 
         * @param {boolean} [fDonneesVerifiees] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesPrestationGetCollection: async (page?: number, itemsPerPage?: number, libelle?: string, factArticleRubArticle?: string, factArticleRubArticle2?: Array<string>, factArticleNatureTva?: string, factArticleNatureTva2?: Array<string>, idPrestaId?: number, idPrestaId2?: Array<number>, factArticleId?: number, factArticleId2?: Array<number>, fDonneesVerifiees?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/articles-prestation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (libelle !== undefined) {
                localVarQueryParameter['libelle'] = libelle;
            }

            if (factArticleRubArticle !== undefined) {
                localVarQueryParameter['factArticle.rubArticle'] = factArticleRubArticle;
            }

            if (factArticleRubArticle2) {
                localVarQueryParameter['factArticle.rubArticle[]'] = factArticleRubArticle2;
            }

            if (factArticleNatureTva !== undefined) {
                localVarQueryParameter['factArticle.natureTva'] = factArticleNatureTva;
            }

            if (factArticleNatureTva2) {
                localVarQueryParameter['factArticle.natureTva[]'] = factArticleNatureTva2;
            }

            if (idPrestaId !== undefined) {
                localVarQueryParameter['idPresta.id'] = idPrestaId;
            }

            if (idPrestaId2) {
                localVarQueryParameter['idPresta.id[]'] = idPrestaId2;
            }

            if (factArticleId !== undefined) {
                localVarQueryParameter['factArticle.id'] = factArticleId;
            }

            if (factArticleId2) {
                localVarQueryParameter['factArticle.id[]'] = factArticleId2;
            }

            if (fDonneesVerifiees !== undefined) {
                localVarQueryParameter['fDonneesVerifiees'] = fDonneesVerifiees;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un article d\'une prestation
         * @summary Récupérer les informations d\'un article d\'une prestation
         * @param {string} id FproPrestationArticle identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesPrestationIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiArticlesPrestationIdGet', 'id', id)
            const localVarPath = `/formalite/articles-prestation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des articles par prestation
         * @summary Obtenir la liste des articles par prestation
         * @param {string} prestation FproPrestation identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {string} [factArticleRubArticle] 
         * @param {Array<string>} [factArticleRubArticle2] 
         * @param {string} [factArticleNatureTva] 
         * @param {Array<string>} [factArticleNatureTva2] 
         * @param {number} [idPrestaId] 
         * @param {Array<number>} [idPrestaId2] 
         * @param {number} [factArticleId] 
         * @param {Array<number>} [factArticleId2] 
         * @param {boolean} [fDonneesVerifiees] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrestationsPrestationArticlesPrestationGetCollection: async (prestation: string, page?: number, itemsPerPage?: number, libelle?: string, factArticleRubArticle?: string, factArticleRubArticle2?: Array<string>, factArticleNatureTva?: string, factArticleNatureTva2?: Array<string>, idPrestaId?: number, idPrestaId2?: Array<number>, factArticleId?: number, factArticleId2?: Array<number>, fDonneesVerifiees?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prestation' is not null or undefined
            assertParamExists('apiPrestationsPrestationArticlesPrestationGetCollection', 'prestation', prestation)
            const localVarPath = `/formalite/prestations/{prestation}/articles-prestation`
                .replace(`{${"prestation"}}`, encodeURIComponent(String(prestation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (libelle !== undefined) {
                localVarQueryParameter['libelle'] = libelle;
            }

            if (factArticleRubArticle !== undefined) {
                localVarQueryParameter['factArticle.rubArticle'] = factArticleRubArticle;
            }

            if (factArticleRubArticle2) {
                localVarQueryParameter['factArticle.rubArticle[]'] = factArticleRubArticle2;
            }

            if (factArticleNatureTva !== undefined) {
                localVarQueryParameter['factArticle.natureTva'] = factArticleNatureTva;
            }

            if (factArticleNatureTva2) {
                localVarQueryParameter['factArticle.natureTva[]'] = factArticleNatureTva2;
            }

            if (idPrestaId !== undefined) {
                localVarQueryParameter['idPresta.id'] = idPrestaId;
            }

            if (idPrestaId2) {
                localVarQueryParameter['idPresta.id[]'] = idPrestaId2;
            }

            if (factArticleId !== undefined) {
                localVarQueryParameter['factArticle.id'] = factArticleId;
            }

            if (factArticleId2) {
                localVarQueryParameter['factArticle.id[]'] = factArticleId2;
            }

            if (fDonneesVerifiees !== undefined) {
                localVarQueryParameter['fDonneesVerifiees'] = fDonneesVerifiees;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrestationArticlesApi - functional programming interface
 * @export
 */
export const PrestationArticlesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrestationArticlesApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les articles de prestation
         * @summary Lister les articles de prestation
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {string} [factArticleRubArticle] 
         * @param {Array<string>} [factArticleRubArticle2] 
         * @param {string} [factArticleNatureTva] 
         * @param {Array<string>} [factArticleNatureTva2] 
         * @param {number} [idPrestaId] 
         * @param {Array<number>} [idPrestaId2] 
         * @param {number} [factArticleId] 
         * @param {Array<number>} [factArticleId2] 
         * @param {boolean} [fDonneesVerifiees] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiArticlesPrestationGetCollection(page?: number, itemsPerPage?: number, libelle?: string, factArticleRubArticle?: string, factArticleRubArticle2?: Array<string>, factArticleNatureTva?: string, factArticleNatureTva2?: Array<string>, idPrestaId?: number, idPrestaId2?: Array<number>, factArticleId?: number, factArticleId2?: Array<number>, fDonneesVerifiees?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiArticlesPrestationGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticlesPrestationGetCollection(page, itemsPerPage, libelle, factArticleRubArticle, factArticleRubArticle2, factArticleNatureTva, factArticleNatureTva2, idPrestaId, idPrestaId2, factArticleId, factArticleId2, fDonneesVerifiees, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrestationArticlesApi.apiArticlesPrestationGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un article d\'une prestation
         * @summary Récupérer les informations d\'un article d\'une prestation
         * @param {string} id FproPrestationArticle identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiArticlesPrestationIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrestationArticlesJsonldPrestationrelationRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticlesPrestationIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrestationArticlesApi.apiArticlesPrestationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des articles par prestation
         * @summary Obtenir la liste des articles par prestation
         * @param {string} prestation FproPrestation identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {string} [factArticleRubArticle] 
         * @param {Array<string>} [factArticleRubArticle2] 
         * @param {string} [factArticleNatureTva] 
         * @param {Array<string>} [factArticleNatureTva2] 
         * @param {number} [idPrestaId] 
         * @param {Array<number>} [idPrestaId2] 
         * @param {number} [factArticleId] 
         * @param {Array<number>} [factArticleId2] 
         * @param {boolean} [fDonneesVerifiees] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrestationsPrestationArticlesPrestationGetCollection(prestation: string, page?: number, itemsPerPage?: number, libelle?: string, factArticleRubArticle?: string, factArticleRubArticle2?: Array<string>, factArticleNatureTva?: string, factArticleNatureTva2?: Array<string>, idPrestaId?: number, idPrestaId2?: Array<number>, factArticleId?: number, factArticleId2?: Array<number>, fDonneesVerifiees?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiArticlesPrestationGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrestationsPrestationArticlesPrestationGetCollection(prestation, page, itemsPerPage, libelle, factArticleRubArticle, factArticleRubArticle2, factArticleNatureTva, factArticleNatureTva2, idPrestaId, idPrestaId2, factArticleId, factArticleId2, fDonneesVerifiees, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrestationArticlesApi.apiPrestationsPrestationArticlesPrestationGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PrestationArticlesApi - factory interface
 * @export
 */
export const PrestationArticlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrestationArticlesApiFp(configuration)
    return {
        /**
         * Lister les articles de prestation
         * @summary Lister les articles de prestation
         * @param {PrestationArticlesApiApiArticlesPrestationGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesPrestationGetCollection(requestParameters: PrestationArticlesApiApiArticlesPrestationGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiArticlesPrestationGetCollection200Response> {
            return localVarFp.apiArticlesPrestationGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, requestParameters.factArticleRubArticle, requestParameters.factArticleRubArticle2, requestParameters.factArticleNatureTva, requestParameters.factArticleNatureTva2, requestParameters.idPrestaId, requestParameters.idPrestaId2, requestParameters.factArticleId, requestParameters.factArticleId2, requestParameters.fDonneesVerifiees, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un article d\'une prestation
         * @summary Récupérer les informations d\'un article d\'une prestation
         * @param {PrestationArticlesApiApiArticlesPrestationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesPrestationIdGet(requestParameters: PrestationArticlesApiApiArticlesPrestationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PrestationArticlesJsonldPrestationrelationRead> {
            return localVarFp.apiArticlesPrestationIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des articles par prestation
         * @summary Obtenir la liste des articles par prestation
         * @param {PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrestationsPrestationArticlesPrestationGetCollection(requestParameters: PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiArticlesPrestationGetCollection200Response> {
            return localVarFp.apiPrestationsPrestationArticlesPrestationGetCollection(requestParameters.prestation, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, requestParameters.factArticleRubArticle, requestParameters.factArticleRubArticle2, requestParameters.factArticleNatureTva, requestParameters.factArticleNatureTva2, requestParameters.idPrestaId, requestParameters.idPrestaId2, requestParameters.factArticleId, requestParameters.factArticleId2, requestParameters.fDonneesVerifiees, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiArticlesPrestationGetCollection operation in PrestationArticlesApi.
 * @export
 * @interface PrestationArticlesApiApiArticlesPrestationGetCollectionRequest
 */
export interface PrestationArticlesApiApiArticlesPrestationGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof PrestationArticlesApiApiArticlesPrestationGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof PrestationArticlesApiApiArticlesPrestationGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof PrestationArticlesApiApiArticlesPrestationGetCollection
     */
    readonly libelle?: string

    /**
     * 
     * @type {string}
     * @memberof PrestationArticlesApiApiArticlesPrestationGetCollection
     */
    readonly factArticleRubArticle?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof PrestationArticlesApiApiArticlesPrestationGetCollection
     */
    readonly factArticleRubArticle2?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof PrestationArticlesApiApiArticlesPrestationGetCollection
     */
    readonly factArticleNatureTva?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof PrestationArticlesApiApiArticlesPrestationGetCollection
     */
    readonly factArticleNatureTva2?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof PrestationArticlesApiApiArticlesPrestationGetCollection
     */
    readonly idPrestaId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof PrestationArticlesApiApiArticlesPrestationGetCollection
     */
    readonly idPrestaId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof PrestationArticlesApiApiArticlesPrestationGetCollection
     */
    readonly factArticleId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof PrestationArticlesApiApiArticlesPrestationGetCollection
     */
    readonly factArticleId2?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof PrestationArticlesApiApiArticlesPrestationGetCollection
     */
    readonly fDonneesVerifiees?: boolean
}

/**
 * Request parameters for apiArticlesPrestationIdGet operation in PrestationArticlesApi.
 * @export
 * @interface PrestationArticlesApiApiArticlesPrestationIdGetRequest
 */
export interface PrestationArticlesApiApiArticlesPrestationIdGetRequest {
    /**
     * FproPrestationArticle identifier
     * @type {string}
     * @memberof PrestationArticlesApiApiArticlesPrestationIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiPrestationsPrestationArticlesPrestationGetCollection operation in PrestationArticlesApi.
 * @export
 * @interface PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollectionRequest
 */
export interface PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollectionRequest {
    /**
     * FproPrestation identifier
     * @type {string}
     * @memberof PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollection
     */
    readonly prestation: string

    /**
     * The collection page number
     * @type {number}
     * @memberof PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollection
     */
    readonly libelle?: string

    /**
     * 
     * @type {string}
     * @memberof PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollection
     */
    readonly factArticleRubArticle?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollection
     */
    readonly factArticleRubArticle2?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollection
     */
    readonly factArticleNatureTva?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollection
     */
    readonly factArticleNatureTva2?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollection
     */
    readonly idPrestaId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollection
     */
    readonly idPrestaId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollection
     */
    readonly factArticleId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollection
     */
    readonly factArticleId2?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollection
     */
    readonly fDonneesVerifiees?: boolean
}

/**
 * PrestationArticlesApi - object-oriented interface
 * @export
 * @class PrestationArticlesApi
 * @extends {BaseAPI}
 */
export class PrestationArticlesApi extends BaseAPI {
    /**
     * Lister les articles de prestation
     * @summary Lister les articles de prestation
     * @param {PrestationArticlesApiApiArticlesPrestationGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrestationArticlesApi
     */
    public apiArticlesPrestationGetCollection(requestParameters: PrestationArticlesApiApiArticlesPrestationGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return PrestationArticlesApiFp(this.configuration).apiArticlesPrestationGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, requestParameters.factArticleRubArticle, requestParameters.factArticleRubArticle2, requestParameters.factArticleNatureTva, requestParameters.factArticleNatureTva2, requestParameters.idPrestaId, requestParameters.idPrestaId2, requestParameters.factArticleId, requestParameters.factArticleId2, requestParameters.fDonneesVerifiees, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un article d\'une prestation
     * @summary Récupérer les informations d\'un article d\'une prestation
     * @param {PrestationArticlesApiApiArticlesPrestationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrestationArticlesApi
     */
    public apiArticlesPrestationIdGet(requestParameters: PrestationArticlesApiApiArticlesPrestationIdGetRequest, options?: RawAxiosRequestConfig) {
        return PrestationArticlesApiFp(this.configuration).apiArticlesPrestationIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des articles par prestation
     * @summary Obtenir la liste des articles par prestation
     * @param {PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrestationArticlesApi
     */
    public apiPrestationsPrestationArticlesPrestationGetCollection(requestParameters: PrestationArticlesApiApiPrestationsPrestationArticlesPrestationGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return PrestationArticlesApiFp(this.configuration).apiPrestationsPrestationArticlesPrestationGetCollection(requestParameters.prestation, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, requestParameters.factArticleRubArticle, requestParameters.factArticleRubArticle2, requestParameters.factArticleNatureTva, requestParameters.factArticleNatureTva2, requestParameters.idPrestaId, requestParameters.idPrestaId2, requestParameters.factArticleId, requestParameters.factArticleId2, requestParameters.fDonneesVerifiees, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProcdureDeValidationDesDossiersApi - axios parameter creator
 * @export
 */
export const ProcdureDeValidationDesDossiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les procédures de validation des dossiers
         * @summary Lister les procédures de validation des dossiers
         * @param {number} dossier L\&#39;identifiant du dossier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierValidationGetCollection: async (dossier: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierValidationGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/validation`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Déclencher une action pour mettre à jour une procédure de validation d\'un dossier
         * @summary Déclencher une action pour mettre à jour une procédure de validation d\'un dossier
         * @param {number} id L\&#39;identifiant de la procédure de validation
         * @param {ApiDossiersValidationIdActionPutActionEnum} action L\&#39;action à déclencher
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersValidationIdActionPut: async (id: number, action: ApiDossiersValidationIdActionPutActionEnum, body?: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersValidationIdActionPut', 'id', id)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('apiDossiersValidationIdActionPut', 'action', action)
            const localVarPath = `/formalite/dossiers-validation/{id}/{action}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"action"}}`, encodeURIComponent(String(action)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une procédure de validation d\'un dossier
         * @summary Récupérer les informations d\'une procédure de validation d\'un dossier
         * @param {number} id L\&#39;identifiant de la procédure de validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersValidationIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersValidationIdGet', 'id', id)
            const localVarPath = `/formalite/dossiers-validation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Procédure de validation des dossiers resource.
         * @summary Creates a Procédure de validation des dossiers resource.
         * @param {ProcDureDeValidationDesDossiersJsonldValidatorsProcessCreate} procDureDeValidationDesDossiersJsonldValidatorsProcessCreate The new Procédure de validation des dossiers resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersValidationPost: async (procDureDeValidationDesDossiersJsonldValidatorsProcessCreate: ProcDureDeValidationDesDossiersJsonldValidatorsProcessCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'procDureDeValidationDesDossiersJsonldValidatorsProcessCreate' is not null or undefined
            assertParamExists('apiDossiersValidationPost', 'procDureDeValidationDesDossiersJsonldValidatorsProcessCreate', procDureDeValidationDesDossiersJsonldValidatorsProcessCreate)
            const localVarPath = `/formalite/dossiers-validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(procDureDeValidationDesDossiersJsonldValidatorsProcessCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcdureDeValidationDesDossiersApi - functional programming interface
 * @export
 */
export const ProcdureDeValidationDesDossiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProcdureDeValidationDesDossiersApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les procédures de validation des dossiers
         * @summary Lister les procédures de validation des dossiers
         * @param {number} dossier L\&#39;identifiant du dossier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierValidationGetCollection(dossier: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierValidationGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierValidationGetCollection(dossier, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProcdureDeValidationDesDossiersApi.apiDossiersDossierValidationGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Déclencher une action pour mettre à jour une procédure de validation d\'un dossier
         * @summary Déclencher une action pour mettre à jour une procédure de validation d\'un dossier
         * @param {number} id L\&#39;identifiant de la procédure de validation
         * @param {ApiDossiersValidationIdActionPutActionEnum} action L\&#39;action à déclencher
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersValidationIdActionPut(id: number, action: ApiDossiersValidationIdActionPutActionEnum, body?: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersValidationIdActionPut(id, action, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProcdureDeValidationDesDossiersApi.apiDossiersValidationIdActionPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une procédure de validation d\'un dossier
         * @summary Récupérer les informations d\'une procédure de validation d\'un dossier
         * @param {number} id L\&#39;identifiant de la procédure de validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersValidationIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersValidationIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProcdureDeValidationDesDossiersApi.apiDossiersValidationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Procédure de validation des dossiers resource.
         * @summary Creates a Procédure de validation des dossiers resource.
         * @param {ProcDureDeValidationDesDossiersJsonldValidatorsProcessCreate} procDureDeValidationDesDossiersJsonldValidatorsProcessCreate The new Procédure de validation des dossiers resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersValidationPost(procDureDeValidationDesDossiersJsonldValidatorsProcessCreate: ProcDureDeValidationDesDossiersJsonldValidatorsProcessCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersValidationPost(procDureDeValidationDesDossiersJsonldValidatorsProcessCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProcdureDeValidationDesDossiersApi.apiDossiersValidationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProcdureDeValidationDesDossiersApi - factory interface
 * @export
 */
export const ProcdureDeValidationDesDossiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProcdureDeValidationDesDossiersApiFp(configuration)
    return {
        /**
         * Lister les procédures de validation des dossiers
         * @summary Lister les procédures de validation des dossiers
         * @param {ProcdureDeValidationDesDossiersApiApiDossiersDossierValidationGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierValidationGetCollection(requestParameters: ProcdureDeValidationDesDossiersApiApiDossiersDossierValidationGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersDossierValidationGetCollection200Response> {
            return localVarFp.apiDossiersDossierValidationGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Déclencher une action pour mettre à jour une procédure de validation d\'un dossier
         * @summary Déclencher une action pour mettre à jour une procédure de validation d\'un dossier
         * @param {ProcdureDeValidationDesDossiersApiApiDossiersValidationIdActionPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersValidationIdActionPut(requestParameters: ProcdureDeValidationDesDossiersApiApiDossiersValidationIdActionPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead> {
            return localVarFp.apiDossiersValidationIdActionPut(requestParameters.id, requestParameters.action, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une procédure de validation d\'un dossier
         * @summary Récupérer les informations d\'une procédure de validation d\'un dossier
         * @param {ProcdureDeValidationDesDossiersApiApiDossiersValidationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersValidationIdGet(requestParameters: ProcdureDeValidationDesDossiersApiApiDossiersValidationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead> {
            return localVarFp.apiDossiersValidationIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Procédure de validation des dossiers resource.
         * @summary Creates a Procédure de validation des dossiers resource.
         * @param {ProcdureDeValidationDesDossiersApiApiDossiersValidationPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersValidationPost(requestParameters: ProcdureDeValidationDesDossiersApiApiDossiersValidationPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProcDureDeValidationDesDossiersJsonldValidatorsProcessRead> {
            return localVarFp.apiDossiersValidationPost(requestParameters.procDureDeValidationDesDossiersJsonldValidatorsProcessCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierValidationGetCollection operation in ProcdureDeValidationDesDossiersApi.
 * @export
 * @interface ProcdureDeValidationDesDossiersApiApiDossiersDossierValidationGetCollectionRequest
 */
export interface ProcdureDeValidationDesDossiersApiApiDossiersDossierValidationGetCollectionRequest {
    /**
     * L\&#39;identifiant du dossier
     * @type {number}
     * @memberof ProcdureDeValidationDesDossiersApiApiDossiersDossierValidationGetCollection
     */
    readonly dossier: number

    /**
     * The collection page number
     * @type {number}
     * @memberof ProcdureDeValidationDesDossiersApiApiDossiersDossierValidationGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ProcdureDeValidationDesDossiersApiApiDossiersDossierValidationGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiDossiersValidationIdActionPut operation in ProcdureDeValidationDesDossiersApi.
 * @export
 * @interface ProcdureDeValidationDesDossiersApiApiDossiersValidationIdActionPutRequest
 */
export interface ProcdureDeValidationDesDossiersApiApiDossiersValidationIdActionPutRequest {
    /**
     * L\&#39;identifiant de la procédure de validation
     * @type {number}
     * @memberof ProcdureDeValidationDesDossiersApiApiDossiersValidationIdActionPut
     */
    readonly id: number

    /**
     * L\&#39;action à déclencher
     * @type {'default' | 'failed' | 'rescheduled' | 'validate'}
     * @memberof ProcdureDeValidationDesDossiersApiApiDossiersValidationIdActionPut
     */
    readonly action: ApiDossiersValidationIdActionPutActionEnum

    /**
     * 
     * @type {object}
     * @memberof ProcdureDeValidationDesDossiersApiApiDossiersValidationIdActionPut
     */
    readonly body?: object
}

/**
 * Request parameters for apiDossiersValidationIdGet operation in ProcdureDeValidationDesDossiersApi.
 * @export
 * @interface ProcdureDeValidationDesDossiersApiApiDossiersValidationIdGetRequest
 */
export interface ProcdureDeValidationDesDossiersApiApiDossiersValidationIdGetRequest {
    /**
     * L\&#39;identifiant de la procédure de validation
     * @type {number}
     * @memberof ProcdureDeValidationDesDossiersApiApiDossiersValidationIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiDossiersValidationPost operation in ProcdureDeValidationDesDossiersApi.
 * @export
 * @interface ProcdureDeValidationDesDossiersApiApiDossiersValidationPostRequest
 */
export interface ProcdureDeValidationDesDossiersApiApiDossiersValidationPostRequest {
    /**
     * The new Procédure de validation des dossiers resource
     * @type {ProcDureDeValidationDesDossiersJsonldValidatorsProcessCreate}
     * @memberof ProcdureDeValidationDesDossiersApiApiDossiersValidationPost
     */
    readonly procDureDeValidationDesDossiersJsonldValidatorsProcessCreate: ProcDureDeValidationDesDossiersJsonldValidatorsProcessCreate
}

/**
 * ProcdureDeValidationDesDossiersApi - object-oriented interface
 * @export
 * @class ProcdureDeValidationDesDossiersApi
 * @extends {BaseAPI}
 */
export class ProcdureDeValidationDesDossiersApi extends BaseAPI {
    /**
     * Lister les procédures de validation des dossiers
     * @summary Lister les procédures de validation des dossiers
     * @param {ProcdureDeValidationDesDossiersApiApiDossiersDossierValidationGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcdureDeValidationDesDossiersApi
     */
    public apiDossiersDossierValidationGetCollection(requestParameters: ProcdureDeValidationDesDossiersApiApiDossiersDossierValidationGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return ProcdureDeValidationDesDossiersApiFp(this.configuration).apiDossiersDossierValidationGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Déclencher une action pour mettre à jour une procédure de validation d\'un dossier
     * @summary Déclencher une action pour mettre à jour une procédure de validation d\'un dossier
     * @param {ProcdureDeValidationDesDossiersApiApiDossiersValidationIdActionPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcdureDeValidationDesDossiersApi
     */
    public apiDossiersValidationIdActionPut(requestParameters: ProcdureDeValidationDesDossiersApiApiDossiersValidationIdActionPutRequest, options?: RawAxiosRequestConfig) {
        return ProcdureDeValidationDesDossiersApiFp(this.configuration).apiDossiersValidationIdActionPut(requestParameters.id, requestParameters.action, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une procédure de validation d\'un dossier
     * @summary Récupérer les informations d\'une procédure de validation d\'un dossier
     * @param {ProcdureDeValidationDesDossiersApiApiDossiersValidationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcdureDeValidationDesDossiersApi
     */
    public apiDossiersValidationIdGet(requestParameters: ProcdureDeValidationDesDossiersApiApiDossiersValidationIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProcdureDeValidationDesDossiersApiFp(this.configuration).apiDossiersValidationIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Procédure de validation des dossiers resource.
     * @summary Creates a Procédure de validation des dossiers resource.
     * @param {ProcdureDeValidationDesDossiersApiApiDossiersValidationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcdureDeValidationDesDossiersApi
     */
    public apiDossiersValidationPost(requestParameters: ProcdureDeValidationDesDossiersApiApiDossiersValidationPostRequest, options?: RawAxiosRequestConfig) {
        return ProcdureDeValidationDesDossiersApiFp(this.configuration).apiDossiersValidationPost(requestParameters.procDureDeValidationDesDossiersJsonldValidatorsProcessCreate, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiDossiersValidationIdActionPutActionEnum = {
    Default: 'default',
    Failed: 'failed',
    Rescheduled: 'rescheduled',
    Validate: 'validate'
} as const;
export type ApiDossiersValidationIdActionPutActionEnum = typeof ApiDossiersValidationIdActionPutActionEnum[keyof typeof ApiDossiersValidationIdActionPutActionEnum];


/**
 * RappelsApi - axios parameter creator
 * @export
 */
export const RappelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des rappels
         * @summary Obtenir la liste des rappels
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierRappelsGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierRappelsGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/rappels`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un rappel
         * @summary Récupérer les informations d\'un rappel
         * @param {string} id TblEvenementRappel identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRappelsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRappelsIdGet', 'id', id)
            const localVarPath = `/formalite/rappels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Notes et rappels resource.
         * @summary Modifier un rappel
         * @param {string} id TblEvenementRappel identifier
         * @param {NotesEtRappelsJsonldNoterappelUpdate} notesEtRappelsJsonldNoterappelUpdate The updated Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRappelsIdPut: async (id: string, notesEtRappelsJsonldNoterappelUpdate: NotesEtRappelsJsonldNoterappelUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRappelsIdPut', 'id', id)
            // verify required parameter 'notesEtRappelsJsonldNoterappelUpdate' is not null or undefined
            assertParamExists('apiRappelsIdPut', 'notesEtRappelsJsonldNoterappelUpdate', notesEtRappelsJsonldNoterappelUpdate)
            const localVarPath = `/formalite/rappels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notesEtRappelsJsonldNoterappelUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Notes et rappels resource.
         * @summary Créer un rappel
         * @param {NotesEtRappelsJsonldNoterappelCreate} notesEtRappelsJsonldNoterappelCreate The new Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRappelsPost: async (notesEtRappelsJsonldNoterappelCreate: NotesEtRappelsJsonldNoterappelCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notesEtRappelsJsonldNoterappelCreate' is not null or undefined
            assertParamExists('apiRappelsPost', 'notesEtRappelsJsonldNoterappelCreate', notesEtRappelsJsonldNoterappelCreate)
            const localVarPath = `/formalite/rappels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notesEtRappelsJsonldNoterappelCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RappelsApi - functional programming interface
 * @export
 */
export const RappelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RappelsApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des rappels
         * @summary Obtenir la liste des rappels
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierRappelsGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierRappelsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierRappelsGetCollection(dossier, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RappelsApi.apiDossiersDossierRappelsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un rappel
         * @summary Récupérer les informations d\'un rappel
         * @param {string} id TblEvenementRappel identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRappelsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesEtRappelsJsonldNoterappelRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRappelsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RappelsApi.apiRappelsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Notes et rappels resource.
         * @summary Modifier un rappel
         * @param {string} id TblEvenementRappel identifier
         * @param {NotesEtRappelsJsonldNoterappelUpdate} notesEtRappelsJsonldNoterappelUpdate The updated Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRappelsIdPut(id: string, notesEtRappelsJsonldNoterappelUpdate: NotesEtRappelsJsonldNoterappelUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesEtRappelsJsonldNoterappelRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRappelsIdPut(id, notesEtRappelsJsonldNoterappelUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RappelsApi.apiRappelsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Notes et rappels resource.
         * @summary Créer un rappel
         * @param {NotesEtRappelsJsonldNoterappelCreate} notesEtRappelsJsonldNoterappelCreate The new Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRappelsPost(notesEtRappelsJsonldNoterappelCreate: NotesEtRappelsJsonldNoterappelCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesEtRappelsJsonldNoterappelCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRappelsPost(notesEtRappelsJsonldNoterappelCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RappelsApi.apiRappelsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RappelsApi - factory interface
 * @export
 */
export const RappelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RappelsApiFp(configuration)
    return {
        /**
         * Obtenir la liste des rappels
         * @summary Obtenir la liste des rappels
         * @param {RappelsApiApiDossiersDossierRappelsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierRappelsGetCollection(requestParameters: RappelsApiApiDossiersDossierRappelsGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersDossierRappelsGetCollection200Response> {
            return localVarFp.apiDossiersDossierRappelsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un rappel
         * @summary Récupérer les informations d\'un rappel
         * @param {RappelsApiApiRappelsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRappelsIdGet(requestParameters: RappelsApiApiRappelsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<NotesEtRappelsJsonldNoterappelRead> {
            return localVarFp.apiRappelsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Notes et rappels resource.
         * @summary Modifier un rappel
         * @param {RappelsApiApiRappelsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRappelsIdPut(requestParameters: RappelsApiApiRappelsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<NotesEtRappelsJsonldNoterappelRead> {
            return localVarFp.apiRappelsIdPut(requestParameters.id, requestParameters.notesEtRappelsJsonldNoterappelUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Notes et rappels resource.
         * @summary Créer un rappel
         * @param {RappelsApiApiRappelsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRappelsPost(requestParameters: RappelsApiApiRappelsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<NotesEtRappelsJsonldNoterappelCreated> {
            return localVarFp.apiRappelsPost(requestParameters.notesEtRappelsJsonldNoterappelCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierRappelsGetCollection operation in RappelsApi.
 * @export
 * @interface RappelsApiApiDossiersDossierRappelsGetCollectionRequest
 */
export interface RappelsApiApiDossiersDossierRappelsGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof RappelsApiApiDossiersDossierRappelsGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof RappelsApiApiDossiersDossierRappelsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof RappelsApiApiDossiersDossierRappelsGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiRappelsIdGet operation in RappelsApi.
 * @export
 * @interface RappelsApiApiRappelsIdGetRequest
 */
export interface RappelsApiApiRappelsIdGetRequest {
    /**
     * TblEvenementRappel identifier
     * @type {string}
     * @memberof RappelsApiApiRappelsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiRappelsIdPut operation in RappelsApi.
 * @export
 * @interface RappelsApiApiRappelsIdPutRequest
 */
export interface RappelsApiApiRappelsIdPutRequest {
    /**
     * TblEvenementRappel identifier
     * @type {string}
     * @memberof RappelsApiApiRappelsIdPut
     */
    readonly id: string

    /**
     * The updated Notes et rappels resource
     * @type {NotesEtRappelsJsonldNoterappelUpdate}
     * @memberof RappelsApiApiRappelsIdPut
     */
    readonly notesEtRappelsJsonldNoterappelUpdate: NotesEtRappelsJsonldNoterappelUpdate
}

/**
 * Request parameters for apiRappelsPost operation in RappelsApi.
 * @export
 * @interface RappelsApiApiRappelsPostRequest
 */
export interface RappelsApiApiRappelsPostRequest {
    /**
     * The new Notes et rappels resource
     * @type {NotesEtRappelsJsonldNoterappelCreate}
     * @memberof RappelsApiApiRappelsPost
     */
    readonly notesEtRappelsJsonldNoterappelCreate: NotesEtRappelsJsonldNoterappelCreate
}

/**
 * RappelsApi - object-oriented interface
 * @export
 * @class RappelsApi
 * @extends {BaseAPI}
 */
export class RappelsApi extends BaseAPI {
    /**
     * Obtenir la liste des rappels
     * @summary Obtenir la liste des rappels
     * @param {RappelsApiApiDossiersDossierRappelsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RappelsApi
     */
    public apiDossiersDossierRappelsGetCollection(requestParameters: RappelsApiApiDossiersDossierRappelsGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return RappelsApiFp(this.configuration).apiDossiersDossierRappelsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un rappel
     * @summary Récupérer les informations d\'un rappel
     * @param {RappelsApiApiRappelsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RappelsApi
     */
    public apiRappelsIdGet(requestParameters: RappelsApiApiRappelsIdGetRequest, options?: RawAxiosRequestConfig) {
        return RappelsApiFp(this.configuration).apiRappelsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Notes et rappels resource.
     * @summary Modifier un rappel
     * @param {RappelsApiApiRappelsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RappelsApi
     */
    public apiRappelsIdPut(requestParameters: RappelsApiApiRappelsIdPutRequest, options?: RawAxiosRequestConfig) {
        return RappelsApiFp(this.configuration).apiRappelsIdPut(requestParameters.id, requestParameters.notesEtRappelsJsonldNoterappelUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Notes et rappels resource.
     * @summary Créer un rappel
     * @param {RappelsApiApiRappelsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RappelsApi
     */
    public apiRappelsPost(requestParameters: RappelsApiApiRappelsPostRequest, options?: RawAxiosRequestConfig) {
        return RappelsApiFp(this.configuration).apiRappelsPost(requestParameters.notesEtRappelsJsonldNoterappelCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReglementsApi - axios parameter creator
 * @export
 */
export const ReglementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des règlements
         * @summary Obtenir la liste des règlements
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierReglementsGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierReglementsGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/reglements`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un règlement
         * @summary Récupérer les informations d\'un règlement
         * @param {string} id TblReglement2 identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReglementsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReglementsIdGet', 'id', id)
            const localVarPath = `/formalite/reglements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Reglements et acomptes CB resource.
         * @summary Modifier un règlement
         * @param {string} id TblReglement2 identifier
         * @param {ReglementsEtAcomptesCBJsonldReglementUpdate} reglementsEtAcomptesCBJsonldReglementUpdate The updated Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReglementsIdPut: async (id: string, reglementsEtAcomptesCBJsonldReglementUpdate: ReglementsEtAcomptesCBJsonldReglementUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReglementsIdPut', 'id', id)
            // verify required parameter 'reglementsEtAcomptesCBJsonldReglementUpdate' is not null or undefined
            assertParamExists('apiReglementsIdPut', 'reglementsEtAcomptesCBJsonldReglementUpdate', reglementsEtAcomptesCBJsonldReglementUpdate)
            const localVarPath = `/formalite/reglements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reglementsEtAcomptesCBJsonldReglementUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Reglements et acomptes CB resource.
         * @summary Créer un règlement
         * @param {ReglementsEtAcomptesCBJsonldReglementCreate} reglementsEtAcomptesCBJsonldReglementCreate The new Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReglementsPost: async (reglementsEtAcomptesCBJsonldReglementCreate: ReglementsEtAcomptesCBJsonldReglementCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reglementsEtAcomptesCBJsonldReglementCreate' is not null or undefined
            assertParamExists('apiReglementsPost', 'reglementsEtAcomptesCBJsonldReglementCreate', reglementsEtAcomptesCBJsonldReglementCreate)
            const localVarPath = `/formalite/reglements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reglementsEtAcomptesCBJsonldReglementCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReglementsApi - functional programming interface
 * @export
 */
export const ReglementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReglementsApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des règlements
         * @summary Obtenir la liste des règlements
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierReglementsGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierAcomptesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierReglementsGetCollection(dossier, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReglementsApi.apiDossiersDossierReglementsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un règlement
         * @summary Récupérer les informations d\'un règlement
         * @param {string} id TblReglement2 identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReglementsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReglementsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReglementsApi.apiReglementsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Reglements et acomptes CB resource.
         * @summary Modifier un règlement
         * @param {string} id TblReglement2 identifier
         * @param {ReglementsEtAcomptesCBJsonldReglementUpdate} reglementsEtAcomptesCBJsonldReglementUpdate The updated Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReglementsIdPut(id: string, reglementsEtAcomptesCBJsonldReglementUpdate: ReglementsEtAcomptesCBJsonldReglementUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReglementsEtAcomptesCBJsonldReglementRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReglementsIdPut(id, reglementsEtAcomptesCBJsonldReglementUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReglementsApi.apiReglementsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Reglements et acomptes CB resource.
         * @summary Créer un règlement
         * @param {ReglementsEtAcomptesCBJsonldReglementCreate} reglementsEtAcomptesCBJsonldReglementCreate The new Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReglementsPost(reglementsEtAcomptesCBJsonldReglementCreate: ReglementsEtAcomptesCBJsonldReglementCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReglementsEtAcomptesCBJsonldReglementCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReglementsPost(reglementsEtAcomptesCBJsonldReglementCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReglementsApi.apiReglementsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReglementsApi - factory interface
 * @export
 */
export const ReglementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReglementsApiFp(configuration)
    return {
        /**
         * Obtenir la liste des règlements
         * @summary Obtenir la liste des règlements
         * @param {ReglementsApiApiDossiersDossierReglementsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierReglementsGetCollection(requestParameters: ReglementsApiApiDossiersDossierReglementsGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersDossierAcomptesGetCollection200Response> {
            return localVarFp.apiDossiersDossierReglementsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un règlement
         * @summary Récupérer les informations d\'un règlement
         * @param {ReglementsApiApiReglementsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReglementsIdGet(requestParameters: ReglementsApiApiReglementsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteRead> {
            return localVarFp.apiReglementsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Reglements et acomptes CB resource.
         * @summary Modifier un règlement
         * @param {ReglementsApiApiReglementsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReglementsIdPut(requestParameters: ReglementsApiApiReglementsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReglementsEtAcomptesCBJsonldReglementRead> {
            return localVarFp.apiReglementsIdPut(requestParameters.id, requestParameters.reglementsEtAcomptesCBJsonldReglementUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Reglements et acomptes CB resource.
         * @summary Créer un règlement
         * @param {ReglementsApiApiReglementsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReglementsPost(requestParameters: ReglementsApiApiReglementsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReglementsEtAcomptesCBJsonldReglementCreated> {
            return localVarFp.apiReglementsPost(requestParameters.reglementsEtAcomptesCBJsonldReglementCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierReglementsGetCollection operation in ReglementsApi.
 * @export
 * @interface ReglementsApiApiDossiersDossierReglementsGetCollectionRequest
 */
export interface ReglementsApiApiDossiersDossierReglementsGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof ReglementsApiApiDossiersDossierReglementsGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof ReglementsApiApiDossiersDossierReglementsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ReglementsApiApiDossiersDossierReglementsGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiReglementsIdGet operation in ReglementsApi.
 * @export
 * @interface ReglementsApiApiReglementsIdGetRequest
 */
export interface ReglementsApiApiReglementsIdGetRequest {
    /**
     * TblReglement2 identifier
     * @type {string}
     * @memberof ReglementsApiApiReglementsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiReglementsIdPut operation in ReglementsApi.
 * @export
 * @interface ReglementsApiApiReglementsIdPutRequest
 */
export interface ReglementsApiApiReglementsIdPutRequest {
    /**
     * TblReglement2 identifier
     * @type {string}
     * @memberof ReglementsApiApiReglementsIdPut
     */
    readonly id: string

    /**
     * The updated Reglements et acomptes CB resource
     * @type {ReglementsEtAcomptesCBJsonldReglementUpdate}
     * @memberof ReglementsApiApiReglementsIdPut
     */
    readonly reglementsEtAcomptesCBJsonldReglementUpdate: ReglementsEtAcomptesCBJsonldReglementUpdate
}

/**
 * Request parameters for apiReglementsPost operation in ReglementsApi.
 * @export
 * @interface ReglementsApiApiReglementsPostRequest
 */
export interface ReglementsApiApiReglementsPostRequest {
    /**
     * The new Reglements et acomptes CB resource
     * @type {ReglementsEtAcomptesCBJsonldReglementCreate}
     * @memberof ReglementsApiApiReglementsPost
     */
    readonly reglementsEtAcomptesCBJsonldReglementCreate: ReglementsEtAcomptesCBJsonldReglementCreate
}

/**
 * ReglementsApi - object-oriented interface
 * @export
 * @class ReglementsApi
 * @extends {BaseAPI}
 */
export class ReglementsApi extends BaseAPI {
    /**
     * Obtenir la liste des règlements
     * @summary Obtenir la liste des règlements
     * @param {ReglementsApiApiDossiersDossierReglementsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReglementsApi
     */
    public apiDossiersDossierReglementsGetCollection(requestParameters: ReglementsApiApiDossiersDossierReglementsGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return ReglementsApiFp(this.configuration).apiDossiersDossierReglementsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un règlement
     * @summary Récupérer les informations d\'un règlement
     * @param {ReglementsApiApiReglementsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReglementsApi
     */
    public apiReglementsIdGet(requestParameters: ReglementsApiApiReglementsIdGetRequest, options?: RawAxiosRequestConfig) {
        return ReglementsApiFp(this.configuration).apiReglementsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Reglements et acomptes CB resource.
     * @summary Modifier un règlement
     * @param {ReglementsApiApiReglementsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReglementsApi
     */
    public apiReglementsIdPut(requestParameters: ReglementsApiApiReglementsIdPutRequest, options?: RawAxiosRequestConfig) {
        return ReglementsApiFp(this.configuration).apiReglementsIdPut(requestParameters.id, requestParameters.reglementsEtAcomptesCBJsonldReglementUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Reglements et acomptes CB resource.
     * @summary Créer un règlement
     * @param {ReglementsApiApiReglementsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReglementsApi
     */
    public apiReglementsPost(requestParameters: ReglementsApiApiReglementsPostRequest, options?: RawAxiosRequestConfig) {
        return ReglementsApiFp(this.configuration).apiReglementsPost(requestParameters.reglementsEtAcomptesCBJsonldReglementCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SousTypesAnnonceApi - axios parameter creator
 * @export
 */
export const SousTypesAnnonceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'un article
         * @summary Récupérer les informations d\'un article
         * @param {string} id TblAnnonceSType identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSousTypeAnnonceIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSousTypeAnnonceIdGet', 'id', id)
            const localVarPath = `/formalite/sous-type-annonce/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SousTypesAnnonceApi - functional programming interface
 * @export
 */
export const SousTypesAnnonceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SousTypesAnnonceApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'un article
         * @summary Récupérer les informations d\'un article
         * @param {string} id TblAnnonceSType identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSousTypeAnnonceIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SousTypesAnnonceJsonldSousTypeAnnonceRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSousTypeAnnonceIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousTypesAnnonceApi.apiSousTypeAnnonceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SousTypesAnnonceApi - factory interface
 * @export
 */
export const SousTypesAnnonceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SousTypesAnnonceApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'un article
         * @summary Récupérer les informations d\'un article
         * @param {SousTypesAnnonceApiApiSousTypeAnnonceIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSousTypeAnnonceIdGet(requestParameters: SousTypesAnnonceApiApiSousTypeAnnonceIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<SousTypesAnnonceJsonldSousTypeAnnonceRead> {
            return localVarFp.apiSousTypeAnnonceIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiSousTypeAnnonceIdGet operation in SousTypesAnnonceApi.
 * @export
 * @interface SousTypesAnnonceApiApiSousTypeAnnonceIdGetRequest
 */
export interface SousTypesAnnonceApiApiSousTypeAnnonceIdGetRequest {
    /**
     * TblAnnonceSType identifier
     * @type {string}
     * @memberof SousTypesAnnonceApiApiSousTypeAnnonceIdGet
     */
    readonly id: string
}

/**
 * SousTypesAnnonceApi - object-oriented interface
 * @export
 * @class SousTypesAnnonceApi
 * @extends {BaseAPI}
 */
export class SousTypesAnnonceApi extends BaseAPI {
    /**
     * Récupérer les informations d\'un article
     * @summary Récupérer les informations d\'un article
     * @param {SousTypesAnnonceApiApiSousTypeAnnonceIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousTypesAnnonceApi
     */
    public apiSousTypeAnnonceIdGet(requestParameters: SousTypesAnnonceApiApiSousTypeAnnonceIdGetRequest, options?: RawAxiosRequestConfig) {
        return SousTypesAnnonceApiFp(this.configuration).apiSousTypeAnnonceIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StatutApi - axios parameter creator
 * @export
 */
export const StatutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des statuts
         * @summary Obtenir la liste des statuts
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [type] 
         * @param {Array<number>} [type2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsGetCollection: async (page?: number, itemsPerPage?: number, type?: number, type2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/statuts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (type2) {
                localVarQueryParameter['type[]'] = type2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un statut
         * @summary Supprimer un statut
         * @param {string} id FproDosStatut identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStatutsIdDelete', 'id', id)
            const localVarPath = `/formalite/statuts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un statut
         * @summary Récupérer les informations d\'un statut
         * @param {string} id FproDosStatut identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStatutsIdGet', 'id', id)
            const localVarPath = `/formalite/statuts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Statut resource.
         * @summary Modifier un statut
         * @param {string} id FproDosStatut identifier
         * @param {StatutJsonldStatutUpdate} statutJsonldStatutUpdate The updated Statut resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsIdPut: async (id: string, statutJsonldStatutUpdate: StatutJsonldStatutUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStatutsIdPut', 'id', id)
            // verify required parameter 'statutJsonldStatutUpdate' is not null or undefined
            assertParamExists('apiStatutsIdPut', 'statutJsonldStatutUpdate', statutJsonldStatutUpdate)
            const localVarPath = `/formalite/statuts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statutJsonldStatutUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Statut resource.
         * @summary Créer un statut
         * @param {StatutJsonldStatutCreate} statutJsonldStatutCreate The new Statut resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsPost: async (statutJsonldStatutCreate: StatutJsonldStatutCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'statutJsonldStatutCreate' is not null or undefined
            assertParamExists('apiStatutsPost', 'statutJsonldStatutCreate', statutJsonldStatutCreate)
            const localVarPath = `/formalite/statuts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statutJsonldStatutCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatutApi - functional programming interface
 * @export
 */
export const StatutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatutApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des statuts
         * @summary Obtenir la liste des statuts
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [type] 
         * @param {Array<number>} [type2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatutsGetCollection(page?: number, itemsPerPage?: number, type?: number, type2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiStatutsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatutsGetCollection(page, itemsPerPage, type, type2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatutApi.apiStatutsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer un statut
         * @summary Supprimer un statut
         * @param {string} id FproDosStatut identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatutsIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatutsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatutApi.apiStatutsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un statut
         * @summary Récupérer les informations d\'un statut
         * @param {string} id FproDosStatut identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatutsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatutJsonldStatutRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatutsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatutApi.apiStatutsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Statut resource.
         * @summary Modifier un statut
         * @param {string} id FproDosStatut identifier
         * @param {StatutJsonldStatutUpdate} statutJsonldStatutUpdate The updated Statut resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatutsIdPut(id: string, statutJsonldStatutUpdate: StatutJsonldStatutUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatutJsonldStatutRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatutsIdPut(id, statutJsonldStatutUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatutApi.apiStatutsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Statut resource.
         * @summary Créer un statut
         * @param {StatutJsonldStatutCreate} statutJsonldStatutCreate The new Statut resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatutsPost(statutJsonldStatutCreate: StatutJsonldStatutCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatutJsonldStatutCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatutsPost(statutJsonldStatutCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatutApi.apiStatutsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StatutApi - factory interface
 * @export
 */
export const StatutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatutApiFp(configuration)
    return {
        /**
         * Obtenir la liste des statuts
         * @summary Obtenir la liste des statuts
         * @param {StatutApiApiStatutsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsGetCollection(requestParameters: StatutApiApiStatutsGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiStatutsGetCollection200Response> {
            return localVarFp.apiStatutsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.type, requestParameters.type2, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un statut
         * @summary Supprimer un statut
         * @param {StatutApiApiStatutsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsIdDelete(requestParameters: StatutApiApiStatutsIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiStatutsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un statut
         * @summary Récupérer les informations d\'un statut
         * @param {StatutApiApiStatutsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsIdGet(requestParameters: StatutApiApiStatutsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatutJsonldStatutRead> {
            return localVarFp.apiStatutsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Statut resource.
         * @summary Modifier un statut
         * @param {StatutApiApiStatutsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsIdPut(requestParameters: StatutApiApiStatutsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatutJsonldStatutRead> {
            return localVarFp.apiStatutsIdPut(requestParameters.id, requestParameters.statutJsonldStatutUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Statut resource.
         * @summary Créer un statut
         * @param {StatutApiApiStatutsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsPost(requestParameters: StatutApiApiStatutsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatutJsonldStatutCreated> {
            return localVarFp.apiStatutsPost(requestParameters.statutJsonldStatutCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiStatutsGetCollection operation in StatutApi.
 * @export
 * @interface StatutApiApiStatutsGetCollectionRequest
 */
export interface StatutApiApiStatutsGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof StatutApiApiStatutsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof StatutApiApiStatutsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof StatutApiApiStatutsGetCollection
     */
    readonly type?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof StatutApiApiStatutsGetCollection
     */
    readonly type2?: Array<number>
}

/**
 * Request parameters for apiStatutsIdDelete operation in StatutApi.
 * @export
 * @interface StatutApiApiStatutsIdDeleteRequest
 */
export interface StatutApiApiStatutsIdDeleteRequest {
    /**
     * FproDosStatut identifier
     * @type {string}
     * @memberof StatutApiApiStatutsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiStatutsIdGet operation in StatutApi.
 * @export
 * @interface StatutApiApiStatutsIdGetRequest
 */
export interface StatutApiApiStatutsIdGetRequest {
    /**
     * FproDosStatut identifier
     * @type {string}
     * @memberof StatutApiApiStatutsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiStatutsIdPut operation in StatutApi.
 * @export
 * @interface StatutApiApiStatutsIdPutRequest
 */
export interface StatutApiApiStatutsIdPutRequest {
    /**
     * FproDosStatut identifier
     * @type {string}
     * @memberof StatutApiApiStatutsIdPut
     */
    readonly id: string

    /**
     * The updated Statut resource
     * @type {StatutJsonldStatutUpdate}
     * @memberof StatutApiApiStatutsIdPut
     */
    readonly statutJsonldStatutUpdate: StatutJsonldStatutUpdate
}

/**
 * Request parameters for apiStatutsPost operation in StatutApi.
 * @export
 * @interface StatutApiApiStatutsPostRequest
 */
export interface StatutApiApiStatutsPostRequest {
    /**
     * The new Statut resource
     * @type {StatutJsonldStatutCreate}
     * @memberof StatutApiApiStatutsPost
     */
    readonly statutJsonldStatutCreate: StatutJsonldStatutCreate
}

/**
 * StatutApi - object-oriented interface
 * @export
 * @class StatutApi
 * @extends {BaseAPI}
 */
export class StatutApi extends BaseAPI {
    /**
     * Obtenir la liste des statuts
     * @summary Obtenir la liste des statuts
     * @param {StatutApiApiStatutsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatutApi
     */
    public apiStatutsGetCollection(requestParameters: StatutApiApiStatutsGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return StatutApiFp(this.configuration).apiStatutsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.type, requestParameters.type2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un statut
     * @summary Supprimer un statut
     * @param {StatutApiApiStatutsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatutApi
     */
    public apiStatutsIdDelete(requestParameters: StatutApiApiStatutsIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return StatutApiFp(this.configuration).apiStatutsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un statut
     * @summary Récupérer les informations d\'un statut
     * @param {StatutApiApiStatutsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatutApi
     */
    public apiStatutsIdGet(requestParameters: StatutApiApiStatutsIdGetRequest, options?: RawAxiosRequestConfig) {
        return StatutApiFp(this.configuration).apiStatutsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Statut resource.
     * @summary Modifier un statut
     * @param {StatutApiApiStatutsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatutApi
     */
    public apiStatutsIdPut(requestParameters: StatutApiApiStatutsIdPutRequest, options?: RawAxiosRequestConfig) {
        return StatutApiFp(this.configuration).apiStatutsIdPut(requestParameters.id, requestParameters.statutJsonldStatutUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Statut resource.
     * @summary Créer un statut
     * @param {StatutApiApiStatutsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatutApi
     */
    public apiStatutsPost(requestParameters: StatutApiApiStatutsPostRequest, options?: RawAxiosRequestConfig) {
        return StatutApiFp(this.configuration).apiStatutsPost(requestParameters.statutJsonldStatutCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TapesDeTraitementDesDossiersApi - axios parameter creator
 * @export
 */
export const TapesDeTraitementDesDossiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les étapes de traitement des dossiers
         * @summary Lister les étapes de traitement des dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEtapesDossiersGetCollection: async (page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/etapes-dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TapesDeTraitementDesDossiersApi - functional programming interface
 * @export
 */
export const TapesDeTraitementDesDossiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TapesDeTraitementDesDossiersApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les étapes de traitement des dossiers
         * @summary Lister les étapes de traitement des dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEtapesDossiersGetCollection(page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiEtapesDossiersGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEtapesDossiersGetCollection(page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TapesDeTraitementDesDossiersApi.apiEtapesDossiersGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TapesDeTraitementDesDossiersApi - factory interface
 * @export
 */
export const TapesDeTraitementDesDossiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TapesDeTraitementDesDossiersApiFp(configuration)
    return {
        /**
         * Lister les étapes de traitement des dossiers
         * @summary Lister les étapes de traitement des dossiers
         * @param {TapesDeTraitementDesDossiersApiApiEtapesDossiersGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEtapesDossiersGetCollection(requestParameters: TapesDeTraitementDesDossiersApiApiEtapesDossiersGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiEtapesDossiersGetCollection200Response> {
            return localVarFp.apiEtapesDossiersGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiEtapesDossiersGetCollection operation in TapesDeTraitementDesDossiersApi.
 * @export
 * @interface TapesDeTraitementDesDossiersApiApiEtapesDossiersGetCollectionRequest
 */
export interface TapesDeTraitementDesDossiersApiApiEtapesDossiersGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof TapesDeTraitementDesDossiersApiApiEtapesDossiersGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TapesDeTraitementDesDossiersApiApiEtapesDossiersGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * TapesDeTraitementDesDossiersApi - object-oriented interface
 * @export
 * @class TapesDeTraitementDesDossiersApi
 * @extends {BaseAPI}
 */
export class TapesDeTraitementDesDossiersApi extends BaseAPI {
    /**
     * Lister les étapes de traitement des dossiers
     * @summary Lister les étapes de traitement des dossiers
     * @param {TapesDeTraitementDesDossiersApiApiEtapesDossiersGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TapesDeTraitementDesDossiersApi
     */
    public apiEtapesDossiersGetCollection(requestParameters: TapesDeTraitementDesDossiersApiApiEtapesDossiersGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return TapesDeTraitementDesDossiersApiFp(this.configuration).apiEtapesDossiersGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TiersApi - axios parameter creator
 * @export
 */
export const TiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Tiers resources.
         * @summary supprimer les fichiers des dossiers d\'un prescripteur
         * @param {number} idPrescripteur Identifiant du prescripteur
         * @param {number} idSociete Société du prescripteur
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection: async (idPrescripteur: number, idSociete: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPrescripteur' is not null or undefined
            assertParamExists('apiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection', 'idPrescripteur', idPrescripteur)
            // verify required parameter 'idSociete' is not null or undefined
            assertParamExists('apiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection', 'idSociete', idSociete)
            const localVarPath = `/formalite/prescripteur/{idPrescripteur}/societe/{idSociete}/purge-fichiers`
                .replace(`{${"idPrescripteur"}}`, encodeURIComponent(String(idPrescripteur)))
                .replace(`{${"idSociete"}}`, encodeURIComponent(String(idSociete)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TiersApi - functional programming interface
 * @export
 */
export const TiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TiersApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of Tiers resources.
         * @summary supprimer les fichiers des dossiers d\'un prescripteur
         * @param {number} idPrescripteur Identifiant du prescripteur
         * @param {number} idSociete Société du prescripteur
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection(idPrescripteur: number, idSociete: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection(idPrescripteur, idSociete, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TiersApi.apiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TiersApi - factory interface
 * @export
 */
export const TiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TiersApiFp(configuration)
    return {
        /**
         * Retrieves the collection of Tiers resources.
         * @summary supprimer les fichiers des dossiers d\'un prescripteur
         * @param {TiersApiApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection(requestParameters: TiersApiApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection200Response> {
            return localVarFp.apiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection(requestParameters.idPrescripteur, requestParameters.idSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection operation in TiersApi.
 * @export
 * @interface TiersApiApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollectionRequest
 */
export interface TiersApiApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollectionRequest {
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof TiersApiApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection
     */
    readonly idPrescripteur: number

    /**
     * Société du prescripteur
     * @type {number}
     * @memberof TiersApiApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection
     */
    readonly idSociete: number

    /**
     * The collection page number
     * @type {number}
     * @memberof TiersApiApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TiersApiApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * TiersApi - object-oriented interface
 * @export
 * @class TiersApi
 * @extends {BaseAPI}
 */
export class TiersApi extends BaseAPI {
    /**
     * Retrieves the collection of Tiers resources.
     * @summary supprimer les fichiers des dossiers d\'un prescripteur
     * @param {TiersApiApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiersApi
     */
    public apiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection(requestParameters: TiersApiApiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return TiersApiFp(this.configuration).apiPrescripteurIdPrescripteurSocieteIdSocietePurgeFichiersGetCollection(requestParameters.idPrescripteur, requestParameters.idSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TypeDeDocumentApi - axios parameter creator
 * @export
 */
export const TypeDeDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des types de document
         * @summary Obtenir la liste des types de document
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [type] 
         * @param {Array<number>} [type2] 
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsGetCollection: async (page?: number, itemsPerPage?: number, type?: number, type2?: Array<number>, domaineId?: number, domaineId2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/types-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (type2) {
                localVarQueryParameter['type[]'] = type2;
            }

            if (domaineId !== undefined) {
                localVarQueryParameter['domaine.id'] = domaineId;
            }

            if (domaineId2) {
                localVarQueryParameter['domaine.id[]'] = domaineId2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une relation type de document
         * @summary Supprimer une relation type de document
         * @param {string} id TblTypeDocument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocumentsIdDelete', 'id', id)
            const localVarPath = `/formalite/types-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une relation type de document
         * @summary Récupérer les informations d\'une relation type de document
         * @param {string} id TblTypeDocument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocumentsIdGet', 'id', id)
            const localVarPath = `/formalite/types-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Type de document resource.
         * @summary Modifier un type de document
         * @param {string} id TblTypeDocument identifier
         * @param {TypeDeDocumentJsonldTypedocumentUpdate} typeDeDocumentJsonldTypedocumentUpdate The updated Type de document resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdPut: async (id: string, typeDeDocumentJsonldTypedocumentUpdate: TypeDeDocumentJsonldTypedocumentUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocumentsIdPut', 'id', id)
            // verify required parameter 'typeDeDocumentJsonldTypedocumentUpdate' is not null or undefined
            assertParamExists('apiTypesDocumentsIdPut', 'typeDeDocumentJsonldTypedocumentUpdate', typeDeDocumentJsonldTypedocumentUpdate)
            const localVarPath = `/formalite/types-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeDeDocumentJsonldTypedocumentUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Type de document resource.
         * @summary Ajouter un type de document
         * @param {TypeDeDocumentJsonldTypedocumentCreate} typeDeDocumentJsonldTypedocumentCreate The new Type de document resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsPost: async (typeDeDocumentJsonldTypedocumentCreate: TypeDeDocumentJsonldTypedocumentCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeDeDocumentJsonldTypedocumentCreate' is not null or undefined
            assertParamExists('apiTypesDocumentsPost', 'typeDeDocumentJsonldTypedocumentCreate', typeDeDocumentJsonldTypedocumentCreate)
            const localVarPath = `/formalite/types-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeDeDocumentJsonldTypedocumentCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TypeDeDocumentApi - functional programming interface
 * @export
 */
export const TypeDeDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TypeDeDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des types de document
         * @summary Obtenir la liste des types de document
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [type] 
         * @param {Array<number>} [type2] 
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsGetCollection(page?: number, itemsPerPage?: number, type?: number, type2?: Array<number>, domaineId?: number, domaineId2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTypesDocumentsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsGetCollection(page, itemsPerPage, type, type2, domaineId, domaineId2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentApi.apiTypesDocumentsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer une relation type de document
         * @summary Supprimer une relation type de document
         * @param {string} id TblTypeDocument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentApi.apiTypesDocumentsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une relation type de document
         * @summary Récupérer les informations d\'une relation type de document
         * @param {string} id TblTypeDocument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentJsonldTypedocumentRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentApi.apiTypesDocumentsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Type de document resource.
         * @summary Modifier un type de document
         * @param {string} id TblTypeDocument identifier
         * @param {TypeDeDocumentJsonldTypedocumentUpdate} typeDeDocumentJsonldTypedocumentUpdate The updated Type de document resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsIdPut(id: string, typeDeDocumentJsonldTypedocumentUpdate: TypeDeDocumentJsonldTypedocumentUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentJsonldTypedocumentRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsIdPut(id, typeDeDocumentJsonldTypedocumentUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentApi.apiTypesDocumentsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Type de document resource.
         * @summary Ajouter un type de document
         * @param {TypeDeDocumentJsonldTypedocumentCreate} typeDeDocumentJsonldTypedocumentCreate The new Type de document resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsPost(typeDeDocumentJsonldTypedocumentCreate: TypeDeDocumentJsonldTypedocumentCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentJsonldTypedocumentCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsPost(typeDeDocumentJsonldTypedocumentCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentApi.apiTypesDocumentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TypeDeDocumentApi - factory interface
 * @export
 */
export const TypeDeDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TypeDeDocumentApiFp(configuration)
    return {
        /**
         * Obtenir la liste des types de document
         * @summary Obtenir la liste des types de document
         * @param {TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsGetCollection(requestParameters: TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiTypesDocumentsGetCollection200Response> {
            return localVarFp.apiTypesDocumentsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.type, requestParameters.type2, requestParameters.domaineId, requestParameters.domaineId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une relation type de document
         * @summary Supprimer une relation type de document
         * @param {TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdDelete(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiTypesDocumentsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une relation type de document
         * @summary Récupérer les informations d\'une relation type de document
         * @param {TypeDeDocumentApiApiTypesDocumentsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdGet(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TypeDeDocumentJsonldTypedocumentRead> {
            return localVarFp.apiTypesDocumentsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Type de document resource.
         * @summary Modifier un type de document
         * @param {TypeDeDocumentApiApiTypesDocumentsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdPut(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TypeDeDocumentJsonldTypedocumentRead> {
            return localVarFp.apiTypesDocumentsIdPut(requestParameters.id, requestParameters.typeDeDocumentJsonldTypedocumentUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Type de document resource.
         * @summary Ajouter un type de document
         * @param {TypeDeDocumentApiApiTypesDocumentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsPost(requestParameters: TypeDeDocumentApiApiTypesDocumentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TypeDeDocumentJsonldTypedocumentCreated> {
            return localVarFp.apiTypesDocumentsPost(requestParameters.typeDeDocumentJsonldTypedocumentCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiTypesDocumentsGetCollection operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly type?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly type2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly domaineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly domaineId2?: Array<number>
}

/**
 * Request parameters for apiTypesDocumentsIdDelete operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest {
    /**
     * TblTypeDocument identifier
     * @type {string}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiTypesDocumentsIdGet operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsIdGetRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsIdGetRequest {
    /**
     * TblTypeDocument identifier
     * @type {string}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiTypesDocumentsIdPut operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsIdPutRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsIdPutRequest {
    /**
     * TblTypeDocument identifier
     * @type {string}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdPut
     */
    readonly id: string

    /**
     * The updated Type de document resource
     * @type {TypeDeDocumentJsonldTypedocumentUpdate}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdPut
     */
    readonly typeDeDocumentJsonldTypedocumentUpdate: TypeDeDocumentJsonldTypedocumentUpdate
}

/**
 * Request parameters for apiTypesDocumentsPost operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsPostRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsPostRequest {
    /**
     * The new Type de document resource
     * @type {TypeDeDocumentJsonldTypedocumentCreate}
     * @memberof TypeDeDocumentApiApiTypesDocumentsPost
     */
    readonly typeDeDocumentJsonldTypedocumentCreate: TypeDeDocumentJsonldTypedocumentCreate
}

/**
 * TypeDeDocumentApi - object-oriented interface
 * @export
 * @class TypeDeDocumentApi
 * @extends {BaseAPI}
 */
export class TypeDeDocumentApi extends BaseAPI {
    /**
     * Obtenir la liste des types de document
     * @summary Obtenir la liste des types de document
     * @param {TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsGetCollection(requestParameters: TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.type, requestParameters.type2, requestParameters.domaineId, requestParameters.domaineId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une relation type de document
     * @summary Supprimer une relation type de document
     * @param {TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsIdDelete(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une relation type de document
     * @summary Récupérer les informations d\'une relation type de document
     * @param {TypeDeDocumentApiApiTypesDocumentsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsIdGet(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdGetRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Type de document resource.
     * @summary Modifier un type de document
     * @param {TypeDeDocumentApiApiTypesDocumentsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsIdPut(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdPutRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsIdPut(requestParameters.id, requestParameters.typeDeDocumentJsonldTypedocumentUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Type de document resource.
     * @summary Ajouter un type de document
     * @param {TypeDeDocumentApiApiTypesDocumentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsPost(requestParameters: TypeDeDocumentApiApiTypesDocumentsPostRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsPost(requestParameters.typeDeDocumentJsonldTypedocumentCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TypeDeDocumentDesDossiersApi - axios parameter creator
 * @export
 */
export const TypeDeDocumentDesDossiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des types de document liés au(x) dossier(s)
         * @summary Obtenir la liste des types de document liés au(x) dossier(s)
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {Array<string>} [dossierNumero2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierTypesDocDossierGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, dossierNumero?: string, dossierNumero2?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierTypesDocDossierGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/types-doc-dossier`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (dossierNumero !== undefined) {
                localVarQueryParameter['dossier.numero'] = dossierNumero;
            }

            if (dossierNumero2) {
                localVarQueryParameter['dossier.numero[]'] = dossierNumero2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une relation type de document dossier
         * @summary Supprimer une relation type de document dossier
         * @param {string} id FproDossierDoc identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocDossierIdDelete', 'id', id)
            const localVarPath = `/formalite/types-doc-dossier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une relation type de document dossier
         * @summary Récupérer les informations d\'une relation type de document dossier
         * @param {string} id FproDossierDoc identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocDossierIdGet', 'id', id)
            const localVarPath = `/formalite/types-doc-dossier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Type de document des dossiers resource.
         * @summary Updates the Type de document des dossiers resource.
         * @param {string} id FproDossierDoc identifier
         * @param {TypeDeDocumentDesDossiersTypedocumentPatch} typeDeDocumentDesDossiersTypedocumentPatch The updated Type de document des dossiers resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdPatch: async (id: string, typeDeDocumentDesDossiersTypedocumentPatch: TypeDeDocumentDesDossiersTypedocumentPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocDossierIdPatch', 'id', id)
            // verify required parameter 'typeDeDocumentDesDossiersTypedocumentPatch' is not null or undefined
            assertParamExists('apiTypesDocDossierIdPatch', 'typeDeDocumentDesDossiersTypedocumentPatch', typeDeDocumentDesDossiersTypedocumentPatch)
            const localVarPath = `/formalite/types-doc-dossier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeDeDocumentDesDossiersTypedocumentPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Type de document des dossiers resource.
         * @summary Modifier une relation type de document dossier
         * @param {string} id FproDossierDoc identifier
         * @param {TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate} typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate The updated Type de document des dossiers resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdPut: async (id: string, typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate: TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocDossierIdPut', 'id', id)
            // verify required parameter 'typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate' is not null or undefined
            assertParamExists('apiTypesDocDossierIdPut', 'typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate', typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate)
            const localVarPath = `/formalite/types-doc-dossier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Type de document des dossiers resource.
         * @summary Créer une relation type de document dossier
         * @param {TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate} typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate The new Type de document des dossiers resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierPost: async (typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate: TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate' is not null or undefined
            assertParamExists('apiTypesDocDossierPost', 'typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate', typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate)
            const localVarPath = `/formalite/types-doc-dossier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TypeDeDocumentDesDossiersApi - functional programming interface
 * @export
 */
export const TypeDeDocumentDesDossiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TypeDeDocumentDesDossiersApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des types de document liés au(x) dossier(s)
         * @summary Obtenir la liste des types de document liés au(x) dossier(s)
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {Array<string>} [dossierNumero2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierTypesDocDossierGetCollection(dossier: string, page?: number, itemsPerPage?: number, dossierNumero?: string, dossierNumero2?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierTypesDocDossierGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierTypesDocDossierGetCollection(dossier, page, itemsPerPage, dossierNumero, dossierNumero2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentDesDossiersApi.apiDossiersDossierTypesDocDossierGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer une relation type de document dossier
         * @summary Supprimer une relation type de document dossier
         * @param {string} id FproDossierDoc identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocDossierIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocDossierIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentDesDossiersApi.apiTypesDocDossierIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une relation type de document dossier
         * @summary Récupérer les informations d\'une relation type de document dossier
         * @param {string} id FproDossierDoc identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocDossierIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocDossierIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentDesDossiersApi.apiTypesDocDossierIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the Type de document des dossiers resource.
         * @summary Updates the Type de document des dossiers resource.
         * @param {string} id FproDossierDoc identifier
         * @param {TypeDeDocumentDesDossiersTypedocumentPatch} typeDeDocumentDesDossiersTypedocumentPatch The updated Type de document des dossiers resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocDossierIdPatch(id: string, typeDeDocumentDesDossiersTypedocumentPatch: TypeDeDocumentDesDossiersTypedocumentPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocDossierIdPatch(id, typeDeDocumentDesDossiersTypedocumentPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentDesDossiersApi.apiTypesDocDossierIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Type de document des dossiers resource.
         * @summary Modifier une relation type de document dossier
         * @param {string} id FproDossierDoc identifier
         * @param {TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate} typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate The updated Type de document des dossiers resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocDossierIdPut(id: string, typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate: TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocDossierIdPut(id, typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentDesDossiersApi.apiTypesDocDossierIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Type de document des dossiers resource.
         * @summary Créer une relation type de document dossier
         * @param {TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate} typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate The new Type de document des dossiers resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocDossierPost(typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate: TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocDossierPost(typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentDesDossiersApi.apiTypesDocDossierPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TypeDeDocumentDesDossiersApi - factory interface
 * @export
 */
export const TypeDeDocumentDesDossiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TypeDeDocumentDesDossiersApiFp(configuration)
    return {
        /**
         * Obtenir la liste des types de document liés au(x) dossier(s)
         * @summary Obtenir la liste des types de document liés au(x) dossier(s)
         * @param {TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierTypesDocDossierGetCollection(requestParameters: TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersDossierTypesDocDossierGetCollection200Response> {
            return localVarFp.apiDossiersDossierTypesDocDossierGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.dossierNumero2, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une relation type de document dossier
         * @summary Supprimer une relation type de document dossier
         * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdDelete(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiTypesDocDossierIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une relation type de document dossier
         * @summary Récupérer les informations d\'une relation type de document dossier
         * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdGet(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead> {
            return localVarFp.apiTypesDocDossierIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Type de document des dossiers resource.
         * @summary Updates the Type de document des dossiers resource.
         * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdPatch(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead> {
            return localVarFp.apiTypesDocDossierIdPatch(requestParameters.id, requestParameters.typeDeDocumentDesDossiersTypedocumentPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Type de document des dossiers resource.
         * @summary Modifier une relation type de document dossier
         * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdPut(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead> {
            return localVarFp.apiTypesDocDossierIdPut(requestParameters.id, requestParameters.typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Type de document des dossiers resource.
         * @summary Créer une relation type de document dossier
         * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierPost(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated> {
            return localVarFp.apiTypesDocDossierPost(requestParameters.typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierTypesDocDossierGetCollection operation in TypeDeDocumentDesDossiersApi.
 * @export
 * @interface TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollectionRequest
 */
export interface TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollection
     */
    readonly dossierNumero?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollection
     */
    readonly dossierNumero2?: Array<string>
}

/**
 * Request parameters for apiTypesDocDossierIdDelete operation in TypeDeDocumentDesDossiersApi.
 * @export
 * @interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDeleteRequest
 */
export interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDeleteRequest {
    /**
     * FproDossierDoc identifier
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiTypesDocDossierIdGet operation in TypeDeDocumentDesDossiersApi.
 * @export
 * @interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGetRequest
 */
export interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGetRequest {
    /**
     * FproDossierDoc identifier
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiTypesDocDossierIdPatch operation in TypeDeDocumentDesDossiersApi.
 * @export
 * @interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPatchRequest
 */
export interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPatchRequest {
    /**
     * FproDossierDoc identifier
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPatch
     */
    readonly id: string

    /**
     * The updated Type de document des dossiers resource
     * @type {TypeDeDocumentDesDossiersTypedocumentPatch}
     * @memberof TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPatch
     */
    readonly typeDeDocumentDesDossiersTypedocumentPatch: TypeDeDocumentDesDossiersTypedocumentPatch
}

/**
 * Request parameters for apiTypesDocDossierIdPut operation in TypeDeDocumentDesDossiersApi.
 * @export
 * @interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPutRequest
 */
export interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPutRequest {
    /**
     * FproDossierDoc identifier
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPut
     */
    readonly id: string

    /**
     * The updated Type de document des dossiers resource
     * @type {TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate}
     * @memberof TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPut
     */
    readonly typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate: TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
}

/**
 * Request parameters for apiTypesDocDossierPost operation in TypeDeDocumentDesDossiersApi.
 * @export
 * @interface TypeDeDocumentDesDossiersApiApiTypesDocDossierPostRequest
 */
export interface TypeDeDocumentDesDossiersApiApiTypesDocDossierPostRequest {
    /**
     * The new Type de document des dossiers resource
     * @type {TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate}
     * @memberof TypeDeDocumentDesDossiersApiApiTypesDocDossierPost
     */
    readonly typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate: TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
}

/**
 * TypeDeDocumentDesDossiersApi - object-oriented interface
 * @export
 * @class TypeDeDocumentDesDossiersApi
 * @extends {BaseAPI}
 */
export class TypeDeDocumentDesDossiersApi extends BaseAPI {
    /**
     * Obtenir la liste des types de document liés au(x) dossier(s)
     * @summary Obtenir la liste des types de document liés au(x) dossier(s)
     * @param {TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentDesDossiersApi
     */
    public apiDossiersDossierTypesDocDossierGetCollection(requestParameters: TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentDesDossiersApiFp(this.configuration).apiDossiersDossierTypesDocDossierGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.dossierNumero2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une relation type de document dossier
     * @summary Supprimer une relation type de document dossier
     * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentDesDossiersApi
     */
    public apiTypesDocDossierIdDelete(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentDesDossiersApiFp(this.configuration).apiTypesDocDossierIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une relation type de document dossier
     * @summary Récupérer les informations d\'une relation type de document dossier
     * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentDesDossiersApi
     */
    public apiTypesDocDossierIdGet(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGetRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentDesDossiersApiFp(this.configuration).apiTypesDocDossierIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Type de document des dossiers resource.
     * @summary Updates the Type de document des dossiers resource.
     * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentDesDossiersApi
     */
    public apiTypesDocDossierIdPatch(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPatchRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentDesDossiersApiFp(this.configuration).apiTypesDocDossierIdPatch(requestParameters.id, requestParameters.typeDeDocumentDesDossiersTypedocumentPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Type de document des dossiers resource.
     * @summary Modifier une relation type de document dossier
     * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentDesDossiersApi
     */
    public apiTypesDocDossierIdPut(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPutRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentDesDossiersApiFp(this.configuration).apiTypesDocDossierIdPut(requestParameters.id, requestParameters.typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Type de document des dossiers resource.
     * @summary Créer une relation type de document dossier
     * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentDesDossiersApi
     */
    public apiTypesDocDossierPost(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierPostRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentDesDossiersApiFp(this.configuration).apiTypesDocDossierPost(requestParameters.typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ValideursDesDossiersApi - axios parameter creator
 * @export
 */
export const ValideursDesDossiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les valideurs des dossiers
         * @summary Lister les valideurs des dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersValideursGetCollection: async (page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/dossiers-valideurs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un valideur de dossier
         * @summary Récupérer les informations d\'un valideur de dossier
         * @param {string} id FproDossierValidator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersValideursIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersValideursIdGet', 'id', id)
            const localVarPath = `/formalite/dossiers-valideurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ValideursDesDossiersApi - functional programming interface
 * @export
 */
export const ValideursDesDossiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ValideursDesDossiersApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les valideurs des dossiers
         * @summary Lister les valideurs des dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersValideursGetCollection(page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersValideursGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersValideursGetCollection(page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ValideursDesDossiersApi.apiDossiersValideursGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un valideur de dossier
         * @summary Récupérer les informations d\'un valideur de dossier
         * @param {string} id FproDossierValidator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersValideursIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValideursDesDossiersJsonldValidatorsDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersValideursIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ValideursDesDossiersApi.apiDossiersValideursIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ValideursDesDossiersApi - factory interface
 * @export
 */
export const ValideursDesDossiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ValideursDesDossiersApiFp(configuration)
    return {
        /**
         * Lister les valideurs des dossiers
         * @summary Lister les valideurs des dossiers
         * @param {ValideursDesDossiersApiApiDossiersValideursGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersValideursGetCollection(requestParameters: ValideursDesDossiersApiApiDossiersValideursGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiDossiersValideursGetCollection200Response> {
            return localVarFp.apiDossiersValideursGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un valideur de dossier
         * @summary Récupérer les informations d\'un valideur de dossier
         * @param {ValideursDesDossiersApiApiDossiersValideursIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersValideursIdGet(requestParameters: ValideursDesDossiersApiApiDossiersValideursIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ValideursDesDossiersJsonldValidatorsDossierRead> {
            return localVarFp.apiDossiersValideursIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersValideursGetCollection operation in ValideursDesDossiersApi.
 * @export
 * @interface ValideursDesDossiersApiApiDossiersValideursGetCollectionRequest
 */
export interface ValideursDesDossiersApiApiDossiersValideursGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof ValideursDesDossiersApiApiDossiersValideursGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ValideursDesDossiersApiApiDossiersValideursGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiDossiersValideursIdGet operation in ValideursDesDossiersApi.
 * @export
 * @interface ValideursDesDossiersApiApiDossiersValideursIdGetRequest
 */
export interface ValideursDesDossiersApiApiDossiersValideursIdGetRequest {
    /**
     * FproDossierValidator identifier
     * @type {string}
     * @memberof ValideursDesDossiersApiApiDossiersValideursIdGet
     */
    readonly id: string
}

/**
 * ValideursDesDossiersApi - object-oriented interface
 * @export
 * @class ValideursDesDossiersApi
 * @extends {BaseAPI}
 */
export class ValideursDesDossiersApi extends BaseAPI {
    /**
     * Lister les valideurs des dossiers
     * @summary Lister les valideurs des dossiers
     * @param {ValideursDesDossiersApiApiDossiersValideursGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValideursDesDossiersApi
     */
    public apiDossiersValideursGetCollection(requestParameters: ValideursDesDossiersApiApiDossiersValideursGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return ValideursDesDossiersApiFp(this.configuration).apiDossiersValideursGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un valideur de dossier
     * @summary Récupérer les informations d\'un valideur de dossier
     * @param {ValideursDesDossiersApiApiDossiersValideursIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValideursDesDossiersApi
     */
    public apiDossiersValideursIdGet(requestParameters: ValideursDesDossiersApiApiDossiersValideursIdGetRequest, options?: RawAxiosRequestConfig) {
        return ValideursDesDossiersApiFp(this.configuration).apiDossiersValideursIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



